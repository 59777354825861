import React from 'react';

export default (store: any) => ({
  path: 'bank_feeds_landing',
  enableHeader: true,
  enableFooter: true,
  getComponent(nextState: any, cb: any) {
    const BankFeedsLanding = React.lazy(
      () => import('./containers/BankFeedsLandingContainer'),
    );
    cb(null, BankFeedsLanding);
  },
});
