import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import warnUnsavedFormChanges from './WarnUnsavedFormChanges';

class LumiForm extends Component {
  render() {
    const { handleSubmit, children, className, warnUnsaved, onChange } =
      this.props;
    return (
      <form className={className} onChange={onChange} onSubmit={handleSubmit}>
        {children}
      </form>
    );
  }
}

LumiForm.propTypes = {
  handleSubmit: PropTypes.func,
  className: PropTypes.string,
  warnUnsaved: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const mapStateToProps = (state, props) => {
  const clientFormState = {};
  if (props.clientForm) clientFormState.getFormState = state => state.form_new;
  return {
    form: props.name,
    ...clientFormState,
    validate: props.validate,
    touchOnBlur: props.touchOnBlur,
  };
};

export default warnUnsavedFormChanges(
  compose(connect(mapStateToProps), reduxForm({}))(LumiForm),
);
