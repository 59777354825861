import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import LumiLogoHorizontal from '../../../common/images/logo/lumi-h.svg';

import * as styles from './DocumentCollectionLayout.scss';
import Breadcrumbs, { Crumb } from '../../components/Breadcrumbs';
import Info from '../../../common/images/info.svg';

import {
  DocumentCollectionProgress,
  determineRequiredPages,
  determineCompletedPages,
  Page,
  getNextPath,
  PAGE_FLOW,
  DocumentCollectionStages,
} from '../../routes/DocumentCollection/DocumentCollectionUtils';
import {
  useGraphQuery,
  isQueryLoading,
} from '../../../common/utils/graphql_utils';
import { getExperimentGroupQuery } from '../../../graphql/queries/queries';
import {
  getRemainingOutcomeTimeByPath,
  getCompletedTime,
  getTotalRemainingTime,
} from './DocumentCollectionExperimentConfig';
import Progress from '../../../junction/components/progress/Progress';
import Feedback from '../../../junction/components/feedback/Feedback';

const welcomeCrumb = { label: '👋', value: 'start' };
const thanksCrumb = { label: '👌', value: 'confirmation' };

export const pathIndex = 4;

const crumbs: Crumb[] = [
  {
    value: 'license',
    label: 'License Photo',
  },
  {
    value: 'banking_info',
    label: 'Bank Statements',
  },
  {
    value: 'loan_info',
    label: 'Loan Info',
  },
  {
    value: 'about',
    label: 'About Your Business',
  },
  {
    value: 'financials',
    label: 'Financial Statements',
  },
  {
    value: 'identity',
    label: 'Identity',
  },
  {
    value: 'other-documents',
    label: 'Other Documents',
  },
  {
    value: 'sofp-income',
    label: 'Income',
  },
  {
    value: 'sofp-assets',
    label: 'Assets',
  },
  {
    value: 'sofp-debt',
    label: 'Debts',
  },
];

type Props = {
  location?: { pathname: string };
  push?: (path: string) => void;
  params?: { id: string };
  documentProgress?: DocumentCollectionProgress;
  children?: any;
};

type MapDispatchToProps = {
  push?: (path: string) => void;
};

const mapDispatchToProps: MapDispatchToProps = {
  push,
};

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    documentProgress: progress.document_capture_progress,
  };
};

function DocumentCollectionLayout(props: Props) {
  const {
    documentProgress,
    location: { pathname },
    params: { id },
    push,
    children,
  } = props;
  const { account_id } = documentProgress || {};
  const splitPath = pathname.split('/');
  const path = splitPath[pathIndex];

  const [activeCrumb, setActiveCrumb] = useState(welcomeCrumb);
  const [disabledCrumbs, setDisabledCrumbs] = useState([]);
  const [generatedCrumbs, setGeneratedCrumbs] = useState([]);
  const [completedPages, setCompletedPages] = useState([]);

  const [extraInfoVisible, setExtraInfoVisible] = useState(false);
  const dcExperimentGroup = '';
  /*
        ================= BEGIN ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
        Get the experiment group the user belongs to
     */
  // const {
  //     data: experimentGroup,
  //     status: experimentGroupQueryStatus,
  // } = useGraphQuery(['experimentGroup', { userId: account_id, experimentName: 'ProgressIncentiveExperimentDocCapture' }], getExperimentGroupQuery);
  // const isRetrievingGroup = isQueryLoading(experimentGroupQueryStatus);
  // const dcExperimentGroup = experimentGroup?.data.experiment_group;

  // useEffect(() => {
  //     localStorage.setItem('dc_experiment_group', dcExperimentGroup);
  // }, [dcExperimentGroup]);
  /*
        ================= END ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
     */

  const handleCrumbClicked = useCallback((clickedCrumb: Crumb) => {
    const {
      push,
      params: { id },
    } = props;
    push(`/app/documents/${id}/${clickedCrumb.value}`);
    setActiveCrumb(clickedCrumb);
  }, []);

  useEffect(() => {
    if (!['error', 'thanks'].includes(path)) {
      updateCrumbState(documentProgress.stages, path);
    }
  }, [documentProgress, path]);

  const updateCrumbState = useCallback(
    (stages: DocumentCollectionStages, path: string) => {
      const pages = determineRequiredPages(stages);
      const completedPages = determineCompletedPages(pages, stages).map(
        page => page.path,
      );
      const generatedCrumbs = generateCrumbs(pages);
      const disabledCrumbs = getDisabledCrumbs(
        completedPages,
        generatedCrumbs,
        path,
      );

      setActiveCrumb(getActiveCrumbFromUrl());
      setGeneratedCrumbs(generatedCrumbs);
      setDisabledCrumbs(disabledCrumbs);
      setCompletedPages(completedPages);
    },
    [path, documentProgress],
  );

  const getActiveCrumbFromUrl = useCallback(() => {
    if (path === welcomeCrumb.value) return welcomeCrumb;
    if (path === thanksCrumb.value) return thanksCrumb;
    return crumbs.find(crumb => crumb.value === path) || welcomeCrumb;
  }, [path]);

  const generateCrumbs = useCallback((pages: Page[]) => {
    const pageCrumbs = pages.map(page => {
      return crumbs.find(crumb => crumb.value === page.path);
    });
    return [welcomeCrumb, ...pageCrumbs, thanksCrumb];
  }, []);

  const getDisabledCrumbs = useCallback(
    (completedPages: string[], crumbs: Crumb[], path: string): any => {
      const currentPageIndex = PAGE_FLOW.findIndex(page => {
        return page.path === path;
      });
      const disabledPages = [...PAGE_FLOW].splice(
        currentPageIndex + 1,
        PAGE_FLOW.length,
      );
      const disabledCrumbs = disabledPages.reduce(
        (arr: Crumb[], page: Page) => {
          const crumb = crumbs.find(crumb => crumb.value === page.path);
          if (crumb) arr.push(crumb);
          return arr;
        },
        [],
      );
      const filteredNotCompleted = disabledCrumbs.filter(
        crumb => !completedPages.includes(crumb.value),
      );

      if (!completedPages.length) return disabledCrumbs;
      return filteredNotCompleted;
    },
    [],
  );

  const renderCrumbs = !['error', 'thanks'].includes(path);
  let nextCrumb: any = {};

  if (renderCrumbs) {
    if (!documentProgress) return null;
    const nextPath = getNextPath(path, documentProgress.stages);
    nextCrumb = generatedCrumbs.find(crumb => crumb.value === nextPath);
  }

  /*
        ================= BEGIN ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
        Get the current step the user is at in the process for the progress bar
        Also calculate how much time is remaining based on how much the user has completed and
        how much time the user will shave by completing each requirement
     */
  let currentStep = 0;
  const steps = generatedCrumbs
    .filter(crumb => !['start', 'confirmation'].includes(crumb.value))
    .map((crumb, i) => {
      const timeShavedByPath = getRemainingOutcomeTimeByPath(
        crumb.value,
        documentProgress?.stages || {},
      );
      if (timeShavedByPath === 0) {
        currentStep = i + 1;
      }
      return {
        step: i,
        label: `-${timeShavedByPath} Hours`,
      };
    });

  const completedTime =
    2 +
    getTotalRemainingTime(documentProgress?.stages || {}) -
    getCompletedTime(documentProgress?.stages || {});
  /*
        ================= END ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
     */

  return (
    <div className={styles.documentCollectionContainer}>
      <div className={styles.documentCollectionHeader}>
        <div className='lm-flex lm-flex-col lm-w-full lm-mb-4 lm-items-start'>
          <LumiLogoHorizontal className='lm-my-4' style={{ height: '55px' }} />
          <div
            className='lm-flex lm-justify-center lm-w-full lm-mb-4'
            style={{ height: '50px' }}
          >
            {renderCrumbs && (
              <Breadcrumbs
                crumbs={generatedCrumbs}
                activeCrumb={activeCrumb}
                completedCrumbs={completedPages}
                disabledCrumbs={disabledCrumbs}
                nextCrumb={nextCrumb}
                onClick={handleCrumbClicked}
                experimentGroup={dcExperimentGroup}
                stages={documentProgress?.stages || {}}
              />
            )}
          </div>
          {
            /*
                            ================= BEGIN ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
                        */
            // dcExperimentGroup === 'variant' &&
            // <React.Fragment>
            // <div className={styles.stepProgress}><Progress type='step' steps={steps} currentStep={currentStep} /></div>
            // <div className='lm-flex lm-items-center lm-mt-4 lm-justify-center lm-w-full lm-flex-col'>
            //     <div className='lm-flex lm-items-center lm-w-full lm-justify-center'>
            //         <span className='lm-text-center lm-inline-block lm-font-medium lm-text-base-blue lm-text-lg lm-mr-2'>Estimated Outcome Time is {completedTime} Hours</span>
            //         <Info className='lm-fill-current lm-cursor-pointer' onClick={() => setExtraInfoVisible(!extraInfoVisible)} style={{ width: '24px', height: '100%' }} />
            //     </div>
            //     {
            //         extraInfoVisible &&
            //         <Feedback className='lm-my-2 lm-max-w-5xl' type='lumi'>
            //             Your individual estimated application time is calculated by the amount of information you provide us with.
            //             Essentially, the more info you give us, the quicker our credit team can assess your application and provide you with an outcome. Our business hours run from Monday - Friday, 8:00am - 7:00pm.
            //         </Feedback>
            //     }
            // </div>
            // </React.Fragment>
            /*
                            ================= END ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
                        */
          }
        </div>
      </div>
      {children}
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentCollectionLayout);
