/**
 *  Name: DashboardIndex
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout';

import DashboardRoute from './Dashboard';

export default store => ({
  path: 'app',
  component: DashboardLayout,
  indexRoute: {
    onEnter: (nextState, replace) => replace('/app/login'),
  },
  childRoutes: [DashboardRoute(store)],
});
