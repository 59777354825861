import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Base from '../../../../components/base';
import ConnectBankAccount from '../../../../../common/components/ConnectBankAccount';
import spinner from '../../../../../common/sass/components/_spinner.scss';
import styles from '../../../../../common/sass/components/_bankfeeds.scss';

import * as analyticsUtils from '../../../../utils/analytics';
import { getUrl } from '../../../../../common/utils/utils';
import BankFeedsHelpTooltip from '../../../../../common/components/BankFeedsHelpTooltip';

class BankFeedsLanding extends Base {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const token = this.props.location.query.token;
    const exportSelection = this.props.location.query.request_export_selection;
    if (token) {
      this.setState({ reference: token, exportSelection });
    }
  }

  componentDidUpdate(prevProps) {
    const { account } = this.props;
    const prevAccount = prevProps.account || {};
    if (account && account.id !== prevAccount.id) {
      analyticsUtils.identify(
        account.id,
        ['email', 'first_name', 'role'],
        account,
      );
    }
  }

  render() {
    const { reference } = this.state;

    // ensure that the export selection is boolean
    let exportSelection = this.state.exportSelection;
    if (typeof exportSelection !== 'boolean') {
      exportSelection = exportSelection === 'true';
    }

    let body = (
      <div className={[spinner.spinner, spinner.pageSpinner].join(' ')} />
    );
    const redirectUrl = getUrl('/app/bank_feeds_thanks');
    if (reference) {
      body = (
        <ConnectBankAccount
          referenceId={this.state.reference}
          redirectUrl={redirectUrl}
          requestExportSelection={exportSelection}
        />
      );
    }
    return (
      <div
        className={[styles.landingWrapper, styles.bankfeedsContainer].join(' ')}
      >
        <h2 className='text-center'>Verify Your Bank Account</h2>
        <div className='lm-flex lm-gap-2 lm-justify-center lm-items-center lm-mt-3 lm-mb-10'>
          Connect securely to your business bank account.
          <BankFeedsHelpTooltip />
        </div>
        {body}
      </div>
    );
  }
}

BankFeedsLanding.propTypes = {
  location: PropTypes.object.isRequired,
  account: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    account: state.banking.account,
  };
}

export default connect(mapStateToProps)(BankFeedsLanding);
