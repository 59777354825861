import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buttonStyle from '../sass/components/_button.scss';
import Progress from './ProgressBar';
import icons from '../sass/icons/lumi-icon.scss';
import style from '../sass/components/_upload_button.scss';
import { Field } from 'redux-form';

const fileUploadButton = prop => {
  return fieldData => {
    const { isUploading, progress, disabled } = prop;
    const meta = fieldData.meta || fieldData;
    const input = fieldData.input || fieldData;

    const update = (value, override) => {
      const { onChange } = prop;
      if (value || override) {
        input.onChange(value);
        if (onChange) {
          onChange(value);
        }
      }
    };

    const clear = () => update('', true);

    const onChange = val => update(val);

    let error;
    let body;
    if (!isUploading && meta.touched && meta.error) {
      error = (
        <div className='error-text mt-4 ml-1'>
          <i className={icons['icon-exclaimation-triangle']} />
          {meta.error}
        </div>
      );
    }
    if (input.value && input.value.length) {
      body = (
        <span className={style.fileName}>
          <label>{input.value[0].name}</label>
          <i
            className={[icons['icon-close'], 'lg-icon'].join(' ')}
            onClick={clear}
          />
        </span>
      );
    } else if (!isUploading) {
      body = (
        <span className={disabled ? style.disabled : ''}>
          <label
            className={[buttonStyle.button, buttonStyle.upload].join(' ')}
            htmlFor={`${input.name}-field`}
          >
            Upload file
            <i
              className={[icons['icon-upload'], icons['icon-margin']].join(' ')}
            />
          </label>
          <input
            name={input.name}
            style={{ display: 'none' }}
            id={`${input.name}-field`}
            onChange={onChange}
            type='file'
          />
        </span>
      );
    }

    return (
      <div>
        {body}
        {isUploading && <Progress value={progress} />}
        {error}
      </div>
    );
  };
};
class UploadButton extends Component {
  componentWillMount() {
    this.fieldRenderer = fileUploadButton(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fieldRenderer = fileUploadButton(nextProps);
  }

  render() {
    const { name, fieldData } = this.props;
    // Support name and fieldData for different versions of redux form
    if (fieldData) {
      return this.fieldRenderer(fieldData);
    } else {
      return <Field name={name} component={this.fieldRenderer} />;
    }
  }
}

/* eslint-disable react/no-unused-prop-types */
UploadButton.propTypes = {
  fieldData: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isUploading: PropTypes.bool,
  progress: PropTypes.number,
};

UploadButton.defaultProps = {
  progress: 0,
};

export default UploadButton;
