import moment from 'moment';
import moment_timezone from 'moment-timezone';

export const dateFormat = 'DD/MM/YYYY';
export const dateToUtc = (dateString: string) =>
  moment.utc(dateString, dateFormat);
export const utcToBirthDate = (dateString: string) =>
  moment(dateString).format(dateFormat);

export const checkDateRangeValid = (start_date: Date, end_date: Date) => {
  let mStart, mEnd;
  if (start_date) {
    mStart = moment(start_date, 'DD/MM/YYYY');
    if (!mStart.isValid()) {
      return { error: new Error('Start date is not a valid date') };
    }
  }
  if (end_date) {
    mEnd = moment(end_date, 'DD/MM/YYYY');
    if (!mEnd.isValid()) {
      return { error: new Error('End date is not a valid date') };
    }
  }
  if (start_date && end_date && mEnd.isBefore(mStart)) {
    return { error: new Error('The start date must be before the end date') };
  }
  return { result: true };
};

export const dateIsInFuture = (date: moment.Moment) => {
  return date > moment().endOf('day');
};

export const dateIsInPast = (date: moment.Moment) => {
  return date < moment().startOf('day');
};

export const formatTimestamp = (timestamp: string | Date) => {
  return timestamp ? moment(timestamp).format('D MMM YY, h:mm A') : '-';
};

export const formatDateTime = (timestamp: string | Date) => {
  return timestamp ? moment(timestamp).format('D MMMM YYYY, h:mm A') : '-';
};

export const formatDateTimeShort = (timestamp: string | Date) => {
  return timestamp ? moment(timestamp).format('D MMM YYYY, h:mm A') : '-';
};

export const formatDate = (timestamp: string | Date) => {
  return timestamp ? moment(timestamp).format('D MMMM YYYY') : '-';
};

export const formatDateShort = (timestamp: string | Date) => {
  return timestamp ? moment(timestamp).format('D MMM YYYY') : '-';
};

/**
 * common component LumiDatePicker is using package react-dates, which returns UTC timezone,
 * and set time on selected date as 1am, e.g. 2020-01-01T01:00:00.000Z
 *
 * because in mongodb, all dates and times are saved as UTC,
 * this function is for converting start of day in Sydney timezone to UTC
 * e.g. if i select 20/12/2023 as start date for customer statements,
 * after converted, it'll pass 19/12/2023T13:00:00.000Z to server
 */
export const getSydneyTimezoneStartOfDayInUTC = (timestamp: string | Date) => {
  if (!timestamp || !moment_timezone(timestamp).isValid) {
    return timestamp;
  }
  return moment_timezone(timestamp).tz('Australia/Sydney').startOf('days');
};

/**
 * if i select 20/12/2023 as end date for customer statements, after converted,
 * it'll pass 20/12/2023T12:59:59.999Z to server
 */
export const getSydneyTimezoneEndOfDayInUTC = (timestamp: string | Date) => {
  if (!timestamp || !moment_timezone(timestamp).isValid) {
    return timestamp;
  }
  return moment_timezone(timestamp).tz('Australia/Sydney').endOf('days');
};
