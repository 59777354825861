'use strict';

class GeneralError extends Error {
  constructor(message, code) {
    super(message);
    this.name = this.constructor.name;
    this.message = message.message || message || this.name;
    this.code = code;
  }
}

function E(code, defaultMessage) {
  class PlatformError extends GeneralError {
    constructor(message) {
      if (!message) {
        message = defaultMessage;
      }
      super(message, code);
    }
  }
  PlatformError.CODE = code;
  return PlatformError;
}

export { E, GeneralError };
