import * as React from 'react';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import ProgressSpinner from '../../../../../common/components/ProgressSpinner';
import { mergeClasses } from '../../../../../common/utils/utils';
import * as analyticsUtils from '../../../../utils/analytics';
import { RouteComponentProps } from 'react-router-dom';

class Loading extends React.Component<RouteComponentProps<any>> {
  componentDidMount() {
    analyticsUtils.trackPageView('document_capture_error');
  }

  render() {
    return (
      <div className={layoutStyles.documentCapturePage}>
        <div
          className={mergeClasses(
            layoutStyles.documentCaptureBox,
            layoutStyles.center,
          )}
        >
          <div className={layoutStyles.innerBox}>
            <h1>Error</h1>
            <span>
              {(this.props.location.state as any).message ||
                `An unknown error occured. Please contact your sales representative on 1300 00 LUMI`}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
