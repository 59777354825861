import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Help from '../../components/help';
import style from '../../styles/base.scss';
import LumiSpinner from '../../../common/components/LumiSpinner';

class AuthLayout extends Component {
  render() {
    const { children, location } = this.props;
    return (
      <React.Suspense fallback={<LumiSpinner fullSize />}>
        <div
          className={[style['signup-container'], style['auth-container']].join(
            ' ',
          )}
        >
          <Header location={location} hideSteps />
          <div className={style['signup-body']}>
            <div className={style['signup-content']}>{children}</div>
          </div>
          <Help fixed />
        </div>
      </React.Suspense>
    );
  }
}

AuthLayout.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
};

export default AuthLayout;
