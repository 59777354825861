import * as React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { change, formValueSelector } from 'redux-form';

import {
  mapFields,
  validateAbn,
  validateAcn,
} from '../../../../../common/utils/utils';
import * as analyticsUtils from '../../../../utils/analytics';

import { companyLookup } from '../../../Application/BusinessInfo/modules/actions';
import {
  msgError,
  saveApplicationFromDocCapture,
  saveAccount,
  accountantSubmit,
} from '../../../../CommonCreators';

import * as inputStyle from '../../../../styles/components/_input.scss';
import * as styles from './IdentityCollection.scss';
import * as layoutSyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';

import LumiForm from '../../../../../common/components/LumiForm';
import LumiField from '../../../../../common/components/LumiField';
import LumiButton from '../../../../../common/components/LumiButton';
import LumiCheckbox from '../../../../../common/components/LumiCheckbox';
import LumiRadioGroup from '../../../../../common/components/LumiRadioGroup/LumiRadioGroup';
import LumiSelect from '../../../../../common/components/LumiSelect';
import {
  DocumentCollectionProgress,
  getNextPath,
  getDynamicFields,
} from '../../DocumentCollectionUtils';
import { updateProgress as docCaptureProgress } from '../../DocumentCaptureActions';
import ABNLookup from '../../../../../common/components/ABNLookup';
import moment from 'moment';
import {
  dateToUtc,
  utcToBirthDate,
} from '../../../../../common/utils/date_utils';
import { intersection } from 'lodash';

const identityForm = 'identity';
const path = 'identity';
const selector = formValueSelector(identityForm);

const consentCopy = (
  <span>
    I confirm that I am authorised to provide the personal details presented and
    I consent to my information being checked with the document issuer or
    official record holder via third party systems for the purpose of confirming
    my identity.
    <br />
    Please see our{' '}
    <a href='/privacy_policy' target='_blank'>
      Privacy Policy
    </a>{' '}
    and &nbsp;
    <a href='/tos' target='_blank'>
      Terms of Service
    </a>
    .
  </span>
);

const identityFields = [
  'drivers-license-number',
  'abn',
  'date-of-birth',
  'has-property',
  'other-loans',
  'veda-consent',
];

/**
 * Map the document capture stage as the key, to the fields which are required
 * under it Also supply the copy here that is displayed as the validation
 * message when the field is not completed
 */
const getFieldConfig = (
  companyLookupInProgress: boolean,
  onCompanyLookupButtonClick: any,
  companyOptions: any,
): { [key: string]: { fields: any[] } } => ({
  'drivers-license-number': {
    fields: [
      {
        name: 'id_document_number',
        label: 'Drivers License Number',
        wrapperClassName: styles.identityFormField,
        component: LumiField,
      },
    ],
  },
  abn: {
    fields: [
      {
        name: 'company_number',
        label: 'ABN/ACN',
        wrapperClassName: styles.identityFormField,
        component: LumiField,
        validate: (value: any) =>
          !(validateAbn(value) || validateAcn(value))
            ? 'Invalid ABN or ACN'
            : '',
        extras: {
          inlineElement: ABNLookup({
            loading: companyLookupInProgress,
            onClick: onCompanyLookupButtonClick,
            className: inputStyle.rightActions,
          }),
          onBlur: onCompanyLookupButtonClick,
        },
      },
      {
        name: 'selected_company_index',
        component: LumiSelect,
        label: 'Business Name',
        options: companyOptions,
        placeholder: !companyOptions.length
          ? 'Please enter your company ABN/ACN'
          : '',
        disabled: !companyOptions || !companyOptions.length,
        wrapperClassName: styles.identityFormField,
      },
    ],
  },
  'date-of-birth': {
    fields: [
      {
        name: 'date_of_birth',
        label: `Date of Birth e.g. 13/01/1990`,
        type: 'mask',
        wrapperClassName: styles.identityFormField,
        component: LumiField,
        keepCharPositions: true,
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      },
    ],
  },
  'has-property': {
    fields: [
      {
        name: 'has_property',
        label: 'Do you own a property?',
        component: LumiRadioGroup,
        extras: {
          id: 'has_property',
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ],
        },
        wrapperClassName: styles.identityFormField,
      },
    ],
  },
  'other-loans': {
    fields: [
      {
        name: 'has_other_loans',
        component: LumiRadioGroup,
        label: 'Do you have any other loans?',
        extras: {
          id: 'has_other_loans',
          options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
          ],
        },
        wrapperClassName: styles.identityFormField,
      },
    ],
  },
  'veda-consent': {
    fields: [
      {
        name: 'consent',
        component: LumiCheckbox,
        extras: {
          id: 'consent',
          copy: consentCopy,
        },
        wrapperClassName: styles.identityFormField,
      },
    ],
  },
});

const identityFormFields = (
  requiredInfo: any,
  companyLookupInProgress: boolean,
  onCompanyLookupButtonClick: any,
  companyOptions: any,
) => {
  const fieldConfig = getFieldConfig(
    companyLookupInProgress,
    onCompanyLookupButtonClick,
    companyOptions,
  );
  return getDynamicFields(fieldConfig, Object.keys(requiredInfo));
};

type MapDispatchToProps = {
  msgError: (title: string, error: string) => void;
  change: (formName: string, field: string, value: any) => void;
  companyLookup: (
    companyNumber: number,
    loanId: string,
    onSuccess: Function,
    onError: Function,
  ) => void;
  saveApplicationFromDocCapture: (
    application: any,
    callback: (app: any) => void,
  ) => void;
  saveAccount: (account: any, callback: (account: any) => void) => void;
  push: (path: string) => void;
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
};

const mapStateToProps = (state: any) => {
  const fakeState = {
    ...state,
    form: state.form_new,
  };
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    companyNumber: selector(fakeState, 'company_number'),
    documentProgress: progress.document_capture_progress,
    application: state.user.application,
    account: state.user.account,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  msgError,
  change,
  companyLookup,
  saveApplicationFromDocCapture,
  saveAccount,
  push,
  updateProgress: docCaptureProgress,
};

type Props = {
  companyNumber?: number;
  documentProgress?: DocumentCollectionProgress;
  application?: any;
  account?: any;
  account_details?: any;
  params?: { id: string };
} & MapDispatchToProps;

const validate = (state: any) => (requiredInfo: any) => (values: any) => {
  const companyIndex = values.selected_company_index || -1;
  let company = state.companyNameData[companyIndex - 1] || {};
  if (companyIndex.label) company = companyIndex;
  const errors: any = {};
  if (requiredInfo && requiredInfo['abn'] && !company.label)
    errors.selected_company_index = 'Required';
  if (requiredInfo && requiredInfo['abn'] && !values.company_number)
    errors.company_number = 'Required';
  if (
    requiredInfo &&
    requiredInfo['drivers-license-number'] &&
    !values.drivers_license_number
  )
    errors.drivers_license_number = 'Required';
  if (requiredInfo && requiredInfo['veda-consent'] && !values.consent)
    errors.consent = 'Please confirm your consent';
  if (requiredInfo && requiredInfo['date-of-birth']) {
    const date = dateToUtc(values.date_of_birth);
    if (!values.date_of_birth) {
      errors.date_of_birth = 'Required';
    } else if (!date.isValid() || date.toDate() > moment().toDate()) {
      errors.date_of_birth = 'Invalid date';
    }
  }
  if (requiredInfo && requiredInfo['has-property'] && !values.has_property)
    errors.has_property = 'Required';
  if (requiredInfo && requiredInfo['other-loans'] && !values.has_other_loans)
    errors.has_other_loans = 'Required';
  return errors;
};

class IdentityCollection extends React.Component<Props> {
  state: any = {
    companyLookupInProgress: false,
    companyNameData: [],
  };

  componentDidMount() {
    analyticsUtils.trackPageView('document_capture_identity');
    const { application, change } = this.props;
    if (application && application.company) {
      const { company } = application;
      if (!company.company_name || !company.company_number) return;
      const selectObject = {
        label: company.company_name,
        value: 1,
        companyNumber: company.company_number,
      };
      this.setState({
        companyNameData: [selectObject],
      });
      change(identityForm, 'selected_company_index', selectObject);
    }
  }

  handleSubmit = (form: any) => {
    const {
      application,
      account,
      saveApplicationFromDocCapture,
      saveAccount,
      documentProgress,
      updateProgress,
      push,
      params,
    } = this.props;
    const { companyNameData } = this.state;
    const {
      selected_company_index,
      id_document_number,
      has_property,
      date_of_birth,
      has_other_loans,
      consent,
    } = form;
    let foundCompany =
      companyNameData[selected_company_index - 1] || selected_company_index;
    const nextPath = getNextPath(path, documentProgress.stages);
    const requiredInfoKeys = Object.keys(documentProgress.stages) || [];
    const stageNames = intersection(requiredInfoKeys, identityFields);

    const progressThrough = () => {
      updateProgress(documentProgress.document_capture_id, stageNames);
      push(`/app/documents/${params.id}/${nextPath}`);
    };

    if (application && application.id) {
      const updatedApplication: any = {
        id: application.id,
      };
      const appendAppInfo = (innerKey: string, obj: any) =>
        (updatedApplication[innerKey] = {
          ...updatedApplication[innerKey],
          ...obj,
        });
      if (foundCompany && foundCompany.companyNumber && foundCompany.label) {
        appendAppInfo('company', {
          company_number: foundCompany.companyNumber,
          company_name: foundCompany.label,
        });
      }
      if (id_document_number) {
        appendAppInfo('account_details', {
          id_document_number: id_document_number,
        });
      }
      if (date_of_birth) {
        appendAppInfo('account_details', {
          birth_date: dateToUtc(date_of_birth),
        });
      }
      if (consent) {
        updatedApplication.credit_consented = true;
      }
      if (has_property) {
        appendAppInfo('additional_info', { has_property });
      }
      if (has_other_loans) {
        appendAppInfo('additional_info', { has_other_loans });
      }
      saveApplicationFromDocCapture(updatedApplication, () => false);
    }
    if (account && account.id) {
      // Pass in the first name so it doesn't get set to blank on the back-end

      const updatedAccount: any = {
        id: account.id,
        first_name: account.first_name,
      };
      if (id_document_number || consent) updatedAccount.account_details = {};
      if (id_document_number)
        updatedAccount.account_details.id_document_number = id_document_number;
      if (date_of_birth)
        updatedAccount.account_details.birth_date = dateToUtc(date_of_birth);
      if (consent) updatedAccount.credit_consented = true;

      saveAccount(updatedAccount, () => false);
    }
    progressThrough();
  };

  handleCompanyLookupSuccess = (response: any) => {
    const { change } = this.props;
    this.setState({ companyLookupInProgress: false });
    if (response.success && response.result.length > 0) {
      if (response.result) {
        this.setState({
          companyNameData: response.result.map(
            (company: any, index: number) => {
              return {
                value: index + 1,
                type: company.company_type,
                label: company.company_name,
                companyNumber: company.company_number,
                businessNameId: company.business_name_id,
              };
            },
          ),
        });
        if (response.result.length) {
          change(identityForm, 'selected_company_index', -1);
          change(identityForm, 'selected_company_index', 1);
        }
      }
    } else {
      throw new Error(response.error);
    }
  };

  handleCompanyLookupError = (error: any) => {
    const { msgError } = this.props;
    this.setState({ companyLookupInProgress: false, companyNameData: [] });
    msgError('Invalid Company', 'Could not find that company');
  };

  handleCompanyLookupButtonClick = () => {
    const { companyNumber, companyLookup, application } = this.props;
    const isValid = validateAbn(companyNumber) || validateAcn(companyNumber);
    if (!isValid) {
      return { company_number: 'Invalid ABN/ACN number' };
    }
    this.setState({ companyLookupInProgress: true });
    companyLookup(
      companyNumber,
      application.loan.id,
      this.handleCompanyLookupSuccess,
      this.handleCompanyLookupError,
    );
  };

  render() {
    const { companyLookupInProgress, companyNameData } = this.state;
    const { documentProgress, application, account } = this.props;
    const _document_progress: any = documentProgress || {};
    const initialValues: any = {
      selected_company_index: 1,
      company: { ...(application.company || {}) },
      additional_info: {},
    };
    if (account && account.account_details) {
      initialValues.id_document_number =
        account.account_details.id_document_number;
      initialValues.consent = !!account.account_details.credit_consented_at;
      if (account.account_details?.birth_date) {
        initialValues.date_of_birth = utcToBirthDate(
          account.account_details.birth_date,
        );
      }
    }
    if (application) {
      initialValues.company_number = application?.company?.company_number;
      initialValues.additional_info.has_property =
        application.additional_info?.has_property;
      initialValues.additional_info.has_other_loans =
        application.additional_info?.has_other_loans;
      if (application.account_details?.birth_date) {
        initialValues.date_of_birth = utcToBirthDate(
          application.account_details.birth_date,
        );
      }
    }

    return (
      <div className={layoutSyles.documentCapturePage}>
        <div className={layoutSyles.documentCaptureBox}>
          <LumiForm
            name={identityForm}
            initialValues={initialValues}
            warnUnsaved={false}
            onSubmit={this.handleSubmit}
            className={styles.identityForm}
            validate={validate(this.state)(
              _document_progress && _document_progress.stages,
            )}
            clientForm
            touchOnBlur={false}
          >
            <div className={layoutSyles.innerBox}>
              <h1>About You</h1>
              <span>
                To continue, we just need the following information from you.
              </span>

              {mapFields(
                identityFormFields(
                  _document_progress.stages,
                  companyLookupInProgress,
                  this.handleCompanyLookupButtonClick,
                  companyNameData,
                ),
              )}
            </div>
            <div className={styles.nextButton}>
              <LumiButton
                disabled={companyLookupInProgress}
                rounded
                phat
                type='submit'
                label='NEXT'
              />
            </div>
          </LumiForm>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityCollection);
