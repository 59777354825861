import { IProgressStages } from './../../redux/modules/documentCapture';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';

export type DocumentCollectionProgress = {
  id: string;
  document_capture_id: string;
  send_sms: boolean;
  send_email: boolean;
  sent_at: string;
  stages: DocumentCollectionStages;
  account_id: string;
};

export type DocumentCollectionStages = {
  [stage: string]: { completed_at: string };
};

type RequirementsMap = {
  [key: string]: string[];
};

export type Page = {
  path: string;
  stages: string[];
};

export const endPath = 'thanks';

export const PAGE_FLOW: Page[] = [
  {
    path: 'start',
    stages: [],
  },
  {
    path: 'identity',
    stages: [
      'abn',
      'drivers-license-number',
      'veda-consent',
      'date-of-birth',
      'has-property',
      'other-loans',
    ],
  },
  {
    path: 'financials',
    stages: ['financial-statements'],
  },
  {
    path: 'banking_info',
    stages: ['bank-feeds'],
  },
  {
    path: 'loan_info',
    stages: ['loan-amount', 'loan-purpose'],
  },
  {
    path: 'about',
    stages: ['about', 'time-in-business'],
  },
  {
    path: 'license',
    stages: ['drivers-license'],
  },
  {
    path: 'other-documents',
    stages: ['other-documents'],
  },
  {
    path: 'sofp-income',
    stages: ['sofp-income'],
  },
  {
    path: 'sofp-debt',
    stages: ['sofp-debt'],
  },
  {
    path: 'sofp-assets',
    stages: ['sofp-assets'],
  },
  {
    path: 'confirmation',
    stages: [],
  },
];

export const determineRequiredPages = (stages: DocumentCollectionStages) => {
  const stageLabels = Object.keys(stages || {});
  const pages = pickBy(
    PAGE_FLOW.map(page => {
      if (stageLabels.some(stage => page.stages.includes(stage))) {
        return page;
      }
    }),
  );
  return values(pages);
};

export const determineCompletedPages = (
  pages: any,
  stages: DocumentCollectionStages,
) => {
  return values(
    pickBy(
      values(pages).map((page: any) => {
        const pageStages = page.stages;

        const incompletePageStages = Object.keys(stages || {}).filter(
          stageKey =>
            pageStages.includes(stageKey) && !stages[stageKey].completed_at,
        );

        if (!incompletePageStages.length) {
          return page;
        }
      }),
    ),
  );
};

export const getNextPath = (
  currentPath: string,
  progressObject: DocumentCollectionStages | IProgressStages,
) => {
  if (currentPath === 'confirmation') return 'confirmation';
  let currentPageIndex = PAGE_FLOW.findIndex(page => page.path === currentPath);
  if (currentPageIndex === -1) return 'start';
  const documentCollectionRequirements = Object.keys(progressObject || {});
  for (let i = currentPageIndex + 1; i < PAGE_FLOW.length; i++) {
    if (i === PAGE_FLOW.length - 1) return 'confirmation';
    const pathRequirement = PAGE_FLOW[i];
    if (
      pathRequirement.stages.some(r =>
        documentCollectionRequirements.includes(r),
      )
    ) {
      return pathRequirement.path;
    }
  }
};

interface FieldConfig {
  [documentCaptureStage: string]: {
    fields: any[];
  };
}

export const getDynamicFields = (
  fieldConfig: FieldConfig,
  stages: string[],
) => {
  const fields: any = [];
  for (const stage of stages) {
    const field = fieldConfig[stage];
    if (field) fields.push(...field.fields);
  }

  return fields;
};
