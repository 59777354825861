/**
 *  Name: Login
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureNotLoggedIn } from '../../../utils/auth';
import React from 'react';

export default (store: any) => ({
  path: 'login',
  onEnter: ensureNotLoggedIn(store),
  getComponent(nextState: any, cb: any) {
    const Login = React.lazy(() => import('./containers/LoginContainer'));
    cb(null, Login);
  },
});
