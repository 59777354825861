/**
 * Key is HubSpot stages ID, copied over from server
 */
const stagingHubspotStages: Record<string, string> = {
  '838293': 'To Contact',
  '838294': 'Missed Call',
  '838296': 'Call back',
  '838295': 'Qualified Processing',
  '7198024': 'Completed Application',
  '838297': 'Credit Approval',
  '838395': 'Further Information',
  '7407472': 'Update Loan Details',
  '838298': 'Loan Docs',
  '838300': 'Waiting Settlement',
  '838394': 'Conditions Pending',
  '15694503': 'Settlement Check',
  '838299': 'To Pay',
  '838302': 'Additional Information',
  '6160257': 'Credit Declined',
  closedlost: 'Closed Lost',
  closedwon: 'Closed Won',
  '838301': 'Nurture Lead',
  '4950845': 'Application Started',
  '16292467': 'Application Completed',
  '5800721': 'EDM Loan Qualified',
  '5800722': 'EDM ABN Collected',
  '5800723': 'EDM Banking Collected',
  '5800724': 'EDM DC Completed',
  '13893538': 'REFI Eligible For Refinance',
};

const productionHubspotStages: Record<string, string> = {
  '583898': 'To Contact',
  '583897': 'Missed Call',
  '583896': 'Call back',
  '583895': 'Qualified Processing',
  '7198025': 'Completed Application',
  '583894': 'Credit Approval',
  '56f05b20-3b9c-426f-9419-9c9f1e8e906c': 'Further Information',
  '7725452': 'Update Loan Details',
  'f5995459-a226-47d6-b5f4-f2407f8d1611': 'Loan Docs',
  '9808c7b1-3112-45ad-9cd4-fc2382ea4e30': 'Waiting Settlement',
  '353c1bb5-989b-46d4-999d-d992475bde31': 'Conditions Pending',
  '16903346': 'Settlement Check',
  '45f31f32-f7d8-4f0e-808c-d3e4bb2f92fb': 'To Pay',
  '802691': 'Additional Information',
  '6253476': 'Credit Declined',
  '583899': 'Closed Lost',
  '583900': 'Closed Won',
  '679485': 'Nurture Lead',
  '13166009': 'Application Started',
  '2055007': 'Application Completed',
  '7923287': 'EDM Loan Qualified',
  '7923288': 'EDM ABN Collected',
  '7923289': 'EDM Banking Collected',
  '7923290': 'EDM DC Completed',
  '13893538': 'REFI Eligible For Refinance',
};

let defaults = {
  APP_NAME: 'buzz',
  DIST: 'dist/buzz',
  LOCAL_STORAGE_NAME: 'buzz_access_token',
  LOCAL_STORAGE_USER_NAME: 'buzz_user',
  LOGIN_PATH: '/buzz/login',
  ERROR_LOGGING: true,
  BUZZ_CLIENT_URL: 'http://localhost:5050/buzz',
  HUBSPOT_STAGES: stagingHubspotStages,
};

// add any staging / production overrides here
const buzz_config = {
  staging: {},
  production: {
    HUBSPOT_STAGES: productionHubspotStages,
  },
};

type BuzzConfig = typeof defaults &
  Partial<(typeof buzz_config)[keyof typeof buzz_config]>;

export default function (
  environment: string,
  clientAppHost: string,
): BuzzConfig {
  if (environment === 'staging' || environment === 'production') {
    defaults = Object.assign(defaults, buzz_config[environment]);
    if (clientAppHost) {
      defaults.BUZZ_CLIENT_URL = `https://${clientAppHost}/buzz`;
    }
  }

  return defaults;
}
