import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import React from 'react';

export default (store: any) => ({
  path: 'sofp-debt',
  onEnter: documentCaptureRetrieve(store),
  getComponent(nextState: any, cb: any) {
    const Identity = React.lazy(() => import('./containers/DebtContainer'));
    cb(null, Identity);
  },
});
