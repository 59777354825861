import React from 'react';
import PropTypes from 'prop-types';
import { createTextMaskInputElement } from 'text-mask-core';

export default class MaskedInput extends React.Component {
  initTextMask() {
    const {
      props,
      props: { value },
    } = this;

    this.textMaskInputElement = createTextMaskInputElement({
      inputElement: this.inputElement,
      ...props,
    });
    this.textMaskInputElement.update(value);
  }

  componentDidMount() {
    this.initTextMask();
  }

  componentDidUpdate() {
    this.initTextMask();
  }

  render() {
    const props = { ...this.props };

    delete props.mask;
    delete props.guide;
    delete props.pipe;
    delete props.placeholderChar;
    delete props.keepCharPositions;
    delete props.value;
    delete props.onChange;
    delete props.showMask;
    return (
      <input
        {...props}
        onInput={event => this.onChange(event)}
        defaultValue={this.props.value}
        ref={inputElement => (this.inputElement = inputElement)}
      />
    );
  }

  onChange(event) {
    this.textMaskInputElement.update();

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(event);
    }
  }
}

MaskedInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

MaskedInput.defaultProps = {
  mask: [],
};
