import PropTypes from 'prop-types';
import React from 'react';
import styles from '../sass/components/_button.scss';
import { mergeClasses } from '../utils/utils';

const LumiButton = props => {
  const {
    addIconButton,
    cancelButton,
    cancelIconButton,
    className,
    destructive,
    disabled,
    icon,
    id,
    isBorder,
    label,
    noAnimate,
    nofill,
    noMargin,
    onClick,
    phat,
    refreshButton,
    rounded,
    saveButton,
    secondary,
    small,
    style,
    title,
    type,
  } = props;
  let animatedStyle = label ? styles.animatedIcon : styles.icon;
  const buttonStyle = () => {
    let style = label ? styles.lumiButton : styles.lumiIconButton;
    if (disabled) {
      style = styles.disabledLumiButton;
    }
    if (phat) {
      style = mergeClasses(style, styles.large);
    }
    if (small) {
      style = mergeClasses(style, styles.small);
    }
    if (secondary) {
      style = mergeClasses(style, styles.secondary);
    }
    if (cancelButton) {
      style = mergeClasses(style, styles.cancelButton);
    }
    if (nofill) {
      style = mergeClasses(style, styles.nofill);
    }
    if (rounded) {
      style = mergeClasses(style, styles.rounded);
    }
    if (noAnimate) {
      style = mergeClasses(style, styles.noAnimate);
    }
    if (destructive) {
      style = mergeClasses(style, styles.destructive);
    }
    if (saveButton) {
      style = mergeClasses(style, styles.saveButton);
    }
    if (refreshButton) {
      style = mergeClasses(style, styles.refreshButton);
    }
    if (isBorder) {
      style = mergeClasses(style, styles.isBorder);
    }
    if (addIconButton) {
      style = mergeClasses(style, styles.addIconButton);
    }
    if (cancelIconButton) {
      style = mergeClasses(style, styles.cancelIconButton);
    }

    if (noMargin) style = mergeClasses(style, styles.noMargin);

    return style;
  };
  const labelContent = label && (
    <span className={styles.animatedLabel}>{label}</span>
  );

  return (
    <button
      id={id}
      className={[buttonStyle(), className].join(' ')}
      type={type}
      onClick={onClick == null ? null : onClick}
      style={style}
      title={title}
      disabled={disabled}
    >
      {labelContent}
      {!cancelButton && icon && <div className={animatedStyle}>{icon}</div>}
    </button>
  );
};

LumiButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.any,
  icon: PropTypes.element,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  phat: PropTypes.bool,
  title: PropTypes.string,
  saveButton: PropTypes.bool,
  refreshButton: PropTypes.bool,
  cancelButton: PropTypes.bool,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  nofill: PropTypes.bool,
  rounded: PropTypes.bool,
  noAnimate: PropTypes.bool,
  id: PropTypes.string,
  destructive: PropTypes.bool,
  isBorder: PropTypes.bool,
  noMargin: PropTypes.bool,
  cancelIconButton: PropTypes.bool,
  addIconButton: PropTypes.bool,
};

LumiButton.defaultProps = {
  className: '',
  type: 'button',
};

export default LumiButton;
