/**
 *  Name: ResetPassword
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */
import React from 'react';

export const SetUpPasswordRoute = (store: any) => ({
  path: 'setup_password',

  getComponent(nextState: any, cb: any) {
    const ResetPassword = React.lazy(
      () => import('./containers/ResetPasswordContainer'),
    );
    cb(null, ResetPassword);
  },
});

export default (store: any) => ({
  path: 'reset_password',
  getComponent(nextState: any, cb: any) {
    const ResetPassword = React.lazy(
      () => import('./containers/ResetPasswordContainer'),
    );
    cb(null, ResetPassword);
  },
});
