import { apiFetch, serverURL } from '../../../../../common/utils/fetch_utils';
import { msgError } from '../../../../CommonCreators';

export function resetPassword(userData, resolve, reject) {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/accounts/resetPassword`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
          reset_password_token: userData.reset_password_token,
          new_password: userData.password,
        }),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error || 'Error setting password');
        }
        console.log('request succeeded with JSON response', data);
        resolve(data);
      },
    ).catch(error => {
      console.log('request for forgotPassword failed', error);
      dispatch(msgError('Error', error.message));
      reject(error);
    });
}
