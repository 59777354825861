import React from 'react';
import CloseCircle from '../../assets/close-circle.svg';
import Caution from '../../assets/warning-triangle-outlined.svg';
import Info from './assets/info.svg';
import Success from './assets/success.svg';
import Hidden from '../../layout/hidden/Hidden';
import { mergeClasses } from 'common/utils/utils';

export type Props = {
  children: React.ReactNode | React.ReactNode[];
  type: 'neutral' | 'caution' | 'success' | 'error' | 'lumi';
  className?: string;
  size?: 'small' | 'medium' | 'header' | 'none';
  Icon?: React.FC<React.SVGProps<React.ReactSVGElement>>;
  hideIcon?: boolean;
  iconClassName?: string;
};

const errorClass = 'lm-bg-red-100 lm-border-base-red lm-text-base-red';
const successClass = 'lm-bg-green-100 lm-border-green-400';
const cautionClass = 'lm-bg-orange-100 lm-border-orange-500';
const neutralClass = 'lm-bg-white lm-border-base-blue';
const lumiClass = 'lm-bg-pink-100 lm-border-base-pink lm-pink-500';

const getIconComponent = (type: string) => {
  switch (type) {
    case 'error':
      return CloseCircle;
    case 'caution':
      return Caution;
    case 'success':
      return Success;
    default:
      return Info;
  }
};

const getSizeClass = (size: string) => {
  if (size === 'small') return 'lm-p-2 lm-border-2';
  if (size === 'medium') return 'lm-py-2 lm-px-3 lm-border';
  if (size === 'none') return '';
  return 'lm-p-4 lm-border-2 ';
};

export const getIconClass = (type: string) => {
  if (type === 'lumi') return 'lm-text-base-pink';
  if (type === 'neutral') return 'lm-text-base-blue';
  if (type === 'caution') return 'lm-text-orange-500';
  if (type === 'success') return 'lm-text-green-500';
  if (type === 'error') return 'lm-text-base-red';
  return '';
};

function getClass(type: string) {
  if (type === 'error') return errorClass;
  if (type === 'caution') return cautionClass;
  if (type === 'neutral') return neutralClass;
  if (type === 'lumi') return lumiClass;
  if (type === 'success') return successClass;
}

export default function Feedback(props: Props) {
  const {
    children,
    type,
    className,
    size,
    Icon,
    hideIcon = false,
    iconClassName = 'lm-h-5 lm-w-5',
  } = props;

  const IconComponent = Icon || getIconComponent(type);

  return (
    <div
      className={`lm-flex lm-rounded-lg lm-leading-5 lm-shadow-md ${getClass(
        type,
      )} ${getSizeClass(size)} ${className}`}
    >
      <Hidden when={hideIcon}>
        <strong
          className={`lm-font-bold ${
            size === 'small' || size === 'medium' ? 'lm-mr-2' : 'lm-mr-4'
          } lm-flex lm-self-start`}
        >
          <IconComponent
            className={mergeClasses(
              iconClassName,
              getIconClass(type),
              'lm-fill-current lm-my-auto',
            )}
          />
        </strong>
      </Hidden>
      <span className='lm-flex-1 lm-text-sm lm-font-proMedium lm-text-base-blue'>
        {children}
      </span>
    </div>
  );
}
