import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/components/_spinner.scss';
import ProgressDots from './ProgressDots';

export const LumiSpinner = ({ states, fullSize }) => {
  if (fullSize) {
    return (
      <div className={styles.spinnerContainer}>
        <ProgressDots />
      </div>
    );
  }
  return <ProgressDots />;
};

LumiSpinner.propTypes = {
  states: PropTypes.array,
  fullSize: PropTypes.bool,
};

export default LumiSpinner;
