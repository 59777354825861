import * as React from 'react';

import FileUpload from '../../../../../common/components/file_upload';
import Tick from '../../../../../common/images/tick.svg';

import * as styles from './GenericDocuments.scss';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import LumiButton from '../../../../../common/components/LumiButton';
import { getNextPath } from '../../DocumentCollectionUtils';

const path = 'other-documents';

const GenericDocuments = (props: any) => {
  const {
    uploading,
    uploaded,
    document_capture,
    document_progress,
    uploadGenericDocuments,
    updateProgress,
    push,
    params,
  } = props;

  const completed = !!document_progress.stages['other-documents'].completed_at;
  const showUploaded = uploaded || completed;

  const onUpload = (files: any) => {
    const { deal_id } = document_capture;
    uploadGenericDocuments(deal_id, document_progress.id, files);
  };

  const progressThrough = () => {
    const nextPath = getNextPath(path, document_progress.stages);
    if (!completed) {
      updateProgress(document_capture.id, ['other-documents']);
    }
    push(`/app/documents/${params.id}/${nextPath}`);
  };

  const uploadedIndicator = (
    <div className={styles.alreadyUploaded}>
      <div>
        <Tick /> Uploaded
      </div>
    </div>
  );

  let uploadBody = (
    <div className={styles.genericUploadWrapper}>
      <FileUpload
        uploading={uploading}
        upload_complete={uploaded}
        onFile={onUpload}
        multiFile
        acceptAllTypes
      />
    </div>
  );

  if (showUploaded) {
    uploadBody = uploadedIndicator;
  }

  return (
    <div className={layoutStyles.documentCapturePage}>
      <div className={layoutStyles.documentCaptureBox}>
        <div className={layoutStyles.innerBox}>
          <h1>Other Documents</h1>
          {uploadBody}
        </div>
        <div className={layoutStyles.nextButton}>
          {showUploaded && (
            <LumiButton
              noAnimate
              rounded
              phat
              label='Next'
              onClick={progressThrough}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GenericDocuments;
