/**
 *  Name: Dashboard
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureAuthenticated } from '../../../utils/auth';
import React from 'react';

export default (store: any) => ({
  path: 'dashboard(/:step)',
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const Dashboard = React.lazy(
      () => import('./containers/DashboardContainer'),
    );
    cb(null, Dashboard);
  },
});
