import * as React from 'react';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import ConnectBankAccount from '../../../../../common/components/ConnectBankAccount';
import { getUrl } from '../../../../../common/utils/utils';
import { connect } from 'react-redux';
import * as cardStyle from '../../../../../common/sass/components/_card.scss';
import { DocumentCollectionProgress } from '../../DocumentCollectionUtils';
import { push } from 'react-router-redux';
import HelpCard from '../../../../components/help_card';
import * as analyticsUtils from '../../../../utils/analytics';

type Props = {
  account_id: string;
  user: any;
  params: Params;
  document_progress: DocumentCollectionProgress;
  dispatch: any;
};

type Params = {
  id: string;
};

type State = {
  open: boolean;
};

const _stage = 'bank-feeds';

class BankingInfo extends React.Component<Props, State> {
  state: State = {
    open: false,
  };

  constructor(props: Props) {
    super(props);

    const { document_progress, dispatch, params } = this.props;
    const stage = document_progress.stages[_stage];
    if (stage && stage.completed_at)
      dispatch(push(`/app/documents/${params.id}/banking_info/landing`));
  }

  componentDidMount() {
    analyticsUtils.trackPageView('document_capture_banking_info_view');
  }

  render() {
    const { params, account_id } = this.props;
    const redirectUrl = getUrl(
      `/app/documents/${params.id}/banking_info/landing`,
    );

    return (
      <div className={['lm-mt-6', cardStyle.pageCard].join(' ')}>
        <HelpCard />
        <ConnectBankAccount
          referenceId={account_id}
          redirectUrl={redirectUrl}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    account_id: state.user.account.id,
    document_progress: state.documentCapture.progress.document_capture_progress,
  };
}

export default connect(mapStateToProps)(BankingInfo);
