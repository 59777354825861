import * as React from 'react';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import UploadStatements from '../../../../components/UploadStatements';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { uploadStatements } from '../../../../CommonCreators';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../DocumentCollectionUtils';
import LumiButton from '../../../../../common/components/LumiButton';
import * as styles from './Financials.scss';
import { updateProgress } from '../../DocumentCaptureActions';
import * as analyticsUtils from '../../../../utils/analytics';

type State = {
  uploaded: boolean;
  error: boolean;
  attachmentsExist: boolean;
};

type Props = {
  application: any;
  dispatch: any;
  uploading: boolean;
  profit_progress: number;
  balance_progress: number;
  params: Param;
  document_progress: DocumentCollectionProgress;
  document_capture: any;
};

type Param = {
  id: String;
};

class Financials extends React.Component<Props, State> {
  state = {
    uploaded: false,
    error: false,
    attachmentsExist: false,
  };
  componentDidMount() {
    analyticsUtils.trackPageView('document_capture_financials');
  }
  statementSubmit = async (values: any) => {
    const { uploaded, attachmentsExist } = this.state;
    const {
      dispatch,
      application,
      params,
      document_progress,
      document_capture,
    } = this.props;
    const path = 'financials';
    const nextPath = getNextPath(path, document_progress.stages);
    if (uploaded) {
      try {
        await uploadStatements(
          document_capture.deal_id,
          document_progress.id,
          values,
          dispatch,
        );
      } catch (err) {
        return this.toggleError();
      }
    }
    if (attachmentsExist) {
      dispatch(
        updateProgress(document_progress.document_capture_id, [
          'financial-statements',
        ]),
      );
      return dispatch(push(`/app/documents/${params.id}/${nextPath}`));
    }
    return this.toggleError();
  };

  toggleError = () => {
    const { error } = this.state;
    this.setState({ error: !error });
  };

  fileSelected = (val: any) => {
    this.setState({ uploaded: true, attachmentsExist: true });
  };

  exists = () => {
    this.setState({ attachmentsExist: true });
  };

  render() {
    const {
      application,
      uploading,
      profit_progress,
      balance_progress,
      document_progress,
      document_capture,
    } = this.props;
    const { error, uploaded } = this.state;

    const uploadStatementsBlock = (
      <div className='mb-10 text-center'>
        <h2 className='text-center'>Upload my financial statements</h2>
        <p className='text-center mb-12'>
          Please upload latest copies of your financial statements
        </p>
        <UploadStatements
          onUpload={this.statementSubmit}
          onFileSelected={this.fileSelected}
          application={application}
          isUploading={uploading}
          profitProgress={profit_progress}
          balanceProgress={balance_progress}
          isExisting={this.exists}
          dealId={document_capture.deal_id}
          documentCaptureProgressId={document_progress.id}
        />
      </div>
    );
    const uploadStatements = !error ? uploadStatementsBlock : null;

    const errorTextBlock = (
      <div className={styles.financials}>
        <h2 className='text-center'>Error</h2>
        <p className='text-center mb-12'>
          Failed to upload files. Please try again
        </p>
      </div>
    );
    const errorText = error ? errorTextBlock : null;

    const errorButtonBlock = (
      <div className={layoutStyles.nextButton}>
        <LumiButton
          noAnimate
          rounded
          phat
          style={{ float: 'none' }}
          label='TRY AGAIN'
          onClick={this.toggleError}
        />
      </div>
    );
    const errorButton = error ? errorButtonBlock : null;

    return (
      <div className={layoutStyles.documentCapturePage}>
        <div className={layoutStyles.documentCaptureBox}>
          <div className={layoutStyles.innerBox}>
            {uploadStatements}
            {errorText}
          </div>
          {errorButton}
        </div>
      </div>
    );
  }
}

export default Financials;
