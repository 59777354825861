import React, { useEffect, useMemo, useState } from 'react';
import { reduxForm } from 'redux-form-legacy';
import { signup, getApplication, updateApplication } from '../modules/actions';
import FormInput from '../../../../../common/components/TextField';
import {
  mandatoryFieldsValidation,
  validateMobilePhone,
  regexTestSimpleEmail,
  validateAbn,
  validateAcn,
} from '../../../../../common/utils/utils';
import Button from '../../../../components/button';

import * as analyticsUtils from '../../../../utils/analytics';
import formStyle from '../../../../../common/sass/components/_form.scss';
import icons from '../../../../../common/sass/icons/lumi-icon.scss';
import inputStyle from '../../../../styles/components/_input.scss';
import { consentCopy } from '../../../../../common/strings/legal';
import {
  useGraphQuery,
  isQueryLoading,
} from '../../../../../common/utils/graphql_utils';
import { getExperimentGroupQuery } from '../../../../../graphql/queries/queries';
import { useCookies } from 'react-cookie';
import { useQuery, useMutation } from 'react-query';
import { ExperimentObserver } from '../../../../observers/ExperimentObserver';
import { getNextOnboardingStep } from '../../../../CommonCreators';
import { push, replace } from 'react-router-redux';
import { getNextApplicationRoute, getExperimentGroup } from '../..';
import Loading from '../../../../../junction/components/loading/Loading';
import { connect } from 'react-redux';

export const fields = [
  'email',
  'password',
  'mobile_phone',
  'full_name',
  'consent',
  'company_number',
];
export const mandatories = [
  'email',
  'password',
  'mobile_phone',
  'full_name',
  'consent',
  'company_number',
];

const asyncValidate = (values: any) => {
  return new Promise<void>((resolve, reject) => {
    let errors: any = {};

    if (!regexTestSimpleEmail(values.email)) {
      errors['email'] = 'Invalid email address';
    }

    if (
      !validateAbn(values.company_number) &&
      !validateAcn(values.company_number)
    ) {
      errors['company_number'] =
        'The company number must be a valid ABN (11 Digits) or ACN (9 Digits)';
    }

    if (!validateMobilePhone(values.mobile_phone)) {
      errors['mobile_phone'] =
        'Mobile phone must start with 04 and be 10 digits';
    }

    if (values.password.length < 4) {
      errors['password'] = 'Password must be at least medium strength';
    }

    if (errors && Object.keys(errors).length > 0) {
      reject(errors);
    } else {
      resolve();
    }
  });
};

const validate = (values: any) => {
  let _mandatories = mandatories;
  return mandatoryFieldsValidation(values, _mandatories);
};

type Props = {
  fields: {
    email: any;
    password: any;
    mobile_phone: any;
    full_name: any;
    consent: any;
    company_number: any;
  };
  error: string;
  handleSubmit: any;
  submitting: boolean;
  dispatch: any;
};

const getPasswordStrength = (password: any) => {
  let passwordStrength;
  let passwordStrengthClass;
  if (password.value) {
    let strength = password.value.length;

    if (strength < 2) {
      passwordStrength = 'Weak';
      passwordStrengthClass = inputStyle.error;
    } else if (strength < 4) {
      passwordStrength = 'Medium';
      passwordStrengthClass = inputStyle.warning;
    } else {
      passwordStrength = 'Strong';
      passwordStrengthClass = inputStyle.success;
    }
  }
  return {
    strength: passwordStrength,
    class: passwordStrengthClass,
  };
};

const formConfig = {
  form: 'signup',
  fields,
  asyncValidate,
  asyncBlurFields: ['email', 'mobile_phone', 'password'],
  validate,
};

function Signup(props: Props) {
  const {
    fields: {
      email,
      password,
      mobile_phone,
      full_name,
      consent,
      company_number,
    },
    error,
    handleSubmit,
    submitting,
    dispatch,
  } = props;

  const [pageLoaded, setPageLoaded] = useState(false);

  const [refetchAppVariables, setRefetchAppVariables] = useState({});

  // this is a dependent query, it will only fetch the application once the accountId is known
  // and that will only be once a request is sent to create the customers account and set into
  // state.
  const { data: applicationResponse, refetch: fetchApplication } = useQuery(
    ['application', refetchAppVariables],
    getApplication,
    { enabled: (refetchAppVariables as any).accountId },
  );
  const application = applicationResponse?.application;
  const [updateApp] = useMutation(updateApplication);

  useEffect(() => {
    const updateApplicationAndNavigate = async () => {
      fetchApplication();
      if (!application?.id || !(refetchAppVariables as any).email || pageLoaded)
        return;
      setPageLoaded(true);

      const appData = JSON.parse(localStorage.getItem('app_data') || '{}');

      // Update the application now that it is created with the info that we have been
      // saving to localstorage in the first few steps
      await updateApp({
        id: application.id,
        application: {
          ...appData,
          company: {
            ...appData.company,
            company_number: company_number.value,
          },
        },
      });

      localStorage.removeItem('app_data');

      dispatch(
        push(
          getNextApplicationRoute('/app/variant/signup', getExperimentGroup()),
        ),
      );
    };
    // useEffect cannot be async due to some type issue, so make an arrow func inside and execute that
    // bad boi
    updateApplicationAndNavigate();
  }, [(refetchAppVariables as any)?.accountId, application, pageLoaded]);

  const submit = (values: any, dispatch: any) => {
    return new Promise((resolve, reject) => {
      const getAndUpdateApplication = async (account: any) => {
        // once we get the account we also need to do something with the company
        // number the customer gives us, at this point we don't have access to the
        // application, so we need to retrieve it by the account id that was just
        // created. To fetch the application in a proper manner, we set a temporary
        // state for react query to run a dependent query
        setRefetchAppVariables({ accountId: account.id, email: account.email });
      };
      dispatch(signup(values, resolve, reject, getAndUpdateApplication));
    });
  };

  const companyNumberInputProps = {
    fieldData: { ...company_number, value: company_number.value || '' },
    label: 'Company Number (ABN/ACN)',
    type: 'text',
  };

  const nameInputProps = {
    fieldData: { ...full_name, value: full_name.value || '' },
    label: 'Your name',
    type: 'text',
  };

  const emailInputProps = {
    fieldData: { ...email, value: email.value || '' },
    label: 'Email address',
    type: 'email',
  };

  const passwordInputProps = {
    fieldData: { ...password, value: password.value || '' },
    label: 'Password',
    type: 'password',
    enablePasswordStrength: true,
  };

  const phoneInputProps = {
    fieldData: { ...mobile_phone, value: mobile_phone.value || '' },
    label: 'Phone number',
    mask: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
    type: 'text',
  };

  const continueButtonProps = useMemo(() => {
    return {
      className: 'm-2',
      disabled: submitting,
      loading: submitting,
      label: 'Next step',
      type: 'submit',
    };
  }, [submitting]);

  const passwordStrength = useMemo(
    () => getPasswordStrength(password),
    [password],
  );

  return (
    <div className={formStyle['mp-50']}>
      <h1 className='title sm-padded'>Your details</h1>
      <form className={formStyle.container} onSubmit={handleSubmit(submit)}>
        <FormInput {...companyNumberInputProps} />
        <FormInput {...nameInputProps} />
        <FormInput {...emailInputProps} />
        <FormInput {...phoneInputProps} />
        <div className={inputStyle.wrapper}>
          <FormInput {...passwordInputProps} />
          <div className={inputStyle.rightActions}>
            <span
              className={[inputStyle.actionText, passwordStrength.class].join(
                ' ',
              )}
            >
              {passwordStrength.strength}
            </span>
          </div>
        </div>
        <div className={inputStyle.checkbox}>
          <input
            type='checkbox'
            id='consent'
            value={consent.value}
            onChange={consent.onChange}
          />
          <label htmlFor='consent'>{consentCopy}</label>
          {!!email.touched && !!consent.error && (
            <div className='error-text'>
              <i className={icons['icon-exclaimation-triangle']} /> Required
            </div>
          )}
        </div>
        <div style={{ display: error ? 'none' : 'block' }}>{error}</div>
        <div style={{ display: 'flex' }}>
          <Button {...continueButtonProps} />
        </div>
        <p className='text-muted mt-8 text-left'>
          <small>
            All loans are subject to eligibility criteria and approval by Lumi
            Funding.
          </small>
        </p>
      </form>
    </div>
  );
}

export default connect()(reduxForm(formConfig, () => {})(Signup));
