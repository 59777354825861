import * as React from 'react';
import * as styles from './Thanks.scss';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import LumiButton from '../../../../../common/components/LumiButton';
import * as analyticsUtils from '../../../../utils/analytics';
import {
  thanksApplicationCopy,
  thanksHardshipCopy,
} from '../../DocumentCaptureTypeCopy';

interface PropTypes {
  account_details: any;
  documentCapture?: any;
}

export default class Thanks extends React.Component<PropTypes> {
  async componentDidMount() {
    analyticsUtils.trackPageView('document_capture_thanks');
  }

  render() {
    const { documentCapture } = this.props;

    const body =
      documentCapture?.type === 'APPLICATIONS'
        ? thanksApplicationCopy
        : thanksHardshipCopy;

    return (
      <div className={layoutStyles.documentCapturePage}>
        <div className={layoutStyles.documentCaptureBox}>
          <div className={layoutStyles.innerBox}>
            <h1>That’s it!</h1>
            {body}
          </div>
        </div>
      </div>
    );
  }
}
