import React from 'react';
import { Loader as SkeletonLoader } from '../../../../../junction/layout/skeleton/Skeleton';
import Stack from '../../../../../junction/layout/stack/Stack';
import Heading from '../../../../../junction/components/heading/Heading';
import { Hidden } from '../../../../../junction/layout/hidden/Hidden';
import Breakpoint from '../../../../../junction/layout/breakpoint/Breakpoint';
import Text from '../../../../../junction/components/text/Text';

type Props = {
  placeholder?: boolean;
};

const BankingSkeleton = ({ placeholder }: Props) => {
  return (
    <Stack className='lm-select-none'>
      <Hidden when={!placeholder}>
        <Stack orientation='vertical' spacing='m'>
          <Heading unselectable size='5'>
            Bank Statements
          </Heading>
          <Text className='lm-sub lm-text-subheading'>
            Please upload the following:
          </Text>
        </Stack>
      </Hidden>
      <Breakpoint screen='lsTablet' direction='down'>
        <SkeletonLoader
          animate={!placeholder}
          loading={placeholder}
          width='100%'
          height='511'
        >
          <rect x='8.5%' y='30' rx='0' ry='0' width='80%' height='22' />
          <rect x='8.5%' y='74' rx='0' ry='0' width='80%' height='48' />

          <rect x='25%' y='160' rx='0' ry='0' width='50%' height='57' />
          <rect x='25%' y='250' rx='0' ry='0' width='50%' height='57' />
          <rect x='25%' y='340' rx='0' ry='0' width='50%' height='57' />
          <rect x='25%' y='430' rx='0' ry='0' width='50%' height='57' />
        </SkeletonLoader>
      </Breakpoint>
      <Breakpoint screen='lsTablet' direction='up'>
        <SkeletonLoader
          animate={!placeholder}
          loading={placeholder}
          width='100%'
          height='871'
        >
          <rect x='33' y='30' rx='0' ry='0' width='80%' height='22' />
          <rect x='33' y='74' rx='0' ry='0' width='80%' height='48' />

          <rect x='125' y='160' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='250' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='340' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='430' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='520' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='610' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='700' rx='0' ry='0' width='200' height='57' />
          <rect x='125' y='790' rx='0' ry='0' width='200' height='57' />
        </SkeletonLoader>
      </Breakpoint>
    </Stack>
  );
};

export default BankingSkeleton;
