import { getRouteProgress, getExperimentGroup } from '..';
/**
 *  Name: DriverLicenseUpload
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureAuthenticated } from '../../../utils/auth';
import React from 'react';

export default (store: any) => ({
  path: 'driver_license_upload',
  progress: (group: string) =>
    getRouteProgress(
      '/app/driver_license_upload',
      group || getExperimentGroup(),
    ),
  enableHeader: true,
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const DriverLicenseUpload = React.lazy(
      () => import('./containers/DriverLicenseUploadContainer'),
    );
    cb(null, DriverLicenseUpload);
  },
});
