import { connect } from 'react-redux';

import License from '../components/License';

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    uploading: state.ui.uploading,
    upload_progress: state.ui.upload_progress || 0,
    account_id: state.user.account_id,
    application: state.user.application,
    documentProgress: progress.document_capture_progress,
  };
};

export default connect(mapStateToProps)(License);
