import React from 'react';
import { ensureAuthenticated } from '../../../utils/auth';

export default (store: any) => ({
  path: 'banking_info',
  progress: { step: 5, percentage: 80 },
  enableHeader: true,
  enableFooter: true,
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const BankingInfo = React.lazy(
      () => import('./containers/BankingInfoContainer'),
    );
    cb(null, BankingInfo);
  },
});
