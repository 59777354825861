/**
 *  Name: Signup
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureNotLoggedIn } from '../../../utils/auth';
import React from 'react';
import Thanks from './children/Thanks';

export default (store: any) => ({
  path: 'signup',
  onEnter: ensureNotLoggedIn(store),
  indexRoute: {
    getComponent(nextState: any, cb: any) {
      const Signup = React.lazy(() => import('./containers/SignupContainer'));
      cb(null, Signup);
    },
  },
  childRoutes: [Thanks(store)],
});
