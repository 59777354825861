import lumiEnvironment from '../../lumi.environment';

/**
 * Exports feature flags used for guarding sections of code.
 * This allows us to correlate flags to jira tickets to make them more easily
 *   identifiable.
 */

export const featureFlags = {
  LF4216ManualStatementsWarning: false,
  PAR2OpenPayDisbursements: true,
  LEN2BankStatementsOcr: true,
  PAR110DocCapsByAccountId: false,
  LEN98PayoutLetter: true,
  BET207NewFinanceProcess: true,
  LEN404DebitGraphs: true,
  LEN399AnnualisedRevenueBasedOnSelectedPeriods: false,
  LEN1505ShowLeadStatusHistory: false,
  BET1588ConnectBankingBanner: true,
};
