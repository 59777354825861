import * as React from 'react';
import * as styles from './Welcome.scss';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import LumiButton from '../../../../../common/components/LumiButton';
import { push } from 'react-router-redux';
import { DispatchProp } from 'react-redux';
import { IDocumentCaptureProgress } from '../../../../redux/modules/documentCapture';
import { getNextPath } from '../../DocumentCollectionUtils';
import { pathIndex } from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout';
import * as analyticsUtils from '../../../../utils/analytics';
import {
  welcomeApplicationCopy,
  welcomeHardshipCopy,
} from '../../DocumentCaptureTypeCopy';

interface Props extends DispatchProp {
  params: { id: string };
  account: any;
  location: { pathname: string };
  documentProgress: IDocumentCaptureProgress;
  documentCapture?: any;
  push?: (path: string) => void;
  linkOpened?: (documentCaptureId: string) => void;
}

const requirementsCopy = [
  {
    match: [
      'date-of-birth',
      'has-property',
      'other-loans',
      'drivers-license-number',
    ],
    copy: 'Some information about you.',
  },
  { match: ['abn'], copy: 'Your company or business details.' },
  { match: 'veda-consent', copy: 'Your consent to perform a credit check.' },
  { match: 'financial-statements', copy: 'Your financial statements.' },
  { match: 'bank-feeds', copy: 'Your bank statements.' },
  { match: ['loan-amount', 'loan-purpose'], copy: 'About Your Loan.' },
  { match: ['about', 'time-in-business'], copy: 'About Your Business.' },
  { match: 'drivers-license', copy: 'A photo of your drivers license.' },
  {
    match: ['sofp-income', 'sofp-debt', 'sofp-assets', 'sofp-proposed-payment'],
    copy: 'A Statement of Financial Position (SOFP)',
  },
];

class Welcome extends React.Component<Props> {
  nextPage = () => {
    const {
      push,
      params,
      location: { pathname },
      documentProgress: { stages },
    } = this.props;
    const splitPath = pathname.split('/');
    const path = splitPath[pathIndex];
    const nextPath = getNextPath(path, stages);
    push(`/app/documents/${params.id}/${nextPath}`);
  };
  componentDidMount() {
    const { account } = this.props;
    analyticsUtils.identifySimple(account.id, {
      email: account.email,
    });
    const { linkOpened, documentProgress } = this.props;
    linkOpened(documentProgress.document_capture_id);
  }
  render() {
    const { account, documentProgress, documentCapture } = this.props;
    let firstName = account.first_name || '';
    const requiredInformation = Object.keys(documentProgress.stages || {});
    const otherRequirements = documentProgress.other_documents || [];

    const requirementsElements = requirementsCopy.map((copyObj, i) => {
      const { match, copy } = copyObj;
      const copyElem = (
        <div key={`requirements-${i}`} className={styles.requirementBullet}>
          {copy}
        </div>
      );

      if (Array.isArray(copyObj.match)) {
        for (const info of requiredInformation) {
          if (match.includes(info)) {
            return copyElem;
          }
        }
      } else if (requiredInformation.includes(copyObj.match)) {
        return copyElem;
      }
    });

    const otherRequirementsElements = otherRequirements.map(
      (requirement: string) => {
        return (
          <div key={requirement} className={styles.requirementBullet}>
            {requirement}
          </div>
        );
      },
    );

    const body =
      documentCapture?.type === 'HARDSHIP'
        ? welcomeHardshipCopy(
            firstName,
            requirementsElements,
            otherRequirementsElements,
          )
        : welcomeApplicationCopy(
            firstName,
            requirementsElements,
            otherRequirementsElements,
          );

    return (
      <div className={layoutStyles.documentCapturePage}>
        <div className={layoutStyles.documentCaptureBox}>
          {body}
          <div className={layoutStyles.nextButton}>
            <LumiButton
              noAnimate
              rounded
              phat
              label='START'
              onClick={this.nextPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
