import React from 'react';

export type ActionButtonProps = React.HTMLProps<HTMLButtonElement> & {
  isDestructive?: boolean;
  isSecondary?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

const normalClasses = `
    lm-outline-none
    active:lm-bg-gray-100
    lm-border-gray-400
    lm-border
    lm-text-gray-800
    lm-px-5
    focus:lm-outline-none
    lm-focus-border
    lm-py-2
    lm-rounded-lg
    lm-font-medium
    lm-shadow-button
    hover:lm-bg-gray-100
    active:lm-shadow-button-active-inset
    lm-text-sm
`;

const secondaryClasses = `
    lm-underline
    active:lm-text-gray-900
    lm-text-purple-900
    lm-px-5
    lm-py-2
    lm-text-sm
`;

const disabledClasses = `
    lm-outline-none
    lm-text-gray-300
    lm-px-5
    focus:lm-outline-none
    lm-focus-border
    lm-py-2
    lm-rounded-lg
    lm-cursor-not-allowed
    lm-font-medium
    lm-shadow-button-active
    lm-text-sm
`;

const destructiveClasses = `
    lm-outline-none
    lm-text-white
    lm-bg-red-600
    lm-px-5
    lm-py-2
    lm-rounded-lg
    focus:lm-outline-none
    lm-focus-border
    lm-font-medium
    lm-shadow-button
    hover:lm-bg-red-500
    active:lm-shadow-button-active-inset
    lm-text-sm
    active:lm-bg-red-500

`;

const disabledDestructiveClasses = `
    lm-outline-none
    lm-text-white
    lm-bg-red-200
    lm-px-5
    lm-py-2
    lm-rounded-lg
    focus:lm-outline-none
    lm-focus-border
    lm-font-medium
    lm-shadow-button-active
    lm-text-sm
    lm-cursor-not-allowed
`;

/**
 * @deprecated Use Button instead
 * @param props
 * @returns
 */
export function ActionButton(props: ActionButtonProps) {
  const {
    children,
    className,
    disabled,
    id,
    isDestructive,
    isSecondary,
    onClick,
    style,
    type = 'button',

    ...buttonProps
  } = props;

  let baseClass = normalClasses;
  if (isSecondary) baseClass = secondaryClasses;
  if (disabled && !isDestructive) baseClass = disabledClasses;

  if (isDestructive) baseClass = destructiveClasses;
  if (isDestructive && disabled) baseClass = disabledDestructiveClasses;

  const _className = `${baseClass} ${className}`;

  return (
    <button
      {...buttonProps}
      disabled={disabled}
      className={_className}
      onClick={onClick}
      id={id}
      style={style}
      type={type}
    >
      {children}
    </button>
  );
}

export default ActionButton;
