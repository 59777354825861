/** Any material changes to this config should be documented in
 * https://www.notion.so/lumifinance/Onboarding-Analytics-Handbook-a523ed869dc54f58abf67b7f5d83a7c6
 */
import {
  ApplicationInfo,
  AccountInfo,
} from '../layout/modules/OnboardingActions';
import {
  getLocalStorage,
  setLocalStorage,
} from '../../../utils/localStorageUtils';
import { useRef } from 'react';

export const KickstarterAnalyticsTableName = 'covidkickstarter_progress';

export const OnboardingAnalyticsTableName = 'onboarding_progress';

export const OnboardingQualifyTableName = 'onboarding_do_you_qualify';

export const AffordabilityAnalyticsTableName = 'affordability_progress';

export enum OnboardingActions {
  ProgressUpdated = 'progress_updated',
}

export enum OnboardingStages {
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  Password = 'password',
  CreditConsent = 'credit_consent',
  MonthlyTurnover = 'monthly_turnover',
  ABN = 'abn',
  CompanyName = 'company_name',
  TimeInBusiness = 'time_in_business',
  Industry = 'industry',
  LoanProduct = 'loan_product',
  LoanAmount = 'loan_amount',
  AtoBalance = 'ato_balance',
  LoanPurpose = 'loan_purpose',
  LoanTerm = 'loan_term',
  BusinessOwner = 'business_owner',
}

/**
 * The initial_application_data can be set by something outside of this app. Lets say you have a landing page on the website where you
 * capture a few details before sending them through to the onboarding, you can store those details in here, on page of the onboarding app,
 * they will be loaded in. Optionally you can compare this data in the OnboardingRoute config to decide if you would like to skip a
 * particular step of the onboarding as well
 * @returns ApplicationInfo
 */
export function getInitialLocalApplicationData(): ApplicationInfo | null {
  return getLocalStorage('initial_application_data', { fromJson: true });
}

export function getLocalApplication(): ApplicationInfo | null {
  return getLocalStorage('apply_application_data', { fromJson: true });
}

export const getFlowName = (application: ApplicationInfo | null) => {
  if (application?.segment?.flow_name) {
    return application.segment.flow_name;
  }

  return 'onboarding';
};

export function setLocalApplication(applicationInfo: ApplicationInfo): void {
  const existingApp = getLocalApplication();
  return setLocalStorage(
    'apply_application_data',
    {
      ...existingApp,
      ...applicationInfo,
    },
    { toJson: true },
  );
}

// Set up an obvserver pattern so that views can listen to updates from the
// local storage changes
const experimentObservers: ((group: string) => void)[] = [];

export function setLocalExperimentGroup(experimentGroup: string): void {
  setLocalStorage('onboarding_experiment_group', experimentGroup, {});
  // Let the observers know that something happened
  experimentObservers.forEach(func => func(experimentGroup));
}

export function getLocalExperimentGroup(): string | null {
  return getLocalStorage('onboarding_experiment_group', {});
}

// An obvserver pattern util to allow the view to listen to changes inside the
// onbarding_experiment_group local storage object
export function useLocalExperimentGroup() {
  const group = useRef(getLocalExperimentGroup());
  const onUpdated = (value: string) => {
    group.current = value;
  };
  experimentObservers.push(onUpdated);
  return group.current;
}

// This util is used to flatten the application array for the segment analytics events.
export function flattenApplication(application: ApplicationInfo) {
  const { loan, account_details, additional_info, company } = application;

  return {
    ...(loan || {}),
    ...(account_details || {}),
    ...(additional_info || {}),
    ...(company || {}),
  };
}

// This util is used to clean the account so we do not raise analytics events with sensitive information
// such as the users password, etc.
export function cleanAccountForAnalytics(account: AccountInfo) {
  const _acc: any = { ...account };
  if (_acc.password) delete _acc.password;
  if (_acc.phone) _acc.phone = true;

  return _acc;
}
