import React, { useEffect, useState } from 'react';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../DocumentCollectionUtils';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import { currencyMaskOptions } from '../../../../../junction/components/field/masks/currency';
import Field from '../../../../../junction/components/field/InternalField';
import PickerField from '../../../../../junction/components/picker_field/PickerField';
import { useForm } from 'react-hook-form';
import Button from '../../../../../junction/components/button/Button';
import numeral from 'numeral';
import Feedback from '../../../../../junction/components/feedback/Feedback';
import {
  useGraphMutation,
  useGraphQuery,
  isQueryLoading,
} from '../../../../../common/utils/graphql_utils';
import { formSchema } from '../modules/IncomeSchema';
import {
  updateSOFPLiteral,
  timeOptions,
  accommodationOptions,
  getSOFPLiteral,
} from '../modules/actions';
import Loading from '../../../../../junction/components/loading/Loading';
import Stack from '../../../../../junction/layout/stack/Stack';
import { yupResolver } from '@hookform/resolvers';

const path = 'sofp-income';

type PropTypes = {
  dispatch: any;
  application: any;
  documentProgress?: DocumentCollectionProgress;
  params: { id: string };
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
  account?: any;
  saveApplication: (application: any, callback: (app: any) => void) => void;
  push: (path: string) => void;
};

const currencyMask = createNumberMask({
  ...currencyMaskOptions,
  allowDecimal: false,
});

const Income = (props: PropTypes) => {
  const {
    data: sofpData,
    status: sofpStatus,
    isFetching,
  } = useGraphQuery(
    [
      'sofp',
      { document_capture_id: props.documentProgress.document_capture_id },
    ],
    getSOFPLiteral,
  );

  const initialFormData = sofpData?.data?.getSOFP?.income;

  const [saveSOFP, { status, error }] = useGraphMutation(updateSOFPLiteral, {
    throwOnError: true,
  });
  const { control, register, errors, handleSubmit, reset } = useForm({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onBlur',
    defaultValues: sofpData?.data?.getSOFP?.income,
  });

  // This sets the form initial values as we do not have them on first render
  useEffect(() => {
    if (!initialFormData) return;
    reset({ ...initialFormData } as any);
  }, [sofpData]);

  const submit = async (values: any) => {
    const { documentProgress, updateProgress, params, push } = props;
    const sofpId = sofpData?.data?.getSOFP?.id;
    const nextPath = getNextPath(path, documentProgress.stages);
    const progressThrough = () => {
      updateProgress(documentProgress.document_capture_id, ['sofp-income']);
      push(`/app/documents/${params.id}/${nextPath}`);
    };

    try {
      await saveSOFP({ request: { id: sofpId, income: { ...values } } });
    } catch (err) {
      return;
    }

    progressThrough();
  };

  const commonProps = {
    control,
    mask: currencyMask,
    placeholder: '$0,000',
    type: 'text',
  };

  if (isQueryLoading(sofpStatus) || isFetching)
    return <Loading color='purple' />;

  return (
    <div
      className={`${layoutStyles.documentCapturePage} lm-w-1/2 lm-border-t lm-bg-white`}
    >
      <div style={{ maxWidth: '520px' }}>
        <form onSubmit={handleSubmit(submit)}>
          <Stack spacing='m' orientation='vertical'>
            <div className='lm-mt-4 lm-max-w-80'>
              {error && (
                <Feedback type='error'>
                  There was an error updating your information. Please contact
                  your account manager.
                </Feedback>
              )}
              <h1>Total Income</h1>
              <Feedback type='lumi'>
                Please add here your income and select the frequency that your
                salary is paid
              </Feedback>
              <div className='lm-mt-4'>
                <PickerField
                  label='What is your after tax salary?'
                  pickName='salary_frequency'
                  fieldName='salary_amount'
                  options={timeOptions}
                  selectedOption={initialFormData?.salary_frequency || 'WEEKLY'}
                  error={(errors?.salary_amount as any)?.message}
                  format={(event: any) =>
                    numeral((event.target as any).value).value()
                  }
                  attachedToField
                  {...commonProps}
                />
              </div>
            </div>
            <div>
              <h1>Living Expenses</h1>
              <Stack orientation='vertical' spacing='m'>
                <div className='lm-mt-2 lm-max-w-80'>
                  <Feedback className='lm-mb-4' type='lumi'>
                    Select mortgage or rent and the monthly cost of your primary
                    residence. Also the average of your living expenses
                    including utility bills, internet, groceries etc
                  </Feedback>
                  <PickerField
                    label='What is your monthly mortgage or rent cost?'
                    wrapperClassName='lm-mt-2'
                    pickName='accommodation_type'
                    fieldName='accommodation_amount'
                    options={accommodationOptions}
                    selectedOption={
                      initialFormData?.accommodation_type || 'RENT'
                    }
                    error={(errors?.accommodation_amount as any)?.message}
                    format={(event: any) =>
                      numeral((event.target as any).value).value()
                    }
                    attachedToField
                    {...commonProps}
                  />
                </div>
                <div className='lm-mt-2'>
                  <Field
                    label='What is your monthly living cost?'
                    name='monthly_living_cost'
                    type='text'
                    format={(event: any) =>
                      numeral((event.target as any).value).value()
                    }
                    error={(errors?.monthly_living_cost as any)?.message}
                    {...commonProps}
                  />
                </div>
              </Stack>
            </div>
          </Stack>
          <div className='lm-flex lm-justify-center lm-mt-4'>
            <Button
              type='submit'
              children='Next'
              className='lm-w-40'
              loading={isQueryLoading(status)}
              disabled={isQueryLoading(status)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Income;
