import React from 'react';
import { documentCaptureRetrieve } from '../DocumentCaptureActions';

export default (store: any) => ({
  path: 'thanks',
  onEnter: documentCaptureRetrieve(store, null, 'thanks'),
  getComponent(nextState: any, cb: any) {
    const Thanks = React.lazy(() => import('./containers/ThanksContainer'));
    cb(null, Thanks);
  },
});
