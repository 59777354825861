import {
  apiFetch,
  apiGet,
  serverURL,
  apiPost,
  upload,
} from './../common/utils/fetch_utils';
import {
  GET_APPLICATION_SUCCESS,
  SAVED_NAME,
  TOKENS_ARRIVED,
  PHONE_UPDATED,
  GET_LAST_SUBMITTED_APPLICATION_SUCCESS,
  GET_LOAN_SUCCESS,
  ACCOUNTING_REPORT_RETRIEVED,
  BANK_REPORT_RETRIEVED,
  BANK_REPORT_SAVED,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  GET_ACCOUNT_STARTED,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_ERROR,
  SAVE_ACCOUNT_STARTED,
  SAVE_ACCOUNT_SUCCESS,
  SAVE_ACCOUNT_ERROR,
} from './redux/modules/user';

import {
  UPLOADING,
  UPLOADING_PROFIT,
  UPLOADING_BALANCE,
  UPLOADING_GENERIC_DOCS,
  GENERIC_DOCUMENTS_UPLOAD_STARTED,
  GENERIC_DOCUMENTS_UPLOAD_SUCCESS,
  GENERIC_DOCUMENTS_UPLOAD_PROGRESS,
  GENERIC_DOCUMENTS_UPLOAD_ERROR,
} from './redux/modules/ui';
import { DIALOG_SHOW } from './redux/modules/alert';
import * as Errors from './utils/errors';

export const msgBox = (type, title, message) => {
  return dispatch => {
    dispatch({ type: DIALOG_SHOW, message: { title, message, type } });
  };
};

export const msgSuccess = (title, message) => {
  return msgBox('success', title, message);
};

export const msgError = (title, message) => {
  return msgBox('error', title, message);
};

export const msgInfo = (title, message) => {
  return msgBox('info', title, message);
};

export const bankingReportSuccess = response => {
  return dispatch => {
    dispatch({ response, type: BANK_REPORT_RETRIEVED });
  };
};

export const bankingReportSavedSuccess = response => {
  return dispatch => {
    dispatch({ response, type: BANK_REPORT_SAVED });
  };
};

export const accountingReportSuccess = response => {
  return dispatch => {
    dispatch({ response, type: ACCOUNTING_REPORT_RETRIEVED });
  };
};

export const getApplicationSuccess = response => {
  return dispatch => {
    dispatch({ response, type: GET_APPLICATION_SUCCESS });
  };
};
export const getLastSubmittedApplicationSuccess = response => {
  return dispatch => {
    dispatch({ response, type: GET_LAST_SUBMITTED_APPLICATION_SUCCESS });
  };
};

export const savedNameSuccess = response => {
  return dispatch => {
    dispatch({ response, type: SAVED_NAME });
  };
};

export const accountTokensArrived = response => {
  return dispatch => {
    dispatch({ response, type: TOKENS_ARRIVED });
  };
};

export const updatePhoneSuccess = response => {
  return dispatch => {
    dispatch({ response, type: PHONE_UPDATED });
  };
};

export const getLoanSuccess = response => {
  return { response, type: GET_LOAN_SUCCESS };
};

export const getLoanData = (loanId, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/loans/${loanId}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          console.log(JSON.stringify(data.error));
          throw new Error(data.error);
        }
        dispatch(getLoanSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getLoan failed', JSON.stringify(error));
      reject(new Errors.GeneralError(error));
    });
};

export const getLastSubmittedApplication = () => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/application/submitted`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('getLastSubmittedApp succeeded response=', data);
        dispatch(getLastSubmittedApplicationSuccess(data));
      },
    ).catch(function (error) {
      console.log('getLastSubmittedApp failed', error);
    });
};

export const getApplication = (
  searchString,
  resolve,
  reject,
  createOnExpired,
) => {
  const url = `${serverURL}/api/application${searchString}?createOnExpired=${
    createOnExpired || false
  }`;
  return dispatch =>
    apiFetch(
      url,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        dispatch(getApplicationSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getApplication failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const saveApplication = (params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/application/`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('application save succeeded response=', data);
        dispatch(getApplicationSuccess(data));
        resolve(data);
      },
    ).catch(error => {
      console.log('application save failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const saveApplicationFromDocCapture = (params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/application/from-document-capture`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('application save succeeded response=', data);
        dispatch(getApplicationSuccess(data));
        resolve(data);
      },
    ).catch(error => {
      console.log('application save failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const getLoanReasons = (resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/v1/leads/loanReasons`,
      {
        method: 'get',
        headers: {},
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getLoanReasons failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const activateAccountingToken = (vendor, params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/auth/${vendor}/activateToken`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        resolve(data);
      },
    ).catch(error => {
      console.log('application save failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const submitApplication = (application, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/application/submit`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(application),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('application submit succeeded response=', data);
        resolve(data);
      },
    ).catch(function (error) {
      console.log('application submit failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const saveName = (accountId, first, middle, last, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/accounts/${accountId}`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: first,
          middle_name: middle,
          last_name: last,
        }),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('save name succeeded response=', data);
        dispatch(savedNameSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('save name failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const updateDriversLicenseImage = (
  stakeholderId,
  documentUrl,
  resolve,
  reject,
) => {
  console.log(
    'updateDriversLicenseImage, passed:' + stakeholderId + ', ' + documentUrl,
  );
  // FAKE NEWS! Endpoint doesn't exist
  return dispatch =>
    apiFetch(
      `${serverURL}/api/stakeholders/${stakeholderId}/idDocument`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          document_url: documentUrl,
        }),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('updateDriversLicenseImage succeeded response = ', data);
        resolve(data);
      },
    ).catch(function (error) {
      console.log('updateDriversLicenseImage failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const getAccountTokens = (resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/accounts/tokens`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('getAccountTokens succeeded response = ', data);
        dispatch(accountTokensArrived(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getAccountTokens failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const updatePhoneNumber = (phone, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/accounts/updatePhone`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: phone,
        }),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('update phone succeeded response=', data);
        dispatch(updatePhoneSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('update phone failed', error);
      reject(new Errors.GeneralError(error));
    });
};

// company_id: companyId, current_pnl_income, current_pnl_cost_of_sales,current_pnl_expenses,  current_pnl_other_income, current_pnl_other_expenses, current_pnl_from_date,
// current_pnl_to_date, previous_pnl_income, previous_pnl_cost_of_sales, previous_pnl_expenses, previous_pnl_other_income, previous_pnl_other_expenses, previous_pnl_from_date, previous_pnl_to_date
// current_balance_assets, current_balance_liabilities, current_balance_equity, current_balance_from_date
// previous_balance_assets, previous_balance_liabilities, previous_balance_equity, previous_balance_from_date
export const saveAccountingReport = (params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/companies/${params.company_id}/accountingreport`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('saveAccountingReport succeeded response = ', data);
        dispatch(accountingReportSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('saveAccountingReport failed', error);
      reject(new Errors.GeneralError(error));
    });
};
export const getAccountingReport = (params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/companies/${params.company_id}/accountingreport`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('getAccountingReport succeeded response = ', data);
        dispatch(accountingReportSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getAccountingReport failed', error);
      reject(new Errors.GeneralError(error));
    });
};
// bsb, holder, name, account_number, created_at, bank_name
export const saveBankingReport = (params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/companies/${params.company_id}/bankingreport`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('saveBankingReport succeeded response = ', data);
        dispatch(bankingReportSavedSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('saveBankingReport failed', error);
      reject(new Errors.GeneralError(error));
    });
};
export const getBankingReport = (params, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/companies/${params.company_id}/bankingreport`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('getBankingReport succeeded response = ', data);
        dispatch(bankingReportSuccess(data));
        // dispatch a save something somewhere to then check from your page and see it was done successfully. for the caller render.
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getBankingReport failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const getLatestActiveLoanIdByAccountId = (
  searchString,
  resolve,
  reject,
) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/loans/latest${searchString}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log(
          'getLatestActiveLoanIdByAccountId succeeded response = ',
          data,
        );
        resolve(data);
      },
    ).catch(function (error) {
      console.log('getLatestActiveLoanIdByAccountId failed', error);
      reject(new Errors.GeneralError(error));
    });
};

export const getNextOnboardingStep = (email, resolve, reject) => {
  return async dispatch => {
    try {
      const response = await apiGet(`/deals/onboarding-step/${email}`);
      if (!response || !response.success) throw new Error(response.error);
      resolve(response.result);
    } catch (error) {
      console.error(error);
      reject(error);
      throw new Error(`An error ocurred.`);
    }
  };
};

export function verifyEmailError(error) {
  console.log('verifyEmail failed:' + error);
  return { error, type: VERIFY_EMAIL_FAILURE };
}

export function verifyEmailSuccess(response) {
  return dispatch => {
    dispatch({ response, type: VERIFY_EMAIL_SUCCESS });
  };
}

export function verifyEmail(email, token, resolve, reject) {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/accounts/verifyEmail`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          email_verification_token: token,
        }),
      },
      data => {
        if (data.success) {
          dispatch(verifyEmailSuccess(data));
          resolve(data);
        } else {
          console.log('request failed', data.error);
          dispatch(verifyEmailError(data.error));
          reject(data.error);
        }
      },
    ).catch(error => {
      console.log('request failed', error);
      dispatch(verifyEmailError(error));
      reject(error);
    });
}

export function emailSubscribe(passed_mail, resolve, reject) {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/mail/subscribe`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: passed_mail,
        }),
      },
      data => {
        //   dispatch(signupSuccess(userData))
        resolve(data);
      },
    ).catch(error => {
      console.log('request failed', error);
      //   dispatch(signupError(error))
      reject();
    });
}

export function getAccountStarted() {
  return { type: GET_ACCOUNT_STARTED };
}

export function getAccountSuccess(response) {
  return { type: GET_ACCOUNT_SUCCESS, response };
}

export function getAccountError(error) {
  return { type: GET_ACCOUNT_ERROR, error };
}

export function getAccount(callback, reject) {
  return async dispatch => {
    dispatch(getAccountStarted());
    try {
      const response = await apiGet(`${serverURL}/api/accounts/me`);
      if (!response || response.error) {
        throw new Error((response || {}).error || 'Unknown error');
      }
      dispatch(getAccountSuccess(response));
      if (callback) callback(response);
    } catch (err) {
      dispatch(getAccountError(err.message));
      if (reject) reject(err);
    }
  };
}

export function saveAccountStarted() {
  return { type: SAVE_ACCOUNT_STARTED };
}

export function saveAccountSuccess(response) {
  return { type: SAVE_ACCOUNT_SUCCESS, response };
}

export function saveAccountError(error) {
  return { type: SAVE_ACCOUNT_ERROR, error };
}

export function saveAccount(account, callback) {
  return async dispatch => {
    dispatch(saveAccountStarted());
    try {
      const response = await apiPost(
        `${serverURL}/api/accounts/${account.id}`,
        account,
      );
      if (!response || response.error) {
        throw new Error((response || {}).error || 'Unknown error');
      }
      dispatch(saveAccountSuccess(response));
      callback(response);
    } catch (err) {
      dispatch(saveAccountError(err.message));
    }
  };
}

export const uploadStatements = async (
  dealId,
  documentCaptureProgressId,
  values,
  dispatch,
) => {
  const doRequest = (url, type, value) => {
    return new Promise((resolve, reject) => {
      if (!value.type) return resolve();

      let formData = new FormData();
      formData.append('document', value);
      upload(
        url,
        {
          method: 'post',
          headers: {},
          body: formData,
        },
        progress => {
          const val = (progress.loaded / progress.total) * 100;
          if (type === 'balance') {
            dispatch({ type: UPLOADING_BALANCE, progress: val });
          } else if (type === 'profit') {
            dispatch({ type: UPLOADING_PROFIT, progress: val });
          }
        },
      ).then(data => {
        if (!(data && data.success)) {
          reject(data.error);
        }
        resolve(data);
      });
    });
  };
  const baseUrl = `${serverURL}/api/documents/attachments?owner_id=${dealId}&document_capture_progress_id=${documentCaptureProgressId}&owner_type=DEAL&document_type=`;
  const PNL = 'PNL_REPORT';
  const balanceSheet = 'BALANCE_SHEET_REPORT';
  const metadata = { document_capture_progress_id: documentCaptureProgressId };
  dispatch({ type: UPLOADING, isUploading: true });
  const promises = [
    doRequest(`${baseUrl}${PNL}`, 'profit', values['profit_loss'][0]),
    doRequest(
      `${baseUrl}${balanceSheet}`,
      'balance',
      values['balance_sheet'][0],
    ),
  ];
  await Promise.all(promises).catch(err => {
    dispatch(msgError('Error', err.message));
    throw err;
  });
  dispatch({ type: UPLOADING, isUploading: false });
};

function genericDocUploadStarted() {
  return { type: GENERIC_DOCUMENTS_UPLOAD_STARTED };
}

function genericDocUploadSuccess() {
  return { type: GENERIC_DOCUMENTS_UPLOAD_SUCCESS };
}

function genericDocUploadError() {
  return { type: GENERIC_DOCUMENTS_UPLOAD_ERROR };
}

export function uploadGenericDocuments(
  dealId,
  documentCaptureProgressId,
  files,
  finishCallback,
) {
  const baseUrl = `${serverURL}/api/documents/attachments?owner_id=${dealId}&document_capture_progress_id=${documentCaptureProgressId}&owner_type=DEAL&document_type=GENERIC_DOCUMENT`;
  return async dispatch => {
    const uploadFile = async file => {
      const formData = new FormData();
      formData.append('document', file);

      const data = await upload(baseUrl, {
        method: 'post',
        headers: {},
        body: formData,
      });

      if (!(data && data.success)) {
        throw new Error(data.error);
      }

      if (finishCallback) {
        finishCallback();
      }
    };
    try {
      dispatch(genericDocUploadStarted());
      const uploadedFiles = await Promise.all(
        files.map(file => {
          return uploadFile(file);
        }),
      );
      dispatch(genericDocUploadSuccess(uploadedFiles));
    } catch (error) {
      dispatch(genericDocUploadError());
    }
  };
}

export const getStatements = (application, dispatch) => {
  return apiFetch(
    `${serverURL}/api/loans/${application.loan.id}/deal`,
    {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
    dealData => {
      if (!(dealData && dealData.success)) {
        console.log(JSON.stringify(dealData.error));
        throw new Error(dealData.error);
      }
      console.log('getDeal succeeded response=', JSON.stringify(dealData));
      const url = `${serverURL}/api/documents/attachments?owner_id=${dealData.result.id}&owner_type=DEAL&document_type=`;
      const options = {
        method: 'get',
        headers: {},
      };
      const promises = [];
      promises.push(apiFetch(`${url}PNL_REPORT`, options));
      promises.push(apiFetch(`${url}BALANCE_SHEET_REPORT`, options));
      return Promise.all(promises)
        .then(results => {
          const getFile = data => {
            if (!data.success || !data.result || !data.result.length) {
              return null;
            }
            const result = data.result[0];
            return {
              name: result.file_name,
              size: result.size,
            };
          };
          return {
            profit_loss: getFile(results[0]),
            balance_sheet: getFile(results[1]),
          };
        })
        .catch(err => {
          dispatch(msgError('Error', err.message));
          throw err;
        });
    },
  ).catch(function (error) {
    console.log('getDeal failed', JSON.stringify(error));
  });
};

export const getDocumentCaptureStatements = (
  dealId,
  documentCaptureProgressId,
  dispatch,
) => {
  const url = `${serverURL}/api/documents/attachments?owner_id=${dealId}&document_capture_progress_id=${documentCaptureProgressId}&owner_type=DEAL&document_type=`;
  const options = {
    method: 'get',
    headers: {},
  };
  const promises = [];
  promises.push(apiFetch(`${url}PNL_REPORT`, options));
  promises.push(apiFetch(`${url}BALANCE_SHEET_REPORT`, options));
  return Promise.all(promises)
    .then(results => {
      const getFile = data => {
        if (!data.success || !data.result || !data.result.length) {
          return null;
        }
        const result = data.result[0];
        return {
          name: result.file_name,
          size: result.size,
        };
      };
      return {
        profit_loss: getFile(results[0]),
        balance_sheet: getFile(results[1]),
      };
    })
    .catch(err => {
      dispatch(msgError('Error', err.message));
      throw err;
    });
};
export const getDocumentCaptureOther = async (
  dealId,
  documentCaptureProgressId,
  dispatch,
) => {
  const url = `${serverURL}/api/documents/attachments?owner_id=${dealId}&document_capture_progress_id=${documentCaptureProgressId}&owner_type=DEAL&document_type=`;
  const options = {
    method: 'get',
    headers: {},
  };
  try {
    const response = await apiFetch(`${url}GENERIC_DOCUMENT`, options);
    return response.result;
  } catch (err) {
    dispatch(msgError('Error', err.message));
    throw err;
  }
};

export const accountantSubmit = (application, values, dispatch) => {
  const name = values.accountant_name;
  const phone = values.accountant_phone;
  const note = `Contact accountant for financial statements. \n Accountant name: ${name} \n Accountant Phone No: ${phone}`;

  return apiFetch(`${serverURL}/api/loans/${application.loan.id}/notes`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      note,
    }),
  })
    .then(data => {
      if (!data.success) {
        throw new Error(data.error);
      }
      return data;
    })
    .catch(err => {
      dispatch(msgError('Error', err.message));
      throw err;
    });
};
