import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import GenericDocuments from '../components/GenericDocuments';
import { uploadGenericDocuments } from '../../../../CommonCreators';
import { updateProgress as docCaptureProgress } from '../../DocumentCaptureActions';

type GenericDocumentsMapDispatchToProps = {
  uploadGenericDocuments: (
    dealId: string,
    documentCaptureProgressId: string,
    files: any,
  ) => void;
  push: (path: string) => void;
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
};

const mapDispatchToProps: GenericDocumentsMapDispatchToProps = {
  uploadGenericDocuments,
  push,
  updateProgress: docCaptureProgress,
};

function mapStateToProps(state: any) {
  return {
    application: state.user.application,
    uploading: state.ui.generic_doc_uploading,
    uploaded: state.ui.generic_doc_uploaded,
    document_progress: state.documentCapture.progress.document_capture_progress,
    document_capture: state.documentCapture.progress.document_capture,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericDocuments);
