import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form-legacy';
import bindAll from 'lodash/bindAll';
import { push } from 'react-router-redux';
import { companyLookup } from '../modules/actions';
import { saveApplication, msgError } from '../../../../CommonCreators';
import { validateABN, getNumber } from '../../../../utils/validators';
import Base from '../../../../components/base';
import GeoSuggestBox from '../../../../../common/components/geo_suggest_box';
import FormInput from '../../../../../common/components/TextField';
import FormSelect from '../../../../components/form_select';
import * as analyticsUtils from '../../../../utils/analytics';
import formStyle from '../../../../../common/sass/components/_form.scss';
import inputStyle from '../../../../styles/components/_input.scss';
import icons from '../../../../../common/sass/icons/lumi-icon.scss';
import spinner from '../../../../../common/sass/components/_spinner.scss';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Step3 from '../../../../images/step3.svg';
import Button from '../../../../components/button';
import { scrollTop } from '../../../../utils/view';
import { parseGeocodingResponse } from '../../../../../common/utils/google_maps';

export const fields = [
  'industry_code',
  'address',
  'monthly_turnover',
  'time_in_business',
];

const initialValues = {};

const texts = {
  title: 'Business\nInformation',
  subtitle: 'Tell us about your company',
  companyNumberLabel: 'ABN/ACN',
  companyNumberPlaceholder: 'company number',
  abrLinkText: 'Find my ABN/ACN',
  companyNameLabel: 'BUSINESS NAME',
  companyNamePlaceholder: 'select...',
  industryLabel: 'INDUSTRY',
  industryPlaceholder: 'select',
  addressLabel: 'BUSINESS ADDRESS',
  addressPlaceholder: 'search address',
  prevButton: 'Previous',
  nextButton: 'CONTINUE ▶',
};

const abrLink = 'http://abr.business.gov.au/';

const industryOptions = [
  { value: 'A', label: 'Agriculture, Forestry and Fishing' },
  { value: 'B', label: 'Mining' },
  { value: 'C', label: 'Manufacturing' },
  { value: 'D', label: 'Electricity, Gas, Water and Waste Services' },
  { value: 'E', label: 'Construction' },
  { value: 'F', label: 'Wholesale Trade' },
  { value: 'G', label: 'Retail Trade' },
  { value: 'H', label: 'Accommodation and Food Services' },
  { value: 'I', label: 'Transport, Postal and Warehousing' },
  { value: 'J', label: 'Information Media and Telecommunications' },
  { value: 'K', label: 'Financial and Insurance Services' },
  { value: 'L', label: 'Rental, Hiring and Real Estate Services' },
  { value: 'M', label: 'Professional, Scientific and Technical Services' },
  { value: 'N', label: 'Administrative and Support Services' },
  { value: 'O', label: 'Public Administration and Safety' },
  { value: 'OD', label: 'Specialised Reports' },
  { value: 'P', label: 'Education and Training' },
  { value: 'Q', label: 'Health Care And Social Assistance' },
  { value: 'R', label: 'Arts and Recreation Services' },
  { value: 'S', label: 'Other Services' },
  { value: 'X', label: 'Thematic Reports' },
];

function loadFromApplication(app) {
  let company = app.company || {};
  let additionalInfo = app.additional_info || {};

  return {
    company_number: company.company_number,
    industry_code: company.industry_code,
    monthly_turnover: company.monthly_turnover || '',
    time_in_business: additionalInfo.time_in_business_months || '',
    selected_company_index: 1,
    address: company.address || {},
  };
}

function asyncValidate(values /*, dispatch */) {
  return new Promise((resolve, reject) => {
    let errors = {};

    if (errors && Object.keys(errors).length > 0) {
      scrollTop();
      reject(errors);
    } else {
      resolve();
    }
  });
}

function validate(values) {
  const errors = {};
  fields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

class BusinessInfo extends Base {
  constructor(props) {
    super(props);
    const application = this.props.application;
    const label =
      application && application.company && application.company.company_name
        ? application.company.company_name
        : '';
    const companyNumber =
      application && application.company && application.company.company_number
        ? application.company.company_number
        : '';
    const businessNameId =
      application && application.company && application.company.business_name_id
        ? application.company.business_name_id
        : '';
    const type =
      application && application.company && application.company.company_type
        ? application.company.company_type
        : 'OTHER';
    this.state = {
      companyLookupInProgress: false,
      companyNameData: label
        ? [{ label, value: 1, businessNameId, type, companyNumber }]
        : [],
    };

    bindAll(
      this,
      'submit',
      'onCompanyLookupButtonClick',
      'onSuggestSelect',
      'onSuggestNoResults',
    );
    this.onCompanyNumberBlur = this.onCompanyNumberBlur.bind(this);
    this.onCompanyLookupButtonClick =
      this.onCompanyLookupButtonClick.bind(this);
  }

  resolveCompanyName(index) {
    try {
      const data = this.state.companyNameData;
      if (!data || !(index <= data.length)) {
        return '';
      }

      return data[index - 1].label;
    } catch (e) {
      return '';
    }
  }

  resolveCompanyType(index) {
    try {
      const data = this.state.companyNameData;
      if (!data || !(index <= data.length)) {
        return 'SOLE_TRADER';
      }

      return data[index - 1].type || 'SOLE_TRADER';
    } catch (e) {
      return 'SOLE_TRADER';
    }
  }

  resolveCompanyNumber(index) {
    try {
      const data = this.state.companyNameData;
      if (!data || !(index <= data.length)) {
        return '';
      }

      return data[index - 1].companyNumber;
    } catch (e) {
      return '';
    }
  }

  resolveBusinessNameId(index) {
    try {
      const data = this.state.companyNameData;
      if (!data || !(index <= data.length)) {
        return '';
      }

      return data[index - 1].businessNameId;
    } catch (e) {
      return '';
    }
  }

  submit(values, dispatch) {
    return new Promise((resolve, reject) => {
      const index = parseInt(values.selected_company_index);
      let application = {
        company: {
          monthly_turnover: getNumber(values.monthly_turnover),
          industry_code: values.industry_code,
          address: values.address,
        },
        additional_info: {
          time_in_business_months: getNumber(values.time_in_business),
        },
      };

      dispatch(
        saveApplication(
          application,
          async response => {
            let fields = [
              'company_name',
              'business_name_id',
              'company_number',
              'industry_code',
              'company_type',
              'company_status',
              'rent_rate',
              'street',
            ];

            await analyticsUtils.track('leads', {
              action: 'business_info_completed',
              platform: 'website',
              funnel_step: analyticsUtils.funnel_step(
                'business_info',
                analyticsUtils.funnel_step_version(),
              ),
              version: analyticsUtils.funnel_step_version(),
            });
            dispatch(push('/app/director_info'));
          },
          err => {
            dispatch(msgError('Error', err.message));
          },
        ),
      );
    }).catch(console.error);
  }

  onCompanyLookupButtonClick() {
    const {
      fields: { company_number },
      dispatch,
      application,
    } = this.props;
    const isValidAbn = validateABN(company_number.value);
    analyticsUtils.track('leads', {
      action: 'abn_lookup',
      platform: 'website',
      funnel_step: analyticsUtils.funnel_step(
        'business_info',
        analyticsUtils.funnel_step_version(),
      ),
      version: analyticsUtils.funnel_step_version(),
      company_number: company_number.value,
      valid: isValidAbn,
    });

    if (!isValidAbn) {
      return { company_number: 'Invalid ABN/ACN number' };
    }

    this.setState({ companyLookupInProgress: true });
    dispatch(
      companyLookup(
        company_number.value,
        application.loan.id,
        response => {
          this.setState({ companyLookupInProgress: false });

          if (response.success && response.result.length > 0) {
            // dispatch(change('business_info', 'company_number', response.result[0].company_number));
            analyticsUtils.track('leads', {
              action: 'abn_lookup_success',
              platform: 'website',
              funnel_step: analyticsUtils.funnel_step(
                'business_info',
                analyticsUtils.funnel_step_version(),
              ),
              version: analyticsUtils.funnel_step_version(),
              company_number: company_number.value,
              numResults: response.result.length,
            });
            analyticsUtils.track('leads', {
              action: 'abn_verified',
              platform: 'website',
              funnel_step: analyticsUtils.funnel_step(
                'business_info',
                analyticsUtils.funnel_step_version(),
              ),
              version: analyticsUtils.funnel_step_version(),
              company_number: company_number.value,
              valid: validateABN(company_number.value),
            });
            if (response.result) {
              this.setState({
                companyNameData: response.result.map((company, index) => {
                  return {
                    value: index + 1,
                    type: company.company_type,
                    label: company.company_name,
                    companyNumber: company.company_number,
                    businessNameId: company.business_name_id,
                  };
                }),
              });
              if (response.result.length === 1) {
                dispatch(change('business_info', 'selected_company_index', 1));
              }
            }
          } else {
            analyticsUtils.track('leads', {
              action: 'abn_lookup_failed',
              platform: 'website',
              funnel_step: analyticsUtils.funnel_step(
                'business_info',
                analyticsUtils.funnel_step_version(),
              ),
              version: analyticsUtils.funnel_step_version(),
              error: response.error,
            });
            throw new Error(response.error);
          }
        },
        err => {
          analyticsUtils.track('leads', {
            action: 'abn_lookup_failed',
            platform: 'website',
            funnel_step: analyticsUtils.funnel_step(
              'business_info',
              analyticsUtils.funnel_step_version(),
            ),
            version: analyticsUtils.funnel_step_version(),
            error: err,
          });
          console.log('error while lookup: ' + err);
          this.setState({
            companyLookupInProgress: false,
            companyNameData: [],
          });
          this.props.dispatch(
            msgError('Invalid Company', 'Could not find that company'),
          );
        },
      ),
    );
  }

  onSuggestSelect(suggest) {
    const {
      fields: { address },
    } = this.props;
    if (suggest && suggest.gmaps) {
      let gmapObject = parseGeocodingResponse(suggest.gmaps);

      address.onChange(Object.assign({}, gmapObject));
    }
  }

  onSuggestNoResults(userInput) {
    const {
      fields: { address },
    } = this.props;
    address.onChange({ street: userInput, formatted: userInput });
  }

  isSubmitEnabled() {
    const {
      fields: { industry_code, address },
    } = this.props;
    const result =
      industry_code.value !== undefined &&
      !!address.value &&
      ((address.value.formatted && address.value.formatted.length >= 2) ||
        (address.value.street && address.value.street.length >= 2));
    return result;
  }

  async componentDidMount() {
    await analyticsUtils.track('leads', {
      action: 'business_info_started',
      platform: 'website',
      funnel_step: analyticsUtils.funnel_step(
        'business_info',
        analyticsUtils.funnel_step_version(),
      ),
      version: analyticsUtils.funnel_step_version(),
    });
  }

  onCompanyNumberBlur() {
    const companyNumber = this.props.fields.company_number.value;
    if (!this.state.companyLookupInProgress && validateABN(companyNumber)) {
      this.onCompanyLookupButtonClick();
    }
  }

  render() {
    const {
      fields: {
        company_number,
        industry_code,
        address,
        selected_company_index,
        monthly_turnover,
        time_in_business,
      },
      handleSubmit,
      submitting,
    } = this.props;
    const { companyLookupInProgress } = this.state;

    const names = this.state.companyNameData;
    let companyNameSelectProps = {
      fieldData: !names.length ? {} : selected_company_index || 1,
      disabled: !names.length,
      style: !names.length ? { display: 'none' } : {},
      label: 'Business name',
      id: 'business-info-company-name',
      optionsData: names,
    };

    let industrySelectProps = {
      fieldData: industry_code,
      label: 'Industry',
      id: 'business-info-industry',
      optionsData: industryOptions,
    };

    let avgMonthlyProps = {
      fieldData: monthly_turnover,
      label: 'Average monthly turnover',
      type: 'text',
      mask: createNumberMask({ prefix: '$' }),
    };

    const timeInBusinessProps = {
      fieldData: time_in_business,
      label: 'Time in business (months)',
      type: 'text',
      mask: createNumberMask({ prefix: '' }),
    };

    const addressProps = {
      fieldData: address,
      onSuggestSelect: this.onSuggestSelect,
      onSuggestNoResults: this.onSuggestNoResults,
      label: 'Business address',
    };

    const submitEnabled = this.isSubmitEnabled();
    const buttonDisabled = submitting || !submitEnabled;

    return (
      <div className={formStyle['mp-50']}>
        <h1 className='title sm-padded text-center'>About your business</h1>
        <form
          className={formStyle.container}
          onSubmit={handleSubmit(this.submit)}
        >
          <div className={inputStyle.wrapper}>
            <div className={inputStyle.rightActions}>
              <span
                onClick={this.onCompanyLookupButtonClick}
                className='clickable md-icon ml-2'
              >
                {!companyLookupInProgress && (
                  <i className={icons['icon-search-simple']} />
                )}
                {companyLookupInProgress && (
                  <i
                    className={[
                      spinner.spinner,
                      spinner.purple,
                      spinner.small,
                    ].join(' ')}
                  />
                )}{' '}
                Check ABN/ACN
              </span>
            </div>
          </div>
          <FormSelect {...companyNameSelectProps} />
          <GeoSuggestBox {...addressProps} />
          <FormInput {...avgMonthlyProps} />
          <FormInput {...timeInBusinessProps} />
          <FormSelect {...industrySelectProps} />
          <Button
            disabled={buttonDisabled}
            loading={submitting}
            label='Next step'
          />
        </form>
      </div>
    );
  }
}

BusinessInfo.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  application: PropTypes.object,
};

function mapStateToProps(state) {
  let app = state.user && state.user.application;

  return {
    application: state.user.application,
    initialValues: app ? loadFromApplication(app) : initialValues,
  };
}

export default reduxForm(
  {
    form: 'business_info',
    fields,
    asyncValidate,
    asyncBlurFields: ['company_number', 'selected_company_index'],
    validate,
    initialValues,
  },
  mapStateToProps,
)(BusinessInfo);
