/**
 *  Name: AuthIndex
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import AuthLayout from '../../layouts/OnboardingLayout/AuthLayout';
import ForgotPasswordRoute from './ForgotPassword';
import LoginRoute from './Login';
import ResetPasswordRoute, { SetUpPasswordRoute } from './ResetPassword';
import { handleLogout } from '../../utils/auth';

const logoutRoute = store => ({
  path: 'logout',
  onEnter: handleLogout(store),
});

export default store => ({
  path: '/app/',
  component: AuthLayout,
  childRoutes: [
    ForgotPasswordRoute(store),
    LoginRoute(store),
    ResetPasswordRoute(store),
    SetUpPasswordRoute(store),
    logoutRoute(store),
  ],
});
