export const GOT_REASONS = 'GOT_REASONS';
export const GETTING_REASONS = 'GETTING_REASONS';

export default function reason(state = {}, action) {
  switch (action.type) {
    case GOT_REASONS:
      return {
        isLoading: false,
        reasons: action.response.reasons,
      };
    case GETTING_REASONS:
      return {
        isLoading: true,
      };
    default:
      return state;
  }
}
