import { isPlainObject, pickBy } from 'lodash';
import React, {
  Ref,
  RefCallback,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Control, FormProvider, useForm, useWatch } from 'react-hook-form';
import { JunctionTheme } from './junction.t';
import { Screen } from './layout/layout.t';
import { Alignment, Justification, Orientation } from './types';

export function useOutsideAlerter(ref: any, onClose: any) {
  function handleClickOutside(event: Event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', function (event) {
      const key = event.key; // Or const {key} = event; in ES6+
      if (key === 'Escape') onClose();
    });
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export const JunctionThemeProvider = React.createContext(
  null as unknown as JunctionTheme,
);

export const useJunctionTheme = () => {
  const providedTheme = useContext(JunctionThemeProvider);
  if (!providedTheme) {
    console.error(
      'Could not find a theme provider. Using the default [junction] theme instead.',
    );
    return 'junction';
  }
  return providedTheme;
};

/**
 * Use this function to retrieve the class you have styled for either a junction or junction internal
 * component. The purpose of this function is to abstract away the extraction of classNames for
 * the theme which has been provided to the app at the root level
 * @param overrides An object of format { [theme]: { [override: 'className(s)' ]} }
 * @param override The override you want to apply from the provided overrides
 * @param themeOverride If you have a custom override object outside the boundaries of junction, supply that here
 */
export const useClassNameOverride = (
  overrides: any,
  override = '',
  themeOverride?: string,
) => {
  const providedTheme = useContext(JunctionThemeProvider);
  const theme = themeOverride || providedTheme || 'junction';
  const themeOverrides = overrides[theme] || {};
  const stateOverride = themeOverrides[override] || '';
  return stateOverride;
};

/**
 * Given a variable, choose a className depending on the value of that variable
 * e.g. given the function is called with:
 *
 * getClassName(state, [
 *      ['active', 'green-text'],
 *      ['error', 'red-text'],
 * ])
 *
 * given state = 'active' the function will return 'green-text'
 *
 * This is useful when writing conditional logic depending on some
 * component properties. I.e. rather than writing the above as a bunch
 * of if statements, just call this function with an array of tuples
 *
 * @param variable
 * @param classTuples
 */
export function getClassName<T>(variable: T, classTuples: [T, string][]) {
  return (classTuples.find(tuple => tuple[0] === variable) || [])[1] || '';
}

export function deepPick<T>(object: any) {
  const _object = pickBy(object || {});
  const result = Object.keys(_object).reduce(
    (pickedObject: any, currentKey: string) => {
      if (isPlainObject(_object[currentKey])) {
        pickedObject[currentKey] = deepPick(_object[currentKey]);
        return pickedObject;
      }
      pickedObject[currentKey] = _object[currentKey];
      return pickedObject;
    },
    {} as any,
  );
  return result;
}
/**
 * the useWatch will cause the component to rerender every time, by putting the
 * watch inside its own component, isolated to everything else means that it
 * limits the re-renders to just this small component rather than the whole
 * outer component, significantly improves performance
 */
export const WatchIsolator = ({
  control,
  name,
}: {
  control: Control;
  name: string;
}) => {
  const value = useWatch({
    control,
    name,
    defaultValue: '', // default value before the render
  });
  return <React.Fragment>{value}</React.Fragment>;
};

export const isMobileDevice = () => {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  ) {
    return true;
  }
  return false;
};

/**
 * Registers a reference to the provided pathname, which can be an
 * arbitrary string in reference to the pathname being supplied by
 * react-router or any other routing functionality and restores the
 * scroll position back to 0 on that route change.
 * @param pathname
 */
export const useScrollRestore = (pathname: string) => {
  const prevLocation = useRef(pathname);

  useEffect(() => {
    if (prevLocation.current !== pathname) {
      window.scrollTo(0, 0);
      prevLocation.current = pathname;
    }
  }, [pathname]);
};

type RefArray = { ref: any; name?: string }[];

/**
 * Util that keeps track of the refs for each input field, and ties into the
 * errors from react hook form. When there is a validation error, then scroll
 * the first errored field into view Returns a register function which will
 * create a ref, this should be added to each field in the form
 * @param control React hook form control that comes from 'useForm
 */
export function useFieldValidationFocus(
  formControl: Control,
): RefCallback<any> {
  // export function useFieldValidationFocus(formControl: Control): (name: string) => void {
  const refs = useRef<RefArray>([]);
  useEffect(() => {
    const errors = (formControl as any)?.errorsRef?.current;
    if (!errors) return;

    // Grab the first item in errors object from react hook form.
    // This will be the name of the field
    const firstErrorName = Object.keys(errors)[0];

    const getInputElem = (ref: any) => ref?.inputElement || ref;
    const refObj = (refs?.current || []).find(
      x => (x.name || getInputElem(x.ref)?.name) === firstErrorName,
    );
    if (!refObj?.ref) return;

    const ref = refObj.ref;
    const inputElem = getInputElem(ref);

    // Add guard here to stop the page from crashing if we cant find the func
    if (typeof inputElem.scrollIntoView === 'function') {
      // Now that we have found the first error, scroll the page into view
      inputElem.scrollIntoView();
    }

    if (typeof inputElem.focus === 'function') {
      inputElem.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(formControl as any).formState?.isSubmitting]);

  return useCallback((ref: Ref<any>) => {
    if (refs.current) {
      refs.current.push({
        ref,
      });
    }
    return ref;
  }, []);
}

export const withStoryForm = (config: any) => (Story: any) => {
  const form = useForm(config || {});
  return (
    <FormProvider {...form}>
      <Story />
    </FormProvider>
  );
};

export const responsiveStackClassNamesByBreakpoint: Record<
  Screen,
  Record<Orientation, string>
> = {
  base: {
    horizontal: 'lm-stack-horizontal',
    vertical: 'lm-stack-vertical',
  },
  smallMobile: {
    horizontal: 'xsm:lm-stack-horizontal',
    vertical: 'xsm:lm-stack-vertical',
  },
  tablet: {
    horizontal: 'sm:lm-stack-horizontal',
    vertical: 'sm:lm-stack-vertical',
  },
  lsTablet: {
    horizontal: 'md:lm-stack-horizontal',
    vertical: 'md:lm-stack-vertical',
  },
  smDesktop: {
    horizontal: 'lg:lm-stack-horizontal',
    vertical: 'lg:lm-stack-vertical',
  },
  lgDesktop: {
    horizontal: 'xl:lm-stack-horizontal',
    vertical: 'xl:lm-stack-vertical',
  },
};

export const responsiveAlignmentClassNamesByBreakpoint: Record<
  Screen,
  Record<Alignment, string>
> = {
  base: {
    start: 'lm-items-start',
    center: 'lm-items-center',
    end: 'lm-items-end',
    baseline: 'lm-items-baseline',
    stretch: 'lm-items-stretch',
    initial: '',
  },
  smallMobile: {
    start: 'xsm:lm-items-start',
    center: 'xsm:lm-items-center',
    end: 'xsm:lm-items-end',
    baseline: 'xsm:lm-items-baseline',
    stretch: 'xsm:lm-items-stretch',
    initial: '',
  },
  tablet: {
    start: 'sm:lm-items-start',
    center: 'sm:lm-items-center',
    end: 'sm:lm-items-end',
    baseline: 'sm:lm-items-baseline',
    stretch: 'sm:lm-items-stretch',
    initial: '',
  },
  lsTablet: {
    start: 'md:lm-items-start',
    center: 'md:lm-items-center',
    end: 'md:lm-items-end',
    baseline: 'md:lm-items-baseline',
    stretch: 'md:lm-items-stretch',
    initial: '',
  },
  smDesktop: {
    start: 'lg:lm-items-start',
    center: 'lg:lm-items-center',
    end: 'lg:lm-items-end',
    baseline: 'lg:lm-items-baseline',
    stretch: 'lg:lm-items-stretch',
    initial: '',
  },
  lgDesktop: {
    start: 'xl:lm-items-start',
    center: 'xl:lm-items-center',
    end: 'xl:lm-items-end',
    baseline: 'xl:lm-items-baseline',
    stretch: 'xl:lm-items-stretch',
    initial: '',
  },
};

export const responsiveJustificationClassNamesByBreakpoint: Record<
  Screen,
  Record<Justification, string>
> = {
  base: {
    start: 'lm-justify-start',
    center: 'lm-justify-center',
    end: 'lm-justify-end',
    between: 'lm-justify-between',
    around: 'lm-justify-around',
    evenly: 'lm-justify-evenly',
    initial: '',
  },
  smallMobile: {
    start: 'xsm:lm-justify-start',
    center: 'xsm:lm-justify-center',
    end: 'xsm:lm-justify-end',
    between: 'xsm:lm-justify-between',
    around: 'xsm:lm-justify-around',
    evenly: 'xsm:lm-justify-evenly',
    initial: '',
  },
  tablet: {
    start: 'sm:lm-justify-start',
    center: 'sm:lm-justify-center',
    end: 'sm:lm-justify-end',
    between: 'sm:lm-justify-between',
    around: 'sm:lm-justify-around',
    evenly: 'sm:lm-justify-evenly',
    initial: '',
  },
  lsTablet: {
    start: 'md:lm-justify-start',
    center: 'md:lm-justify-center',
    end: 'md:lm-justify-end',
    between: 'md:lm-justify-between',
    around: 'md:lm-justify-around',
    evenly: 'md:lm-justify-evenly',
    initial: '',
  },
  smDesktop: {
    start: 'lg:lm-justify-start',
    center: 'lg:lm-justify-center',
    end: 'lg:lm-justify-end',
    between: 'lg:lm-justify-between',
    around: 'lg:lm-justify-around',
    evenly: 'lg:lm-justify-evenly',
    initial: '',
  },
  lgDesktop: {
    start: 'xl:lm-justify-start',
    center: 'xl:lm-justify-center',
    end: 'xl:lm-justify-end',
    between: 'xl:lm-justify-between',
    around: 'xl:lm-justify-around',
    evenly: 'xl:lm-justify-evenly',
    initial: '',
  },
};

/**
 * This function is required because the package we have installed called
 * react-copy-to-clipboard doesn't copy rich text properly. When copying rich
 * text, the package only stores the text/html data to the clipboard. This means
 * you cannot paste to plain text inputs because it will try to retrieve the
 * text/plain data which does not exist.
 *
 * This function saves both text/plain and text/html data so that you can paste
 * to both kinds of inputs.
 *
 * @param text - The text to be copied to the clipboard
 * @config text.plainText - The text/plain version of the text to be copied
 * @config text.htmlText - The text/html version of the text to be copied
 * @returns true if copy was successful, false otherwise
 */
export const copyToClipboard = async ({
  plainText,
  htmlText,
}: {
  plainText: string;
  htmlText: string;
}) => {
  if (window.isSecureContext === true) {
    const blobPlain = new Blob([plainText], { type: 'text/plain' });
    const blobHtml = new Blob([htmlText], { type: 'text/html' });
    const data = [
      // eslint-disable-next-line no-undef
      new ClipboardItem({
        ['text/plain']: blobPlain,
        ['text/html']: blobHtml,
      }),
    ];

    return await navigator.clipboard.write(data).then(
      () => true,
      () => false,
    );
  }

  return false;
};
