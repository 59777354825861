import React, { FC } from 'react';

export type HiddenProps = React.PropsWithChildren<{
  when: boolean;
}>;

export const Hidden: FC<HiddenProps> = props => {
  const { when, children } = props;

  if (when !== undefined && when) return null;
  return <React.Fragment>{children}</React.Fragment>;
};

export default Hidden;
