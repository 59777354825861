/**
 *  Name: ForgotPassword
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */
import React from 'react';

export default (store: any) => ({
  path: 'forgot_password',
  getComponent(nextState: any, cb: any) {
    const ForgotPassword = React.lazy(
      () => import('./containers/ForgotPasswordContainer'),
    );
    cb(null, ForgotPassword);
  },
});
