import React, { useState } from 'react';
import Select from '../../../../../junction/components/select/Select';
import Field from '../../../../../junction/components/field/InternalField';
import { Button } from '../../../../../junction/components/button/Button';
import Stack from '../../../../../junction/layout/stack/Stack';
import PickerField from '../../../../../junction/components/picker_field/PickerField';
import { currencyMask, numberMask } from '../modules/assetUtils';
import numeral from 'numeral';
import ActionButton from '../../../../../junction/components/action_button/ActionButton';

type Props = {
  control?: any;
  register?: any;
  formSubmit: any;
  errors?: any;
  isDisabled?: boolean;
  values?: any;
};

const vehicleTypeOptions = [
  { label: 'Car', value: 'CAR' },
  { label: 'Truck/Van', value: 'TRUCK_OR_VAN' },
  { label: 'Motorbike', value: 'MOTORBIKE' },
];

const VehicleForm = (props: Props) => {
  const { control, register, formSubmit, errors, isDisabled, values } = props;
  const commonProps = {
    control,
    disabled: isDisabled,
  };
  return (
    <form onSubmit={formSubmit}>
      <Stack orientation='vertical' spacing='m'>
        <Select
          label=''
          options={[]}
          name='type'
          wrapperClassName='lm-hidden'
          value='vehicle'
          control={control}
        />
        <PickerField
          label='Vehicle Type'
          pickName='vehicle_type'
          fieldName=''
          inputClassName='lm-hidden'
          options={vehicleTypeOptions}
          selectedOption={values?.vehicle_type || 'CAR'}
          error={(errors?.vehicle_type as any)?.message}
          isFormPicker
          {...commonProps}
        />
        <Stack orientation='horizontal' spacing='m'>
          <Field
            label='Make'
            name='make'
            type='text'
            placeholder={values?.make}
            disabled={isDisabled}
            error={(errors?.make as any)?.message}
            {...commonProps}
          />
          <Field
            label='Model'
            name='model'
            type='text'
            placeholder={values?.model}
            disabled={isDisabled}
            error={(errors?.model as any)?.message}
            {...commonProps}
          />
        </Stack>
        <Stack spacing='m' orientation='horizontal'>
          <Field
            label='Year'
            name='year'
            type='text'
            disabled={isDisabled}
            placeholder={values?.year || '2019'}
            error={(errors?.year as any)?.message}
            mask={numberMask}
            {...commonProps}
          />
          <Field
            label='Estimated Value'
            name='estimated_value'
            type='text'
            placeholder={
              values?.estimated_value ? `$${values?.estimated_value}` : '$0'
            }
            disabled={isDisabled}
            error={(errors?.estimated_value as any)?.message}
            mask={currencyMask}
            format={(event: any) =>
              numeral((event.target as any).value).value()
            }
            {...commonProps}
          />
        </Stack>
        <div className='lm-flex lm-justify-center'>
          {isDisabled && (
            <ActionButton type='submit' isSecondary className='lm-mb-4'>
              - Remove Asset
            </ActionButton>
          )}
          {!isDisabled && (
            <ActionButton type='submit' isSecondary>
              + Add to list
            </ActionButton>
          )}
        </div>
      </Stack>
    </form>
  );
};

export default VehicleForm;
