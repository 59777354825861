export class ExperimentObserver {
  static listeners: any[] = [];

  static bindListener(func: any) {
    this.listeners.push(func);
  }

  static publish(experimentGroup: string) {
    this.listeners.forEach(listener => {
      listener(experimentGroup);
    });
  }
}
