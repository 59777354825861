const defaults = {
  APP_NAME: 'broker',
  DIST: 'dist/broker',
  LOCAL_STORAGE_NAME: 'broker_access_token',
  LOCAL_STORAGE_USER_NAME: 'user',
  LOGIN_PATH: '/broker/login',
  ERROR_LOGGING: true,
  LINK_TIMEOUT_MS: 60000,
  ROOT_ROUTE: `/broker`, // this used to be the 'basename' for the React router
  STAFF_PHOTOS_BUCKET: 'https://www.lumi.com.au/staff-images',
  DEFAULT_SALES_ACCOUNT: {
    email: 'sales@lumi.com.au',
    phone: '1300 005 864',
  },
  AUTH0_CLIENT_ID: 'YOxr4uWgFSsVHEbNeKj84RFvhFJunoYK',
  AUTH0_MANAGEMENT_DOMAIN: 'broker.au.auth0.com',
};

const staging = {
  LINK_TIMEOUT_MS: 290000,
  AUTH0_CLIENT_ID: 'ZpH65pifSRL18Ov0961mMFYqxxzTxNLT',
};

const devDeploy = {
  LINK_TIMEOUT_MS: 290000,
  AUTH0_CLIENT_ID: 'gdNQbBjGhFCNdcYBlUrAzk9znDTcGYhm',
};

const production = {
  LINK_TIMEOUT_MS: 870000,
  AUTH0_CLIENT_ID: 'ocWG71NION1fMRSWiYDCbqI8HD70CkZV',
  AUTH0_MANAGEMENT_DOMAIN: 'lumi-ext.au.auth0.com',
};

const client_config = {
  staging,
  production,
  devDeploy,
};

/**
 * @param {string} environment
 * @param {string} devVersion
 */
export default function (environment, devVersion) {
  const environmentIncludingDevDeploy =
    devVersion && devVersion !== 'null' ? 'devDeploy' : environment;

  if (
    environmentIncludingDevDeploy !== 'staging' &&
    environmentIncludingDevDeploy !== 'production' &&
    environmentIncludingDevDeploy !== 'devDeploy'
  ) {
    return defaults;
  }

  return {
    ...defaults,
    ...(client_config[environmentIncludingDevDeploy] || {}),
  };
}
