import {
  DocumentCaptureStageKey,
  DocumentCaptureProgress,
  DocumentCapture,
} from 'common/api/dataTypes/documentCapture';
import { last } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { QueryConfig, queryCache, useMutation, useQuery } from 'react-query';
import { MutateFunction } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  trackActionByApp,
  trackWithEnv,
} from '../../../common/utils/analytics';
import { usePublicGraphMutation } from '../../../common/utils/graphql_utils';
import { getUser } from '../../../common/utils/utils';
import {
  GetDocumentCaptureProgressResult,
  IUpdateDocCaptureRequest,
  getDocumentCaptureProgress,
  updateDocumentCapture as updateDocumentCaptureAction,
} from '../../layouts/actions/DocumentCaptureLayoutActions';
import { DocumentCaptureStages } from '../../layouts/viewmodels/DocumentCaptureLayoutViewModel';
import {
  ApplicationInfo,
  requalifyDeal,
} from '../Onboarding/layout/modules/OnboardingActions';
import {
  getFlowName,
  getLocalApplication,
} from '../Onboarding/utils/OnboardingUtils';
import {
  DocCapRoute,
  getDocCapRouteContext,
} from './DocumentCaptureRouteConfig';

export const DocCapState = createContext({} as DocCapContext);
export const useDocCapState = () => useContext(DocCapState);

export type DocCapContext = {
  /**
   * Once the form has been submitted, trigger this method to navigate to the
   * next step. This will return the next item in the RouteConfig
   */
  nextStep: (redirect?: boolean) => void;

  /**
   * Route change event listener. Call this method when the route changes.
   * This will make sure that the current route context is updated correctly
   */
  onRouteChange: (currentRoute: string) => void;

  currentRouteContext: DocCapRoute | undefined;

  experimentGroup: string | null;

  documentCaptureId: string;

  updateDocumentCapture: MutateFunction<
    any,
    any,
    IUpdateDocCaptureRequest,
    void
  >;

  documentCaptureProgress: any;

  documentCapture: any;

  isLoading: boolean;

  serverError: Error | undefined;

  isUpdatingDetails: boolean;

  partner: string | undefined;

  localApplication: ApplicationInfo | null;
};

type RouterFuncs = {
  /**
   *   Accept a pushRoute function through the args here so that we can have a
   *   platform agnostic way of changing the route. Since react and react-native
   *   implement their own history api
   */
  pushRoute: (route: string, redirect?: boolean) => void;

  /**
   * Get the current route nah from the view in a platform agnostic way web vs natie
   */
  getRoute: () => string;
};

export enum ExperimentGroup {
  Control = 'control',
  Variant = 'variant',
}

const docCapProgressQueryKey = 'progress';

export const useDocumentCaptureProgress = (
  documentCaptureId: string,
  options?: QueryConfig<GetDocumentCaptureProgressResult, unknown>,
) => {
  return useQuery<GetDocumentCaptureProgressResult>(
    [docCapProgressQueryKey, documentCaptureId],
    getDocumentCaptureProgress,
    options,
  );
};

export function useDocCapViewModel(routerFuncs?: RouterFuncs): DocCapContext {
  const { id: documentCaptureId } = useParams() as { id: string };
  const [currentRouteContext, setCurrentRouteContext] = useState<DocCapRoute>();
  const [lastSavedStage, setLastSavedStage] = useState<string | null>(null);
  const experimentGroup = ExperimentGroup.Control;

  const [localApplication, setLocalApplication] = useState(
    getLocalApplication(),
  );

  const [{ ajs_anonymous_id: anonymous_id }] = useCookies([
    'ajs_anonymous_id',
  ]) as any;

  const { data: progressResponse, isLoading } =
    useDocumentCaptureProgress(documentCaptureId);

  const [
    updateDocumentCapture,
    { error: documentCaptureError, isLoading: isUpdatingDetails },
  ] = useMutation(updateDocumentCaptureAction, {
    // before we save we should note what stage we're saving to reference later
    onMutate: () => {
      setLastSavedStage(location.pathname);
    },
    onSuccess: () => queryCache.invalidateQueries(docCapProgressQueryKey),
    throwOnError: true,
    onError: (err: any) => {
      err.identifier = 'generic-error';
    },
  });

  const [requalifyDealAction] = usePublicGraphMutation(requalifyDeal, {
    throwOnError: true,
    onError(err: any) {
      trackActionByApp('requalification_error', err.message);
    },
  });

  const docCapture = progressResponse?.document_capture;

  const partner = docCapture?.partner;
  useEffect(() => {
    const isSuccessPage =
      last((location?.pathname || '').split('/')) === 'success';
    // If the doc capture has expired, then redirect to the expiry page
    if (docCapture?.id && !docCapture.active && !isSuccessPage) {
      routerFuncs?.pushRoute(`/hub/document-capture/expired`, true);
    }
  }, [docCapture?.active, routerFuncs, docCapture?.id]);

  const getCurrentRouteInfo = useCallback(
    (currentRoute: string) => {
      const routeInfo = getDocCapRouteContext(currentRoute, experimentGroup);

      if (!routeInfo) {
        // tslint:disable-next-line
        console.warn(
          `Could not find the current single doc cap route config for current route ${currentRoute}. Rediecting to /expired`,
        );
        routerFuncs?.pushRoute(`/hub/document-capture/expired`);
        return;
      }
      return routeInfo;
    },
    [experimentGroup, routerFuncs],
  );

  useEffect(() => {
    // in the onboarding if we have pre-done steps, iterate over them and update the stages
    const updatePreCompletedStages = async () => {
      if (
        localApplication?.precompleted_stages &&
        localApplication?.precompleted_stages?.length > 0
      ) {
        const body = localApplication.precompleted_stages.map(stage => {
          return {
            name: stage.name as DocumentCaptureStages,
            value: stage.value,
          };
        });

        await updateDocumentCapture({
          id: documentCaptureId,
          body: { stages: body },
        });
      }

      if (localApplication?.loan?.requalify) {
        // if they have the requlification object then they've come from
        // requalify and need to be set to to contact

        await requalifyDealAction({
          id: documentCaptureId,
        });
      }
    };

    updatePreCompletedStages();
  }, [
    anonymous_id,
    documentCaptureId,
    localApplication?.loan?.requalify,
    localApplication?.precompleted_stages,
    requalifyDealAction,
    updateDocumentCapture,
  ]);

  // given a stage url, derive the particular stage
  const getStageName = (path: string | null) => {
    if (!path) return;

    const currentRoute = path.substring(path.lastIndexOf('/') + 1);
    // the first entry point into doc cap is the /single root page
    // so we exclude this page
    if (currentRoute !== 'single') {
      return currentRoute as DocumentCaptureStageKey;
    }

    return null;
  };

  const onRouteChange = (currentRoute: string) => {
    // allow navigation to about page after final success page
    if (currentRoute !== '/about') {
      const routeInfo = getCurrentRouteInfo(currentRoute);
      // we want to track every step except the initial landing page
      // as it's not actually a step
      if (routeInfo?.url !== '/single') {
        trackWithEnv('step_started', {
          step_title: routeInfo?.title,
          step_name: getStageName(location.pathname),
          step_category: routeInfo?.progressKey,
          document_capture_id: documentCaptureId,
          user_id: getUser()?.id,
          flow_name: getFlowName(localApplication),
        });
        setCurrentRouteContext(routeInfo);
      }
    }
  };

  // The response of the step is async so when this updates
  useEffect(() => {
    if (!lastSavedStage) return;
    const stepName = getStageName(lastSavedStage);
    // if the step is valid, then we can log it to segment
    if (stepName) {
      const updatedStage =
        progressResponse?.document_capture_progress?.stages[stepName];

      const routeInfo = getCurrentRouteInfo(lastSavedStage);
      if (updatedStage) {
        trackWithEnv('step_completed', {
          step_title: routeInfo?.title,
          step_name: stepName,
          step_category: routeInfo?.progressKey,
          url: location.origin + lastSavedStage,
          document_capture_id: documentCaptureId,
          user_id: getUser()?.id,
          flow_name: getFlowName(localApplication),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressResponse]); // this needs to stay as it is, otherwise segment will receive step_completed 5 times

  const nextStep = (redirect?: boolean) => {
    if (!routerFuncs) return;
    const routeInfo = getCurrentRouteInfo(routerFuncs.getRoute());

    if (!routeInfo?.nextRoute) {
      // tslint:disable-next-line
      console.error(
        `No next step found in the route config for current route ${routeInfo?.url}`,
      );
      return;
    }

    if (typeof routeInfo.nextRoute !== 'function') {
      throw new Error(
        `Next route is not a function. Please make sure it is a function that returns a route`,
      );
    }

    const stages = progressResponse?.document_capture_progress?.stages || {};
    routerFuncs?.pushRoute(
      routeInfo.nextRoute({
        documentCaptureId,
        stages: Object.keys(stages),
        routeInfo,
        experimentGroup: experimentGroup as string,
      }),
      redirect,
    );
  };

  return {
    isLoading,
    nextStep,
    currentRouteContext,
    onRouteChange,
    experimentGroup,
    documentCaptureId,
    updateDocumentCapture,
    documentCaptureProgress: progressResponse?.document_capture_progress || {},
    documentCapture: progressResponse?.document_capture || {},
    serverError: documentCaptureError,
    isUpdatingDetails,
    partner,
    localApplication,
  };
}
