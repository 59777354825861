import { INDUSTRY_CHANGED } from '../../../../redux/modules/ui';
import { apiFetch, serverURL } from '../../../../../common/utils/fetch_utils';
import * as Errors from '../../../../utils/errors';
import { msgError } from '../../../../CommonCreators';

export function industryChanged(newIndustry) {
  return { newIndustry, type: INDUSTRY_CHANGED };
}

export const companyLookup = (companyNumber, loanId, resolve, reject) => {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/companies/lookup`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_number: companyNumber,
          loan_id: loanId,
        }),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        resolve(data);
      },
    ).catch(function (error) {
      dispatch(msgError('Error', error.message));
      reject(new Errors.GeneralError(error));
    });
};
