import { motion } from 'framer-motion';
import React, { ReactElement, useMemo } from 'react';
import * as analyticsUtils from '../../../common/utils/analytics';
import { isAutoLayoutChild } from '../../../common/utils/animation_utils';
import { mergeClasses } from '../../../common/utils/utils';
import Negative from '../../assets/negative.svg';
import Hidden from '../../layout/hidden/Hidden';
import { ResponsiveBreakPoint } from '../../layout/layout.t';
import Stack from '../../layout/stack/Stack';
import { Size, Spacing } from '../../types';
import { getClassName, useClassNameOverride } from '../../utils';
import Loading from '../loading/Loading';
import Shown from '../../layout/shown/Shown';

export type Props = {
  children?: string | any;
  /**
   * The button type to be used in forms
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * On click handlers
   */
  onClick?: (e: any) => any;
  id?: string;
  className?: string;
  name?: string;
  /**
   * This disables the button and prvents a click action
   * @default false
   */
  disabled?: boolean;
  loading?: boolean;
  /**
   * How big the button is
   * @default m
   */
  size?: Size | 'xxs';
  isFullWidth?: boolean | ResponsiveBreakPoint<boolean>;
  style?: any;
  trackClick?: boolean;
  secondary?: boolean;
  neutral?: boolean;
  destructive?: boolean;
  showNegativeIcon?: boolean;
  tertiary?: boolean;
  tertiaryColor?: 'navy' | 'pink' | 'purple';
  base?: boolean;
  tabIndex?: number;
  icon?: ReactElement;
  bgColor?: string;
  /**
   * Framer motion animation props
   */
  animation?: any;
  /**
   * Stack spacing, to override default tailwind spacing class, use none and pass in classname in icon component
   */
  spacing?: Spacing | 'none';
  rounded?: 'full' | 'card' | 'sm' | 'md' | 'lg';
  isLeftIcon?: boolean;
  ariaLabel?: string; // for the button with icon if can not do queryByRole with name, do getByLabelText
  ariaDescribedBy?: string;
  form?: string;
  value?: string;
  'data-transaction-name'?: string;
};

const buttonBaseClass = `
    lm-outline-none
    lm-font-demi
    focus:lm-outline-none
    lm-leading-sm
    lm-flex
    lm-justify-center
    lm-items-center
    lm-relative
`;

const primaryClass = `
    ${buttonBaseClass}
    lm-bg-base-pink
    lm-text-white
    lm-focus-yellow
    active:lm-shadow-button-active
    hover:lm-bg-pink-600
    hover:lm-text-white
    active:lm-bg-pink-600
`;

const secondaryClass = `
    ${buttonBaseClass}
    lm-bg-base-purple
    lm-text-white
    lm-focus-yellow
    active:lm-shadow-button-active
    hover:lm-bg-purple-800
    hover:lm-text-white
    active:lm-bg-purple-800
`;

const neutralClass = `
    ${buttonBaseClass}
    lm-text-label-copy
    lm-bg-light-gray
    lm-focus-yellow
    active:lm-shadow-button-active
    hover:lm-bg-gray-300
    active:lm-bg-gray-300
`;

const tertiaryClass = `
    ${buttonBaseClass}
    lm-text-base-navy
    lm-bg-white
    lm-focus-yellow
    active:lm-shadow-button-active
    active:lm-bg-gray-300
    hover:lm-bg-base-navy
    hover:lm-text-white
    lm-border
    lm-border-base-navy
`;

const tertiaryPinkClass = `
    ${buttonBaseClass}
    lm-text-base-pink
    lm-border-2
    lm-border-base-pink
    lm-focus-yellow
    lm-bg-white
    active:lm-shadow-button-active
    active:lm-bg-base-pink
    active:lm-text-white
    hover:lm-bg-base-pink
    hover:lm-text-white
`;

const tertiaryPurpleClass = `
    ${buttonBaseClass}
    lm-text-base-purple
    lm-border-2
    lm-border-base-purple
    lm-focus-yellow
    lm-bg-white
    active:lm-shadow-button-active
    active:lm-bg-base-purple
    active:lm-text-white
    hover:lm-bg-base-purple
    hover:lm-text-white
`;

const destructiveClass = `
    ${buttonBaseClass}
    lm-text-gray-primary
    lm-bg-white
    lm-border
    lm-border-gray-400
    lm-focus-yellow
    active:lm-shadow-button-active
    active:lm-bg-gray-300
    hover:lm-text-base-red
    hover:lm-border-base-red
`;

const disabledClass = `
    ${buttonBaseClass}
    lm-bg-gray-200
    lm-text-gray-500
    hover:lm-text-gray-500
    lm-focus-border
    lm-cursor-not-allowed
`;

const xxsmallSizeClass = `
    lm-px-3
    lm-py-0
    lm-text-sm
`;

const xsmallSizeClass = `
    lm-px-4
    lm-py-1
    lm-text-base
`;

const smallSizeClass = `
    lm-px-4
    lm-py-2
    lm-text-base
`;

const mediumSizeClass = `
    lm-px-5
    lm-py-3
    lm-text-base
`;

const largeSizeClass = `
    lm-px-8
    lm-py-3
    lm-text-base
`;

const xlargeSizeClass = `
    lm-px-10
    lm-py-4
    lm-text-xl
`;

const classNameOverrides = {
  junction: {
    primary: primaryClass,
    secondary: secondaryClass,
    disabled: disabledClass,
    neutral: neutralClass,
    destructive: destructiveClass,
    tertiary: tertiaryClass,
    tertiary_purple: tertiaryPurpleClass,
    base: buttonBaseClass,
  },
  'junction-internal': {
    primary: primaryClass,
    secondary: secondaryClass,
    disabled: disabledClass,
    neutral: neutralClass,
    destructive: destructiveClass,
    tertiary: tertiaryClass,
    tertiary_pink: tertiaryPinkClass,
    tertiary_purple: tertiaryPurpleClass,
    base: buttonBaseClass,
  },
};

function getState(
  isSecondary: boolean,
  isDisabled: boolean,
  isNeutral: boolean,
  isDestructive: boolean,
  isTertiary: boolean,
  isBase: boolean,
  tertiaryColor: 'navy' | 'pink' | 'purple',
) {
  if (isDisabled) return 'disabled';
  if (isTertiary) {
    switch (tertiaryColor) {
      case 'navy':
        return 'tertiary';

      case 'pink':
        return 'tertiary_pink';

      case 'purple':
        return 'tertiary_purple';

      default:
        return 'tertiary';
    }
  }
  if (isSecondary) return 'secondary';
  if (isNeutral) return 'neutral';
  if (isDestructive) return 'destructive';
  if (isBase) return 'base';

  return 'primary';
}

export const useButtonClassName = ({
  base,
  bgColor,
  className: providedClassName,
  destructive,
  disabled,
  isFullWidth,
  neutral,
  secondary,
  size,
  rounded,
  tertiary,
  tertiaryColor,
}: Pick<
  Props,
  | 'base'
  | 'bgColor'
  | 'className'
  | 'destructive'
  | 'disabled'
  | 'isFullWidth'
  | 'neutral'
  | 'rounded'
  | 'secondary'
  | 'size'
  | 'tertiary'
  | 'tertiaryColor'
> = {}) => {
  const state = getState(
    secondary,
    disabled,
    neutral,
    destructive,
    tertiary,
    base,
    tertiaryColor,
  );

  let className = useClassNameOverride(classNameOverrides, state);

  const sizeClass = getClassName(size, [
    ['xxs', xxsmallSizeClass],
    ['xs', xsmallSizeClass],
    ['s', smallSizeClass],
    ['l', largeSizeClass],
    ['m', mediumSizeClass],
    ['xl', xlargeSizeClass],
    ['', mediumSizeClass],
  ]);

  let roundedClassName = '';
  switch (rounded) {
    case 'full': {
      roundedClassName = 'lm-rounded-full';
      break;
    }
    case 'card': {
      roundedClassName = 'lm-rounded-card';
      break;
    }
    case 'sm': {
      roundedClassName = 'lm-rounded-sm';
      break;
    }
    case 'md': {
      roundedClassName = 'lm-rounded-md';
      break;
    }
    case 'lg': {
      roundedClassName = 'lm-rounded-lg';
      break;
    }
    default: {
      roundedClassName = 'lm-rounded-full';
      break;
    }
  }

  const widthClass = isFullWidth ? 'lm-w-full' : undefined;

  return mergeClasses(
    className,
    providedClassName,
    sizeClass,
    widthClass,
    bgColor,
    roundedClassName,
  );
};

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    type,
    onClick,
    id,
    className: providedClassName,
    disabled,
    loading,
    size = 'm',
    isFullWidth,
    style,
    trackClick,
    secondary,
    neutral,
    name,
    tabIndex,
    animation = {},
    destructive,
    showNegativeIcon = false,
    tertiary,
    tertiaryColor,
    base,
    icon,
    spacing = 'r',
    rounded = 'full',
    bgColor,
    isLeftIcon = true,
    ariaLabel,
    ariaDescribedBy,
    form,
    value,
    'data-transaction-name': dataTransactionName,
  } = props;

  const className = useButtonClassName({
    base,
    bgColor,
    className: providedClassName,
    destructive,
    disabled,
    isFullWidth,
    neutral,
    rounded,
    secondary,
    size,
    tertiary,
    tertiaryColor,
  });

  const trackOnClick = function (e: any) {
    if (onClick) onClick(e);
    if (trackClick) {
      analyticsUtils.trackInteractionOnPage('click', {
        text: JSON.stringify(children),
      });
    }
  };

  const _style = useMemo(() => {
    const s: any = { ...style };

    if (disabled) {
      s.boxShadow = 'none';
    }
    return s;
  }, [disabled, style]);

  // use visibility:hidden here so that the label still takes up the right
  // amount of width and height - otherwise the loading indicator will cause
  // the buttons dimensions to change
  const buttonInnerClassName = loading ? 'lm-invisible' : '';

  const isAutoLayout = isAutoLayoutChild();

  const loadingSpinnerColor =
    tertiary || destructive || disabled ? '#A0AEC0' : 'white';

  return (
    <motion.button
      disabled={disabled || loading}
      className={className}
      type={type}
      id={id}
      ref={ref}
      name={name}
      onClick={trackOnClick}
      style={_style}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
      form={form}
      value={value}
      data-transaction-name={dataTransactionName}
      {...{ layout: isAutoLayout, animation }}
    >
      <Stack
        animation={{ layout: isAutoLayout }}
        orientation='horizontal'
        align='center'
        justify='center'
        spacing={spacing as Spacing}
      >
        <Hidden when={!showNegativeIcon}>
          <Negative />
        </Hidden>
        <Shown when={icon && isLeftIcon}>{icon}</Shown>

        <Hidden when={!children}>
          <motion.span layout={isAutoLayout} className={buttonInnerClassName}>
            {children}
          </motion.span>
        </Hidden>

        <Shown when={icon && !isLeftIcon}>{icon}</Shown>
      </Stack>
      {loading && (
        <Loading
          className='lm-inline-block lm-absolute'
          color={loadingSpinnerColor}
        />
      )}
    </motion.button>
  );
});

export { Button };
export default Button;

export type BasicProps = {
  children: string | any;
  /**
   * The button type to be used in forms
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * On click handlers
   */
  onClick?: (event: any) => any;
  id?: string;
  className?: string;
  name?: string;
  /**
   * This disables the button and prvents a click action
   * @default false
   */
  disabled?: boolean;
  loading?: boolean;
  /**
   * How big the button is
   * @default m
   */
  size?: Size;
  isFullWidth?: boolean | ResponsiveBreakPoint<boolean>;
  style?: any;
  trackClick?: boolean;
  destructive?: boolean;
  tabIndex?: number;
  icon?: ReactElement;
  /**
   * Framer motion animation props
   */
  animation?: any;
  /**
   * Stack spacing, to override default tailwind spacing class, use none and pass in classname in icon component
   */
  spacing?: Spacing | 'none';
};

/** Roll-your-own-styling version of Button component.   */
// tslint:disable-next-line:ter-max-len
export const BasicButton = React.forwardRef<HTMLButtonElement, BasicProps>(
  (props, ref) => {
    const {
      children,
      type,
      onClick,
      id,
      className,
      disabled,
      loading,
      size = 'm',
      isFullWidth,
      style,
      trackClick,
      name,
      tabIndex,
      animation = {},
      destructive,
      icon,
      spacing = 'r',
    } = props;

    const sizeClass = getClassName(size, [
      ['s', smallSizeClass],
      ['l', largeSizeClass],
      ['m', mediumSizeClass],
      ['xl', 'lm-px-10 lm-py-5 lm-text-base'],
      ['', mediumSizeClass],
    ]);

    const widthClass = isFullWidth ? 'lm-w-full' : undefined;

    const _className = mergeClasses(
      'lm-rounded-full lm-bg-transparent',
      buttonBaseClass,
      className,
      sizeClass,
      widthClass,
    );

    const trackOnClick = function (e: any) {
      if (onClick) onClick(e);
      if (trackClick) {
        analyticsUtils.trackInteractionOnPage('click', {
          text: JSON.stringify(children),
        });
      }
    };

    const _style = { ...style };
    if (disabled) {
      _style.boxShadow = 'none';
    }

    // use visibility:hidden here so that the label still takes up the right
    // amount of width and height - otherwise the loading indicator will cause
    // the buttons dimensions to change
    const buttonInnerClassName = loading ? 'lm-invisible' : '';

    const isAutoLayout = isAutoLayoutChild();

    return (
      <motion.button
        disabled={disabled || loading}
        className={_className}
        type={type}
        id={id}
        ref={ref}
        name={name}
        onClick={trackOnClick}
        style={_style}
        tabIndex={tabIndex}
        {...{ layout: isAutoLayout, animation }}
      >
        <Stack
          animation={{ layout: isAutoLayout }}
          orientation='horizontal'
          align='center'
          spacing={spacing as Spacing}
        >
          <Hidden when={!destructive}>
            <Negative />
          </Hidden>
          <Hidden when={!icon}>{icon}</Hidden>
          <motion.span layout={isAutoLayout} className={buttonInnerClassName}>
            {children}
          </motion.span>
        </Stack>
        {loading && (
          <Loading className='lm-inline-block lm-absolute' color='#5D7287' />
        )}
      </motion.button>
    );
  },
);
