import React, { useMemo, SyntheticEvent, FC, useCallback } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

interface MaskedInputProps extends Partial<FieldProps> {
  mask: any;
  control?: any;
  style?: any;
  innerRef?: React.MutableRefObject<any> | ((instance: any) => void);
  id?: string;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
  input?: any;
  icon?: any;
}

export function MaskedField(inputProps: MaskedInputProps) {
  const { innerRef, input, ...restProps } = inputProps;

  return (
    <MaskedInput
      {...input}
      {...restProps}
      ref={maskedInputInstance => {
        // The instance we get isn't actually the <input> element, it's a
        // wrapper around it. We want the ref prop to behave consistently with
        // non-masked input fields, so we're using the actual input element
        // instance as the value of the ref.
        if (typeof innerRef === 'function') {
          innerRef(maskedInputInstance?.inputElement);
        } else if (innerRef) {
          innerRef.current = maskedInputInstance?.inputElement;
        }
      }}
    />
  );
}

export interface FieldProps {
  /** Label the field  */
  label?: string;
  /** Additional components to render with the label */
  labelChildren?: React.ReactNode;
  /** The field name. This is the key used to reference from form values */
  name: string;
  /** The type of input. */
  type?:
    | 'password'
    | 'text'
    | 'number'
    | 'date'
    | 'color'
    | 'email'
    | 'file'
    | 'tel'
    | any;
  /** Function to execute on input changes */
  onChange?: any;
  //* * Needed when preventing 'enter' keydown default event */
  onKeyDown?: (args: any) => any;
  onKeyUp?: (args: any) => any;
  /** Provide a value to the field. Only valid when not form controlled */
  value?: string;
  /** Provide an error message to the field. Only valid when not form controlled */
  error?: string;
  /** Custom class to provide additional styles to the core input element */
  inputClassName?: string;
  /**
   * Tells the browser what type of data to expect here. On IOS/Android this
   * shows the correct keyboard
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
   */
  inputMode?:
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url'
    | any;

  /** Class names to apply to the outermost container */
  className?: string;

  /** Custom object to provide addition styles to the core input element */
  inputStyle?: any;
  /** Custom class to provide additional styles to the input element wrapper */
  wrapperClassName?: string;
  /** Disables input to the field */
  disabled?: boolean;
  /** Focus the field on page render */
  autoFocus?: boolean;
  /** Input placeholder text */
  placeholder?: string;
  /** Input mask regular expression */
  mask?: Function | (string | RegExp)[];
  placeholderChar?: string;
  /**
   * Specify a max length that the string in the input can be
   */
  maxLength?: number;
  /** REQUIRED - To attach the field to a form */
  control?: Control;
  rules?: any;
  wrapperStyle?: any;
  onBlur?: (args: any) => any;
  onFocus?: (args: any) => any;
  onClick?: (args: any) => any;
  format?: (event: SyntheticEvent<HTMLInputElement>) => any;
  typeLabel?: string;
  /** A flag to visually indicate the input is valid */
  isValid?: boolean;
  /** Use this if you want a text area instead of a field */
  rows?: number;

  /** Show browser autocomplete menu. Defaults to on */
  autocomplete?: 'on' | 'off' | 'no';

  /** Framer motion animation props */
  animation?: any;

  /**
   * Input ref
   */
  innerRef?: React.Ref<any>;

  size?: 'base' | 'small' | 'auto';

  readOnly?: boolean;

  /**
   * Set to `true` if this field's value should not interact with
   * the form context from react-hook-forms
   */
  shouldNotUseFormContext?: boolean;

  defaultValue?: any;
  icon?: any;
}

type InputComponentProps = FieldProps & {
  innerOnBlur?: any;
  innerOnFocus?: any;
};

const InputComponent: FC<InputComponentProps> = props => {
  const {
    autocomplete = 'on',
    autoFocus,
    defaultValue,
    disabled,
    innerOnBlur,
    innerOnFocus,
    innerRef,
    inputClassName,
    inputMode,
    inputStyle,
    mask,
    maxLength,
    name,
    onBlur,
    onFocus,
    onChange,
    onClick,
    onKeyDown,
    onKeyUp,
    placeholder,
    placeholderChar,
    readOnly,
    rows,
    type = 'text',
    value,
  } = props;

  const _onBlur = (args: any) => {
    if (onBlur) onBlur(args);
    if (innerOnBlur) innerOnBlur();
  };

  const _onFocus = (e: any) => {
    if (onFocus) onFocus(e);
    if (innerOnFocus) innerOnFocus();
  };

  // create a clean set of props which will be provided to the root <input> element
  // any unsupported props will result in the console spitting errors.
  // e.g. wrapperClassName
  // This eliminates valid HTML props, like ARIA attributes

  const cleanProps = useMemo(
    () => ({
      autoComplete: autocomplete,
      autoFocus,
      className: inputClassName,
      defaultValue,
      disabled,
      inputMode,
      maxLength,
      name,
      onBlur: _onBlur,
      onChange,
      onClick,
      onFocus: _onFocus,
      onKeyDown,
      onKeyUp,
      placeholder,
      readOnly,
      type,
      value,
    }),
    // eslint-disable-next-line
    [value, disabled, type],
  );

  if ((props as any)['aria-label']) {
    (cleanProps as any)['aria-label'] = (props as any)['aria-label'];
  }

  if ((props as any)['aria-labelledby']) {
    (cleanProps as any)['aria-labelledby'] = (props as any)['aria-labelledby'];
  }

  // swapping the component allows us to keep the styles while having the flexibility of the implementation
  // of another (masked) input component
  let InputComponent = (
    <input id={name} ref={innerRef} {...cleanProps} style={inputStyle} />
  );
  if (mask)
    InputComponent = (
      <MaskedField
        id={name}
        innerRef={innerRef}
        {...cleanProps}
        style={inputStyle}
        mask={mask}
        placeholderChar={placeholderChar}
      />
    );
  if (rows)
    InputComponent = (
      <textarea
        id={name}
        rows={rows}
        ref={innerRef}
        {...cleanProps}
        style={{ ...inputStyle, resize: 'none' }}
      />
    );

  return InputComponent;
};

const ControlledInput: FC<InputComponentProps> = props => {
  const { onChange, format, control, rules, name, onBlur } = props;

  const _onChange = (controllerOnChange: any) =>
    useCallback(
      (event: SyntheticEvent<HTMLInputElement>) => {
        event.persist();
        if (onChange) onChange(event);
        if (format) return controllerOnChange(format(event));
        return controllerOnChange(event);
      },
      [controllerOnChange],
    );

  const _onBlur = (controllerOnBlur: any) =>
    useCallback(
      (event: SyntheticEvent<HTMLInputElement>) => {
        event.persist();
        if (onBlur) onBlur(event);
        if (format) return controllerOnBlur(format(event));
        return controllerOnBlur(event);
      },
      [controllerOnBlur],
    );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={controllerProps => {
        // Sometimes typescript doesn't think 'ref' exists in
        // controllerProps, sometimes it does. I've seen with my own
        // two eyes that it does exist, so we're using 'as any' to
        // keep typescript quiet when it gets confused.
        // See also: src/junction/components/textarea/Textarea.tsx
        const { ref, ...restControllerProps } = controllerProps as any;

        // Just in case ref is actually missing and starts to cause
        // issues
        if (ref === undefined) {
          // tslint:disable-next-line: no-console
          console.warn(
            `No 'ref' was passed from 'Controller' for input ` +
              `with name ${name}`,
          );
        }

        return (
          <InputComponent
            {...props}
            {...restControllerProps}
            innerRef={ref}
            onBlur={_onBlur(controllerProps.onBlur)}
            onChange={_onChange(controllerProps.onChange)}
          />
        );
      }}
    />
  );
};

const InputRenderer: FC<InputComponentProps> = props => {
  const formContext = useFormContext();
  const _props = { ...props };

  if (formContext && !props.shouldNotUseFormContext) {
    _props.control = formContext.control;
    if (formContext.errors[props.name]) {
      _props.error = formContext.errors[props.name];
    }
  }

  if (_props.control) return <ControlledInput {..._props} />;
  return <InputComponent {..._props} />;
};

export default InputRenderer;
