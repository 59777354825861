import { MotionStyle, motion } from 'framer-motion';
import { isPlainObject } from 'lodash';
import React, { FC, MouseEvent, useMemo } from 'react';
import { isAutoLayoutChild } from '../../../common/utils/animation_utils';
import { mergeClasses } from '../../../common/utils/utils';
import {
  colSpanClassNamesByBreakpoint,
  ColSpanUnit,
  Layout,
  ResponsiveBreakPoint,
  ScreenBreakpointMap,
  ScreenBreakpointValue,
  Shadow,
  shadowClassNames,
  Spacing,
  SpacingBreakPoints,
  spacingClassMap,
  SpacingToTailwindUnitMap,
} from '../layout.t';

/**
 *!  className is for the purposes of internal system
    usage and SHOULD NOT be consumed outside of the
    components within junction.
 */
export type ContainerProps = Layout & {
  background?: string;
  shadow?: Shadow;
  className?: string;
  minWidth?: React.CSSProperties['minWidth'];
  maxWidth?: React.CSSProperties['maxWidth'];
  minHeight?: React.CSSProperties['minHeight'];
  animation?: any;
  maxHeight?: any;
  innerRef?: React.Ref<any>;
  style?: React.CSSProperties | MotionStyle;
  role?: string;
  onClick?: (event: MouseEvent) => void;
  onHoverStart?: (event: MouseEvent) => void;
  onHoverEnd?: (event: MouseEvent) => void;
};

type Direction = '' | 'l' | 'r' | 't' | 'b' | 'x' | 'y';

const isResponsiveBreakpoint = (x: any): x is ResponsiveBreakPoint<number> =>
  isPlainObject(x);

const getSpacingClass = (
  directions: [Spacing | SpacingBreakPoints, Direction][],
  type: 'm' | 'p',
) => {
  return directions.reduce((generatedClass, [spacing, direction]) => {
    // responsive list of spacing provided
    if (isPlainObject(spacing)) {
      return Object.keys(spacing).reduce(
        (className: string, screen: string) => {
          const breakpoint =
            ScreenBreakpointMap[screen as keyof typeof ScreenBreakpointMap];

          const paddingValue =
            SpacingToTailwindUnitMap[(spacing as any)[screen as any]];

          return mergeClasses(
            generatedClass,
            className,
            spacingClassMap[breakpoint ?? ''][type][direction][paddingValue],
          );
        },
        ``,
      );
    }

    return mergeClasses(
      generatedClass,
      spacingClassMap[''][type][direction][
        SpacingToTailwindUnitMap[spacing as Spacing]
      ],
    );
  }, '');
};

const getColSpanClassNames = (breakpoints: Layout['spanX']) => {
  if (!breakpoints) return '';

  if (isResponsiveBreakpoint(breakpoints)) {
    return Object.keys(breakpoints)
      .map(screen => {
        const breakpoint = ScreenBreakpointMap[
          screen as keyof typeof ScreenBreakpointMap
        ] as ScreenBreakpointValue;

        const unit = (breakpoints as any)[screen as any] as ColSpanUnit;

        return colSpanClassNamesByBreakpoint[breakpoint][unit];
      })
      .filter(Boolean)
      .join(' ');
  }

  return colSpanClassNamesByBreakpoint[''][String(breakpoints) as ColSpanUnit];
};

export const Container: FC<ContainerProps> = props => {
  const {
    children,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY,
    background = '',
    className = '',
    spanX,
    minWidth,
    maxWidth,
    shadow,
    animation,
    maxHeight,
    minHeight,
    innerRef,
    style,
    role,
    onClick,
    onHoverStart,
    onHoverEnd,
  } = props;

  const pDirections: [Spacing | SpacingBreakPoints, Direction][] = [];
  const mDirections: [Spacing | SpacingBreakPoints, Direction][] = [];

  if (padding) pDirections.push([padding, '']);
  if (paddingBottom) pDirections.push([paddingBottom, 'b']);
  if (paddingLeft) pDirections.push([paddingLeft, 'l']);
  if (paddingRight) pDirections.push([paddingRight, 'r']);
  if (paddingTop) pDirections.push([paddingTop, 't']);
  if (paddingX) pDirections.push([paddingX, 'x']);
  if (paddingY) pDirections.push([paddingY, 'y']);

  if (margin) mDirections.push([margin, '']);
  if (marginBottom) mDirections.push([marginBottom, 'b']);
  if (marginLeft) mDirections.push([marginLeft, 'l']);
  if (marginRight) mDirections.push([marginRight, 'r']);
  if (marginTop) mDirections.push([marginTop, 't']);
  if (marginX) mDirections.push([marginX, 'x']);
  if (marginY) mDirections.push([marginY, 'y']);

  const paddingClass = useMemo(() => {
    if (!pDirections.length) return null;
    return getSpacingClass(pDirections, 'p');
  }, [pDirections.length]);

  const marginClass = useMemo(() => {
    if (!mDirections.length) return null;
    return getSpacingClass(mDirections, 'm');
  }, [mDirections.length]);

  const _className = mergeClasses(
    shadowClassNames[shadow],
    marginClass,
    paddingClass,
    background,
    className,
    spanX ? getColSpanClassNames(spanX) : undefined,
  );

  const isAutoLayout = isAutoLayoutChild();
  const animateProps = { layout: isAutoLayout, ...animation } || {};

  return (
    <motion.div
      ref={innerRef}
      {...animateProps}
      role={role}
      style={{ minWidth, maxWidth, maxHeight, minHeight, ...style }}
      className={_className}
      onClick={onClick}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
    >
      {children}
    </motion.div>
  );
};

export default Container;
