import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../DocumentCollectionUtils';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import { useForm } from 'react-hook-form';
import Button from '../../../../../junction/components/button/Button';
import ActionButton from '../../../../../junction/components/action_button/ActionButton';
import Feedback from '../../../../../junction/components/feedback/Feedback';
import {
  useGraphMutation,
  useGraphQuery,
  isQueryLoading,
} from '../../../../../common/utils/graphql_utils';
import { formSchema } from '../modules/DebtsSchema';
import {
  debtorTypesOptionsQuery,
  upsertSOFPDebt,
  getSOFPLiteral,
} from '../modules/actions';
import Loading from '../../../../../junction/components/loading/Loading';
import DebtItem from './DebtItem';
import Stack from '../../../../../junction/layout/stack/Stack';
import { yupResolver } from '@hookform/resolvers';

const path = 'sofp-debt';

type PropTypes = {
  dispatch: any;
  application: any;
  documentProgress?: DocumentCollectionProgress;
  params: { id: string };
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
  account?: any;
  saveApplication: (application: any, callback: (app: any) => void) => void;
  push: (path: string) => void;
};

export const ReactDebtContext = React.createContext({} as any);

const Debts = (props: PropTypes) => {
  const { data: sofpData, status: sofpStatus } = useGraphQuery(
    [
      'sofp',
      { document_capture_id: props.documentProgress.document_capture_id },
    ],
    getSOFPLiteral,
  );
  const [saveSOFP] = useGraphMutation(upsertSOFPDebt, { throwOnError: true });
  const {
    data: debtorTypesOptionsData,
    status: debtorTypesOptionsQueryStatus,
  } = useGraphQuery(['debtor_types'], debtorTypesOptionsQuery);

  const {
    control,
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      debts: sofpData?.data?.getSOFP?.debt || [{ isRemoved: false }],
    },
  });

  let debtors: any[] = [{ isRemoved: false }];
  const [debtorsCopy, setDebtors] = useState(debtors);

  useEffect(() => {
    if (sofpData?.data?.getSOFP?.debt?.length) {
      setDebtors(sofpData?.data?.getSOFP?.debt);
    }
    reset({ debts: sofpData?.data?.getSOFP?.debt || [{ isRemoved: false }] });
  }, [sofpData]);
  const [numDisplayedItems, setNumDisplayedItems] = useState(
    debtorsCopy.filter(d => !d.isRemoved).length,
  );

  const submit = async (values: any) => {
    const formValues = getValues();
    const debtArray = (formValues.debts as any[]).map(d => ({
      ...d,
      type: d.type.value,
    }));
    const { documentProgress, updateProgress, params, push } = props;
    const sofpId = sofpData?.data?.getSOFP?.id;
    const nextPath = getNextPath(path, documentProgress.stages);
    const progressThrough = () => {
      updateProgress(documentProgress.document_capture_id, [path]);
      push(`/app/documents/${params.id}/${nextPath}`);
    };

    try {
      await saveSOFP({ request: { id: sofpId, debt: debtArray } });
      progressThrough();
    } catch (err) {
      return;
    }
  };

  const debtTypes = debtorTypesOptionsData?.data?.debtorTypesOptions || [];
  useEffect(() => {
    setNumDisplayedItems(debtorsCopy.filter(d => !d.isRemoved).length);
  }, [debtorsCopy]);

  const addNewItem = useCallback(() => {
    setDebtors([
      ...debtorsCopy,
      {
        isRemoved: false,
      },
    ]);
  }, [debtorsCopy]);

  // Might be cheaper to just remove the element altogether?
  const removeDebtItem = useCallback(
    (index: number) => {
      const newDebtorsCopy = debtorsCopy.slice(); // copy the array
      newDebtorsCopy[index].isRemoved = true; // execute the manipulations
      setDebtors(newDebtorsCopy);
    },
    [debtorsCopy],
  );

  if (
    isQueryLoading(debtorTypesOptionsQueryStatus) ||
    isQueryLoading(sofpStatus)
  )
    return <Loading color='purple' />;

  const updateDebtItem = (index: number, update: any) => {
    const newDebtorsCopy = debtorsCopy.slice(); // copy the array
    newDebtorsCopy[index] = { ...newDebtorsCopy[index], ...update };
    setDebtors(newDebtorsCopy);
  };

  return (
    <div
      className={`${layoutStyles.documentCapturePage} lm-w-1/2 lm-border-t lm-bg-white`}
    >
      <div style={{ maxWidth: '520px' }}>
        <form onSubmit={handleSubmit(submit)}>
          <div className='lm-mt-4 lm-max-w-80'>
            <h1>Debts</h1>
            <Feedback className='lm-mb-5' type='lumi'>
              Please list all your current debts here, including store cards,
              layby, and
              <strong>&nbsp;your loan with Lumi</strong>. Use the
              <strong>&nbsp;Add another</strong> option to add more debts to
              this list.
            </Feedback>
            <ReactDebtContext.Provider
              value={{
                debts: debtorsCopy,
                numDisplayedItems,
                errors,
                debtTypes,
                removeDebtItem,
                updateDebtItem,
                control,
                register,
                getValues,
                setValue,
              }}
            >
              <Stack orientation='vertical' spacing='m'>
                {debtorsCopy.map((debtor, index) => {
                  return <DebtItem key={index} index={index} />;
                })}
              </Stack>
            </ReactDebtContext.Provider>
          </div>
          <div className='lm-flex lm-justify-center lm-mt-5'>
            <ActionButton isSecondary type='button' onClick={addNewItem}>
              + Add another debt
            </ActionButton>
          </div>
          <p className='lm-text-center lm-text-sm lm-mb-4'>
            To save this page, press Next
          </p>
          <div className='lm-flex lm-justify-center'>
            <Button type='submit' children='Next' className='lm-w-40' />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Debts;
