import * as React from 'react';

import * as defaultStyles from '../sass/components/_picker.scss';
import * as alternateStyles from '../sass/components/_pickerAlternate.scss';
import * as inputStyles from '../sass/components/_input.scss';

// this type will be used by both LumiPicker and LumiSelect but this is the tsx file that got there first
export type SelectOption = {
  label: string;
  value: any;
};

type LumiPickerOptions = {
  options: SelectOption[];
  selectedOption?: string;
  className?: string;
  pickerClassName?: string;
  input?: any;
  onChange?: Function;
  disabledValues?: string[];
  error?: string;
  meta?: any;
  attachedToField?: boolean;
  isFormPicker?: boolean;
};

const LumiPicker = (props: LumiPickerOptions) => {
  const {
    selectedOption,
    className,
    options,
    input,
    attachedToField,
    onChange,
    disabledValues,
    error,
    meta,
    isFormPicker,
  } = props;
  let pickerClassName = props.pickerClassName || '';
  const _options = options || [];
  const _onChange = onChange || input.onChange;
  const _disabledValues = disabledValues || [];
  const value = selectedOption || input?.value;
  const styles = attachedToField ? alternateStyles : defaultStyles;

  const onChangeWrapper = (newValue: any, disabled: boolean) => {
    if (disabled) return;
    _onChange(newValue);
  };

  const violated = meta && (meta.touched || meta.dirty);
  let _error = error;
  // The field is marked as touched after a focus + unfocus has occurred and during submit
  // all fields are touched ;). However that means a field can be touched ;)
  // yet still have an error, that is because the input can be dirty, hence the dual check.
  if (violated) {
    _error = meta.error;
  }

  let infoLabel;
  if (_error) {
    infoLabel = (
      <label
        className={[inputStyles.lumiFieldErrorLabel, styles.error].join(' ')}
      >
        {_error}
      </label>
    );
  }

  const pickerOptions = _options.map((option, i) => {
    const disabled = _disabledValues.includes(option.value);
    let _className = [styles.pickerOption, className].join(' ');
    if (value === option.value)
      _className = [_className, styles.selectedOption].join(' ');
    if (disabled) _className = [_className, styles.disabledOption].join(' ');
    const width = `${(1 / _options.length) * 100}%`;
    return (
      <button
        type='button'
        style={attachedToField || isFormPicker ? { width } : null}
        key={`lumi-picker-${i}`}
        className={[_className, 'lm-outline-none'].join(' ')}
        onClick={() => {
          onChangeWrapper(option.value, disabled);
        }}
      >
        {option.label}
      </button>
    );
  });
  // Optional style rule to force-wrap
  const pickerClasses: any[] = [styles.lumiPicker];
  if (pickerClassName.indexOf('wrapAround') > -1) {
    pickerClasses.push(styles.wrapAround);
    pickerClassName = pickerClassName.replace('wrapAround', '');
  }
  pickerClasses.push(pickerClassName);

  const style =
    attachedToField || isFormPicker
      ? { display: 'inline-block', width: '100%', fontSize: '12px' }
      : { display: 'inline-block' };
  return (
    <div style={style}>
      <div className={pickerClasses.join(' ')}>
        {pickerOptions}
        {infoLabel}
      </div>
    </div>
  );
};

export default LumiPicker;
