// import Promise from 'bluebird'
import fetch from 'isomorphic-fetch';
import {
  getToken,
  getAuth0RedirectUrl,
  Auth0LocalStorageKey,
} from './auth_utils';
import lumiEnvironment from '../../lumi.environment';

const ALWAYS_PRESENT_HEADERS = {
  'X-Requested-With': lumiEnvironment.CLIENT,
};

const DEFAULT_REQUEST_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...ALWAYS_PRESENT_HEADERS,
};

const persistToken = response => {
  if (response && response.result && response.result.access_token) {
    const authToken = response.result.access_token;
    console.log('Persisting token');
    try {
      localStorage.setItem(lumiEnvironment.LOCAL_STORAGE_NAME, authToken);
      localStorage.setItem(
        lumiEnvironment.LOCAL_STORAGE_USER_NAME,
        JSON.stringify(response.result),
      );
      localStorage.setItem('token_retrieved_at', new Date().getTime());
    } catch (ex) {
      console.log('localStorage error: ' + ex);
    }
  }
  return response;
};

const parse = (response, bypassJSON, readMethod) => {
  return new Promise((resolve, reject) => {
    let promise;
    if (readMethod === ReadMethods.PDF) {
      promise = response.blob();
    } else {
      promise = bypassJSON ? response.text() : response.json();
    }
    promise
      .then(data => {
        resolve({ body: data, status: response.status, response });
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getAbsoluteUrl = path => {
  if (path.charAt(0) === '/') {
    return `${lumiEnvironment.API_URL}${path}`;
  }
  return path;
};

export const upload = async (url, opts, onProgress) => {
  // for uploading files, if token is attached to headers, no need get token again
  // otherwise, would get token from pulse localstorage
  // so to avoid getting pulse token, attaching getAccessToken(auth0) to headers when calling the function.
  // Attaching X-Requested-With : 'admin' can't work because it is attached after again, so it will be a double attachment(admin admin).
  if (!opts?.headers?.Authorization) {
    await addBearer(opts || {});
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', getAbsoluteUrl(url));

    for (const k in opts.headers || []) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    for (const headerKey in ALWAYS_PRESENT_HEADERS) {
      xhr.setRequestHeader(headerKey, ALWAYS_PRESENT_HEADERS[headerKey]);
    }

    xhr.onload = e => {
      try {
        resolve(JSON.parse(e.target.responseText));
      } catch (ex) {
        reject(ex);
      }
    };
    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    }
    xhr.send(opts.body);
  });
};

const checkStatus = (returnResponse, goToRoute) => {
  return response => {
    if (
      response.status === 400 ||
      (response.status >= 200 && response.status < 300)
    ) {
      return returnResponse ? response : response.body;
    } else if (response.status === 401) {
      localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
      localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_USER_NAME);
      localStorage.removeItem(Auth0LocalStorageKey);
      location.href =
        goToRoute ||
        `${
          lumiEnvironment.LOGIN_PATH
        }?reason=401&redirectUrl=${getAuth0RedirectUrl()}`;
      return returnResponse ? response : response.body;
    } else {
      var error = new Error(response.body.error || response.status);
      error.identifier = response.body.identifier;
      throw error;
    }
  };
};

const addBearer = async options => {
  // always add bearer header for all api calls.
  /**
   * because authentication for the admin/broker app are through auth0
   * for the admin/broker app, the token is from auth0 default key
   * others are from LOCAL_STORAGE_NAME
   */
  const accessToken =
    options?.headers?.['X-Requested-With'] === 'admin' ||
    options?.headers?.['X-Requested-With'] === 'broker'
      ? await getToken()
      : localStorage.getItem(lumiEnvironment.LOCAL_STORAGE_NAME);
  if (accessToken) {
    options.headers.Authorization = `BEARER ${accessToken}`;
  }
};

// should use enum but this is a .js file
export const ReadMethods = {
  PDF: 'PDF',
};

export const doFetch = async (path, options) => {
  const newFetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('timeout')), opts.timeout || 120000);
      return fetch(url, opts).then(resolve, reject);
    });
  };

  if (options.body instanceof FormData) {
    if (!options.headers) options.headers = ALWAYS_PRESENT_HEADERS;
  } else {
    if (!options.headers) {
      options.headers = DEFAULT_REQUEST_HEADERS;
    } else {
      options.headers = Object.assign(
        {},
        DEFAULT_REQUEST_HEADERS,
        options.headers,
      );
    }
    if (typeof options.body !== 'string') {
      options.body = JSON.stringify(options.body);
    }
  }
  await addBearer(options);

  const url = getAbsoluteUrl(path);

  return newFetch(url, options);
};

export const apiFetch = async (path, options, handleResponse, errorRoute) => {
  if (!options.noAuth) {
    await refreshToken(errorRoute);
  }
  return rawApiFetch(path, options, handleResponse, errorRoute);
};

export const rawApiFetch = (path, options, handleResponse, errorRoute) => {
  return doFetch(path, options)
    .then(response => {
      return parse(response, options.bypassJSON, options.readMethod);
    })
    .then(checkStatus(false, errorRoute))
    .then(persistToken)
    .then(response => {
      if (handleResponse) {
        return handleResponse(response);
      } else {
        return response;
      }
    });
};

/**
 * this is for refreshing pulse token, for now, Pulse app is using auth0 token, no need for it,
 * and Broker portal is parallel using pulse and auth0 tokens,
 * only Buzz and Chimera are using pulse token
 */
export const refreshToken = async errorRoute => {
  const accessToken = await getToken();
  if (!accessToken) return; // We are not currently authenticated, nothing to do

  const retrievedAt = localStorage.getItem('token_retrieved_at');
  if (!retrievedAt) return; // No retrievedAt nothing to do

  const retrievedAtTime = new Date(parseInt(retrievedAt, 10)).getTime();
  const refreshThreshold = retrievedAtTime + 900000; // 15 minutes

  if (new Date().getTime() > refreshThreshold) {
    const serverUrl = ['broker', 'client'].includes(lumiEnvironment.CLIENT)
      ? lumiEnvironment.PUBLIC_API_URL
      : lumiEnvironment.API_URL;
    return rawApiFetch(
      `${serverUrl}/accounts/refreshToken`,
      { method: 'post', body: {} },
      null,
      errorRoute,
    );
  }
};

export const apiPost = (path, body, errorRoute, options) => {
  return apiFetch(path, { method: 'post', body, ...options }, null, errorRoute);
};

export const apiGet = (path, errorRoute, options) => {
  return apiFetch(path, { method: 'get', ...options }, null, errorRoute);
};

export const apiDelete = path => {
  return apiFetch(path, { method: 'delete' });
};

export const apiPatch = (path, body) => {
  return apiFetch(path, { method: 'PATCH', body });
};

export const apiFetchRaw = (url, options, handleResponse) => {
  return doFetch(url, options).then(checkStatus(true)).then(handleResponse);
};

// this function is only being used from DownloadReportButton components,
// if this function would be used by other components, need to use windowOpenInPost function to download file
export const getDownloadLink = (report, token) => {
  return `${serverURL}/api/documents/attachments/${report.file_id}/download?requestedWith=admin`;
};

export const serverURL = lumiEnvironment.SERVER_HOST;
export const publicServerURL = lumiEnvironment.PUBLIC_SERVER_HOST;
export const graphURL = lumiEnvironment.GRAPHQL_URL;
export const publicGraphURL = lumiEnvironment.PUBLIC_GRAPHQL_URL;
export const leadsApiURL = lumiEnvironment.LEADS_API_URL;
export const localURL = 'http://localhost:8080';
