import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from '../styles/modules/application_progress.scss';
import progressStyle from '../../common/sass/components/_progress.scss';
import icons from '../../common/sass/icons/lumi-icon.scss';
import number from '../../common/sass/components/_number.scss';
import { OnboardingConfig, getExperimentGroup } from '../routes/Application';
import { uniq } from 'lodash';

class ApplicationProgress extends Component {
  render() {
    const progress = this.props.progress;
    const accentClass = progress.percentage === 100 ? progressStyle.accent : '';
    const routeConfigs = OnboardingConfig[getExperimentGroup()];

    const labels = (routeConfigs || []).map(routeConfig => routeConfig.label);
    return (
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.label}>
            <h2 className='mb-0'>{progress.percentage}%</h2>
            <span>completed</span>
          </div>
          <div className={progressStyle.progress}>
            <div
              className={[progressStyle.bar, accentClass].join(' ')}
              style={{ width: `${progress.percentage}%` }}
            />
          </div>
        </div>
        <hr />
        <div className={[style.checklist, number.list].join(' ')}>
          {uniq(labels).map((item, i) => {
            let numberClass = '';
            const step = i + 1;
            if (step < progress.step) numberClass = number.completed;
            else if (step === progress.step) numberClass = number.active;

            return (
              <div className={number.item} key={i}>
                <div className={[number.numberWrapper, numberClass].join(' ')}>
                  <div className={number.numberNoBorder}>
                    <span>{i + 1}</span>
                    <i className={icons['icon-expand']} />
                  </div>
                </div>
                <div className={number.label}>{item}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ApplicationProgress.propTypes = {
  progress: PropTypes.object,
};

ApplicationProgress.defaultProps = {
  progress: {},
};

export default ApplicationProgress;
