import BankFeedsHelpTool from 'common/components/BankFeedsHelpTooltip';
import { uniq } from 'lodash';
import React from 'react';
import IdVerificationInfoTooltip from './IdVerification/IdVerificationInfoTooltip';

export type DocCapRoute = {
  url: string;
  nextRoute?: (request: {
    documentCaptureId: string;
    stages: string[];
    routeInfo: DocCapRoute;
    experimentGroup: string;
  }) => string;
  progressKey?: string;
  title?: string;
  copy?: string | React.ReactNode;
  isConfirmationPageRequired: boolean;
};

type TRouteConfig = {
  control: DocCapRoute[];
  [variantName: string]: DocCapRoute[];
};

enum ExperimentGroup {
  Control = 'control',
  Variant = 'variant',
}

export enum ProgressItem {
  LoanInformation = 'loan-info',
  AboutYou = 'about-you',
  YourBusiness = 'your-business',
  Documents = 'documents',
  AccountsPayable = 'accounts-payable',
  IdentityVerification = 'identity-verification',
  Success = 'success',
}
// these stages fall into a single component 'about-you'
const AboutYouStages: string[] = [
  'veda-consent',
  'mobile-number',
  'full-name',
  'email-address',
];

/**
 * Define the routes here in a central location so that each page does not need
 * to know what its next route should be when they click the 'next step'
 * button. Defining it here will allow us to change the order of the pages much
 * more easily, also it will allow us to run experiments on the order of those
 * pages. Include another key here with the variantName and an alternative route
 * order
 * For full list of stages please see DocumentCaptureStages in DocumentCaptureLayoutViewModel.tsx
 */
export const RouteConfig: TRouteConfig = {
  control: [
    {
      url: '/single',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: false,
    },
    {
      url: '/single/credit-limit',
      progressKey: ProgressItem.LoanInformation,
      title: `About your application`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/loan-amount',
      progressKey: ProgressItem.LoanInformation,
      title: `What is the loan amount you are looking for?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/refinance-loan-purpose',
      progressKey: ProgressItem.LoanInformation,
      title: `What do you need the funds for?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/purchase-purpose',
      progressKey: ProgressItem.LoanInformation,
      title: `What do you need the funds for?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/business-activity',
      progressKey: ProgressItem.YourBusiness,
      title: 'What does your business do?',
      copy: 'You can choose as many as you like',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/about',
      progressKey: ProgressItem.YourBusiness,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/about-your-business',
      progressKey: ProgressItem.YourBusiness,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/abn',
      progressKey: ProgressItem.YourBusiness,
      title: 'What is your ABN/ACN?',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/industry-code',
      progressKey: ProgressItem.YourBusiness,
      title: 'What industry is your business in?',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/monthly-turnover',
      progressKey: ProgressItem.YourBusiness,
      title: "What's your average monthly turnover?",
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/annual-turnover',
      progressKey: ProgressItem.YourBusiness,
      title: `What\'s your average annual turnover?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/social-links',
      progressKey: ProgressItem.YourBusiness,
      title: `Where can we find your business online?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/business-address',
      progressKey: ProgressItem.YourBusiness,
      title: `Your business address`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/company-website',
      progressKey: ProgressItem.YourBusiness,
      title: `What is your business website?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/refinance-business-changes',
      progressKey: ProgressItem.YourBusiness,
      title: `Has there been structural changes to your business?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/about-you',
      progressKey: ProgressItem.AboutYou,
      title: `About you`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/home-address',
      progressKey: ProgressItem.AboutYou,
      title: `What is your home address?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/has-property',
      progressKey: ProgressItem.AboutYou,
      title: `Do you own property?`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/properties',
      progressKey: ProgressItem.AboutYou,
      title: 'Do you own any property?',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/ato-balance',
      progressKey: ProgressItem.AboutYou,
      title: 'What is your outstanding ATO balance?',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/other-loans',
      progressKey: ProgressItem.AboutYou,
      title: `Your Other Financial Commitments`,
      copy: `Select all the options that apply.`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/date-of-birth',
      progressKey: ProgressItem.AboutYou,
      title: `Date of birth`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/bank-feeds',
      progressKey: ProgressItem.Documents,
      title: 'Bank Statements',
      copy: (
        <div>
          <span>Connect securely to your business bank account.</span>{' '}
          <BankFeedsHelpTool />
        </div>
      ),
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/banking-landing',
      progressKey: ProgressItem.Documents,
      title: 'Bank Statements',
      copy: (
        <div>
          <span>Connect securely to your business bank account.</span>{' '}
          <BankFeedsHelpTool />
        </div>
      ),
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/financials',
      progressKey: ProgressItem.Documents,
      title: `Your financial statements`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/drivers-license',
      progressKey: ProgressItem.Documents,
      title: `Your driver licence`,
      copy: 'Please upload a clear image of your drivers licence',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/other-documents',
      progressKey: ProgressItem.Documents,
      title: `Other documents`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/trust-deed',
      progressKey: ProgressItem.Documents,
      title: `Trust deed`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/previous-invoice',
      progressKey: ProgressItem.Documents,
      title: `Your previous invoice`,
      copy: 'Please upload a recent invoice from kogan or another credit provider',
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/accounts-payable',
      progressKey: ProgressItem.AccountsPayable,
      title: `Accounts Payable Contact`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/idv-send-link',
      progressKey: ProgressItem.IdentityVerification,
      title: 'Identity Verification',
      copy: (
        <p>
          We need to collect some personal information to verify your identity.{' '}
          <IdVerificationInfoTooltip />
        </p>
      ),
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: false,
    },
    {
      url: '/single/idv-await-result',
      progressKey: ProgressItem.IdentityVerification,
      title: 'Identity Verification',
      copy: (
        <p>
          We need to collect some personal information to verify your identity.{' '}
          <IdVerificationInfoTooltip />
        </p>
      ),
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: false,
    },
    {
      url: '/single/confirmation',
      progressKey: ProgressItem.Success,
      title: `Please verify that everything is correct`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/single/success',
      progressKey: ProgressItem.Success,
      title: ``,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
    {
      url: '/expired',
      progressKey: ProgressItem.Success,
      title: `Expired`,
      nextRoute: getNextDocCapRoute,
      isConfirmationPageRequired: true,
    },
  ],
  variant: [],
};

export function getDocumentCaptureCompletionState(
  experimentGroup: string,
  stages: string[],
) {
  const routes = RouteConfig[experimentGroup || ExperimentGroup.Control];
  const requiredRoutes = routes.filter(route => {
    const routeStage = route.url.split('/')[2];
    return stages.includes(routeStage);
  });

  const progressItems = uniq(requiredRoutes.map(r => r.progressKey));
  return {
    progressItems,
    requiredRoutes,
  };
}

function getNextDocCapRoute(request: {
  documentCaptureId: string;
  stages: string[];
  routeInfo: DocCapRoute;
  experimentGroup: string;
}) {
  const { documentCaptureId, stages, routeInfo, experimentGroup } = request;
  // about-you component contains 4 single questions. So if any of these stages exist, we must push about-you component onto routes
  let _stages = stages;
  if (stages.some(e => AboutYouStages.includes(e))) {
    _stages.push('about-you');
  }

  // where in the flow the current single question sits
  const currentStageRouteIndex = RouteConfig[
    experimentGroup || ExperimentGroup.Control
  ].findIndex(route => {
    return route.url === routeInfo?.url;
  });
  // need to loop through the routes and determine which route will be next based on the order
  // plus if it is actually requested by the document capture
  let nextRoute = '';

  // start index at + 1, since we start AFTER the current route, otherwise it'll simply
  // match on the current route
  for (
    let i = currentStageRouteIndex + 1;
    i < RouteConfig[experimentGroup || ExperimentGroup.Control].length;
    i++
  ) {
    if (nextRoute) break;

    const route = RouteConfig[experimentGroup || ExperimentGroup.Control][i];
    const routeStage = route.url.split('/')[2];

    if (
      route.progressKey === ProgressItem.Success ||
      _stages.includes(routeStage)
    ) {
      nextRoute = route?.url;
      break;
    }
  }

  if (nextRoute.endsWith('/confirmation')) {
    const shouldSkipConfirmationPage = _stages.every(stage => {
      const routeConfig = RouteConfig[
        experimentGroup || ExperimentGroup.Control
      ].find(rc => rc.url.split('/')[2] === stage);

      return routeConfig?.isConfirmationPageRequired === false;
    });

    if (shouldSkipConfirmationPage) {
      nextRoute = nextRoute.replace(/\/confirmation$/, '/success');
    }
  }

  return `/hub/document-capture/${documentCaptureId}${nextRoute}`;
}

export const getDocCapRouteContext = (
  url: string,
  variantName: string | null,
): DocCapRoute => {
  let routes = RouteConfig[variantName || ExperimentGroup.Control];

  if (!routes) {
    // tslint:disable-next-line
    console.warn(
      `Could not find route config for variant ${variantName}. Using control instead`,
    );
    routes = RouteConfig.control;
  }

  // hard-codes the destination root url "/single"
  if (url.endsWith('/single')) {
    return routes.find(r => r.url === '/single') || routes[0];
  }

  if (url.endsWith('/expired')) {
    return routes.find(r => r.url === '/expired') || routes[0];
  }

  const index = routes
    .map(x => x.url)
    .findIndex(routeConfigUrl => {
      const routeStage = routeConfigUrl.split('/')[2];
      if (!routeStage) return false; // immediately exclude "/single"
      return url.endsWith(routeConfigUrl);
    });
  return routes[index];
};
