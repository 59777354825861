import React from 'react';

type Props = {
  className?: string;
  onChange: any;
  options: { value: string; label: string }[];
  name: string;
  selected: string;
  required?: boolean;
  labelClassName?: any;
};

export default function RadioGroup(props: Props) {
  const {
    className,
    onChange,
    name,
    options,
    selected,
    required,
    labelClassName,
  } = props;

  const radioButtons = options.map((o, i) => {
    return (
      <React.Fragment key={i}>
        <input
          type='radio'
          id={o.value}
          value={o.value}
          name={name}
          checked={selected === o.value}
          onChange={onChange}
          required={required}
        />
        <label htmlFor={o.value}>
          <div className={labelClassName}>
            <span>{o.label}</span>
          </div>
        </label>
      </React.Fragment>
    );
  });

  return <div className={className}>{radioButtons}</div>;
}
