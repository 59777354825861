import React, { Component } from 'react';
import PropTypes from 'prop-types';
import progressStyle from '../sass/components/_progress.scss';

class Progress extends Component {
  render() {
    const progress = this.props.value;
    return (
      <div
        className={[progressStyle.progress, progressStyle.thin, 'mt-2'].join(
          ' ',
        )}
      >
        <div
          className={[progressStyle.bar, progressStyle.accent].join(' ')}
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  }
}

Progress.propTypes = {
  value: PropTypes.number,
};

export default Progress;
