import tableStyles from 'common/sass/components/_table.scss';
import { title } from 'common/utils/utils';
import lumiEnvironment from '../../lumi.environment';
import { featureFlags } from 'common/utils/feature_flags';

export const ADMIN_PATH = '/admin';
export const DEFAULT_TABLE_SIZE = 20;

export const fullUrlPath = dest => `/admin/${dest}`;

export const getRow = (title, accessor, id, options = {}) => {
  return {
    Header: title,
    accessor,
    className: tableStyles.cellStyle,
    headerClassName: tableStyles.headerStyle,
    id: id,
    ...options,
  };
};

export const round = (x, decimalPoints = 0) => {
  const multiple = Math.pow(10, decimalPoints);
  return (x >= 0 ? 1 : -1) * (Math.round(Math.abs(x * multiple)) / multiple);
};

export const roundCurrency = x => {
  return round(x * 100) / 100;
};

export const getRef = (obj, key) => {
  return key.split('.').reduce((a, b) => (a ? a[b] : undefined), obj);
};

export function getSpath(object, spath) {
  if (spath === undefined || !spath.length) {
    return object;
  }
  const components = spath.split('.');
  let currentObject = object;
  for (let i = 0; i < components.length; i++) {
    if (Array.isArray(currentObject)) {
      currentObject = currentObject.map(item =>
        getSpath(item, components.slice(i).join('.')),
      );
      return currentObject;
    }
    if (currentObject[components[i]] === undefined) {
      return undefined;
    }
    if (currentObject[components[i]] === null) {
      return null;
    }
    currentObject = currentObject[components[i]];
  }
  return currentObject;
}

export function setSpath(object, spath, value, atIndex) {
  if (spath === undefined) {
    throw new Error('spath is required');
  }
  const components = spath.split('.');
  if (!components.length) {
    return value;
  }
  let currentObject = object;
  for (let i = 0; i < components.length; i++) {
    if (Array.isArray(currentObject)) {
      const itemPath = components.slice(i).join('.');
      let j = 0;
      let max = currentObject.length;
      if (components.length - 1 === i && atIndex !== undefined) {
        j = atIndex;
        max = atIndex + 1;
      }
      for (j; j < max; j++) {
        const item = currentObject[j];
        const itemValue = Array.isArray(value)
          ? value[Math.min(j, value.length - 1)]
          : value;
        const resItem = setSpath(item, itemPath, itemValue, atIndex);
        currentObject[j] = resItem;
      }
      return currentObject;
    }
    if (i === components.length - 1) {
      currentObject[components[components.length - 1]] = value;
      continue;
    }
    if (currentObject[components[i]] === undefined) {
      currentObject[components[i]] = {};
    }
    currentObject = currentObject[components[i]];
  }
  return object;
}

export const setRef = (obj, key, value) => {
  const keys = key.split('.');
  let cursor = obj;
  keys.forEach((key, index) => {
    if (!isNaN(key)) {
      key = parseInt(key);
    }
    if (index === keys.length - 1) {
      cursor[key] = value;
    } else {
      if (!cursor[key]) {
        cursor[key] = {};
      }
      cursor = cursor[key];
    }
  });
  return obj;
};

export const mergeObj = (obj, newObj) => {
  return Object.assign(obj || {}, newObj || {});
};

export const formatNumber = num => {
  if (!num) return null;
  num = String(num).replace(',', '');
  if (num > 1000000000) return num;
  let collector = [];
  let numberArray = ('' + num).split('').reverse();
  for (let i = 0; i < numberArray.length; i++) {
    if (i % 3 === 0 && i !== 0) collector.push(',');
    collector.push(numberArray[i]);
  }
  return collector.reverse().join('');
};

export function commafyNumber(x, decimalPoints = 2) {
  x = parseFloat(x, 10) || 0;
  return x
    .toFixed(decimalPoints)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  currencyDisplay: 'narrowSymbol',
});

export function commafyNumberOrDash(x, decimalPoints = 2) {
  x = parseFloat(x, 10) || 0;
  return x === 0.0 ? '-' : `$${commafyNumber(x, decimalPoints)}`;
}

/**
 * Fun fact: there is a function on the server side similar to this, named normaliseAddress()
 */
export const normalizeAddress = addrObj => {
  return addrObj
    ? `${addrObj.unit_number ? addrObj.unit_number + '/' : ''}${
        addrObj.street_number ? addrObj.street_number : ''
      }${addrObj.street ? ' ' + addrObj.street : ''}${
        addrObj.street_type ? ' ' + addrObj.street_type : ''
      }${addrObj.city ? ' ' + addrObj.city : ''}${
        addrObj.postal_code ? ', ' + addrObj.postal_code : ''
      }${addrObj.state ? ', ' + addrObj.state : ''}`
    : '';
};

export function matchMedia(query) {
  return window.matchMedia && window.matchMedia(query).matches;
}

export function getScreenWidth() {
  if (matchMedia('screen and (min-width: 1440px)')) {
    return 1440;
  } else if (matchMedia('screen and (min-width: 970px)')) {
    return 970;
  } else if (matchMedia('screen and (min-width: 768px)')) {
    return 768;
  } else {
    return 320;
  }
}

export function capitalize(text) {
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

export { title };

export function humanFileSize(bytes, si) {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export const Auth0LocalStorageKey = `@@auth0spajs@@::${lumiEnvironment.AUTH0_CLIENT_ID}::${lumiEnvironment.AUTH0_API_IDENTIFIER}::openid profile email offline_access`;

export function getAccessToken() {
  const auth0 = localStorage.getItem(Auth0LocalStorageKey);
  if (auth0) {
    try {
      const parsedAuth0 = JSON.parse(auth0);
      return (
        parsedAuth0?.body?.access_token || parsedAuth0?.body?.accessToken
      );
    } catch (error) {
      return null;
    }
  }
}

export function arrayDiff(array1, array2) {
  return array1.filter(i => array2.indexOf(i) < 0);
}

export function getLocalData(key, parse = false) {
  const json = localStorage.getItem(key);
  if (!parse) return json;
  return json ? JSON.parse(json) : {};
}

export function setLocalData(key, value) {
  console.log('setLocalData');
  const json = localStorage.setItem(key, value);
}

export function mapOptions(options) {
  if (!options || !options.length) return [];
  const result = options.map(o => {
    return { value: o, label: title(o) };
  });
  return result;
}

export function hubspotContactLink(portalId, contactId) {
  return `https://app.hubspot.com/contacts/${portalId}/contact/${contactId}`;
}

export function hubspotCompanyLink(portalId, companyId) {
  return `https://app.hubspot.com/contacts/${portalId}/company/${companyId}`;
}

export function hubspotDealLink(portalId, dealId) {
  return `https://app.hubspot.com/contacts/${portalId}/deal/${dealId}`;
}
