/**
 *  Name: Welcome
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import React from 'react';

export default (store: any) => ({
  path: 'start',
  onEnter: documentCaptureRetrieve(store),
  getComponent(nextState: any, cb: any) {
    const Welcome = React.lazy(() => import('./containers/WelcomeContainer'));
    cb(null, Welcome);
  },
});
