import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DashboardLayout from './DashboardLayout';
import Help from '../../components/help';
import ApplicationProgress from '../../components/application_progress';
import progressStyle from '../../../common/sass/components/_progress.scss';
import { connect } from 'react-redux';

import styles from '../../styles/modules/dashboard_application.scss';
import cardStyle from '../../../common/sass/components/_card.scss';
import { refreshToken } from '../../routes/Auth/Login/modules/actions';
import LumiSpinner from '../../../common/components/LumiSpinner';
import { ExperimentObserver } from '../../observers/ExperimentObserver';
import lumiEnvironment from '../../../lumi.environment';

class DashboardApplicationLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
    this.loaded = this.loaded.bind(this);
  }
  loaded() {
    this.setState({ loaded: true });
  }
  componentDidMount() {
    const token = localStorage.getItem(lumiEnvironment.LOCAL_STORAGE_NAME);
    this.props.dispatch(refreshToken('', token, this.loaded, this.loaded));
  }
  render() {
    const { routes } = this.props;
    const currentRoute = routes[routes.length - 1] || {};

    const getProgress = experimentGroup => {
      const isFunc = typeof currentRoute.progress === 'function';
      if (isFunc) return currentRoute.progress(experimentGroup);
      return currentRoute.progress;
    };
    let progress = getProgress();

    const accentClass =
      (progress || {}).percentage === 100 ? progressStyle.accent : '';

    ExperimentObserver.bindListener(experimentGroup => {
      progress = getProgress(experimentGroup);
    });

    if (!this.state.loaded) return null;

    return (
      <React.Suspense fallback={<LumiSpinner fullSize />}>
        <DashboardLayout>
          <div className={styles.wrapper}>
            <div className={styles.body}>
              <div className={styles.progress}>
                <div className={progressStyle.progress}>
                  <div
                    className={[progressStyle.bar, accentClass].join(' ')}
                    style={{ width: `${(progress || {}).percentage}%` }}
                  />
                </div>
              </div>
              {this.props.children}
            </div>
            <div className={styles.progressContainer}>
              <div className={[cardStyle.card, 'p-0'].join(' ')}>
                <ApplicationProgress progress={progress} />
              </div>
              <div className={[cardStyle.card, 'p-3'].join(' ')}>
                <Help />
              </div>
            </div>
          </div>
        </DashboardLayout>
      </React.Suspense>
    );
  }
}

DashboardApplicationLayout.propTypes = {
  children: PropTypes.element.isRequired,
  routes: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(DashboardApplicationLayout);
