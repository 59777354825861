import React, { useEffect, useMemo } from 'react';
import LumiForm from '../../../../../common/components/LumiForm';
import LumiField from '../../../../../common/components/LumiField';
import LumiButton from '../../../../../common/components/LumiButton';
import { getNextPath, getDynamicFields } from '../../DocumentCollectionUtils';
import { mapFields } from '../../../../../common/utils/utils';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import LumiSelect from '../../../../../common/components/LumiSelect';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { getNumber } from '../../../../utils/validators';
import { useQuery } from 'react-query';
import { getLoanReasons } from '../modules/actions';
import { MapDispatchToPropsLoanInfo } from '../containers/LoanInfoContainer';
import numeral from 'numeral';
import { intersection } from 'lodash';

const path = 'loan_info';
const aboutForm = 'loanInfo';

interface PropTypes extends MapDispatchToPropsLoanInfo {
  documentProgress: any;
  application: any;
  account: any;
  params?: any;
}

const validateLoanAmount = (value: string) => {
  const amount = numeral(value).value();
  if (amount < 5000) return 'Loan amount must be at least $5,000';
  if (amount > 200000) return 'Loan amount can not be more than $200,000';
};

const getFields = (loanReasons: any[]) => ({
  'loan-amount': {
    fields: [
      {
        name: 'loan_amount',
        label: 'Loan Amount',
        type: 'mask',
        wrapperClassName: 'lm-my-6 lm-w-full',
        component: LumiField,
        extras: {
          mask: createNumberMask({ prefix: '$', allowDecimal: true }),
        },
        validate: validateLoanAmount,
      },
    ],
  },
  'loan-purpose': {
    fields: [
      {
        name: 'reason_for_loan',
        label: 'Loan Purpose',
        wrapperClassName: 'lm-my-6 lm-w-full',
        component: LumiSelect,
        disabled: !loanReasons.length,
        extras: {
          options: loanReasons,
          isMulti: true,
        },
        validate: (value: any) =>
          value.length === 0 ? 'At least one reason required' : null,
      },
    ],
  },
});

export default function LoanInfo(props: PropTypes) {
  const handleSubmit = (form: any) => {
    const {
      application,
      saveApplication,
      documentProgress,
      updateProgress,
      params,
      push,
    } = props;
    const nextPath = getNextPath(path, documentProgress.stages);
    const requiredInfoKeys = Object.keys(documentProgress.stages) || [];
    const stageNames = intersection(requiredInfoKeys, [
      'loan-purpose',
      'loan-amount',
    ]);
    const progressThrough = () => {
      updateProgress(documentProgress.document_capture_id, stageNames);
      push(`/app/documents/${params.id}/${nextPath}`);
    };

    if (application && application.id) {
      const updatedApplication: any = {
        id: application.id,
      };
      const appendAppInfo = (innerKey: string, obj: any) =>
        (updatedApplication[innerKey] = {
          ...updatedApplication[innerKey],
          ...obj,
        });
      if (form.loan_amount)
        appendAppInfo('loan', { amount: getNumber(form.loan_amount) });
      if (form.reason_for_loan?.length)
        appendAppInfo('loan', {
          reason_for_loan: form.reason_for_loan.map((x: any) => x.value),
        });
      saveApplication(updatedApplication, () => false);
    }
    progressThrough();
  };

  const { application, documentProgress } = props;

  const { data } = useQuery('loanReasons', getLoanReasons);
  const loanReasons = useMemo(() => data?.reasons, [data]);

  const fields = useMemo(() => {
    const reasons = (loanReasons || []).map((r: any) => ({
      value: r.value,
      label: r.text,
    }));
    const fieldConfig = getFields(reasons);
    return getDynamicFields(fieldConfig, Object.keys(documentProgress.stages));
  }, [loanReasons]);

  const initialValues = useMemo(() => {
    // Map the string values of reas_for_loan to the react-select object
    const reasonForLoans = (application?.loan?.reason_for_loan || []).map(
      (x: string) => {
        const reason =
          (loanReasons || []).find((r: any) => r.value === x) || {};
        return { value: x, label: reason.text };
      },
    );
    return {
      loan_amount: application.loan?.amount,
      reason_for_loan: reasonForLoans,
    };
  }, [loanReasons]);

  useEffect(() => {
    props.initialize(aboutForm, initialValues);
  }, [loanReasons]);

  return (
    <div className={layoutStyles.documentCapturePage}>
      <div className={layoutStyles.documentCaptureBox}>
        <LumiForm
          name={aboutForm}
          initialValues={initialValues}
          warnUnsaved={false}
          onSubmit={handleSubmit}
          clientForm
          touchOnBlur={false}
        >
          <div className={layoutStyles.innerBox}>
            <h1>Loan Information</h1>
            {mapFields(fields)}
          </div>
          <div className={layoutStyles.nextButton}>
            <LumiButton rounded phat type='submit' label='NEXT' />
          </div>
        </LumiForm>
      </div>
    </div>
  );
}
