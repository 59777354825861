let scaleFactor = null;

export function getOS() {
  if (isMac()) {
    return 'Mac';
  }
  if (isWindows()) {
    return 'Windows';
  }
  if (isAndroid()) {
    return 'Android';
  }
  if (isIOS()) {
    return 'iOS';
  }
  return null;
}

export function isDesktop() {
  return !isMobile();
}

export function isMobile() {
  let isMobile = false;
  try {
    isMobile = /(iPhone|iPod|iPad|Android|Windows (Phone|Mobile))/i.test(
      navigator.userAgent,
    );
  } catch (e) {}
  return isMobile;
}

export function isMac() {
  return !!navigator.userAgent.match(/Mac OS X/);
}

export function isWindows() {
  return !!navigator.userAgent.match(/Windows/);
}

export function isIOS() {
  return !!navigator.platform.match(/iPhone|iPod|iPad/);
}

export function isIPhone() {
  return !!navigator.platform.match(/iPhone/);
}

export function isIPad() {
  return !!navigator.platform.match(/iPad/);
}

export function isIPod() {
  return !!navigator.platform.match(/iPod/);
}

export function getIOSVersion() {
  if (!isIOS()) {
    return -1;
  }
  let matches = navigator.userAgent.match(/\b[0-9]+_[0-9]+(?:_[0-9]+)?\b/);
  if (!matches) {
    return -1;
  }
  return matches[0];
}

export function getIOSMajorVersion() {
  return getIOSVersion().split('_')[0];
}

export function isAndroid() {
  let ua = navigator.userAgent;
  return ua.indexOf('Android') >= 0;
}

export function isNativeAndroid() {
  let ua = navigator.userAgent;
  let isOpera = !!window.opera || ua.indexOf(' OPR/') >= 0;

  return isAndroid() && !window.chrome && !isOpera;
}

export function getAndroidVersion() {
  let ua = navigator.userAgent;

  if (ua.indexOf('Android;') >= 0) {
    return 4.0;
  }

  return parseFloat(ua.slice(ua.indexOf('Android') + 8));
}

export function isWindowsPhone() {
  return /(Windows (Phone|Mobile))/i.test(navigator.userAgent);
}

export function isLandscape() {
  return window.orientation === -90 || window.orientation === 90;
}

export function getScaleFactor() {
  if (!scaleFactor) {
    scaleFactor = _getScaleFactor();
  }
  return scaleFactor;
}

function _getScaleFactor() {
  let dpr = window.devicePixelRatio;

  if (!isMobile()) {
    return 28;
  }

  if (dpr <= 2) {
    return 30;
  }

  return 28;
}
