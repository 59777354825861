/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import { reduxForm } from 'redux-form-legacy';
import {
  forgotPassword,
  clearPasswordResetSuccesfully,
} from '../modules/actions';
import { Link } from 'react-router';
import {
  mandatoryFieldsValidation,
  regexTestSimpleEmail,
} from '../../../../../common/utils/utils';
import FormInput from '../../../../../common/components/TextField';
import formStyle from '../../../../../common/sass/components/_form.scss';
import buttonStyle from '../../../../../common/sass/components/_button.scss';

export const fields = ['email'];

function asyncValidate(values) {
  return new Promise((resolve, reject) => {
    let errors = {};

    if (!regexTestSimpleEmail(values.email)) {
      errors['email'] = 'Invalid email';
    }

    if (errors && Object.keys(errors).length > 0) {
      reject(errors);
    } else {
      resolve();
    }
  });
}

function validate(values) {
  return mandatoryFieldsValidation(values, fields);
}

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    bindAll(this, 'submit');
  }

  submit(values, dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(forgotPassword(values, resolve, reject));
    });
  }

  componentWillUnmount() {
    this.props.dispatch(clearPasswordResetSuccesfully());
  }

  render() {
    const {
      fields: { email },
      handleSubmit,
      submitting,
      passwordResetSuccessfully,
    } = this.props;

    const emailInputProps = {
      fieldData: email,
      label: 'Email',
      type: 'email',
    };

    const continueButtonProps = {
      className: [buttonStyle.button, buttonStyle.cta].join(' '),
      key: 'continue',
      disabled: submitting,
      type: 'submit',
    };

    const instructions = passwordResetSuccessfully ? (
      <div>
        <p className='max-width mb-8'>
          To reset your password, please check your email and click the link to
          follow the instructions.
        </p>
        <Link to={'/app/login'}>Back to login</Link>
      </div>
    ) : null;

    const form = !passwordResetSuccessfully ? (
      <form onSubmit={handleSubmit(this.submit)}>
        <p className='max-width mb-8'>
          Please enter the email address you use to log in and we'll send you
          instructions to reset your password
        </p>
        <FormInput {...emailInputProps} />
        <button {...continueButtonProps}>Reset Password</button>
      </form>
    ) : null;

    return (
      <div className={formStyle.container}>
        <h1>
          {passwordResetSuccessfully
            ? 'Please check your email'
            : 'Forgot your password?'}
        </h1>
        <div>
          {form}
          {instructions}
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  passwordResetSuccessfully: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { passwordResetSuccessfully: state.user.passwordResetSuccessfully };
}

export default reduxForm(
  {
    form: 'forgot_password',
    fields,
    asyncValidate,
    asyncBlurFields: ['email'],
    validate,
  },
  mapStateToProps,
)(ForgotPassword);
