import * as React from 'react';

import * as styles from './LumiRadioGroup.scss';
import { mergeClasses } from '../../../common/utils/utils';

type Props = {
  input: any;
  meta: { touched: boolean; dirty: boolean; error: string };
  label?: string;
  id: string;
  className: string;
  tabIndex?: number;
  options: { label: string; value: string }[];
};

const LumiRadioGroup = (props: Props) => {
  const { input, meta, label, id, tabIndex, options, className } = props;
  const showError = meta && (meta.touched || meta.dirty) && meta.error;

  const radioButtons = options.map((o, i) => {
    const inputId = `${id}-${i}`;
    return (
      <div
        key={i}
        className='lm-relative lm-inline-block lm-mr-10 lumi-radio-button lm-mb-2'
      >
        <input
          type='radio'
          tabIndex={tabIndex}
          className={styles.radioButton}
          {...input}
          checked={input.value === o.value}
          value={o.value}
          id={inputId}
        />
        <label htmlFor={inputId}>{o.label}</label>
      </div>
    );
  });

  return (
    <div className={className}>
      <div className='lm-font-demi lm-mb-2'>{label}</div>
      <div className='lumi-radio-group-buttons'>{radioButtons}</div>
      {showError && (
        <div className='lm-font-demi lm-text-red-500'>{meta.error}</div>
      )}
    </div>
  );
};

export default LumiRadioGroup;
