import React from 'react';
import * as styles from '../sass/components/_dots.scss';

const ProgressDots = () => (
  <div className={styles.loadIcon}>
    <span />
    <span />
    <span />
  </div>
);

export default ProgressDots;
