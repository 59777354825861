import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import AlertBox from '../components/alert_box';
import '../styles/base.scss';
import { ReactQueryConfigProvider } from 'react-query';
import LumiSpinner from '../../common/components/LumiSpinner';
import lumiEnvironment from '../../lumi.environment';
import * as analyticsUtils from '../utils/analytics';

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 0,
  },
};
export default class Root extends React.Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    store: PropTypes.object.isRequired,
  };

  get content() {
    const onUpdate = () => {
      window.onInit = (callback, interval = 250, maxWaitDuration = 1000) => {
        const analyticsInterval = setInterval(() => {
          if (typeof window.analytics.user !== 'function') return;
          clearInterval(analyticsInterval);
          callback();
        }, interval);

        // Timeout the checking process after the specified max time.
        setTimeout(() => clearInterval(analyticsInterval), maxWaitDuration);
      };

      // Where does this onInit come from?
      // eslint-disable-next-line no-undef
      onInit(() => {
        analyticsUtils.trackPage(
          document.title,
          {},
          {
            anonymousId: window.analytics?.user()?.anonymousId() || null,
            integrations: {
              'Google Analytics': {
                clientId: window.analytics?.user()?.anonymousId() || null,
              },
            },
          },
        );
      });

      window.scrollTo(0, 0);
    };

    return (
      <Router
        onUpdate={onUpdate}
        history={browserHistory}
        children={this.props.routes}
      />
    );
  }

  componentWillMount() {
    const location = (this.props.store.getState().router || {})
      .locationBeforeTransitions;
    let signupData = localStorage.getItem('signupData');
    // remove corrupted local storage.
    if (signupData && signupData[0] !== '{') {
      console.log('Corrupt signup data, removing from local storage');
      signupData = null;
      localStorage.removeItem('signupData');
    }

    if (location && location.query) {
      const query = location.query;
      signupData = {
        utm_source: query.utm_source,
        utm_medium: query.utm_medium,
        utm_campaign: query.utm_campaign,
        utm_term: query.utm_term,
        utm_content: query.utm_content,
      };

      let hasVals = false;
      Object.keys(signupData).forEach(function (key) {
        if (signupData[key]) {
          hasVals = true;
        }
      });

      if (hasVals) {
        signupData.origination = 'web';
        localStorage.setItem('signupData', JSON.stringify(signupData));
      }

      if (query.access_token) {
        localStorage.setItem(
          lumiEnvironment.LOCAL_STORAGE_NAME,
          query.access_token,
        );
      }
    }
  }

  render() {
    return (
      <ReactQueryConfigProvider config={queryConfig}>
        <React.Suspense fallback={<LumiSpinner fullSize />}>
          <Provider store={this.props.store}>
            <div style={{ height: '100%' }}>
              {this.content}
              <AlertBox />
            </div>
          </Provider>
        </React.Suspense>
      </ReactQueryConfigProvider>
    );
  }
}
