/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import defaults from 'lodash/defaults';
import { reduxForm } from 'redux-form-legacy';
import { resetPassword } from '../modules/actions';
import { Link } from 'react-router';
import { mandatoryFieldsValidation } from '../../../../../common/utils/utils';
import { push } from 'react-router-redux';
import formStyle from '../../../../../common/sass/components/_form.scss';
import buttonStyle from '../../../../../common/sass/components/_button.scss';

import FormInput from '../../../../../common/components/TextField';

export const fields = ['password', 'password_confirm'];

function validate(values) {
  let errors = mandatoryFieldsValidation(values, ['password']);

  if (values['password'] !== values['password_confirm']) {
    errors['password_confirm'] = "Passwords don't match";
  }

  return errors;
}

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    bindAll(this, 'submit');
  }

  submit(values, dispatch) {
    const { location } = this.props;

    return new Promise((resolve, reject) => {
      let params = {
        email: location.query.email,
        reset_password_token: location.query.reset_password_token,
        password: values.password,
      };

      dispatch(
        resetPassword(
          params,
          data => {
            dispatch(push('/app/login'));
          },
          reject,
        ),
      );
    });
  }

  componentWillMount() {
    const { location } = this.props;
    let email = location.query.email;
    let token = location.query.reset_password_token;

    if (!email || !token) {
      console.log('missing email/token.');
      this.props.dispatch(push('/app/forgot_password'));
    }
  }

  render() {
    const {
      fields: { password, password_confirm },
      handleSubmit,
      submitting,
    } = this.props;

    let passwordInputProps = {
      fieldData: password,
      label: 'Password',
      type: 'password',
      enablePasswordStrength: true,
    };

    let passwordConfirmInputProps = {
      fieldData: password_confirm,
      label: 'Confirm password',
      type: 'password',
    };

    let continueButtonProps = {
      key: 'continue',
      className: [buttonStyle.button, buttonStyle.cta].join(' '),
      disabled: submitting,
      type: 'submit',
    };

    return (
      <form
        className={formStyle.container}
        onSubmit={handleSubmit(this.submit)}
      >
        <h1>Update your password</h1>
        <p className='max-width mb-8'>
          Please enter a new password for your account
        </p>
        <FormInput {...passwordInputProps} />
        <FormInput {...passwordConfirmInputProps} />
        <button {...continueButtonProps}>Change password</button>
        <hr />
        <p className='mt-5'>
          Still need help?
          <Link to={'support'}> Contact our support</Link>
        </p>
      </form>
    );
  }
}

ResetPassword.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'reset_password',
  fields,
  validate,
})(ResetPassword);
