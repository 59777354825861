import * as React from 'react';
import * as cardStyle from '../../common/sass/components/_card.scss';
import HelpIcon from '../images/help.svg';

type Props = {};

type State = {
  open: boolean;
};

class HelpCard extends React.Component<Props, State> {
  state: State = {
    open: false,
  };

  openHelp = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { open } = this.state;

    const helpCard = (
      <div
        className={[cardStyle.card, cardStyle.amber, 'max-width mb-4'].join(
          ' ',
        )}
      >
        <p className='mb-2'>
          BankFeeds securely connects with your financial institution, and sends
          us up to a year's worth of statements as an encrypted, read-only PDF.
          This allows us to effectively calculate your affordability, equitable
          weekly repayments, verify ownership of accounts and protect both us
          and you against fraud. We don’t want manual copies of your banking
          statements containing sensitive data floating around!
        </p>
      </div>
    );
    return (
      <div>
        <h2 className='text-center'>Financials</h2>
        <p className='text-center'>
          Connect securely to your bank.
          <a className='ml-2' onClick={this.openHelp}>
            <b>Why do I need this?</b>
            <HelpIcon className='ml-1' width={20} height={20} />
          </a>
        </p>
        {open && helpCard}
      </div>
    );
  }
}

export default HelpCard;
