import { isPlainObject } from 'lodash';

export type Spacing =
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'base'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'none';

export type Screen =
  | 'base'
  | 'smallMobile'
  | 'tablet'
  | 'lsTablet'
  | 'smDesktop'
  | 'lgDesktop';

export type Shadow = '100' | '200' | '300';

export const shadowClassNames: Record<Shadow, string> = {
  '100': 'lm-shadow-100',
  '200': 'lm-shadow-200',
  '300': 'lm-shadow-300',
};

export type SpacingBreakPoints = {
  base?: Spacing;
  smallMobile?: Spacing;
  tablet?: Spacing;
  lsTablet?: Spacing;
  smDesktop?: Spacing;
  lgDesktop?: Spacing;
};

export type ResponsiveBreakPoint<T> = {
  base?: T;
  smallMobile?: T;
  tablet?: T;
  lsTablet?: T;
  smDesktop?: T;
  lgDesktop?: T;
};

export const isResponsiveBreakpoint = <T>(
  x: any,
): x is ResponsiveBreakPoint<T> => isPlainObject(x);

export interface IStringMap {
  [key: string]: string;
}

export interface IStringNumberMap {
  [key: string]: number;
}

export type IBreakpointNumberMap = {
  [key in Screen]: number;
};

// number * 4 === number in px, and number / 4 === number in rem
export const SpacingToTailwindUnitMap: IStringNumberMap = {
  none: 0,
  'xx-small': 1,
  'x-small': 2,
  small: 3,
  base: 4,
  medium: 5,
  large: 6,
  'x-large': 8,
  'xx-large': 10,
};

export const ScreenBreakpointMap = {
  base: '' as const,
  smallMobile: 'xsm' as const,
  tablet: 'sm' as const,
  lsTablet: 'md' as const,
  smDesktop: 'lg' as const,
  lgDesktop: 'xl' as const,
};

export type ScreenBreakpointValue =
  typeof ScreenBreakpointMap[keyof typeof ScreenBreakpointMap];

export type Layout = {
  padding?: Spacing | SpacingBreakPoints;
  paddingX?: Spacing | SpacingBreakPoints;
  paddingY?: Spacing | SpacingBreakPoints;
  paddingLeft?: Spacing | SpacingBreakPoints;
  paddingRight?: Spacing | SpacingBreakPoints;
  paddingTop?: Spacing | SpacingBreakPoints;
  paddingBottom?: Spacing | SpacingBreakPoints;
  margin?: Spacing | SpacingBreakPoints;
  marginX?: Spacing | SpacingBreakPoints;
  marginY?: Spacing | SpacingBreakPoints;
  marginLeft?: Spacing | SpacingBreakPoints;
  marginRight?: Spacing | SpacingBreakPoints;
  marginTop?: Spacing | SpacingBreakPoints;
  marginBottom?: Spacing | SpacingBreakPoints;
  spanX?: number | ResponsiveBreakPoint<number>;
  spanY?: number;
};

/**
 * Margin and padding Tawilwind classes mapped by breakpoint,
 * type (margin or padding), direction, then size.
 *
 * For example:
 * ```ts
 * spacingClassMap['sm']['m']['l'][2]
 * > 'sm:lm-ml-2'
 * ```
 *
 * This is required because we can't dynamically build tailwind class names.
 */
export const spacingClassMap: {
  [breakpoint: string]: {
    [type: string]: { [direction: string]: { [size: number]: string } };
  };
} = {
  '': {
    m: {
      '': {
        0: 'lm-m-0',
        1: 'lm-m-1',
        2: 'lm-m-2',
        3: 'lm-m-3',
        4: 'lm-m-4',
        5: 'lm-m-5',
        6: 'lm-m-6',
        8: 'lm-m-8',
        10: 'lm-m-10',
      },
      l: {
        0: 'lm-ml-0',
        1: 'lm-ml-1',
        2: 'lm-ml-2',
        3: 'lm-ml-3',
        4: 'lm-ml-4',
        5: 'lm-ml-5',
        6: 'lm-ml-6',
        8: 'lm-ml-8',
        10: 'lm-ml-10',
      },
      r: {
        0: 'lm-mr-0',
        1: 'lm-mr-1',
        2: 'lm-mr-2',
        3: 'lm-mr-3',
        4: 'lm-mr-4',
        5: 'lm-mr-5',
        6: 'lm-mr-6',
        8: 'lm-mr-8',
        10: 'lm-mr-10',
      },
      t: {
        0: 'lm-mt-0',
        1: 'lm-mt-1',
        2: 'lm-mt-2',
        3: 'lm-mt-3',
        4: 'lm-mt-4',
        5: 'lm-mt-5',
        6: 'lm-mt-6',
        8: 'lm-mt-8',
        10: 'lm-mt-10',
      },
      b: {
        0: 'lm-mb-0',
        1: 'lm-mb-1',
        2: 'lm-mb-2',
        3: 'lm-mb-3',
        4: 'lm-mb-4',
        5: 'lm-mb-5',
        6: 'lm-mb-6',
        8: 'lm-mb-8',
        10: 'lm-mb-10',
      },
      x: {
        0: 'lm-mx-0',
        1: 'lm-mx-1',
        2: 'lm-mx-2',
        3: 'lm-mx-3',
        4: 'lm-mx-4',
        5: 'lm-mx-5',
        6: 'lm-mx-6',
        8: 'lm-mx-8',
        10: 'lm-mx-10',
      },
      y: {
        0: 'lm-my-0',
        1: 'lm-my-1',
        2: 'lm-my-2',
        3: 'lm-my-3',
        4: 'lm-my-4',
        5: 'lm-my-5',
        6: 'lm-my-6',
        8: 'lm-my-8',
        10: 'lm-my-10',
      },
    },
    p: {
      '': {
        0: 'lm-p-0',
        1: 'lm-p-1',
        2: 'lm-p-2',
        3: 'lm-p-3',
        4: 'lm-p-4',
        5: 'lm-p-5',
        6: 'lm-p-6',
        8: 'lm-p-8',
        10: 'lm-p-10',
      },
      l: {
        0: 'lm-pl-0',
        1: 'lm-pl-1',
        2: 'lm-pl-2',
        3: 'lm-pl-3',
        4: 'lm-pl-4',
        5: 'lm-pl-5',
        6: 'lm-pl-6',
        8: 'lm-pl-8',
        10: 'lm-pl-10',
      },
      r: {
        0: 'lm-pr-0',
        1: 'lm-pr-1',
        2: 'lm-pr-2',
        3: 'lm-pr-3',
        4: 'lm-pr-4',
        5: 'lm-pr-5',
        6: 'lm-pr-6',
        8: 'lm-pr-8',
        10: 'lm-pr-10',
      },
      t: {
        0: 'lm-pt-0',
        1: 'lm-pt-1',
        2: 'lm-pt-2',
        3: 'lm-pt-3',
        4: 'lm-pt-4',
        5: 'lm-pt-5',
        6: 'lm-pt-6',
        8: 'lm-pt-8',
        10: 'lm-pt-10',
      },
      b: {
        0: 'lm-pb-0',
        1: 'lm-pb-1',
        2: 'lm-pb-2',
        3: 'lm-pb-3',
        4: 'lm-pb-4',
        5: 'lm-pb-5',
        6: 'lm-pb-6',
        8: 'lm-pb-8',
        10: 'lm-pb-10',
      },
      x: {
        0: 'lm-px-0',
        1: 'lm-px-1',
        2: 'lm-px-2',
        3: 'lm-px-3',
        4: 'lm-px-4',
        5: 'lm-px-5',
        6: 'lm-px-6',
        8: 'lm-px-8',
        10: 'lm-px-10',
      },
      y: {
        0: 'lm-py-0',
        1: 'lm-py-1',
        2: 'lm-py-2',
        3: 'lm-py-3',
        4: 'lm-py-4',
        5: 'lm-py-5',
        6: 'lm-py-6',
        8: 'lm-py-8',
        10: 'lm-py-10',
      },
    },
  },
  xsm: {
    m: {
      '': {
        0: 'xsm:lm-m-0',
        1: 'xsm:lm-m-1',
        2: 'xsm:lm-m-2',
        3: 'xsm:lm-m-3',
        4: 'xsm:lm-m-4',
        5: 'xsm:lm-m-5',
        6: 'xsm:lm-m-6',
        8: 'xsm:lm-m-8',
        10: 'xsm:lm-m-10',
      },
      l: {
        0: 'xsm:lm-ml-0',
        1: 'xsm:lm-ml-1',
        2: 'xsm:lm-ml-2',
        3: 'xsm:lm-ml-3',
        4: 'xsm:lm-ml-4',
        5: 'xsm:lm-ml-5',
        6: 'xsm:lm-ml-6',
        8: 'xsm:lm-ml-8',
        10: 'xsm:lm-ml-10',
      },
      r: {
        0: 'xsm:lm-mr-0',
        1: 'xsm:lm-mr-1',
        2: 'xsm:lm-mr-2',
        3: 'xsm:lm-mr-3',
        4: 'xsm:lm-mr-4',
        5: 'xsm:lm-mr-5',
        6: 'xsm:lm-mr-6',
        8: 'xsm:lm-mr-8',
        10: 'xsm:lm-mr-10',
      },
      t: {
        0: 'xsm:lm-mt-0',
        1: 'xsm:lm-mt-1',
        2: 'xsm:lm-mt-2',
        3: 'xsm:lm-mt-3',
        4: 'xsm:lm-mt-4',
        5: 'xsm:lm-mt-5',
        6: 'xsm:lm-mt-6',
        8: 'xsm:lm-mt-8',
        10: 'xsm:lm-mt-10',
      },
      b: {
        0: 'xsm:lm-mb-0',
        1: 'xsm:lm-mb-1',
        2: 'xsm:lm-mb-2',
        3: 'xsm:lm-mb-3',
        4: 'xsm:lm-mb-4',
        5: 'xsm:lm-mb-5',
        6: 'xsm:lm-mb-6',
        8: 'xsm:lm-mb-8',
        10: 'xsm:lm-mb-10',
      },
      x: {
        0: 'xsm:lm-mx-0',
        1: 'xsm:lm-mx-1',
        2: 'xsm:lm-mx-2',
        3: 'xsm:lm-mx-3',
        4: 'xsm:lm-mx-4',
        5: 'xsm:lm-mx-5',
        6: 'xsm:lm-mx-6',
        8: 'xsm:lm-mx-8',
        10: 'xsm:lm-mx-10',
      },
      y: {
        0: 'xsm:lm-my-0',
        1: 'xsm:lm-my-1',
        2: 'xsm:lm-my-2',
        3: 'xsm:lm-my-3',
        4: 'xsm:lm-my-4',
        5: 'xsm:lm-my-5',
        6: 'xsm:lm-my-6',
        8: 'xsm:lm-my-8',
        10: 'xsm:lm-my-10',
      },
    },
    p: {
      '': {
        0: 'xsm:lm-p-0',
        1: 'xsm:lm-p-1',
        2: 'xsm:lm-p-2',
        3: 'xsm:lm-p-3',
        4: 'xsm:lm-p-4',
        5: 'xsm:lm-p-5',
        6: 'xsm:lm-p-6',
        8: 'xsm:lm-p-8',
        10: 'xsm:lm-p-10',
      },
      l: {
        0: 'xsm:lm-pl-0',
        1: 'xsm:lm-pl-1',
        2: 'xsm:lm-pl-2',
        3: 'xsm:lm-pl-3',
        4: 'xsm:lm-pl-4',
        5: 'xsm:lm-pl-5',
        6: 'xsm:lm-pl-6',
        8: 'xsm:lm-pl-8',
        10: 'xsm:lm-pl-10',
      },
      r: {
        0: 'xsm:lm-pr-0',
        1: 'xsm:lm-pr-1',
        2: 'xsm:lm-pr-2',
        3: 'xsm:lm-pr-3',
        4: 'xsm:lm-pr-4',
        5: 'xsm:lm-pr-5',
        6: 'xsm:lm-pr-6',
        8: 'xsm:lm-pr-8',
        10: 'xsm:lm-pr-10',
      },
      t: {
        0: 'xsm:lm-pt-0',
        1: 'xsm:lm-pt-1',
        2: 'xsm:lm-pt-2',
        3: 'xsm:lm-pt-3',
        4: 'xsm:lm-pt-4',
        5: 'xsm:lm-pt-5',
        6: 'xsm:lm-pt-6',
        8: 'xsm:lm-pt-8',
        10: 'xsm:lm-pt-10',
      },
      b: {
        0: 'xsm:lm-pb-0',
        1: 'xsm:lm-pb-1',
        2: 'xsm:lm-pb-2',
        3: 'xsm:lm-pb-3',
        4: 'xsm:lm-pb-4',
        5: 'xsm:lm-pb-5',
        6: 'xsm:lm-pb-6',
        8: 'xsm:lm-pb-8',
        10: 'xsm:lm-pb-10',
      },
      x: {
        0: 'xsm:lm-px-0',
        1: 'xsm:lm-px-1',
        2: 'xsm:lm-px-2',
        3: 'xsm:lm-px-3',
        4: 'xsm:lm-px-4',
        5: 'xsm:lm-px-5',
        6: 'xsm:lm-px-6',
        8: 'xsm:lm-px-8',
        10: 'xsm:lm-px-10',
      },
      y: {
        0: 'xsm:lm-py-0',
        1: 'xsm:lm-py-1',
        2: 'xsm:lm-py-2',
        3: 'xsm:lm-py-3',
        4: 'xsm:lm-py-4',
        5: 'xsm:lm-py-5',
        6: 'xsm:lm-py-6',
        8: 'xsm:lm-py-8',
        10: 'xsm:lm-py-10',
      },
    },
  },
  sm: {
    m: {
      '': {
        0: 'sm:lm-m-0',
        1: 'sm:lm-m-1',
        2: 'sm:lm-m-2',
        3: 'sm:lm-m-3',
        4: 'sm:lm-m-4',
        5: 'sm:lm-m-5',
        6: 'sm:lm-m-6',
        8: 'sm:lm-m-8',
        10: 'sm:lm-m-10',
      },
      l: {
        0: 'sm:lm-ml-0',
        1: 'sm:lm-ml-1',
        2: 'sm:lm-ml-2',
        3: 'sm:lm-ml-3',
        4: 'sm:lm-ml-4',
        5: 'sm:lm-ml-5',
        6: 'sm:lm-ml-6',
        8: 'sm:lm-ml-8',
        10: 'sm:lm-ml-10',
      },
      r: {
        0: 'sm:lm-mr-0',
        1: 'sm:lm-mr-1',
        2: 'sm:lm-mr-2',
        3: 'sm:lm-mr-3',
        4: 'sm:lm-mr-4',
        5: 'sm:lm-mr-5',
        6: 'sm:lm-mr-6',
        8: 'sm:lm-mr-8',
        10: 'sm:lm-mr-10',
      },
      t: {
        0: 'sm:lm-mt-0',
        1: 'sm:lm-mt-1',
        2: 'sm:lm-mt-2',
        3: 'sm:lm-mt-3',
        4: 'sm:lm-mt-4',
        5: 'sm:lm-mt-5',
        6: 'sm:lm-mt-6',
        8: 'sm:lm-mt-8',
        10: 'sm:lm-mt-10',
      },
      b: {
        0: 'sm:lm-mb-0',
        1: 'sm:lm-mb-1',
        2: 'sm:lm-mb-2',
        3: 'sm:lm-mb-3',
        4: 'sm:lm-mb-4',
        5: 'sm:lm-mb-5',
        6: 'sm:lm-mb-6',
        8: 'sm:lm-mb-8',
        10: 'sm:lm-mb-10',
      },
      x: {
        0: 'sm:lm-mx-0',
        1: 'sm:lm-mx-1',
        2: 'sm:lm-mx-2',
        3: 'sm:lm-mx-3',
        4: 'sm:lm-mx-4',
        5: 'sm:lm-mx-5',
        6: 'sm:lm-mx-6',
        8: 'sm:lm-mx-8',
        10: 'sm:lm-mx-10',
      },
      y: {
        0: 'sm:lm-my-0',
        1: 'sm:lm-my-1',
        2: 'sm:lm-my-2',
        3: 'sm:lm-my-3',
        4: 'sm:lm-my-4',
        5: 'sm:lm-my-5',
        6: 'sm:lm-my-6',
        8: 'sm:lm-my-8',
        10: 'sm:lm-my-10',
      },
    },
    p: {
      '': {
        0: 'sm:lm-p-0',
        1: 'sm:lm-p-1',
        2: 'sm:lm-p-2',
        3: 'sm:lm-p-3',
        4: 'sm:lm-p-4',
        5: 'sm:lm-p-5',
        6: 'sm:lm-p-6',
        8: 'sm:lm-p-8',
        10: 'sm:lm-p-10',
      },
      l: {
        0: 'sm:lm-pl-0',
        1: 'sm:lm-pl-1',
        2: 'sm:lm-pl-2',
        3: 'sm:lm-pl-3',
        4: 'sm:lm-pl-4',
        5: 'sm:lm-pl-5',
        6: 'sm:lm-pl-6',
        8: 'sm:lm-pl-8',
        10: 'sm:lm-pl-10',
      },
      r: {
        0: 'sm:lm-pr-0',
        1: 'sm:lm-pr-1',
        2: 'sm:lm-pr-2',
        3: 'sm:lm-pr-3',
        4: 'sm:lm-pr-4',
        5: 'sm:lm-pr-5',
        6: 'sm:lm-pr-6',
        8: 'sm:lm-pr-8',
        10: 'sm:lm-pr-10',
      },
      t: {
        0: 'sm:lm-pt-0',
        1: 'sm:lm-pt-1',
        2: 'sm:lm-pt-2',
        3: 'sm:lm-pt-3',
        4: 'sm:lm-pt-4',
        5: 'sm:lm-pt-5',
        6: 'sm:lm-pt-6',
        8: 'sm:lm-pt-8',
        10: 'sm:lm-pt-10',
      },
      b: {
        0: 'sm:lm-pb-0',
        1: 'sm:lm-pb-1',
        2: 'sm:lm-pb-2',
        3: 'sm:lm-pb-3',
        4: 'sm:lm-pb-4',
        5: 'sm:lm-pb-5',
        6: 'sm:lm-pb-6',
        8: 'sm:lm-pb-8',
        10: 'sm:lm-pb-10',
      },
      x: {
        0: 'sm:lm-px-0',
        1: 'sm:lm-px-1',
        2: 'sm:lm-px-2',
        3: 'sm:lm-px-3',
        4: 'sm:lm-px-4',
        5: 'sm:lm-px-5',
        6: 'sm:lm-px-6',
        8: 'sm:lm-px-8',
        10: 'sm:lm-px-10',
      },
      y: {
        0: 'sm:lm-py-0',
        1: 'sm:lm-py-1',
        2: 'sm:lm-py-2',
        3: 'sm:lm-py-3',
        4: 'sm:lm-py-4',
        5: 'sm:lm-py-5',
        6: 'sm:lm-py-6',
        8: 'sm:lm-py-8',
        10: 'sm:lm-py-10',
      },
    },
  },
  md: {
    m: {
      '': {
        0: 'md:lm-m-0',
        1: 'md:lm-m-1',
        2: 'md:lm-m-2',
        3: 'md:lm-m-3',
        4: 'md:lm-m-4',
        5: 'md:lm-m-5',
        6: 'md:lm-m-6',
        8: 'md:lm-m-8',
        10: 'md:lm-m-10',
      },
      l: {
        0: 'md:lm-ml-0',
        1: 'md:lm-ml-1',
        2: 'md:lm-ml-2',
        3: 'md:lm-ml-3',
        4: 'md:lm-ml-4',
        5: 'md:lm-ml-5',
        6: 'md:lm-ml-6',
        8: 'md:lm-ml-8',
        10: 'md:lm-ml-10',
      },
      r: {
        0: 'md:lm-mr-0',
        1: 'md:lm-mr-1',
        2: 'md:lm-mr-2',
        3: 'md:lm-mr-3',
        4: 'md:lm-mr-4',
        5: 'md:lm-mr-5',
        6: 'md:lm-mr-6',
        8: 'md:lm-mr-8',
        10: 'md:lm-mr-10',
      },
      t: {
        0: 'md:lm-mt-0',
        1: 'md:lm-mt-1',
        2: 'md:lm-mt-2',
        3: 'md:lm-mt-3',
        4: 'md:lm-mt-4',
        5: 'md:lm-mt-5',
        6: 'md:lm-mt-6',
        8: 'md:lm-mt-8',
        10: 'md:lm-mt-10',
      },
      b: {
        0: 'md:lm-mb-0',
        1: 'md:lm-mb-1',
        2: 'md:lm-mb-2',
        3: 'md:lm-mb-3',
        4: 'md:lm-mb-4',
        5: 'md:lm-mb-5',
        6: 'md:lm-mb-6',
        8: 'md:lm-mb-8',
        10: 'md:lm-mb-10',
      },
      x: {
        0: 'md:lm-mx-0',
        1: 'md:lm-mx-1',
        2: 'md:lm-mx-2',
        3: 'md:lm-mx-3',
        4: 'md:lm-mx-4',
        5: 'md:lm-mx-5',
        6: 'md:lm-mx-6',
        8: 'md:lm-mx-8',
        10: 'md:lm-mx-10',
      },
      y: {
        0: 'md:lm-my-0',
        1: 'md:lm-my-1',
        2: 'md:lm-my-2',
        3: 'md:lm-my-3',
        4: 'md:lm-my-4',
        5: 'md:lm-my-5',
        6: 'md:lm-my-6',
        8: 'md:lm-my-8',
        10: 'md:lm-my-10',
      },
    },
    p: {
      '': {
        0: 'md:lm-p-0',
        1: 'md:lm-p-1',
        2: 'md:lm-p-2',
        3: 'md:lm-p-3',
        4: 'md:lm-p-4',
        5: 'md:lm-p-5',
        6: 'md:lm-p-6',
        8: 'md:lm-p-8',
        10: 'md:lm-p-10',
      },
      l: {
        0: 'md:lm-pl-0',
        1: 'md:lm-pl-1',
        2: 'md:lm-pl-2',
        3: 'md:lm-pl-3',
        4: 'md:lm-pl-4',
        5: 'md:lm-pl-5',
        6: 'md:lm-pl-6',
        8: 'md:lm-pl-8',
        10: 'md:lm-pl-10',
      },
      r: {
        0: 'md:lm-pr-0',
        1: 'md:lm-pr-1',
        2: 'md:lm-pr-2',
        3: 'md:lm-pr-3',
        4: 'md:lm-pr-4',
        5: 'md:lm-pr-5',
        6: 'md:lm-pr-6',
        8: 'md:lm-pr-8',
        10: 'md:lm-pr-10',
      },
      t: {
        0: 'md:lm-pt-0',
        1: 'md:lm-pt-1',
        2: 'md:lm-pt-2',
        3: 'md:lm-pt-3',
        4: 'md:lm-pt-4',
        5: 'md:lm-pt-5',
        6: 'md:lm-pt-6',
        8: 'md:lm-pt-8',
        10: 'md:lm-pt-10',
      },
      b: {
        0: 'md:lm-pb-0',
        1: 'md:lm-pb-1',
        2: 'md:lm-pb-2',
        3: 'md:lm-pb-3',
        4: 'md:lm-pb-4',
        5: 'md:lm-pb-5',
        6: 'md:lm-pb-6',
        8: 'md:lm-pb-8',
        10: 'md:lm-pb-10',
      },
      x: {
        0: 'md:lm-px-0',
        1: 'md:lm-px-1',
        2: 'md:lm-px-2',
        3: 'md:lm-px-3',
        4: 'md:lm-px-4',
        5: 'md:lm-px-5',
        6: 'md:lm-px-6',
        8: 'md:lm-px-8',
        10: 'md:lm-px-10',
      },
      y: {
        0: 'md:lm-py-0',
        1: 'md:lm-py-1',
        2: 'md:lm-py-2',
        3: 'md:lm-py-3',
        4: 'md:lm-py-4',
        5: 'md:lm-py-5',
        6: 'md:lm-py-6',
        8: 'md:lm-py-8',
        10: 'md:lm-py-10',
      },
    },
  },
  lg: {
    m: {
      '': {
        0: 'lg:lm-m-0',
        1: 'lg:lm-m-1',
        2: 'lg:lm-m-2',
        3: 'lg:lm-m-3',
        4: 'lg:lm-m-4',
        5: 'lg:lm-m-5',
        6: 'lg:lm-m-6',
        8: 'lg:lm-m-8',
        10: 'lg:lm-m-10',
      },
      l: {
        0: 'lg:lm-ml-0',
        1: 'lg:lm-ml-1',
        2: 'lg:lm-ml-2',
        3: 'lg:lm-ml-3',
        4: 'lg:lm-ml-4',
        5: 'lg:lm-ml-5',
        6: 'lg:lm-ml-6',
        8: 'lg:lm-ml-8',
        10: 'lg:lm-ml-10',
      },
      r: {
        0: 'lg:lm-mr-0',
        1: 'lg:lm-mr-1',
        2: 'lg:lm-mr-2',
        3: 'lg:lm-mr-3',
        4: 'lg:lm-mr-4',
        5: 'lg:lm-mr-5',
        6: 'lg:lm-mr-6',
        8: 'lg:lm-mr-8',
        10: 'lg:lm-mr-10',
      },
      t: {
        0: 'lg:lm-mt-0',
        1: 'lg:lm-mt-1',
        2: 'lg:lm-mt-2',
        3: 'lg:lm-mt-3',
        4: 'lg:lm-mt-4',
        5: 'lg:lm-mt-5',
        6: 'lg:lm-mt-6',
        8: 'lg:lm-mt-8',
        10: 'lg:lm-mt-10',
      },
      b: {
        0: 'lg:lm-mb-0',
        1: 'lg:lm-mb-1',
        2: 'lg:lm-mb-2',
        3: 'lg:lm-mb-3',
        4: 'lg:lm-mb-4',
        5: 'lg:lm-mb-5',
        6: 'lg:lm-mb-6',
        8: 'lg:lm-mb-8',
        10: 'lg:lm-mb-10',
      },
      x: {
        0: 'lg:lm-mx-0',
        1: 'lg:lm-mx-1',
        2: 'lg:lm-mx-2',
        3: 'lg:lm-mx-3',
        4: 'lg:lm-mx-4',
        5: 'lg:lm-mx-5',
        6: 'lg:lm-mx-6',
        8: 'lg:lm-mx-8',
        10: 'lg:lm-mx-10',
      },
      y: {
        0: 'lg:lm-my-0',
        1: 'lg:lm-my-1',
        2: 'lg:lm-my-2',
        3: 'lg:lm-my-3',
        4: 'lg:lm-my-4',
        5: 'lg:lm-my-5',
        6: 'lg:lm-my-6',
        8: 'lg:lm-my-8',
        10: 'lg:lm-my-10',
      },
    },
    p: {
      '': {
        0: 'lg:lm-p-0',
        1: 'lg:lm-p-1',
        2: 'lg:lm-p-2',
        3: 'lg:lm-p-3',
        4: 'lg:lm-p-4',
        5: 'lg:lm-p-5',
        6: 'lg:lm-p-6',
        8: 'lg:lm-p-8',
        10: 'lg:lm-p-10',
      },
      l: {
        0: 'lg:lm-pl-0',
        1: 'lg:lm-pl-1',
        2: 'lg:lm-pl-2',
        3: 'lg:lm-pl-3',
        4: 'lg:lm-pl-4',
        5: 'lg:lm-pl-5',
        6: 'lg:lm-pl-6',
        8: 'lg:lm-pl-8',
        10: 'lg:lm-pl-10',
      },
      r: {
        0: 'lg:lm-pr-0',
        1: 'lg:lm-pr-1',
        2: 'lg:lm-pr-2',
        3: 'lg:lm-pr-3',
        4: 'lg:lm-pr-4',
        5: 'lg:lm-pr-5',
        6: 'lg:lm-pr-6',
        8: 'lg:lm-pr-8',
        10: 'lg:lm-pr-10',
      },
      t: {
        0: 'lg:lm-pt-0',
        1: 'lg:lm-pt-1',
        2: 'lg:lm-pt-2',
        3: 'lg:lm-pt-3',
        4: 'lg:lm-pt-4',
        5: 'lg:lm-pt-5',
        6: 'lg:lm-pt-6',
        8: 'lg:lm-pt-8',
        10: 'lg:lm-pt-10',
      },
      b: {
        0: 'lg:lm-pb-0',
        1: 'lg:lm-pb-1',
        2: 'lg:lm-pb-2',
        3: 'lg:lm-pb-3',
        4: 'lg:lm-pb-4',
        5: 'lg:lm-pb-5',
        6: 'lg:lm-pb-6',
        8: 'lg:lm-pb-8',
        10: 'lg:lm-pb-10',
      },
      x: {
        0: 'lg:lm-px-0',
        1: 'lg:lm-px-1',
        2: 'lg:lm-px-2',
        3: 'lg:lm-px-3',
        4: 'lg:lm-px-4',
        5: 'lg:lm-px-5',
        6: 'lg:lm-px-6',
        8: 'lg:lm-px-8',
        10: 'lg:lm-px-10',
      },
      y: {
        0: 'lg:lm-py-0',
        1: 'lg:lm-py-1',
        2: 'lg:lm-py-2',
        3: 'lg:lm-py-3',
        4: 'lg:lm-py-4',
        5: 'lg:lm-py-5',
        6: 'lg:lm-py-6',
        8: 'lg:lm-py-8',
        10: 'lg:lm-py-10',
      },
    },
  },
  xl: {
    m: {
      '': {
        0: 'xl:lm-m-0',
        1: 'xl:lm-m-1',
        2: 'xl:lm-m-2',
        3: 'xl:lm-m-3',
        4: 'xl:lm-m-4',
        5: 'xl:lm-m-5',
        6: 'xl:lm-m-6',
        8: 'xl:lm-m-8',
        10: 'xl:lm-m-10',
      },
      l: {
        0: 'xl:lm-ml-0',
        1: 'xl:lm-ml-1',
        2: 'xl:lm-ml-2',
        3: 'xl:lm-ml-3',
        4: 'xl:lm-ml-4',
        5: 'xl:lm-ml-5',
        6: 'xl:lm-ml-6',
        8: 'xl:lm-ml-8',
        10: 'xl:lm-ml-10',
      },
      r: {
        0: 'xl:lm-mr-0',
        1: 'xl:lm-mr-1',
        2: 'xl:lm-mr-2',
        3: 'xl:lm-mr-3',
        4: 'xl:lm-mr-4',
        5: 'xl:lm-mr-5',
        6: 'xl:lm-mr-6',
        8: 'xl:lm-mr-8',
        10: 'xl:lm-mr-10',
      },
      t: {
        0: 'xl:lm-mt-0',
        1: 'xl:lm-mt-1',
        2: 'xl:lm-mt-2',
        3: 'xl:lm-mt-3',
        4: 'xl:lm-mt-4',
        5: 'xl:lm-mt-5',
        6: 'xl:lm-mt-6',
        8: 'xl:lm-mt-8',
        10: 'xl:lm-mt-10',
      },
      b: {
        0: 'xl:lm-mb-0',
        1: 'xl:lm-mb-1',
        2: 'xl:lm-mb-2',
        3: 'xl:lm-mb-3',
        4: 'xl:lm-mb-4',
        5: 'xl:lm-mb-5',
        6: 'xl:lm-mb-6',
        8: 'xl:lm-mb-8',
        10: 'xl:lm-mb-10',
      },
      x: {
        0: 'xl:lm-mx-0',
        1: 'xl:lm-mx-1',
        2: 'xl:lm-mx-2',
        3: 'xl:lm-mx-3',
        4: 'xl:lm-mx-4',
        5: 'xl:lm-mx-5',
        6: 'xl:lm-mx-6',
        8: 'xl:lm-mx-8',
        10: 'xl:lm-mx-10',
      },
      y: {
        0: 'xl:lm-my-0',
        1: 'xl:lm-my-1',
        2: 'xl:lm-my-2',
        3: 'xl:lm-my-3',
        4: 'xl:lm-my-4',
        5: 'xl:lm-my-5',
        6: 'xl:lm-my-6',
        8: 'xl:lm-my-8',
        10: 'xl:lm-my-10',
      },
    },
    p: {
      '': {
        0: 'xl:lm-p-0',
        1: 'xl:lm-p-1',
        2: 'xl:lm-p-2',
        3: 'xl:lm-p-3',
        4: 'xl:lm-p-4',
        5: 'xl:lm-p-5',
        6: 'xl:lm-p-6',
        8: 'xl:lm-p-8',
        10: 'xl:lm-p-10',
      },
      l: {
        0: 'xl:lm-pl-0',
        1: 'xl:lm-pl-1',
        2: 'xl:lm-pl-2',
        3: 'xl:lm-pl-3',
        4: 'xl:lm-pl-4',
        5: 'xl:lm-pl-5',
        6: 'xl:lm-pl-6',
        8: 'xl:lm-pl-8',
        10: 'xl:lm-pl-10',
      },
      r: {
        0: 'xl:lm-pr-0',
        1: 'xl:lm-pr-1',
        2: 'xl:lm-pr-2',
        3: 'xl:lm-pr-3',
        4: 'xl:lm-pr-4',
        5: 'xl:lm-pr-5',
        6: 'xl:lm-pr-6',
        8: 'xl:lm-pr-8',
        10: 'xl:lm-pr-10',
      },
      t: {
        0: 'xl:lm-pt-0',
        1: 'xl:lm-pt-1',
        2: 'xl:lm-pt-2',
        3: 'xl:lm-pt-3',
        4: 'xl:lm-pt-4',
        5: 'xl:lm-pt-5',
        6: 'xl:lm-pt-6',
        8: 'xl:lm-pt-8',
        10: 'xl:lm-pt-10',
      },
      b: {
        0: 'xl:lm-pb-0',
        1: 'xl:lm-pb-1',
        2: 'xl:lm-pb-2',
        3: 'xl:lm-pb-3',
        4: 'xl:lm-pb-4',
        5: 'xl:lm-pb-5',
        6: 'xl:lm-pb-6',
        8: 'xl:lm-pb-8',
        10: 'xl:lm-pb-10',
      },
      x: {
        0: 'xl:lm-px-0',
        1: 'xl:lm-px-1',
        2: 'xl:lm-px-2',
        3: 'xl:lm-px-3',
        4: 'xl:lm-px-4',
        5: 'xl:lm-px-5',
        6: 'xl:lm-px-6',
        8: 'xl:lm-px-8',
        10: 'xl:lm-px-10',
      },
      y: {
        0: 'xl:lm-py-0',
        1: 'xl:lm-py-1',
        2: 'xl:lm-py-2',
        3: 'xl:lm-py-3',
        4: 'xl:lm-py-4',
        5: 'xl:lm-py-5',
        6: 'xl:lm-py-6',
        8: 'xl:lm-py-8',
        10: 'xl:lm-py-10',
      },
    },
  },
};

export type ColSpanUnit =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 'full';

export const colSpanClassNamesByBreakpoint: Record<
  ScreenBreakpointValue,
  Record<ColSpanUnit, string>
> = {
  '': {
    1: 'lm-col-span-1',
    2: 'lm-col-span-2',
    3: 'lm-col-span-3',
    4: 'lm-col-span-4',
    5: 'lm-col-span-5',
    6: 'lm-col-span-6',
    7: 'lm-col-span-7',
    8: 'lm-col-span-8',
    9: 'lm-col-span-9',
    10: 'lm-col-span-10',
    11: 'lm-col-span-11',
    12: 'lm-col-span-12',
    full: 'lm-col-span-full',
  },
  xsm: {
    1: 'xsm:lm-col-span-1',
    2: 'xsm:lm-col-span-2',
    3: 'xsm:lm-col-span-3',
    4: 'xsm:lm-col-span-4',
    5: 'xsm:lm-col-span-5',
    6: 'xsm:lm-col-span-6',
    7: 'xsm:lm-col-span-7',
    8: 'xsm:lm-col-span-8',
    9: 'xsm:lm-col-span-9',
    10: 'xsm:lm-col-span-10',
    11: 'xsm:lm-col-span-11',
    12: 'xsm:lm-col-span-12',
    full: 'xsm:lm-col-span-full',
  },
  sm: {
    1: 'sm:lm-col-span-1',
    2: 'sm:lm-col-span-2',
    3: 'sm:lm-col-span-3',
    4: 'sm:lm-col-span-4',
    5: 'sm:lm-col-span-5',
    6: 'sm:lm-col-span-6',
    7: 'sm:lm-col-span-7',
    8: 'sm:lm-col-span-8',
    9: 'sm:lm-col-span-9',
    10: 'sm:lm-col-span-10',
    11: 'sm:lm-col-span-11',
    12: 'sm:lm-col-span-12',
    full: 'sm:lm-col-span-full',
  },
  md: {
    1: 'md:lm-col-span-1',
    2: 'md:lm-col-span-2',
    3: 'md:lm-col-span-3',
    4: 'md:lm-col-span-4',
    5: 'md:lm-col-span-5',
    6: 'md:lm-col-span-6',
    7: 'md:lm-col-span-7',
    8: 'md:lm-col-span-8',
    9: 'md:lm-col-span-9',
    10: 'md:lm-col-span-10',
    11: 'md:lm-col-span-11',
    12: 'md:lm-col-span-12',
    full: 'md:lm-col-span-full',
  },
  lg: {
    1: 'lg:lm-col-span-1',
    2: 'lg:lm-col-span-2',
    3: 'lg:lm-col-span-3',
    4: 'lg:lm-col-span-4',
    5: 'lg:lm-col-span-5',
    6: 'lg:lm-col-span-6',
    7: 'lg:lm-col-span-7',
    8: 'lg:lm-col-span-8',
    9: 'lg:lm-col-span-9',
    10: 'lg:lm-col-span-10',
    11: 'lg:lm-col-span-11',
    12: 'lg:lm-col-span-12',
    full: 'lg:lm-col-span-full',
  },
  xl: {
    1: 'xl:lm-col-span-1',
    2: 'xl:lm-col-span-2',
    3: 'xl:lm-col-span-3',
    4: 'xl:lm-col-span-4',
    5: 'xl:lm-col-span-5',
    6: 'xl:lm-col-span-6',
    7: 'xl:lm-col-span-7',
    8: 'xl:lm-col-span-8',
    9: 'xl:lm-col-span-9',
    10: 'xl:lm-col-span-10',
    11: 'xl:lm-col-span-11',
    12: 'xl:lm-col-span-12',
    full: 'xl:lm-col-span-full',
  },
};
