import { connect } from 'react-redux';
import Welcome from '../components/Welcome';
import { linkOpened } from '../../DocumentCaptureActions';
import { push } from 'react-router-redux';

const mapDispatchToProps = {
  linkOpened,
  push,
};

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    account: state.user.account,
    documentProgress: progress.document_capture_progress,
    documentCapture: progress.document_capture,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
