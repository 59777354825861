import jwtDecode from 'jwt-decode';
import auth0 from 'auth0-js';
import { Auth0LocalStorageKey } from '../../admin/utils';
import environment from '../../lumi.environment';
import { AccountRole } from '../api/dataTypes/account';

export const getToken = async () => {
  if (environment.CLIENT === 'admin' || environment.CLIENT === 'broker') {
    try {
      let authDataString = localStorage.getItem(Auth0LocalStorageKey);

      if (!authDataString) {
        authDataString = await fetchAuth0Token();
      }

      const parsed = JSON.parse(authDataString);
      return parsed?.body?.access_token || parsed?.body?.accessToken;
    } catch (e) {
      console.error('not authenticated', e);
      return null;
    }
  }

  return localStorage.getItem(environment.LOCAL_STORAGE_NAME);
};

const fetchAuth0Token = async () => {
  const webAuth = new auth0.WebAuth({
    domain: environment.AUTH0_MANAGEMENT_DOMAIN,
    clientID: environment.AUTH0_CLIENT_ID,
    audience: environment.AUTH0_API_IDENTIFIER,
    redirectUri:
      location.origin +
      (environment.CLIENT === 'admin'
        ? '/admin/adminview'
        : '/broker/dashboard'),
    responseType: 'token id_token',
  });

  return new Promise<string>((resolve, reject) => {
    webAuth.checkSession({}, (err: any, authResult: any) => {
      if (err) {
        return reject(err);
      }

      const authTokenString = JSON.stringify({
        body: authResult,
      });
      localStorage.setItem(Auth0LocalStorageKey, authTokenString);
      if (window.location.hash?.length > 1) window.location.hash = '';
      return resolve(authTokenString);
    });
  });
};

export const requestAuth0EmailCode = (email: string) => {
  const webAuth = new auth0.WebAuth({
    domain: environment.AUTH0_MANAGEMENT_DOMAIN,
    clientID: environment.AUTH0_CLIENT_ID,
    audience: environment.AUTH0_API_IDENTIFIER,
    redirectUri: location.origin + '/broker/dashboard',
    responseType: 'token id_token',
  });

  webAuth.passwordlessStart(
    {
      connection: 'email',
      send: 'code',
      email: email,
    },
    function (err: any) {
      if (err) throw err;
    },
  );
};

export const isAuthenticated = () => {
  try {
    const token = localStorage.getItem(environment.LOCAL_STORAGE_NAME);
    if (!token) return false;
    const user: any = jwtDecode(token);
    if (
      user?.role === 'documentCaptureUser' &&
      !location?.pathname?.includes('document-capture')
    ) {
      return false;
    }
    if (user.exp) {
      // the token is expired here
      if (new Date(user.exp * 1000) > new Date()) return true;
    }
    return true;
  } catch (err) {
    return false;
  }
};

const loginListeners: { [key: string]: () => void } = {};

export const callLoginListeners = () => {
  Object.values(loginListeners).forEach(listener => listener());
};

export function getOnboardingRoute(step: string) {
  const routeMap: any = {
    INCOMPLETE: 'apply/success',
    IN_PROGRESS: 'app/dashboard/inprocess',
    COMPLETE: 'app/dashboard/thanks',
  };
  return routeMap[step] || 'apply/success';
}

export function getRedirectUrl(searchParams: string) {
  if (!searchParams) return '';
  const re = /redirectUrl=(.*)/;
  const match = re.exec(searchParams);
  return match?.[1];
}

export type User = {
  account_id?: string;
  access_token?: string;
  combined_name?: string;
  email?: string;
  first_name?: string;
  id?: string;
  has_loc?: boolean;
  last_name?: string;
  phone?: string;
  role?: AccountRole;
  username?: string;
};

export function getUser() {
  const json = localStorage.getItem(environment.LOCAL_STORAGE_USER_NAME);
  if (!json) return {} as User;

  return JSON.parse(json) as User;
}

export const getAuth0RedirectUrl = () => {
  return window.location.pathname + encodeURIComponent(window.location.search); // if query is more than one, it doesnt return full query without encoding
};

export { environment };
