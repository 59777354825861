import { createNumberMask } from 'text-mask-addons';

export const currencyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const currencyMaskOptionsAllowNegative = {
  ...currencyMaskOptions,
  allowNegative: true,
};

export const positiveIntOnlyMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const currencyPositiveIntOnlyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

/**
 * A mask that can be applied to an input that should accept a currency value.
 *
 * Due to issues with the text masking library, we aren't able to enforce 2
 * decimal places to always show in an input element that accepts input from
 * the user (we _can_ do it for read-only inputs - see
 * `currencyWithDecimalsMask`).
 *
 * At the time of writing, this is the best solution we have.
 */
export const currencyMask = createNumberMask(currencyMaskOptions);
export const currencyMaskAllowNegative = createNumberMask(
  currencyMaskOptionsAllowNegative,
);

export const currencyPositiveIntOnlyMask = createNumberMask(
  currencyPositiveIntOnlyMaskOptions,
);

export const currencyMaskForceTwoDecimals = (rawValue: number | string) => {
  let parsedValue = Number(rawValue.toString().replace(/[\$,]/g, ''));
  parsedValue = isNaN(parsedValue) ? 0 : parsedValue;

  return currencyMask(parsedValue.toFixed(2));
};

export function currencyWithDecimalsMaskAllowNegative(
  rawValue: number | string,
) {
  let parsedValue = parseFloat(('' + rawValue).replace(/[\$,]/g, ''));
  parsedValue = isNaN(parsedValue) ? 0 : parsedValue;
  // add logic to generate your mask array
  return currencyMaskAllowNegative(parsedValue.toFixed(2));
}

export const currencyWithoutDecimalMask = createNumberMask({
  ...currencyMaskOptions,
  allowDecimal: false,
});
