import { SelectOption } from 'junction/components/select/Select';
import { isNil, isArray, isEmpty, isObject } from 'lodash';
import * as yup from 'yup';
import numeral from 'numeral';
import moment from 'moment';

export function requiredMessage(shouldShow: any) {
  return shouldShow && 'This field is required';
}

export const prefixStringWithAuto = (string: string) => {
  return `Auto-${string.charAt(0).toLowerCase() + string.slice(1)}`;
};

export const formatAndCleanFormValues = (
  object: any,
  removeEmptyObjects?: boolean,
) => {
  return Object.keys(object).reduce((cleanedObj: any, currentKey: string) => {
    if (
      removeEmptyObjects &&
      isObject(object[currentKey]) &&
      isEmpty(object[currentKey])
    ) {
      return cleanedObj;
    }

    if (!isNil(object[currentKey])) {
      cleanedObj[currentKey] = object[currentKey];
    }

    if (
      object[currentKey] &&
      Object.keys(object[currentKey]).length &&
      typeof object[currentKey] !== 'string'
    ) {
      if (object[currentKey].value) {
        cleanedObj[currentKey] = object[currentKey].value;
        return cleanedObj;
      }

      if (isArray(object[currentKey]) && object[currentKey][0]?.value) {
        const value = object[currentKey].map(
          (option: SelectOption) => option.value,
        );
        if (!value && removeEmptyObjects) return cleanedObj;
        cleanedObj[currentKey] = value;
        return cleanedObj;
      }

      cleanedObj[currentKey] = formatAndCleanFormValues(
        object[currentKey],
        removeEmptyObjects,
      );
      if (removeEmptyObjects && isEmpty(cleanedObj[currentKey])) {
        delete cleanedObj[currentKey];
      }
      return cleanedObj;
    }
    return cleanedObj;
  }, {});
};

/**
 * Can be passed to `transform` in a `yup` schema to transform a value
 * formatted as currency, e.g. '$1,234.56', to a `number`.
 * !Note: in our system 10% actually saved as number 10 instead of 0.1
 * @example
 * const schema = yup.object().shape({
 *  dollarAmount: yup.number().transform(transformYupCurrencyField)
 * })
 *
 * @param _value The field value coerced to a `number` by `yup`. This will be
 *  `NaN` since the input's value is a string formatted as currency.
 * @param originalValue The raw value form the input element
 */
export const transformYupMaskNumberField = (
  _value: any,
  originalValue: number | string | null,
) => {
  if (typeof originalValue === 'number') return originalValue;
  const formattingRemoved = (originalValue || '').replace(/[$%]/g, '');
  return numeral(formattingRemoved).value();
};

/**
 * Can be passed to `transform` in a `yup` schema to transform a value
 * as passed from a Select component which may be passed as a string or
 * a SelectOption.
 * @example
 * const schema = yup.object().shape({
 *  option: yup.string().transform(transformYupSelectField)
 * })
 *
 * @param _value The field value coerced to a `string` by `yup`. This will be
 *  `[object Object]` in the case the input's originalValue is a SelectOption.
 * @param originalValue The raw value form the input element
 */
export const transformYupSelectField = (
  _value: any,
  originalValue: SelectOption | string,
) => {
  if (typeof originalValue === 'string') {
    return originalValue;
  }
  return originalValue?.value;
};

export const transformYupMultiSelectField = (
  _value: any,
  originalValues: (SelectOption | string)[],
) => {
  return originalValues.map((originalValue: SelectOption | string) => {
    if (typeof originalValue === 'string') {
      return originalValue;
    }
    return originalValue?.value;
  });
};

export const transformYupDateFieldToString = (
  originalValue: Date,
  requiredFormate?: string,
) => {
  if (moment.invalid(originalValue as any)) {
    return moment(originalValue).format(requiredFormate || 'DD-MM-YYYY');
  }
  return originalValue;
};

/**
 * Util that ensures a yup schema is required when forceRequired is provided,
 * otherwise is optional.
 */
export const requiredWhenForced = (
  schema: yup.MixedSchema,
  requiredMessage?: string,
) => {
  return schema.when(
    '$forceRequired',
    (forceRequired: boolean, schema: yup.MixedSchema) =>
      forceRequired ? schema.required(requiredMessage) : schema,
  );
};

export const getCommonSelectOptions = ({
  arr,
  labelKey = 'name',
  valueKey = 'id',
}: {
  arr: { [key: string]: any }[];
  labelKey?: string;
  valueKey?: string;
}): { label: string; value: string }[] => {
  if (!arr?.length) return [];
  return arr.map(option => {
    return {
      label: option[labelKey],
      value: option[valueKey],
    };
  });
};

export function windowOpenInPost(
  actionUrl: string,
  inputParams?: { [key: string]: string },
  windowName: string = '_blank',
) {
  let mapForm = document.createElement('form');
  mapForm.target = windowName;
  mapForm.method = 'POST';
  mapForm.action = actionUrl;
  Object.keys(inputParams ?? {}).forEach((keyParam: string) => {
    let mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = keyParam;
    mapInput.value = inputParams[keyParam];
    mapForm.appendChild(mapInput);
  });
  document.body.appendChild(mapForm);
  mapForm.submit();
}
