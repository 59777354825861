import gql from 'graphql-tag';

export const debtorTypesOptionsQuery = gql`
  query DebtorTypesOptions {
    debtorTypesOptions {
      label
      value
    }
  }
`;

export const timeOptions = [
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'FORTNIGHTLY',
    label: 'Fortnightly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'QUARTERLY',
    label: 'Quarterly',
  },
];

export const upsertSOFPDebt = gql`
  mutation updateSOFP($request: GQL_FinancialPositionInput!) {
    updateSOFP(request: $request) {
      id
    }
  }
`;

export const getSOFPLiteral = gql`
  query getSOFP($document_capture_id: ID!) {
    getSOFP(document_capture_id: $document_capture_id) {
      id
      debt {
        type
        name
        balance
        months_remaining
        payment_frequency
        payment_amount
      }
    }
  }
`;
