import { GOT_REASONS, GETTING_REASONS } from '../../../../redux/modules/reason';
import { apiFetch, serverURL } from '../../../../../common/utils/fetch_utils';
import { msgError } from '../../../../CommonCreators';

export function getReasonSuccess(response) {
  return dispatch => {
    dispatch({ response, type: GOT_REASONS });
  };
}

export function gettingReasons() {
  return dispatch => {
    dispatch({ type: GETTING_REASONS });
  };
}

export function getLoanReasons(resolve, reject) {
  return dispatch => {
    dispatch(gettingReasons());
    apiFetch(
      `${serverURL}/v1/leads/loanReasons`,
      {
        method: 'get',
        headers: {},
      },
      data => {
        if (!data.success) {
          throw new Error(data.error);
        }
        dispatch(getReasonSuccess(data));
      },
    ).catch(error => {
      console.log('request failed', error);
      dispatch(msgError('Error', error.message));
      reject(error);
    });
  };
}
