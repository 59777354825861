import React from 'react';

import { mergeClasses } from '../../common/utils/utils';

import ChevronLeft from '../../common/images/chevron-left.svg';
import ChevronRight from '../../common/images/chevron-right.svg';

import * as styles from '../styles/components/_breadcrumbs.scss';
import { getRemainingOutcomeTimeByPath } from '../layouts/DocumentCollectionLayout/DocumentCollectionExperimentConfig';

export type Crumb = {
  value: string;
  label: string;
};

type Props = {
  crumbs: Crumb[];
  disabledCrumbs?: Crumb[];
  activeCrumb: Crumb;
  completedCrumbs: string[];
  nextCrumb: Crumb;
  onClick: (clickedCrumb: Crumb) => void;
  experimentGroup?: string;
  stages?: any;
};

const Breadcrumbs = (props: Props) => {
  const {
    crumbs,
    activeCrumb,
    onClick,
    disabledCrumbs,
    completedCrumbs,
    nextCrumb,
    experimentGroup,
    stages,
  } = props;
  const crumbElements = crumbs.map((crumb, i) => {
    const isFirstCrumb = i === 0;
    const isLastCrumb = i === crumbs.length - 1;
    let handleClick = () => onClick(crumb);

    let isActiveCrumb = crumb.value === activeCrumb.value;
    let isCompletedCrumb = completedCrumbs.find(c => c === crumb.value);
    let crumbStyle = styles.crumb;

    if (isFirstCrumb) crumbStyle = styles.firstCrumb;
    if (isLastCrumb) crumbStyle = styles.lastCrumb;

    if (isActiveCrumb)
      crumbStyle = mergeClasses(crumbStyle, styles.activeCrumb);
    if (isCompletedCrumb)
      crumbStyle = mergeClasses(crumbStyle, styles.completedCrumb);

    if (
      disabledCrumbs &&
      disabledCrumbs.find(disabledCrumb => disabledCrumb.value === crumb.value)
    ) {
      handleClick = null;
    }

    /*
            ================= BEGIN ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
            Get how much time the user has remaining for the path they are on, by summing
            the weights of the fields which exist on that path.

            Please note the crumb element is still part of the control flow
        */
    const timeShavedByPath = getRemainingOutcomeTimeByPath(crumb.value, stages);

    return (
      <div key={`crumb-${i}`} className={crumbStyle} onClick={handleClick}>
        <span>{`${crumb.label}`}</span>
        {experimentGroup === 'variant' && (
          <span className={styles.timeRemaining}>
            {timeShavedByPath ? ` -${timeShavedByPath} Hours` : null}
          </span>
        )}
      </div>
    );
    /*
            ================= END ProgressIncentiveExperimentDocCapture EXPERIMENT CODE =================
        */
  });

  const previousCrumbIndex =
    crumbs.findIndex(crumb => crumb.value === activeCrumb.value) - 1;
  const previousCrumb = crumbs[previousCrumbIndex];

  const nextDisabled = disabledCrumbs.find(crumb => {
    if (crumb.value === 'confirmation') return crumb.value === 'confirmation';
    return crumb.value === nextCrumb.value;
  });
  const previousDisabled = activeCrumb.value === 'start';
  let previousClassName = styles.previous;
  let nextClassName = styles.next;
  if (previousDisabled)
    previousClassName = mergeClasses(previousClassName, styles.disabled);
  if (!nextCrumb) return null;
  const moveForward = nextDisabled && nextDisabled.value === nextCrumb.value;
  if (moveForward) nextClassName = mergeClasses(nextClassName, styles.disabled);
  return (
    <div className={styles.breadcrumbs}>
      <div
        className={previousClassName}
        onClick={() => !previousDisabled && onClick(previousCrumb)}
      >
        <ChevronLeft />
      </div>
      <div className={styles.crumbs}>{crumbElements.reverse()}</div>
      <div
        className={nextClassName}
        onClick={() => !moveForward && onClick(nextCrumb)}
      >
        <ChevronRight />
      </div>
    </div>
  );
};

export default Breadcrumbs;
