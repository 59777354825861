import React from 'react';
import uid from 'uid';
import ReactDOM from 'react-dom';

type Props = {
  idPrefix: string;
  children: React.ReactNode;
};

/**
 * Potal renders the children at the root of the DOM. It will create a div with
 * a unique id that is appended to the body. Children are rendered inside that
 * @param props
 */
export default function Portal(props: Props) {
  const { idPrefix, children } = props;

  const portalId = `${idPrefix}-${uid()}`;
  const [container] = React.useState(() => {
    const el = document.createElement('div');
    el.setAttribute('id', portalId);
    return el;
  });

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
}
