import React, { useEffect } from 'react';
import cardStyle from '../../../../../common/sass/components/_card.scss';
import Cash from '../../../../images/cash.svg';
import Coin from '../../../../images/coin.svg';
import gql from 'graphql-tag';
import {
  useGraphQuery,
  isQueryLoading,
} from '../../../../../common/utils/graphql_utils';
import { connect } from 'react-redux';
import { getApplicantDeal } from '../../../../../graphql/queries/queries';

const documentCaptureQuery = gql`
  {
    documentCaptures(sort_on: "created_at", sort_direction: -1, count: 1) {
      id
    }
  }
`;

type Props = {
  application: any;
};

const mapStateToProps = (state: any) => {
  return {
    application: state.user.application || {},
  };
};

function ThankyouPage(props: Props) {
  const { application } = props;
  const { data, status: dealQueryStatus } = useGraphQuery(
    ['deal', { loanId: application.loan.id }],
    getApplicantDeal(documentCaptureQuery),
  );
  useEffect(() => {
    if (data?.data?.applicantDeal.documentCaptures) {
      // TODO link to hub
      console.log(
        'Your document capture is ',
        data?.data?.applicantDeal.documentCaptures[0].id,
      );
    }
  }, [data]);

  return (
    <div
      className={[
        cardStyle.pageCard,
        cardStyle.iconCard,
        'text-center',
        'mb-6 p-5 pl-10 pr-10',
      ].join(' ')}
    >
      <div className={cardStyle.title}>
        <h2 className='mb-0'>Congratulations</h2>
        <h3>Your loan is now under review.</h3>
        <p className='mt-6 mb-10 max-width'>
          Our customer service team will contact you within 24 hours with a
          lending decision.
        </p>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ThankyouPage);
