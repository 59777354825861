import React, { useState, useEffect, useMemo } from 'react';
import { reduxForm, change } from 'redux-form-legacy';
import { push } from 'react-router-redux';
import { getLoanReasons } from '../modules/actions';
import { saveApplication, msgError } from '../../../../CommonCreators';
import DurationPicker from '../../../../../common/components/DurationPicker';
import FormInput from '../../../../../common/components/TextField';
import { getNumber } from '../../../../utils/validators';
import { scrollTop } from '../../../../utils/view';
import * as analyticsUtils from '../../../../utils/analytics';
import formStyle from '../../../../../common/sass/components/_form.scss';
import loanInfoViewStyles from './LoanInfoStyle.scss';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Button from '../../../../components/button';
import MultiSelect from '../../../../../common/components/FormMultiSelect';
import HelpIcon from '../../../../images/help.svg';
import { getNextApplicationRoute, getExperimentGroup } from '../..';
import LumiRadioGroup from '../../../../../common/components/LumiRadioGroup/LumiRadioGroup';
import ReactModal from 'react-modal';
import * as modalStyles from '../../../../../common/sass/components/_overlayModal.scss';
import { useGraphQuery } from '../../../../../common/utils/graphql_utils';
import { useCookies } from 'react-cookie';
import { getExperimentGroupQuery } from '../../../../../graphql/queries/queries';

const fields = ['loan_product', 'amount', 'term', 'loan_purpose'];

const asyncValidate = (values: any) => {
  return new Promise<void>((resolve, reject) => {
    let errors: any = {};
    const amount = getNumber(values.amount);
    if (!amount || amount < 5000 || amount > 200000) {
      errors['amount'] = 'Amount should be between $5k - $200k';
    }
    if (errors && Object.keys(errors).length > 0) {
      scrollTop();
      reject(errors);
    } else {
      resolve();
    }
  });
};

function loadFromApplication(app: any) {
  let retVal = null;
  let loan = app.loan;
  if (!(app && loan)) {
    retVal = {};
  } else {
    let loan = app.loan;
    retVal = { loan_product: app.customer_selected_product };
    if (loan) {
      retVal = {
        amount: loan.amount || '',
        term: loan.repayment_installments,
        loan_purpose: loan.reason_for_loan,
        loan_product: app.customer_selected_product,
      };
    }
    if (!retVal.term || retVal.term < 13) {
      retVal.term = 20;
    }
  }

  return retVal;
}

function validate(values: any) {
  const errors: any = {};
  fields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  const purpose = values.loan_purpose;
  if (!purpose || purpose.length < 1) {
    errors['loan_purpose'] = 'Please select a purpose';
  }
  return errors;
}

type Props = {
  fields: any;
  handleSubmit: Function;
  error: string;
  resetForm: Function;
  submitting: boolean;
  dispatch: any;
  application: any;
  reason: any;
  values: any;
  location: any;
};

type State = {
  selected: any;
  showHelp: boolean;
};

function LoanInfo(props: Props) {
  const {
    dispatch,
    values,
    fields: { amount, term, loan_product, loan_purpose },
    handleSubmit,
    submitting,
    reason,
  } = props;
  const [selected, setSelected] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [{ ajs_anonymous_id: anonymousId }] = useCookies([
    'ajs_anonymous_id',
  ]) as any;

  const {
    data: experimentGroup,
    status: experimentStatus,
    refetch: fetchExperimentGroup,
  } = useGraphQuery(
    [
      'experimentGroup',
      {
        trialId: anonymousId,
        experimentName: 'ProductTerminologyExperiment',
      },
    ],
    getExperimentGroupQuery,
  );

  const isVariant = useMemo(
    () => experimentGroup?.data?.experiment_group === 'variant',
    [experimentGroup],
  );
  // Luiz only wants to see the variants in fullstory
  if (isVariant) {
    analyticsUtils.track('experiments', {
      anonymous_id: anonymousId,
      experiment_name: 'ProductTerminologyExperiment',
      group: 'variant',
    });
  }

  useEffect(() => {
    analyticsUtils.track('leads', {
      action: 'loan_info_started',
      platform: 'website',
      funnel_step: analyticsUtils.funnel_step(
        'loan_info',
        analyticsUtils.funnel_step_version(),
      ),
      version: analyticsUtils.funnel_step_version(),
    });
    dispatch(getLoanReasons());
  }, []);

  const handleChange = (val: any) => {
    values.loan_purpose = val;
    setSelected(val);
    let selectedVals;
    if (val && val.length > 0) {
      selectedVals = val.map((item: any) => {
        return item['value'];
      });
    }
    props.dispatch(change('loan_info', 'loan_purpose', selectedVals));
  };

  const submit = (values: any, dispatch: any) => {
    return new Promise((resolve, reject) => {
      let application = {
        customer_selected_product: values.loan_product,
        loan: {
          amount: getNumber(values.amount),
          repayment_period_count: 1,
          repayment_period_unit: 'WEEKS',
          repayment_installments: parseInt(values.term),
          reason_for_loan: values.loan_purpose,
        },
      };
      dispatch(
        saveApplication(
          application,
          async (response: any) => {
            let fields = [
              'amount',
              'reason_for_loan',
              'repayment_period_unit',
              'repayment_period_count',
              'repayment_installments',
            ];
            analyticsUtils.identify(
              response.application.account_id,
              fields,
              response.application.loan,
            );
            await analyticsUtils.track('leads', {
              action: 'loan_info_completed',
              platform: 'website',
              funnel_step: analyticsUtils.funnel_step(
                'loan_info',
                analyticsUtils.funnel_step_version(),
              ),
              version: analyticsUtils.funnel_step_version(),
            });
            dispatch(
              push(
                getNextApplicationRoute(
                  location.pathname,
                  getExperimentGroup(),
                ),
              ),
            );
          },
          (err: any) => {
            dispatch(msgError('Error', err.message));
          },
        ),
      );
    });
  };

  let loanReasons = [];
  if (reason && reason.reasons) {
    loanReasons = reason.reasons.map((item: any) => {
      return {
        label: item.text,
        value: item.value,
      };
    });
  }

  let loanAmountProps = {
    fieldData: amount,
    label: 'Loan amount',
    type: 'text',
    mask: createNumberMask({ prefix: '$', allowDecimal: true }),
  };

  let loanTermProps = {
    fieldData: term,
    label: 'Loan duration',
    subLabel: '(months)',
  };
  let nextButtonDisabled = submitting || loan_purpose.value === null;

  const getParentNode = (): HTMLElement => {
    ReactModal.setAppElement('#root');
    return document.querySelector(`#body`);
  };
  const loanProductModal = (
    <ReactModal
      isOpen={showHelp}
      parentSelector={getParentNode}
      overlayClassName={modalStyles.modalWrapper}
      className={['lm-p-5', 'lm-rounded', modalStyles.modalContent].join(' ')}
      shouldCloseOnOverlayClick
      onRequestClose={() => setShowHelp(false)}
    >
      <div className={modalStyles.modalContainer}>
        <p>Start by selecting the right product for you.</p>
        <p>
          <strong>Small business loans</strong> can be secured or unsecured, but
          their main difference when compared to the other types of loans is
          their payment terms and borrowing capacity. Payment terms usually
          range from 3 to 12 months, while loan amounts are often between $5,000
          and $100,000.
        </p>
        <p>
          A <strong>business line of credit</strong> enables you to borrow a
          certain amount of capital annually. The amount is typically based on
          accounts receivable and current inventory but usually less than
          $200,000. A business line of credit can be helpful in managing cash
          flow shortages but should not be used for long-term investments or
          major purchases.
        </p>
        <p>
          If you are still unsure and need advice from our team, just select
          “I’m not sure” and continue the application.
        </p>
      </div>
    </ReactModal>
  );

  let loanTypeOptions = [
    { label: 'Line of Credit', value: 'line-of-credit' },
    { label: 'Term Loan', value: 'term-loan' },
    { label: 'One-off with Payment Pause', value: 'payment-pause-term-loan' },
    { label: "I'm not sure", value: 'unknown' },
  ];
  if (isVariant) {
    loanTypeOptions = [
      { label: 'Ongoing Funds', value: 'line-of-credit' },
      { label: 'One-off Funds', value: 'term-loan' },
      { label: 'One-off with Payment Pause', value: 'payment-pause-term-loan' },
      { label: "I'm not sure", value: 'unknown' },
    ];
  }

  return (
    <div className='lm-m-12 lm-h-full'>
      {loanProductModal}
      <form className={formStyle.container} onSubmit={handleSubmit(submit)}>
        <h1 className='title sm-padded'>About your loan</h1>
        <div className='lm-mb-12'>
          <div className='lm-flex lm-mb-8'>
            <h3 className='lm-text-left'>What type of loan are you after?</h3>
            <a onClick={() => setShowHelp(true)}>
              <HelpIcon className='ml-1' width={25} height={25} />
            </a>
          </div>
          {/* The options are mapped to Hubspot, don't update them unless HS is also updated */}
          <LumiRadioGroup
            input={loan_product}
            className={loanInfoViewStyles.productSelector}
            meta={{
              dirty: loan_product.dirty,
              touched: loan_product.touched,
              error: loan_product.error,
            }}
            id='loan_product'
            options={loanTypeOptions}
          />
        </div>

        <FormInput {...loanAmountProps} />
        <DurationPicker {...loanTermProps} />
        <MultiSelect
          fieldData={loan_purpose}
          label='loan_purpose'
          id='loan-info-purpose'
          placeholder='Loan Purpose'
          options={loanReasons}
          disabled={reason.isLoading}
          onChange={handleChange}
          value={selected}
        />
        <Button
          className='mt-6 mb-6'
          disabled={nextButtonDisabled}
          loading={submitting}
          label='Next step'
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let app = state.user && state.user.application;
  return {
    application: state.user.application,
    reason: state.reason,
    initialValues: loadFromApplication(app),
  };
};

export default reduxForm(
  {
    form: 'loan_info',
    fields,
    validate,
    asyncValidate,
    asyncBlurFields: ['amount'],
  },
  mapStateToProps,
)(LoanInfo);

export { fields };
