import { MotionStyle } from 'framer-motion';
import { get } from 'lodash';
import React, { ReactNode } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import {
  ClearIndicatorProps,
  components as reactSelectComponents,
  SingleValueProps,
} from 'react-select';
import { GroupBase, StylesConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { useJunctionTheme } from '../../utils';
import ExternalSelect from './ExternalSelect';
import InternalSelect from './InternalSelect';
import { mergeClasses } from 'common/utils/utils';

export type SelectOption<TValue = string | number> = {
  label: string | number | React.ReactNode;
  value?: TValue;
  options?: SelectOption[];
  isDisabled?: boolean;
};

export type StylesConfigWithCSSProperties = {
  [K in keyof StylesConfig]?: React.CSSProperties;
};

export type SelectProps = {
  // SYNCHRONOUS PROPS
  /** <junction-internal> Provide a label to show above the Select */
  label?: string;
  /** Additional components to render with the label */
  labelChildren?: React.ReactNode;
  /** A placeholder text value that appears before an option is selected */
  placeholder?: string;
  /** A list of selectable options. Required when isAsync is falsy */
  options?: SelectOption[];
  /** A function which will invoke on a change in select value */
  onChange?: (event?: any) => any;
  /** Displays the provided error below the Select component  */
  error?: { message: string } | string;
  /** Provide a name for the Select, use for controlled Select instances */
  name?: string;
  /** Provide a control to connect Select to a form */
  control?: Control;
  /** The default select value, only for uncontrolled instances */
  defaultValue?: SelectOption | SelectOption[];
  /** The current value to render, only for uncontrolled instances */
  value?: SelectOption | string | number | SelectOption[];
  rules?: any;
  wrapperClassName?: string;
  /** Disables the Select */
  disabled?: boolean;
  nestedSelect?: boolean;
  wrapperStyle?: MotionStyle;
  /** Provide this flag if multiple choices can be selected */
  isMulti?: boolean;
  /** Has a clear button on the right side that you can clear the current value */
  isClearable?: boolean;
  format?: (option: SelectOption) => any;

  /**
   * Defines the height of the select component. Small is a slimline version
   * @default base
   */
  size?: 'small' | 'base';

  /**
   * ExternalSelects only. If you have more than one select component on your form,
   * you can give them different increments so their option lists don't overlap
   */
  zIncrement?: number;

  /**
   * Framer motion animation props
   */
  animation?: any;

  /** `true` if the user can type into the select to search or filter options */
  isSearchable?: boolean;

  /** Custom components to be be passed into Select components */
  customComponents?: Partial<SelectComponents<any, boolean, GroupBase<any>>>;

  /** Custom styles object */
  styles?: StylesConfigWithCSSProperties;

  /** For MultiSelect, keep selected items in menu if it is false */
  hideSelectedOptions?: boolean;

  /**
   * Handle change events on the input
   */
  onInputChange?: (newValue: string) => void;

  /** Text to display when there are no options */
  noOptionsMessage?: string | ((obj: { inputValue: string }) => ReactNode);

  // ASYNCHRONOUS PROPS
  // https://react-select.com/async
  /**
   * Async: load options from a remote source (e.g. API) as the user types.
   */
  isAsync?: boolean;

  /** Async: Is the select in a state of loading */
  isLoading?: boolean;

  /** Async: Text to display when loading options */
  loadingMessage?: (obj: { inputValue: string }) => ReactNode;

  /**
   * Async: If cacheOptions is truthy, then the loaded data will be cached. The cache
   * will remain until `cacheOptions` changes value.
   */
  cacheOptions?: any;

  /**
   * Async: The default set of options to show before the user starts searching (typing). When
   * set to `true`, the results for loadOptions('') will be autoloaded.
   */
  defaultOptions?: SelectOption[] | boolean;

  /**
   * Async: Function that takes in a callback, whose parameter is an array of options
   * that is returned from a filter function,
   * OR,
   * Function that returns a promise, which is the set of options to be used
   * once the promise resolves.
   * See https://react-select.com/async for examples.
   */
  loadOptions?:
    | ((
        inputValue: string,
        callback: (options: Array<SelectOption>) => void,
      ) => void)
    | ((inputValue: string) => Promise<SelectOption>);
};

/**
 * A helper for building a component to be passed to {@link Select}'s
 * `customComponents.ClearIndicator` that shows a custom icon.
 *
 * You can wrap this component with your own one that passes in the icon to be
 * rendered:
 *
 * ```typescript
 * const MyClearIndicator = (props: ClearIndicatorProps) => {
 *  return <SelectClearIndicator {...props} icon={<MyIcon />} />
 * }
 *
 * <Select
 *  customComponents={{
 *    ClearIndicator: MyClearIndicator,
 *  }}
 * />
 * ```
 *
 * See the storybook file for `Select` for an example.
 */
export const SelectClearIndicator = ({
  children,
  icon,
  ...props
}: ClearIndicatorProps & { icon: React.ReactNode }) => (
  <button
    type='button'
    onClick={() => props.clearValue()}
    className='lm-bg-transparent lm-flex lm-items-center'
  >
    {icon}
  </button>
);

/**
 * A helper for building a component to be passed to {@link Select}'s
 * `customComponents.SingleValue` that shows an icon next to the selected
 * value.
 *
 * You can wrap this component with your own one that passes in the icon to be
 * rendered:
 *
 * ```typescript
 * const MySingleValue = (props: SingleValueProps) => {
 *  return <SelectSingleValueWithIcon {...props} icon={<MyIcon />} />
 * }
 *
 * <Select
 *  customComponents={{
 *    SingleValue: MySingleValue,
 *  }}
 * />
 * ```
 *
 * See the storybook file for `Select` for an example.
 */
export const SelectSingleValueWithIcon = ({
  children,
  icon,
  className,
  ...props
}: SingleValueProps & { icon: React.ReactNode }) => {
  return (
    <reactSelectComponents.SingleValue
      {...props}
      className={mergeClasses(
        'lm-flex lm-flex-row lm-items-center lm-gap-x-3 lm-z-50',
        className,
      )}
    >
      {children}
      {icon}
    </reactSelectComponents.SingleValue>
  );
};

const Select = React.forwardRef<HTMLInputElement, SelectProps>((props, ref) => {
  const theme = useJunctionTheme();
  const _props = { ...props };
  const formContext = useFormContext();
  if (formContext) {
    _props.control = formContext.control;
    const error = get(formContext?.errors, props.name);
    if (error) {
      _props.error = error;
    }
  }

  if (theme === 'junction') {
    return <ExternalSelect ref={ref} {..._props} />;
  }

  if (theme === 'junction-internal') {
    return <InternalSelect ref={ref} {..._props} />;
  }

  return <ExternalSelect ref={ref} {..._props} />;
});

export default Select;
