import { ensureAuthenticated } from '../../../../utils/auth';
import React from 'react';
import { getRouteProgress, getExperimentGroup } from '../..';

export default (store: any) => ({
  path: 'variant/signup',
  progress: (group: string) =>
    getRouteProgress('/app/variant/signup', group || getExperimentGroup()),
  enableHeader: true,
  enableFooter: true,
  getComponent(nextState: any, cb: any) {
    const Signupview = React.lazy(() => import('./SignupExperiment'));
    cb(null, Signupview);
  },
});
