import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buttonStyle from '../../common/sass/components/_button.scss';

class Button extends Component {
  render() {
    const { label, disabled, loading, onClick, className } = this.props;
    const loadingClass = loading ? buttonStyle.loading : '';
    const props = {
      className: [
        buttonStyle.button,
        buttonStyle.cta,
        className,
        loadingClass,
        'mt-2',
      ].join(' '),
      disabled: disabled || loading,
    };
    if (onClick) {
      props.onClick = onClick;
    } else {
      props.type = 'submit';
    }
    return <button {...props}>{!loading && label}</button>;
  }
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
