import * as yup from 'yup';

// https://codesandbox.io/s/react-hook-form-array-fields-yq0dm
export const formSchema = yup.object().shape({
  debts: yup.array().of(
    yup.object().shape({
      type: yup
        .mixed()
        // .typeError('Enter type')
        .required('Required'),
      name: yup.string().typeError('Enter name').required('Required'),
      balance: yup.number().typeError('Enter a number').required('Required'),
      months_remaining: yup.number().nullable().typeError('Enter a number'),
      payment_frequency: yup.string().when('type', {
        is: debtType => !['CREDIT_OR_LAYBY', 'OTHER'].includes(debtType?.value),
        then: yup.string().required('Frequency Required'),
      }),
      payment_amount: yup
        .number()
        .nullable()
        .when('type', {
          is: debtType => {
            return !['CREDIT_OR_LAYBY', 'OTHER'].includes(debtType?.value);
          },
          then: yup.number().typeError('Enter a number').required('Required'),
        }),
    }),
  ),
});
