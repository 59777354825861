import React from 'react';
import { getClassName } from '../../utils';
import { Screen, ScreenBreakpointMap } from '../layout.t';

type DisplayValue = 'block' | 'inline' | 'inline-block' | 'flex' | 'grid';

type Props = {
  /**
   * The screensize of the device
   */
  screen: Screen;
  /**
   * Which direction to match the screensize. E.g. if the screensize is set to
   * 'tablet' then, direction 'up' will show for any devices that are larger
   * than a tablet and 'down' will show for any devices smaller than a tablet
   */
  direction: 'up' | 'down';
  className?: string;
  children?: React.ReactNode;
  /**
   * The type of the tag to render in.
   * Please add more to this enum as you see fit.
   * @default div
   */
  htmlTag?: 'div' | 'span' | 'p';

  /**
   * The normal display mode when not hidden, e.g. display: block or display: inline;
   * Please add more as necessary
   * @default block
   */
  display?: DisplayValue;
};

const displayClassNames: Record<DisplayValue, string> = {
  'inline-block': 'lm-inline-block',
  block: 'lm-block',
  flex: 'lm-flex',
  grid: 'lm-grid',
  inline: 'lm-inline',
};

const hiddenClassNamesByResponsivePrefix: Record<string, string> = {
  '': '',
  xsm: 'xsm:lm-hidden',
  sm: 'sm:lm-hidden',
  md: 'md:lm-hidden',
  lg: 'lg:lm-hidden',
  xl: 'xl:lm-hidden',
};

const classNamesByResponsivePrefixThenDisplay: Record<
  string,
  Record<string, string>
> = {
  '': {
    'inline-block': '',
    block: '',
    flex: '',
    grid: '',
    inline: '',
  },
  xsm: {
    'inline-block': 'xsm:lm-inline-block',
    block: 'xsm:lm-block',
    flex: 'xsm:lm-flex',
    grid: 'xsm:lm-grid',
    inline: 'xsm:lm-inline',
  },
  sm: {
    'inline-block': 'sm:lm-inline-block',
    block: 'sm:lm-block',
    flex: 'sm:lm-flex',
    grid: 'sm:lm-grid',
    inline: 'sm:lm-inline',
  },
  md: {
    'inline-block': 'md:lm-inline-block',
    block: 'md:lm-block',
    flex: 'md:lm-flex',
    grid: 'md:lm-grid',
    inline: 'md:lm-inline',
  },
  lg: {
    'inline-block': 'lg:lm-inline-block',
    block: 'lg:lm-block',
    flex: 'lg:lm-flex',
    grid: 'lg:lm-grid',
    inline: 'lg:lm-inline',
  },
  xl: {
    'inline-block': 'xl:lm-inline-block',
    block: 'xl:lm-block',
    flex: 'xl:lm-flex',
    grid: 'xl:lm-grid',
    inline: 'xl:lm-inline',
  },
};

export default function Breakpoint(props: Props) {
  const {
    className,
    screen,
    direction,
    children,
    display = 'block',
    htmlTag = 'div',
  } = props;

  const responsivePrefix = ScreenBreakpointMap[screen] || 'sm';
  const responsiveClass = getClassName(direction, [
    [
      'down',
      `${displayClassNames[display]} ${hiddenClassNamesByResponsivePrefix[responsivePrefix]}`,
    ],
    [
      'up',
      `lm-hidden ${classNamesByResponsivePrefixThenDisplay[responsivePrefix][display]}`,
    ],
  ]);

  const TagName: any = htmlTag;
  return (
    <TagName className={`${responsiveClass} ${className || ''}`}>
      {children}
    </TagName>
  );
}
