import React from 'react';
import Select, { CSSObjectWithLabel } from 'react-select';
import { LumiSelectType, selectStyles } from '.';
import inputStyles from '../../sass/components/_input.scss';
import styles from '../../sass/components/_select.scss';
import { mergeClasses } from '../../utils/utils';

export const multiSelectStyles = {
  ...selectStyles,
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: '18px',
    border: 'solid #818E9A 1px',
    background: '#F8F9FB',
    padding: '.2rem 0.5rem 0.2rem 0px',
  }),
};

const LumiSelect: React.FC<LumiSelectType> = props => {
  const {
    placeholder,
    onChange,
    options,
    clearable,
    error,
    label,
    value,
    input,
    meta,
    className,
    disabled,
    isMulti,
    small,
    maxMenuHeight,
    minMenuHeight,
    width,
    loading,
    ref,
    customStyle,
  } = props;

  let _onChange = onChange || null;
  let _value = value;
  let _error = error;
  const violated = meta && (meta.touched || meta.dirty);
  const active = (meta && meta.active) || (input && input.value) || true;
  // The field is marked as touched after a focus + unfocus has occurred and during submit
  // all fields are touched ;). However that means a field can be touched ;)
  // yet still have an error, that is because the input can be dirty, hence the dual check.
  if (violated) {
    _error = meta.error;
  }
  if (input) {
    _value = input.value;
    if (input.onChange) {
      _onChange = input.onChange;
    }
  }

  const labelClass = active ? inputStyles.activeLabel : inputStyles.label;

  const selectInputId = `${label}-select`;

  let infoLabel = (
    <label className={labelClass} htmlFor={selectInputId}>
      {label}
    </label>
  );
  if (_error) {
    infoLabel = (
      <label className={inputStyles.lumiFieldErrorLabel}>{_error}</label>
    );
  }

  let optionValue = (options || []).find(x => {
    return x.value === _value;
  });
  if (!isMulti) {
    // treat values as objects and check for deep equality, but only for selects not using option groups
    // See https://github.com/JedWatson/react-select/issues/3418
    if (!optionValue && !!_value && typeof _value === 'object') {
      const valueKeys = Object.keys(_value);
      if (valueKeys.length) {
        optionValue = (options || []).find(x => {
          return valueKeys.every(prop => {
            if (!x.value) return false;
            return x.value.hasOwnProperty(prop);
          });
        });
      }
    }
  }

  return (
    <div
      className={mergeClasses(styles.selectContainer, className)}
      style={{
        position: 'relative',
        width: width || '100%',
      }}
    >
      <Select
        inputId={selectInputId}
        isMulti={isMulti}
        placeholder={!label && placeholder}
        menuPlacement={'auto'}
        minMenuHeight={minMenuHeight}
        maxMenuHeight={maxMenuHeight}
        classNamePrefix='react-select'
        value={optionValue || _value}
        isClearable={clearable}
        onChange={_onChange}
        isDisabled={disabled}
        styles={selectStyles({ small, error: _error, disabled, customStyle })}
        options={options}
        isLoading={loading}
        ref={ref}
      />
      {infoLabel}
    </div>
  );
};

LumiSelect.defaultProps = {
  clearable: false,
  options: [],
  minMenuHeight: 100,
  maxMenuHeight: 250,
};

export default LumiSelect;
