import lumiEnvironment from '../../../lumi.environment';

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGGED_SUCCESSFULLY = 'LOGGED_SUCCESSFULLY';
export const LOGGED_OUT_SUCCESSFULLY = 'LOGGED_OUT_SUCCESSFULLY';

export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SIGNED_UP_SUCCESSFULLY = 'SIGNED_UP_SUCCESSFULLY';

export const SAVED_NAME = 'SAVED_NAME';

export const RESET_PASSWORD_SUCCESSFULLY = 'RESET_PASSWORD_SUCCESSFULLY';
export const CLEAR_RESET_PASSWORD_SUCCESSFULLY =
  'CLEAR_RESET_PASSWORD_SUCCESSFULLY';
export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';

export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';
export const AUTHENTICATING = 'AUTHENTICATING';
export const AUTHENTICATING_COMPLETE = 'AUTHENTICATING_COMPLETE';
export const AUTHENTICATED = 'AUTHENTICATED';
export const TOKEN_RESET_SUCCESS = 'TOKEN_RESET_SUCCESS';
export const TOKEN_LOADING = 'TOKEN_LOADING';
export const TOKEN_LOADING_COMPLETE = 'TOKEN_LOADING_COMPLETE';
export const TOKEN_AUTHENTICATE_SUCCESS = 'TOKEN_AUTHENTICATE_SUCCESS';
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';

export const COMPANY_DATA_ARRIVED = 'COMPANY_DATA_ARRIVED';

// Companies
export const BUSINESS_CREATE_ERROR = 'BUSINESS_CREATE_ERROR';
export const BUSINESS_CREATE_SUCCESS = 'BUSINESS_CREATE_SUCCESS';
export const GET_COMPANIES_BY_ACCOUNT_SUCCESS =
  'GET_COMPANIES_BY_ACCOUNT_SUCCESS';
export const GET_COMPANIES_BY_ACCOUNT_ERROR = 'GET_COMPANIES_BY_ACCOUNT_ERROR';
export const COMPANY_CREATED = 'COMPANY_CREATED';

export const STAKE_HOLDERS_FETCHED = 'STAKE_HOLDERS_FETCHED';

export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_LAST_SUBMITTED_APPLICATION_SUCCESS =
  'GET_LAST_SUBMITTED_APPLICATION_SUCCESS';

export const GET_LOAN_SUCCESS = 'GET_LOAN_SUCCESS';

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

export const TOKENS_ARRIVED = 'TOKENS_ARRIVED';
export const PHONE_UPDATED = 'PHONE_UPDATED';

export const BANK_REPORT_RETRIEVED = 'BANK_REPORT_RETRIEVED';
export const BANK_REPORT_SAVED = 'BANK_REPORT_SAVED';
export const ACCOUNTING_REPORT_RETRIEVED = 'ACCOUNTING_REPORT_RETRIEVED';
export const GOT_USER = 'GOT_USER';

export const GET_ACCOUNT_STARTED = 'GET_ACCOUNT_STARTED';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const SAVE_ACCOUNT_STARTED = 'SAVE_ACCOUNT_STARTED';
export const SAVE_ACCOUNT_SUCCESS = 'SAVE_ACCOUNT_SUCCESS';
export const SAVE_ACCOUNT_ERROR = 'SAVE_ACCOUNT_ERROR';

const initialState = {
  authenticated: false,
  isLoggedIn: !!localStorage.getItem(lumiEnvironment.LOCAL_STORAGE_NAME),
  companyData: null,
  isLoading: true,
  passwordResetSuccessfully: false,
  error: null,
  email: null,
  account_id: null,
  first_name: null,
  last_name: null,
  middle_name: null,
  mobile_phone: null,
  email_verified: false,
  companies: null,
  application: null,
};

export default function user(state = initialState, action) {
  // console.log('action=' + JSON.stringify(action, null, 4))
  switch (action.type) {
    case LOGIN_FAILED:
      return Object.assign({}, state, {
        error: action.error,
        isLoggedIn: false,
        first_name: null,
        last_name: null,
        middle_name: null,
        email: null,
      });
    case LOGGED_SUCCESSFULLY:
      return Object.assign({}, state, {
        error: null,
        isLoggedIn: true,
        first_name: action.response.result.first_name,
        last_name: action.response.result.last_name,
        middle_name: action.response.result.middle_name,
        email: action.response.result.email,
        account_id: action.response.result.id,
        mobile_phone: action.response.result.phone,
        email_verified: action.response.result.email_verified,
        mobile_verified: action.response.result.mobile_verified,
        submitted_application: action.response.result.submitted_application,
      });
    case LOGGED_OUT_SUCCESSFULLY:
      return Object.assign({}, state, {
        error: null,
        isLoggedIn: false,
        email: null,
        first_name: null,
        middle_name: null,
        last_name: null,
      });
    case SIGNUP_FAILED:
      return Object.assign({}, state);
    case SIGNED_UP_SUCCESSFULLY:
      return Object.assign({}, state, {
        error: null,
        isLoggedIn: true,
        first_name: action.response.first_name,
        last_name: action.response.last_name,
        middle_name: action.response.middle_name,
        email: action.response.email,
        account_id: action.response.id,
        mobile_phone: action.response.phone,
        email_verified: action.response.email_verified,
        mobile_verified: action.response.mobile_verified,
      });
    case SAVED_NAME:
      return Object.assign({}, state, {
        first_name: action.response.result.first_name,
        middle_name: action.response.result.middle_name,
        last_name: action.response.result.last_name,
      });
    case RESET_PASSWORD_SUCCESSFULLY:
      return Object.assign({}, state, {
        passwordResetSuccessfully: true,
      });
    case CLEAR_RESET_PASSWORD_SUCCESSFULLY:
      return Object.assign({}, state, {
        passwordResetSuccessfully: false,
      });
    case TOKEN_AUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        tokenAuthenticated: true,
        isLoggedIn: true,
      });
    case TOKEN_RESET_SUCCESS:
      return Object.assign({}, state, {
        tokenReset: true,
      });
    case TOKEN_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case TOKEN_LOADING_COMPLETE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case GET_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        email: action.response.result.email,
        phone: action.response.result.phone,
      });
    case AUTHENTICATING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case AUTHENTICATING_COMPLETE:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case AUTHENTICATED:
      return Object.assign({}, state, {
        isLoading: false,
        authenticated: true,
      });
    case AUTHENTICATE_FAILED:
      return Object.assign({}, state, {
        error: action.response,
      });
    case TOKEN_REFRESHED:
      return Object.assign({}, state, {
        isLoggedIn: true,
        first_name: action.response.result.first_name,
        last_name: action.response.result.last_name,
        middle_name: action.response.result.middle_name,
        email: action.response.result.email,
        account_id: action.response.result.id,
        mobile_phone: action.response.result.phone,
        email_verified: action.response.result.email_verified,
        mobile_verified: action.response.result.mobile_verified,
        submitted_application: action.response.result.submitted_application,
      });
    case COMPANY_DATA_ARRIVED:
      return Object.assign({}, state, {
        companyData: action.response.result,
      });
    case GET_COMPANIES_BY_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        companies: action.response.result,
      });
    case GET_COMPANIES_BY_ACCOUNT_ERROR:
      return Object.assign({}, state, {});
    case COMPANY_CREATED:
      return Object.assign({}, state, {
        company: action.company,
      });
    case STAKE_HOLDERS_FETCHED:
      return Object.assign({}, state, {
        stake_holders: action.response.stake_holders,
      });
    case GET_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        application: {
          ...(state.application || {}),
          ...action.response.application,
        },
      });
    case GET_LAST_SUBMITTED_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        last_submitted_application: action.response.application,
      });
    case GET_LOAN_SUCCESS:
      return Object.assign({}, state, {
        loan: action.response.result,
      });
    case VERIFY_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        email_verification_status: 'success',
      });
    case VERIFY_EMAIL_FAILURE:
      return Object.assign({}, state, {
        email_verification_status: 'failure',
      });
    case TOKENS_ARRIVED:
      return Object.assign({}, state, {
        tokens: action.response,
      });
    case PHONE_UPDATED:
      return Object.assign({}, state, {
        mobile_phone: action.response.result.phone,
      });
    case BANK_REPORT_RETRIEVED:
      return Object.assign({}, state, {
        bank_report: action.response.result,
      });
    case BANK_REPORT_SAVED:
      return Object.assign({}, state, {
        bank_report: action.response.result,
        manual_bank_details: true,
      });
    case ACCOUNTING_REPORT_RETRIEVED:
      return Object.assign({}, state, {
        accounting_report: action.response.result,
      });
    case GOT_USER:
      return Object.assign({}, state, {
        user: action.response,
      });
    case GET_ACCOUNT_SUCCESS:
    case SAVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.response.result,
      };
    case GET_ACCOUNT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
