import * as React from 'react';

import * as styles from '../sass/components/_checkbox.scss';
import * as colors from '../sass/_variables.scss';
import LumiTick from '../images/tick.svg';
import { mergeClasses } from '../../common/utils/utils';

type CheckboxProps = {
  input: any;
  meta?: any;
  copy: string;
  id: string;
  disabled?: boolean;
  tabIndex?: number;
};

const LumiCheckbox = (props: CheckboxProps) => {
  const { input, meta, copy, id, disabled, tabIndex } = props;
  const value = input.value || false;
  const showError = meta && (meta.touched || meta.dirty) && meta.error;

  let checkboxClassName = styles.lumiCheckbox;
  if (disabled)
    checkboxClassName = mergeClasses(checkboxClassName, styles.disabled);

  return (
    <div className={styles.lumiCheckboxContainer}>
      <div className={checkboxClassName}>
        <input
          id={id}
          type='checkbox'
          {...input}
          tabIndex={-1}
          disabled={disabled}
        />
        <label htmlFor={id} tabIndex={tabIndex}>
          <div className={styles.tick}>{value && <LumiTick />}</div>
        </label>
      </div>
      <div className={styles.copy}>
        {showError && (
          <span className={styles.checkboxError}>{meta.error}</span>
        )}
        <span>{copy}</span>
      </div>
    </div>
  );
};

export default LumiCheckbox;
