/**
 *  Name: LoanInfo
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureAuthenticated } from '../../../utils/auth';
import React from 'react';

export default (store: any) => ({
  path: 'loan_info',
  progress: { step: 1 },
  enableFooter: true,
  enableHeader: true,
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const LoanInfo = React.lazy(() => import('./containers/LoanInfoContainer'));
    cb(null, LoanInfo);
  },
});
