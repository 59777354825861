import { Action } from 'redux';

export const GET_DOCUMENT_CAPTURE_PROGRESS_STARTED =
  'GET_DOCUMENT_CAPTURE_PROGRESS_STARTED';
export const GET_DOCUMENT_CAPTURE_PROGRESS_SUCCESS =
  'GET_DOCUMENT_CAPTURE_PROGRESS_SUCCESS';
export const GET_DOCUMENT_CAPTURE_PROGRESS_ERROR =
  'GET_DOCUMENT_CAPTURE_PROGRESS_ERROR';

export interface IDocumentCaptureStageDetails {
  completed_at: Date;
}

export interface IProgressStages {
  'drivers-license-number'?: IDocumentCaptureStageDetails;
  abn?: IDocumentCaptureStageDetails;
  'veda-consent'?: IDocumentCaptureStageDetails;
  'financial-statements'?: IDocumentCaptureStageDetails;
  'bank-feeds'?: IDocumentCaptureStageDetails;
  'drivers-license'?: IDocumentCaptureStageDetails;
}

export interface IDocumentCaptureProgress {
  id: string;
  document_capture_id: string;
  full_name: string;
  send_sms: boolean;
  send_email: boolean;
  link_opened_at: Date;
  stages: IProgressStages;
  account_id: string;
  completed: boolean;
  other_documents: string[];
}

interface Payload {
  response?: any;
  error?: string;
}

export default function documentCapture(state = {}, action: Action & Payload) {
  switch (action.type) {
    case GET_DOCUMENT_CAPTURE_PROGRESS_STARTED:
      return {
        loading: true,
      };
    case GET_DOCUMENT_CAPTURE_PROGRESS_SUCCESS:
      return {
        progress: action.response,
        loading: false,
      };
    case GET_DOCUMENT_CAPTURE_PROGRESS_ERROR:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
