import React, { useEffect } from 'react';
import Field from '../../../../../junction/components/field/InternalField';
import Select from '../../../../../junction/components/select/Select';
import Card from '../../../../../junction/components/card/Card';
import { Grid } from '../../../../../common/components/Grid';
import { SelectOption } from '../../../../../common/components/LumiPicker';
import PickerField from '../../../../../junction/components/picker_field/PickerField';
import { timeOptions } from '../modules/actions';
import ActionButton from '../../../../../junction/components/action_button/ActionButton';
import numeral from 'numeral';
import Stack from '../../../../../junction/layout/stack/Stack';
import { ReactDebtContext } from './Debts';
import { currencyMaskOptions } from '../../../../../junction/components/field/masks/currency';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

type PropTypes = {
  index: number;
};
const currencyMask = createNumberMask({
  ...currencyMaskOptions,
  allowDecimal: false,
});

const DebtorItem = (props: PropTypes) => {
  const { index } = props;

  const {
    debts,
    debtTypes,
    errors,
    removeDebtItem,
    numDisplayedItems,
    updateDebtItem,
    control,
    register,
    getValues,
    setValue,
  } = React.useContext(ReactDebtContext);

  const currencyProps = {
    control,
    mask: currencyMask,
    placeholder: '$0,000',
  };
  const intProps = {
    control,
    placeholder: '0',
  };

  if (debts[index].isRemoved) return null;
  // Key each item for the form values
  const getErrorMessage = (index: number, inputName: string): string => {
    const listItem = ((errors || {}).debts || [])[index];
    if (!listItem) return null;
    return (listItem[inputName] as any)?.message;
  };

  return (
    <Card className='lm-pt-3 lm-pb-5'>
      <Stack orientation='vertical' spacing='m'>
        <div>
          <Select
            key={index}
            label='Debt Type*'
            name={`debts[${index}].type`}
            error={getErrorMessage(index, 'type')}
            options={debtTypes}
            control={control}
          />
        </div>
        <div>
          <span className='lm-text-sm'>Owed To*</span>
          <Field
            type='text'
            name={`debts[${index}].name`}
            error={getErrorMessage(index, 'name')}
            control={control}
          />
        </div>
        <Grid rowGap='1rem' columnGap='1rem' columnTemplate='1fr 1fr'>
          <div>
            <span className='lm-text-sm'>Balance Owed*</span>
            <Field
              name={`debts[${index}].balance`}
              type='text'
              format={(event: any) =>
                numeral((event.target as any).value).value()
              }
              error={getErrorMessage(index, 'balance')}
              {...currencyProps}
            />
          </div>
          <div>
            <span className='lm-text-sm'>Months Left</span>
            <Field
              name={`debts[${index}].months_remaining`}
              type='number'
              error={getErrorMessage(index, 'months_remaining')}
              {...intProps}
              format={(event: any) =>
                numeral((event.target as any).value).value()
              }
            />
          </div>
        </Grid>
        <div>
          <span className='lm-text-sm'>Payments</span>
          <PickerField
            key={index}
            attachedToField
            pickName={`debts[${index}].payment_frequency`}
            fieldName={`debts[${index}].payment_amount`}
            options={timeOptions}
            selectedOption={debts[index].payment_frequency || 'WEEKLY'}
            error={getErrorMessage(index, 'payment_amount')}
            format={(event: any) =>
              numeral((event.target as any).value).value()
            }
            {...currencyProps}
          />
        </div>
      </Stack>
      <div className='lm-mt-1 lm-mb-2 lm-flex lm-justify-center'>
        {
          // There must be at least one debt item in the form
          numDisplayedItems > 1 && (
            <ActionButton
              isSecondary
              type='button'
              onClick={() => removeDebtItem(index)}
            >
              - Remove
            </ActionButton>
          )
        }
      </div>
    </Card>
  );
};

export default DebtorItem;
