import * as dotObject from 'dot-object';
import { isArray } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import { SelectOption, SelectProps } from './Select';
import Tooltip, { TooltipContentType } from '../tooltip/Tooltip';

type SelectRendererProps = {
  customComponents?: any;
  themedStyles: any;
} & SelectProps;

const ReactSelectComponent = React.forwardRef<any, SelectRendererProps>(
  (props, ref) => {
    const {
      options,
      onChange,
      name,
      control,
      value,
      rules,
      disabled,
      isMulti,
      format,
      themedStyles,
      label,
      isClearable,
      isSearchable,
      customComponents,
      defaultValue,
      placeholder,
      isAsync,
      loadOptions,
      isLoading,
      loadingMessage,
      cacheOptions,
      defaultOptions,
      noOptionsMessage,
      hideSelectedOptions,
      onInputChange,
    } = props;

    let _value = value;
    if (value && !(value as SelectOption).label && !isMulti) {
      _value = options.find(option => option.value === value);
    }

    // this is necessary to allow providing default values to the select component
    // and not having the need to format those values every single time.
    useEffect(() => {
      // for non form usages, control will not exist and this will error
      if (!control || isAsync) return;
      const formValues = control.getValues();
      const value = dotObject.pick(name, formValues);

      let option: SelectOption | SelectOption[] = options.find(
        option => option.value === value,
      );
      // multi select will receive a list of values
      if (isArray(value) && options.length) {
        option = value.map((v: any) =>
          options.find(
            option => option.value === v?.value || option.value === v,
          ),
        );
      }

      // Don't loop infinitely
      if (
        Array.isArray(option) &&
        Array.isArray(value) &&
        option.every((o, i) => o?.value === value[i]?.value)
      ) {
        return;
      } else if (!Array.isArray(option) && option?.value === value?.value) {
        return;
      }

      if (option) control.setValue(name, option);
    }, [options, control, name, isAsync]);

    useEffect(() => {
      // for non form usages, control will not exist and this will error
      if (!control) return;
      if (value) control.setValue(name, value);
    }, [value, control, name]);

    const theId = `${('' + name).replace(' ', '-')}-select`;

    const commonReactSelectProps: ReactSelectProps = {
      components: customComponents,
      isMulti,
      options,
      styles: themedStyles,
      isDisabled: disabled,
      isClearable,
      isSearchable,
      'aria-label': label,
      id: theId,
      menuPortalTarget: document.body,
      menuPosition: 'fixed',
      onInputChange,
      theme: theme => ({
        ...theme,
        colors: { ...theme.colors, primary: '#FD55D8' },
      }),
      isLoading,
      noOptionsMessage:
        typeof noOptionsMessage === 'string'
          ? () => <>{noOptionsMessage}</>
          : noOptionsMessage,
    };

    const nonControlCommonProps: ReactSelectProps = {
      ...commonReactSelectProps,
      placeholder: placeholder ?? 'Select an option',
      onChange,
      name,
      value: _value as SelectOption,
      defaultValue,
    };

    const commonAsyncReactSelectProps = {
      loadingMessage,
      cacheOptions,
      defaultOptions,
      loadOptions,
    };

    const commonControllerProps = {
      isMulti,
      name,
      control,
      options,
      styles: themedStyles,
      rules,
      isDisabled: disabled,
    };

    if (control) {
      if (isAsync) {
        return (
          <Controller
            {...commonControllerProps}
            render={controllerProps => (
              <ReactSelectAsync
                {...controllerProps}
                {...commonReactSelectProps}
                {...commonAsyncReactSelectProps}
                placeholder={placeholder}
                hideSelectedOptions={hideSelectedOptions}
                isOptionDisabled={option => option.isDisabled}
                ref={ref}
                onChange={(selected: SelectOption) => {
                  controllerProps.onChange(selected);
                  if (onChange) onChange(selected);
                  if (format) return format(selected);
                  return selected?.value;
                }}
              />
            )}
          />
        );
      }

      return (
        <Controller
          {...commonControllerProps}
          render={controllerProps => (
            <ReactSelect
              {...controllerProps}
              {...commonReactSelectProps}
              placeholder={placeholder}
              hideSelectedOptions={hideSelectedOptions}
              isOptionDisabled={option => option.isDisabled}
              ref={ref}
              onChange={(selected: SelectOption) => {
                controllerProps.onChange(selected);
                if (onChange) onChange(selected);
                if (format) return format(selected);
                return selected?.value;
              }}
            />
          )}
        />
      );
    }

    if (isAsync) {
      return (
        <ReactSelectAsync
          {...nonControlCommonProps}
          {...commonAsyncReactSelectProps}
          ref={ref}
        />
      );
    }

    return <ReactSelect {...nonControlCommonProps} ref={ref} />;
  },
);

export default ReactSelectComponent;
