import React, { FC } from 'react';
import Stack from '../../layout/stack/Stack';
import FilledCross from '../../components/feedback/assets/filled-cross.svg';
import Text from '../text/Text';

type Props = {
  children: React.ReactNode;
};

const ValidationError: FC<Props> = ({ children }) => {
  return (
    <Stack orientation='horizontal' spacing='s'>
      {/* This div is needed so the svg doesn't shrink too much at breakpoints */}
      <div>
        <FilledCross
          width='14px'
          height='14px'
          className='lm-text-base-red lm-mt-0.5'
        />
      </div>
      <Text
        element='span'
        className='lm-text-base-blue lm-leading-4'
        weight='demi'
        size='small'
      >
        {children}
      </Text>
    </Stack>
  );
};

export default ValidationError;
