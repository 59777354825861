import React, { FC, useEffect, useState } from 'react';
import lumiEnvironment from '../../lumi.environment';
import { getLinkGqlQuery } from '../modules/actions';
import styles from '../sass/components/_bankfeeds.scss';
import spinner from '../sass/components/_spinner.scss';
import { isQueryLoading, usePublicGraphQuery } from '../utils/graphql_utils';

/* The front-end isn't aware of the token expiry set in the server API,
 * as this is not received in the response. Therefore the timeout is defined separately in the client.
 */
const timeoutInterval = lumiEnvironment.LINK_TIMEOUT_MS || 60000;

type Props = {
  referenceId: string;
  redirectUrl: string;
  requestExportSelection?: boolean;
  setReferenceId?: (referenceId: string) => void;
};

const ConnectBankAccount: FC<Props> = props => {
  const { referenceId, redirectUrl, requestExportSelection, setReferenceId } =
    props;
  const [timeoutId, setTimeoutId] = useState(null);
  const { data, status: getLinksStatus } = usePublicGraphQuery(
    [
      'newLink',
      {
        referenceId,
        redirectUrl,
        requestExportSelection,
        type: 'BANK_FEEDS',
      },
    ],
    getLinkGqlQuery,
    { enabled: true },
    { noAuth: true },
  );
  const loadingLinks = isQueryLoading(getLinksStatus);

  useEffect(() => {
    if (
      setReferenceId &&
      data?.data?.newLink?.reference &&
      data?.data?.newLink?.reference !== referenceId
    ) {
      setReferenceId(data.data.newLink.reference);
    }
  }, [setReferenceId, data?.data?.newLink?.reference, referenceId]);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (!loadingLinks && !timeoutId) {
      let timeoutId = setTimeout(() => {
        alertAndReload();
      }, timeoutInterval);
      setTimeoutId(timeoutId);
    }
  }, [loadingLinks, timeoutId]);

  const alertAndReload = () => {
    alert('This session has expired, press Ok/Close to refresh');
    window.location.reload();
  };

  const onError = () => {
    alert(
      'Something went wrong, press Ok/Close to refresh the page and try again',
    );
    window.location.reload();
  };

  let body = (
    <div className={[spinner.spinner, spinner.pageSpinner].join(' ')} />
  );
  if (!loadingLinks) {
    body = (
      <iframe
        src={data?.data?.newLink?.url}
        onError={onError}
        style={{
          width: '100%',
          border: 'none',
          display: loadingLinks ? 'none' : 'block',
          height: '100%',
        }}
      />
    );
  }
  return <div className={styles.bankfeedsContainer}>{body}</div>;
};

export default ConnectBankAccount;
