import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import React from 'react';

export default (store: any) => ({
  path: 'sofp-income',
  onEnter: documentCaptureRetrieve(store),
  getComponent(nextState: any, cb: any) {
    const Income = React.lazy(() => import('./containers/IncomeContainer'));
    cb(null, Income);
  },
});
