import { HTMLMotionProps, motion } from 'framer-motion';
import React, { FunctionComponent } from 'react';
import { mergeClasses } from '../../../common/utils/utils';

const FieldLabel: FunctionComponent<
  HTMLMotionProps<'label'> & {
    fontSizeClassName?: string;
    fontFamily?: 'medium' | 'normal' | 'demi';
    fontColorClassName?: string;
  }
> = ({
  className,
  fontSizeClassName,
  fontFamily,
  fontColorClassName,
  ...restProps
}) => {
  const fontSize = fontSizeClassName || 'lm-text-sm';
  const fontColor = fontColorClassName || 'lm-text-copy';
  const getFontFamilyStyle = () => {
    switch (fontFamily) {
      case 'medium':
        return 'lm-font-proMedium';
      case 'demi':
        return 'lm-font-demi';
      case 'normal':
        return 'lm-font-normal';
      default:
        return 'lm-font-proMedium';
    }
  };

  return (
    <motion.label
      className={mergeClasses(
        'lm-my-auto',
        className,
        fontSize,
        getFontFamilyStyle(),
        fontColor,
      )}
      {...restProps}
    />
  );
};

export default FieldLabel;
