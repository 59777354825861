import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form-legacy';
import FileUploadLine from '../../common/components/FileUploadLine';
import buttonStyle from '../../common/sass/components/_button.scss';
import {
  getStatements,
  getStatementsByDealId,
  getDocumentCaptureStatements,
} from '../CommonCreators';
import spinner from '../../common/sass/components/_spinner.scss';
import Button from '../components/button';
import LumiButton from '../../common/components/LumiButton';

const fields = ['profit_loss', 'balance_sheet'];
function validate(values) {
  const errors = {};

  if (!values['balance_sheet'] || !values['balance_sheet'].length) {
    errors['balance_sheet'] = 'Required';
  }
  if (!values['profit_loss'] || !values['profit_loss'].length) {
    errors['profit_loss'] = 'Required';
  }
  return errors;
}

class UploadStatements extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  componentDidMount() {
    const { isExisting, application, dealId, documentCaptureProgressId } =
      this.props;
    this.setState({ loading: true });
    let financialStatementCall = () => getStatements(application);
    if (dealId)
      financialStatementCall = () =>
        getDocumentCaptureStatements(dealId, documentCaptureProgressId);
    financialStatementCall()
      .then(files => {
        const {
          fields: { profit_loss, balance_sheet },
        } = this.props;
        if (files.profit_loss) {
          profit_loss.onChange([files.profit_loss]);
          if (isExisting) isExisting();
        }
        if (files.balance_sheet) {
          balance_sheet.onChange([files.balance_sheet]);
          if (isExisting) isExisting();
        }
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }
  render() {
    const {
      fields: { profit_loss, balance_sheet },
      isUploading,
      profitProgress,
      balanceProgress,
      handleSubmit,
      submitting,
      onUpload,
      onBack,
      onFileSelected,
    } = this.props;
    const { loading } = this.state;
    if (loading) {
      return (
        <div className={[spinner.spinner, spinner.pageSpinner].join(' ')} />
      );
    }
    const bothButtonBlock = (
      <div className={buttonStyle.half}>
        <a onClick={onBack}>
          <span className='mr-2'>&lt;</span> Previous
        </a>
        <Button disabled={submitting} loading={submitting} label='Next Step' />
      </div>
    );

    const nextButtonOnlyBlock = (
      <LumiButton
        noAnimate
        rounded
        phat
        disabled={submitting}
        className={buttonStyle.middle}
        type='submit'
        label='Next Step'
      />
    );
    const buttons = onBack ? bothButtonBlock : nextButtonOnlyBlock;

    return (
      <form
        className={buttonStyle.statementsButtons}
        onSubmit={handleSubmit(onUpload)}
      >
        <div className='mb-8 mt-8'>
          <FileUploadLine
            fieldData={profit_loss}
            onChange={onFileSelected}
            isUploading={isUploading}
            progress={profitProgress}
            label='Profit / loss'
          />
          <hr />
          <FileUploadLine
            fieldData={balance_sheet}
            onChange={onFileSelected}
            isUploading={isUploading}
            progress={balanceProgress}
            label='Balance sheet'
          />
          <hr />
        </div>
        {buttons}
      </form>
    );
  }
}

UploadStatements.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  application: PropTypes.object.isRequired,
  isUploading: PropTypes.bool,
  profitProgress: PropTypes.number,
  balanceProgress: PropTypes.number,
  isExisting: PropTypes.func,
  dealId: PropTypes.string,
  documentCaptureProgressId: PropTypes.string,
};

export default reduxForm({
  form: 'upload_statements',
  fields,
  validate,
})(UploadStatements);
