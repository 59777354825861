import Question from 'junction/assets/question.svg';
import Text from 'junction/components/text/Text';
import Tooltip, {
  TooltipContentType,
} from 'junction/components/tooltip/Tooltip';
import * as React from 'react';

const IdVerificationInfoTooltip: React.FC<{}> = () => {
  return (
    <Tooltip placement='bottom' className='lm-leading-none'>
      <Tooltip.Trigger
        useLegacyAlignment
        ariaLabel='More information'
        className='lm-cursor-pointer lm-outline-none lm-focus-yellow lm-h-full'
      >
        <Question className='lm-text-base-pink' />
      </Tooltip.Trigger>
      <Tooltip.Content
        className='lm-max-w-88 lm-mt-2 lm-mr-3'
        type={TooltipContentType.INFO}
        padding='lm-p-4'
      >
        <Text size='small' className='lm-text-base-pink'>
          We need to confirm your personal details as part of our 'Know Your
          Customer' (KYC) requirements under the Anti-Money Laundering and
          Counter-Terrorism Financing Act 2006.
          <br />
          <br />
          Keeping your information safe and protecting you from fraud is our
          priority, which is why we want to make sure that your personal
          information is accurate. To do so, Lumi uses a third-party provider
          (FrankieOne) to securely verify your identity.
        </Text>
      </Tooltip.Content>
    </Tooltip>
  );
};

export default IdVerificationInfoTooltip;
