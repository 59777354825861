import React from 'react';

export default (store: any) => ({
  path: 'bank_feeds_thanks',
  getComponent(nextState: any, cb: any) {
    const BankFeedsThanks = React.lazy(
      () => import('./containers/BankFeedsThanks'),
    );
    cb(null, BankFeedsThanks);
  },
});
