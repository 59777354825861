import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import React from 'react';

export default (store: any) => ({
  path: 'sofp-assets',
  onEnter: documentCaptureRetrieve(store),
  getComponent(nextState: any, cb: any) {
    const Assets = React.lazy(() => import('./containers/AssetsContainer'));
    cb(null, Assets);
  },
});
