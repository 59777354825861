// import fetch from 'isomorphic-fetch'
import {
  SIGNUP_FAILED,
  SIGNED_UP_SUCCESSFULLY,
} from '../../../../redux/modules/user';
import {
  apiFetch,
  serverURL,
  apiGet,
  apiPost,
} from '../../../../../common/utils/fetch_utils';
import { push } from 'react-router-redux';
import * as analyticsUtils from '../../../../utils/analytics';
import { msgError, getNextOnboardingStep } from '../../../../CommonCreators';
import { splitName } from '../../../../../common/utils/utils';
import { getOnboardingRoute } from '../../../../../common/utils/auth_utils';
import gql from 'graphql-tag';
import { getNextApplicationRoute, getExperimentGroup } from '../..';

export function signupError(error) {
  return { error, type: SIGNUP_FAILED };
}

export function signupSuccess(response) {
  return dispatch => {
    dispatch({ response, type: SIGNED_UP_SUCCESSFULLY });
  };
}

function _analyticsAlias(data) {
  let fields = [
    'email',
    'email_verified',
    'first_name',
    'last_name',
    ',middle_name',
    'phone',
    'mobile_verified',
  ];
  analyticsUtils.alias(data.result.id);
  analyticsUtils.identify(data.result.id, fields, data.result);
  analyticsUtils.track('leads', {
    action: 'lead_created',
    platform: 'website',
    funnel_step: analyticsUtils.funnel_step(
      'application',
      analyticsUtils.funnel_step_version(),
    ),
    version: analyticsUtils.funnel_step_version(),
  });
}

function trackAdwords(data) {
  analyticsUtils.track('adwords', {
    action: 'contact_created',
    platform: 'website',
  });
}

export function signup(userData, resolve, reject, callback) {
  const data = localStorage.getItem('signupData');
  return dispatch => {
    const { first_name, last_name } = splitName(userData.full_name);
    apiFetch(
      `${serverURL}/api/accounts`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: first_name,
          last_name: last_name,
          phone: userData.mobile_phone.replace(/-/g, ''),
          email: userData.email,
          password: userData.password,
          email_disabled: false,
          credit_consented: true,
        }),
      },
      data => {
        if (!data.success) {
          throw new Error(data.error);
        } else {
          _analyticsAlias(data);
          if (analyticsUtils.getEnv() === 'prod') {
            trackAdwords(data);
          }
        }
        dispatch(signupSuccess(userData));
        if (callback) callback(data.result);

        resolve();
      },
    ).catch(error => {
      console.log('request failed', error);
      dispatch(msgError('Error', error.message));
      dispatch(signupError(error));
      reject(error);
    });
  };
}

export async function getApplication(key, { accountId }) {
  const response = await apiGet(`${serverURL}/api/application`);
  if (!response || !response.success) throw new Error(response.error);
  return response;
}

export async function updateApplication({ id, application }) {
  const response = await apiPost(
    `${serverURL}/api/application/${id}`,
    application,
  );
  if (!response || !response.success) throw new Error(response.error);
  return response;
}
