import * as yup from 'yup';

const assetFormSchema = yup.object().shape({
  type: yup.mixed().required('Required'),
  estimated_value: yup.number().required('Required'),
  make: yup.string().when('type', {
    is: 'vehicle',
    then: yup.string().required('Required'),
  }),
  model: yup.string().when('type', {
    is: 'vehicle',
    then: yup.string().required('Required'),
  }),
  year: yup.number().when('type', {
    is: 'vehicle',
    then: yup.number().required('Required'),
  }),
  vehicle_type: yup.string().when('type', {
    is: 'vehicle',
    then: yup.string().required('Required'),
  }),
  address: yup.string().when('type', {
    is: (val: any) =>
      ['investment_property', 'residential_property'].includes(val),
    then: yup.string().required('Required'),
  }),
  property_status: yup.string().when('type', {
    is: (val: any) =>
      ['investment_property', 'residential_property'].includes(val),
    then: yup.string().required('Required'),
  }),
  quantity: yup.string().when('type', {
    is: 'other_asset',
    then: yup.string().required('Required'),
  }),
  details: yup.string().when('type', {
    is: 'other_asset',
    then: yup.string().required('Required'),
  }),
});

export default assetFormSchema;
