import { UPLOADING, UPLOAD_PROGRESS } from '../../../../redux/modules/ui';
import { serverURL, apiFetch } from '../../../../../common/utils/fetch_utils';
import { msgError } from '../../../../CommonCreators';

export function setUploading(isUploading) {
  return dispatch => {
    dispatch({ isUploading, type: UPLOADING });
  };
}

export function uploadProgress(progress) {
  return dispatch => {
    dispatch({ progress, type: UPLOAD_PROGRESS });
  };
}

export function sendPreApprovalEmail(accountId, resolve, reject) {
  return dispatch => {
    apiFetch(
      `${serverURL}/api/accounts/sendPreApprovalMail`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account_id: accountId,
        }),
      },
      data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        console.log('sent pre approval email');
        resolve(data);
      },
    ).catch(function (error) {
      console.log('request failed', error);
      dispatch(msgError('Error', error.message));
      reject(error);
    });
  };
}
