import React from 'react';
import { mergeClasses } from 'common/utils/utils';
import * as styles from './_loading.scss';

type Props = {
  color?: 'white' | 'purple' | string;
  size?: 'sm' | 'md';
  className?: string;
};

const defaultColor = '#6245B5';

export default function Loading(props: Props) {
  const { color, className, size = 'md' } = props;
  let _color = color ?? defaultColor;
  if (color === 'purple') _color = defaultColor;

  const sizeClass = {
    sm: 'lm-w-1 lm-h-1',
    md: 'lm-w-[9px] lm-h-[9px]',
  };

  return (
    <div role='progressbar' className={className}>
      <div
        style={{ background: _color }}
        className={mergeClasses(styles.loadingDot, sizeClass[size])}
      />
      <div
        style={{ background: _color }}
        className={mergeClasses(styles.loadingDot, sizeClass[size])}
      />
      <div
        style={{ background: _color }}
        className={mergeClasses(styles.loadingDot, sizeClass[size])}
      />
    </div>
  );
}
