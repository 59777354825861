import React from 'react';

export type Padding =
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | '0'
  | `lm-p-${number}`;

export type BorderRounded = 'lg' | 'card' | 'xl' | 'none' | '2xl';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  padding?: Padding;
  shadow?: boolean;
  borderRounded?: BorderRounded;
  backgroundColor?: 'white' | 'purple' | 'pink' | 'light-gray' | 'none';
  border?: string;
  shadowStyle?: 'lg' | 'button' | 'table' | '300';
};

const getPaddingClass = (padding?: Padding) => {
  if (!padding) {
    return 'lm-p-4';
  }

  switch (padding) {
    case '0':
      return 'lm-p-0';
    case 'xs':
      return 'lm-p-1';
    case 's':
      return 'lm-p-2';
    case 'm':
      return 'lm-p-4';
    case 'l':
      return 'lm-p-5';
    case 'xl':
      return 'lm-p-6';
    case 'xxl':
      return 'lm-p-7';
    default:
      return padding;
  }
};

const getBorderRounded = (size?: 'lg' | 'card' | 'xl' | 'none' | '2xl') => {
  switch (size) {
    case 'card':
      return 'lm-rounded-card';
    case 'lg':
      return 'lm-rounded-lg';
    case 'xl':
      return 'lm-rounded-xl';
    case '2xl':
      return 'lm-rounded-2xl';
    case 'none':
      return '';
    default:
      return 'lm-rounded-lg';
  }
};

const getBackgroundColor = (
  bg?: 'white' | 'purple' | 'pink' | 'light-gray' | 'none',
) => {
  switch (bg) {
    case 'white':
      return 'lm-bg-white';
    case 'purple':
      return 'lm-bg-purple-100';
    case 'pink':
      return 'lm-bg-pink-100';
    case 'light-gray':
      return 'lm-bg-light-gray';
    case 'none':
      return '';
    default:
      return 'lm-bg-white';
  }
};

const getShadowAndBorder = (
  shadow: boolean,
  shadowStyle?: 'lg' | '300' | 'table' | 'button',
  border?: string,
) => (shadow ? `lm-shadow-${shadowStyle}` : border);

export default function Card(props: Props) {
  const {
    children,
    className,
    padding,
    shadow,
    borderRounded = 'lg',
    backgroundColor = 'white',
    shadowStyle = 'lg',
    border = 'lm-border lm-border-gray-400',
  } = props;

  const borderClass = getShadowAndBorder(shadow, shadowStyle, border);

  return (
    <div
      className={`${getBackgroundColor(
        backgroundColor,
      )} ${borderClass} ${getBorderRounded(borderRounded)} ${getPaddingClass(
        padding,
      )} ${className}`}
    >
      {children}
    </div>
  );
}
