import React, { Component, useEffect } from 'react';
import { reduxForm } from 'redux-form-legacy';
import { Link } from 'react-router';
import { login } from '../modules/actions';
import { push } from 'react-router-redux';
import FormInput from '../../../../../common/components/TextField';
import {
  mandatoryFieldsValidation,
  getUser,
} from '../../../../../common/utils/utils';
import buttonStyle from '../../../../../common/sass/components/_button.scss';
import formStyle from '../../../../../common/sass/components/_form.scss';

import * as analyticsUtils from '../../../../utils/analytics';

import { getNextOnboardingStep } from '../../../../CommonCreators';
import { getOnboardingRoute } from '../../../../../common/utils/auth_utils';
import { useGraphQuery } from '../../../../../common/utils/graphql_utils';
import { getExperimentGroupQuery } from '../../../../../graphql/queries/queries';
export const fields = ['email', 'password'];

function validate(values: any) {
  return mandatoryFieldsValidation(values, fields);
}

type Props = {
  fields?: {
    email: any;
    password: any;
  };
  handleSubmit?: any;
  submitting?: any;
  nextPathname?: string;
};

function Login(props: Props) {
  const {
    fields: { email, password },
    handleSubmit,
    submitting,
  } = props;

  const { data: experimentGroup, refetch: getExperimentGroup } = useGraphQuery(
    'experimentGroup',
    getExperimentGroupQuery,
    { enabled: false },
  );

  const submit = (values: any, dispatch: any) => {
    let nextDefaultRoute = '/app/business_info';
    return new Promise((resolve, reject) => {
      dispatch(
        login(
          values,
          async (loginResponse: any) => {
            dispatch(
              getNextOnboardingStep(
                loginResponse.result.email,
                (result: any) => {
                  const user = getUser();
                  if (user.has_loc) {
                    // This logic is temporary. See LF-2890.
                    window.location.pathname = '/hub/login';
                  } else {
                    nextDefaultRoute = `/${getOnboardingRoute(result.step)}`;
                    const route = props.nextPathname || nextDefaultRoute;
                    window.location.pathname = route;
                  }
                },
                reject,
              ),
            );
          },
          (err: any) => {
            console.log('error:' + err);
            reject(err);
          },
        ),
      );
    });
  };

  const emailInputProps = {
    fieldData: { ...email, value: email.value || '' },
    label: 'Email',
    type: 'email',
  };

  const passwordInputProps = {
    fieldData: { ...password, value: password.value || '' },
    label: 'Password',
    type: 'password',
  };

  const loginButtonProps = {
    key: 'login',
    className: [buttonStyle.button, buttonStyle.cta].join(' '),
    disabled: submitting,
    type: 'submit',
  } as any;

  return (
    <form className={formStyle.container} onSubmit={handleSubmit(submit)}>
      <h1 className='mb-8'>Welcome!</h1>
      <FormInput {...emailInputProps} />
      <FormInput {...passwordInputProps} />
      <div className='mb-8'>
        <Link to={'/app/forgot_password'}>Forgot Password?</Link>
      </div>
      <button {...loginButtonProps}>Login</button>
      <hr />
      <p>
        Not Registered?&nbsp;
        <br />
        <a href='/apply'>Signup</a>
      </p>
    </form>
  );
}

export default reduxForm(
  {
    form: 'login',
    fields,
    validate,
  },
  (state: any) => {
    if (state.router) {
      if (state.router.locationBeforeTransitions) {
        if (state.router.locationBeforeTransitions.state) {
          return {
            nextPathname:
              state.router.locationBeforeTransitions.state.nextPathname,
          };
        }
      }
    }
  },
)(Login);
