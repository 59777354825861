import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { currencyMaskOptions } from '../../../../../junction/components/field/masks/currency';
import gql from 'graphql-tag';

export const currencyMask = createNumberMask({
  ...currencyMaskOptions,
  allowDecimal: false,
});

export const numberMask = createNumberMask({
  allowDecimal: false,
  includeThousandsSeparator: false,
  prefix: '',
});

export const getSOFPLiteral = gql`
  query getSOFP($document_capture_id: ID!) {
    getSOFP(document_capture_id: $document_capture_id) {
      id
      assets {
        type
        estimated_value
        vehicle_type
        make
        model
        year
        address
        quantity
        property_status
        details
      }
    }
  }
`;
