import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Subheader from 'material-ui/Subheader';
// import Slider from 'material-ui-slider-label/Slider';
import { cyan500 } from 'material-ui/styles/colors';

const styles = {
  subheader: {
    textTransform: 'capitalize',
  },
  labelStyleOuter: {
    width: '30px',
    height: '30px',
    borderRadius: '50% 50% 50% 0',
    background: cyan500,
    position: 'absolute',
    transform: 'rotate(-45deg)',
    top: '-40px',
    left: '-9px',
  },
  labelStyleInner: {
    transform: 'rotate(45deg)',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    top: '10px',
    right: '0px',
    fontSize: '8px',
  },
};

export default class SlimSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baloon_value: 'Yo',
    };

    this._formatBaloon = this._formatBaloon.bind(this);
  }
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    defaultValue: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
  };

  _formatBaloon(val) {
    return this.props.min > 1000 ? `${val / 1000}K` : val; // Super ugly hard coded shit here.
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ baloon_value: nextProps.value });
  }

  render() {
    const { label, min, max, value, onChange, step, defaultValue } = this.props;
    return (
      <div>
        <Subheader style={styles.subheader}>{label}</Subheader>
      </div>
    );
  }
}
