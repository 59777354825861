import React from 'react';
import Select, { CSSObjectWithLabel, OptionProps } from 'react-select';
import { LumiSelectType, selectStyles } from '.';
import inputStyles from '../../sass/components/_input.scss';
import styles from '../../sass/components/_select.scss';
import { mergeClasses } from '../../utils/utils';

export const CreditIndustrySelect: React.FC<LumiSelectType> = props => {
  const {
    placeholder,
    onChange,
    options,
    clearable,
    error,
    label,
    value,
    input,
    meta,
    className,
    disabled,
    isMulti,
    small,
    maxMenuHeight,
    minMenuHeight,
    width,
    loading,
    ref,
  } = props;

  let _onChange = onChange || null;
  let _value = value;
  let _error = error;
  const violated = meta && (meta.touched || meta.dirty);
  const active = (meta && meta.active) || (input && input.value) || true;
  // The field is marked as touched after a focus + unfocus has occurred and during submit
  // all fields are touched ;). However that means a field can be touched ;)
  // yet still have an error, that is because the input can be dirty, hence the dual check.
  if (violated) {
    _error = meta.error;
  }
  if (input) {
    _value = input.value;
    if (input.onChange) {
      _onChange = input.onChange;
    }
  }

  const labelClass = active ? inputStyles.activeLabelV2 : inputStyles.V2;

  const infoLabel = <label className={labelClass}>{label}</label>;
  let errorLabel = <span />;
  if (_error) {
    errorLabel = (
      <label className={inputStyles.lumiFieldErrorLabelV2}>{_error}</label>
    );
  }

  let optionValue = (options || []).find(x => {
    return x.value === _value;
  });
  if (!isMulti) {
    // treat values as objects and check for deep equality, but only for selects not using option groups
    // See https://github.com/JedWatson/react-select/issues/3418
    if (!optionValue && !!_value && typeof _value === 'object') {
      const valueKeys = Object.keys(_value);
      if (valueKeys.length) {
        optionValue = (options || []).find(x => {
          return valueKeys.every(prop => {
            if (!x.value) {
              return false;
            }
            return x.value.hasOwnProperty(prop);
          });
        });
      }
    }
  }

  return (
    <div
      className={mergeClasses(styles.selectContainer, className)}
      style={{
        position: 'relative',
        width: width || '100%',
      }}
    >
      {infoLabel}
      <Select
        isMulti={isMulti}
        placeholder={!label && placeholder}
        menuPlacement={'auto'}
        minMenuHeight={minMenuHeight}
        maxMenuHeight={maxMenuHeight}
        classNamePrefix='react-select'
        value={optionValue || _value}
        isClearable={clearable}
        onChange={_onChange}
        isDisabled={disabled}
        styles={Object.assign(
          selectStyles({ small, error: _error, disabled }),
          {
            indicatorSeparator: (base: CSSObjectWithLabel) => ({
              ...base,
              display: 'none',
            }),
            dropdownIndicator: (
              base: CSSObjectWithLabel,
              state: OptionProps,
            ) => ({
              ...base,
              color: state.isDisabled ? '#5D7287' : '#00234B',
            }),
          },
        )}
        isOptionDisabled={(option: any) => option.isDisabled}
        options={options}
        isLoading={loading}
        ref={ref}
      />
      {errorLabel}
    </div>
  );
};
