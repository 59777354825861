/**
 *  Name: DirectorInfo
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureAuthenticated } from '../../../../utils/auth';
import React from 'react';
import { getRouteProgress, getExperimentGroup } from '../..';

export default (store: any) => ({
  path: 'variant/director_info',
  progress: (group: string) =>
    getRouteProgress(
      '/app/variant/director_info',
      group || getExperimentGroup(),
    ),
  enableHeader: true,
  enableFooter: true,
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const View = React.lazy(() => import('./containers/DirectorInfoContainer'));
    cb(null, View);
  },
});
