import { combineReducers } from 'redux';
import user from './modules/user';
import ui from './modules/ui';
import alert from './modules/alert';
import banking from './modules/banking';
import reason from './modules/reason';
import documentCapture from './modules/documentCapture';
import { routerReducer as router } from 'react-router-redux';
import { normalizePhone, trimSpaces } from '../components/normalizers';
import { reducer as formReducerLegacy } from 'redux-form-legacy';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

let finalformReducerLegacy = formReducerLegacy.normalize({
  signup: {
    mobile_phone: normalizePhone,
  },
  business_info: {
    company_number: trimSpaces,
  },
});

const makeRootReducer = asyncReducers => {
  return combineReducers({
    user,
    ui,
    form: finalformReducerLegacy,
    form_new: formReducer,
    router,
    alert,
    reason,
    banking,
    notifications,
    documentCapture,
    // ...asyncReducers
  });
};

export default makeRootReducer;
