export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_SHOW = 'DIALOG_SHOW';

export default function alert(state = {}, action) {
  switch (action.type) {
    case DIALOG_CLOSE:
      return {
        open: false,
        message: null,
        title: null,
        type: null,
      };
    case DIALOG_SHOW:
      return {
        open: true,
        message: action.message.message,
        title: action.message.title,
        type: action.message.type || 'info',
      };
    default:
      return state;
  }
}
