const defaults = {
  APP_NAME: 'client',
  DIST: 'dist/client',
  LOCAL_STORAGE_NAME: 'lumi_access_token',
  LOCAL_STORAGE_USER_NAME: 'lumi_user',
  LOGIN_PATH: '/app/login',
  ERROR_LOGGING: true,
  LINK_TIMEOUT_MS: 60000,
};

const staging = {
  LINK_TIMEOUT_MS: 290000,
};

const production = {
  LINK_TIMEOUT_MS: 870000,
};

const client_config = {
  staging,
  production,
};

/**
 * @param {string} environment
 */
export default function (environment) {
  if (environment !== 'staging' && environment !== 'production') {
    return defaults;
  }

  return {
    ...defaults,
    ...(client_config[environment] || {}),
  };
}
