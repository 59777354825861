import * as React from 'react';
import * as styles from './Confirmation.scss';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import LumiButton from '../../../../../common/components/LumiButton';
import PencilEdit from '../../../../../common/images/admin-icons/pencil-alt.svg';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { DocumentCollectionProgress } from '../../DocumentCollectionUtils';
import { submitDocCapture } from '../../DocumentCaptureActions';
import * as analyticsUtils from '../../../../utils/analytics';
import { getDocumentCaptureOther } from '../../../../CommonCreators';
import { getIcon } from '../../../../../common/utils/attachment_icons';
import { isNil } from 'lodash';
import { title } from '../../../../../admin/utils';

type Props = {
  application: any;
  account: any;
  documentProgress: DocumentCollectionProgress;
  documentCapture: any;
  dispatch: any;
  params: Param;
};

type Param = {
  id: string;
};

type State = {
  docs: any;
};

enum routes {
  FINANCIALS = 'financials',
  THANKS = 'thanks',
  IDENTITY = 'identity',
  DRIVERS_LICENSE = 'license',
  ABOUT = 'about',
  LOAN_INFO = 'loan_info',
}

class Confirmation extends React.Component<Props, State> {
  state: State = {
    docs: [],
  };

  async componentDidMount() {
    const { documentProgress, documentCapture, dispatch } = this.props;
    const docs: any = await getDocumentCaptureOther(
      documentCapture.deal_id,
      documentProgress.id,
      dispatch,
    );
    const docNames = docs.map((doc: any) => doc.file_name);
    this.setState({
      docs: docNames,
    });
    analyticsUtils.trackPageView('document_capture_confirmation');
  }

  navigate(route: string, completed?: boolean) {
    const { dispatch, documentProgress, params } = this.props;
    if (completed) {
      dispatch(submitDocCapture(documentProgress.document_capture_id));
    }
    dispatch(push(`/app/documents/${params.id}/${route}`));
  }

  render() {
    const { application, documentProgress, account } = this.props;
    const { customer_notes } = application;
    const { docs } = this.state;
    let mappedDocs = [];
    if (docs && docs.length) {
      mappedDocs = docs.map((file: any, i: number) => {
        console.log('file', file);
        const fileIcon = getIcon(file);
        return (
          <div key={`filepreview-${i}`} className={styles.file}>
            {fileIcon}&nbsp;
            {file}
          </div>
        );
      });
    }
    const company = application.company || {};
    const company_name =
      company.company_name?.value || company.company_name || null;
    const company_number =
      company.company_number?.value || company.company_number || null;
    const account_details = account.account_details || {};
    const id_document_number = account_details.id_document_number || '';
    const { birth_date } = account_details;
    const { time_in_business_months, has_property, has_other_loans } =
      application?.additional_info || {};
    const { amount, reason_for_loan } = application?.loan || {};

    const generalDetails = (documentProgress.stages.abn ||
      documentProgress.stages['drivers-license-number'] ||
      documentProgress.stages['veda-consent'] ||
      documentProgress.stages['has-property'] ||
      documentProgress.stages['date-of-birth'] ||
      documentProgress.stages['other-loans']) && (
      <div>
        <div className={styles.header}>
          <h3>General Details</h3>
          <LumiButton
            type='submit'
            icon={<PencilEdit />}
            onClick={() => this.navigate(routes.IDENTITY)}
          />
        </div>
        {documentProgress.stages['date-of-birth'] && (
          <div className={styles.alignLeft}>
            Date Of Birth: <h4>{new Date(birth_date).toLocaleDateString()}</h4>
          </div>
        )}
        {documentProgress.stages['drivers-license-number'] &&
        documentProgress.stages['drivers-license-number'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Drivers License Number: <h4> {id_document_number} </h4>{' '}
          </div>
        ) : documentProgress.stages['drivers-license-number'] &&
          !documentProgress.stages['drivers-license-number'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Drivers License Number: <h4> Incomplete </h4>{' '}
          </div>
        ) : null}
        {documentProgress.stages.abn &&
        documentProgress.stages.abn.completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            ABN: <h4> {company_number} </h4>{' '}
          </div>
        ) : documentProgress.stages.abn &&
          !documentProgress.stages.abn.completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            ABN: <h4> Incomplete </h4>{' '}
          </div>
        ) : null}
        {documentProgress.stages.abn &&
        documentProgress.stages.abn.completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Business Name: <h4> {company_name} </h4>{' '}
          </div>
        ) : documentProgress.stages.abn &&
          !documentProgress.stages.abn.completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Business Name: <h4> Incomplete </h4>{' '}
          </div>
        ) : null}
        {documentProgress.stages['veda-consent'] &&
        documentProgress.stages['veda-consent'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Consent for credit check: <h4> Complete </h4>{' '}
          </div>
        ) : documentProgress.stages['veda-consent'] &&
          !documentProgress.stages['veda-consent'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Consent for credit check: <h4> Incomplete </h4>{' '}
          </div>
        ) : null}
        {documentProgress.stages['has-property'] && (
          <div className={styles.alignLeft}>
            Property Owned: <h4>{has_property === 'yes' ? 'Yes' : 'No'}</h4>
          </div>
        )}
        {documentProgress.stages['other-loans'] && (
          <div className={styles.alignLeft}>
            Other Loans: <h4>{has_other_loans === 'yes' ? 'Yes' : 'No'}</h4>
          </div>
        )}
      </div>
    );
    const driversLicense = !!documentProgress.stages['drivers-license'] && (
      <div>
        <div className={styles.header}>
          <h3>Drivers License</h3>
          <LumiButton
            type='submit'
            icon={<PencilEdit />}
            onClick={() => this.navigate(routes.DRIVERS_LICENSE)}
          />
        </div>
        {documentProgress.stages['drivers-license'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Drivers Licence: <h4> Complete </h4>{' '}
          </div>
        ) : (
          <div className={styles.alignLeft}>
            {' '}
            Drivers Licence: <h4> Incomplete </h4>{' '}
          </div>
        )}
      </div>
    );

    const financials = !!documentProgress.stages['financial-statements'] && (
      <div>
        <div className={styles.header}>
          <h3>Document Upload Financials</h3>
          <LumiButton
            type='submit'
            icon={<PencilEdit />}
            onClick={() => this.navigate(routes.FINANCIALS)}
          />
        </div>
        {documentProgress.stages['financial-statements'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Profit and Loss: <h4> Complete </h4>{' '}
          </div>
        ) : (
          <div className={styles.alignLeft}>
            {' '}
            Profit and Loss: <h4> Incomplete </h4>{' '}
          </div>
        )}
        {documentProgress.stages['financial-statements'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Balance Sheet: <h4> Complete </h4>{' '}
          </div>
        ) : (
          <div className={styles.alignLeft}>
            {' '}
            Balance Sheet: <h4> Incomplete </h4>{' '}
          </div>
        )}
      </div>
    );

    const bankFeeds = !!documentProgress.stages['bank-feeds'] && (
      <div>
        <div className={styles.header}>
          <h3>Bank Feeds</h3>
        </div>
        {documentProgress.stages['bank-feeds'].completed_at ? (
          <div className={styles.alignLeft}>
            {' '}
            Bank Feeds: <h4> Complete </h4>{' '}
          </div>
        ) : (
          <div className={styles.alignLeft}>
            {' '}
            Bank Feeds: <h4> Incomplete </h4>{' '}
          </div>
        )}
      </div>
    );

    const aboutComponent = () => {
      let aboutText;
      if (customer_notes) {
        aboutText =
          customer_notes.length > 280
            ? `${customer_notes.substring(0, 280)}...`
            : customer_notes;
      }
      return (
        <React.Fragment>
          <div className={styles.header}>
            <h3>About</h3>
            <LumiButton
              type='submit'
              icon={<PencilEdit />}
              onClick={() => this.navigate(routes.ABOUT)}
            />
          </div>
          {documentProgress.stages['time-in-business'] && (
            <div className={styles.alignLeft}>
              <span>Time in business:</span>
              <h4>{`${time_in_business_months} months`}</h4>
            </div>
          )}
          {documentProgress.stages['about'] && (
            <React.Fragment>
              <div className={styles.alignLeft}>
                <span>About Your Business:</span>
              </div>
              <div className={styles.notes}>{aboutText}</div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    };

    const about =
      (!!documentProgress.stages['about'] ||
        !!documentProgress.stages['time-in-business']) &&
      aboutComponent();

    const loanInfoComponent = () => {
      return (
        <React.Fragment>
          <div className={styles.header}>
            <h3>Loan Info</h3>
            <LumiButton
              type='submit'
              icon={<PencilEdit />}
              onClick={() => this.navigate(routes.LOAN_INFO)}
            />
          </div>
          {documentProgress.stages['loan-amount'] && (
            <div className={styles.alignLeft}>
              <span>Loan Amount:</span>
              <h4>{`$${amount}`}</h4>
            </div>
          )}
          {documentProgress.stages['loan-purpose'] && (
            <div className={styles.alignLeft}>
              <span>Loan Reasons:</span>
              {
                <h4>
                  {reason_for_loan.map((reason: any, i: number) => {
                    return `${title(reason)}${
                      i < reason_for_loan.length - 1 ? ', ' : ' '
                    }`;
                  })}
                </h4>
              }
            </div>
          )}
        </React.Fragment>
      );
    };

    const loanInfo =
      (!!documentProgress.stages['loan-amount'] ||
        !!documentProgress.stages['loan-purpose']) &&
      loanInfoComponent();

    const otherDocuments = (
      <div>
        <div className={styles.header}>
          <h3>Other Documents</h3>
        </div>
        {documentProgress.stages['other-documents'] &&
        documentProgress.stages['other-documents'].completed_at ? (
          <div className={styles.alignLeft}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              {mappedDocs}
            </div>
          </div>
        ) : (
          <div className={styles.alignLeft}>
            {' '}
            Other Documents: <h4> None </h4>{' '}
          </div>
        )}
      </div>
    );

    return (
      <div className={layoutStyles.documentCapturePage}>
        <div className={layoutStyles.documentCaptureBox}>
          <div className={layoutStyles.title}>
            <h1>Details Confirmation</h1>
            <span>
              Please confirm that the follow details are correct before
              submitting
            </span>
          </div>
          {generalDetails}
          {driversLicense}
          {financials}
          {bankFeeds}
          {loanInfo}
          {about}
          {!!documentProgress.stages['other-documents'] && otherDocuments}
          <div className={layoutStyles.nextButton}>
            <LumiButton
              rounded
              type='submit'
              label='Submit'
              onClick={() => this.navigate(routes.THANKS, true)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    application: state.user.application,
    account: state.user.account,
    documentProgress: progress.document_capture_progress,
    documentCapture: progress.document_capture,
    reason: state.reason,
  };
};

export default connect(mapStateToProps)(Confirmation);
