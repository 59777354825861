import { BankAccount } from './bankAccount';

export const unknownIllionErrorCode = 90002;

export enum BankingReportStatus {
  Initial = 'INITIAL',
  Retrieving = 'RETRIEVING',
  Saving = 'SAVING',
  AwaitingData = 'AWAITING_DATA',

  /**
   * Banking data from the provider, e.g. Illion, has been saved but not yet
   * processed or classified
   */
  Saved = 'SAVED',

  Classifying = 'CLASSIFYING',
  Processed = 'PROCESSED',

  /**
   * Trancat specific statuses
   */
  AwaitingTrancatResponse = 'AWAITING_TRANCAT_RESPONSE',
  ProcessingTrancatResponse = 'PROCESSING_TRANCAT_RESPONSE',
  Classified = 'CLASSIFIED',
  FailedProcessingTrancatResponse = 'FAILED_PROCESSING_TRANCAT_RESPONSE',

  /** The report has finished, but not all accounts were successful */
  PartiallyProcessed = 'PARTIALLY_PROCESSED',

  FailedRetrieval = 'FAILED_RETRIEVAL',
  FailedSaving = 'FAILED_SAVING',
  FailedClassification = 'FAILED_CLASSIFICATION',
  FailedProcessing = 'FAILED_PROCESSING',

  // Values related to Illion categories used on non-Illion banking data
  AwaitingIllionCategories = 'AWAITING_ILLION_CATEGORIES',
  FailedCategorisationRequest = 'FAILED_CATEGORISATION_REQUEST',
  ProcessingIllionCategories = 'PROCESSING_ILLION_CATEGORIES',
  /** Failed to add Illion categories to non-Illion banking data */
  FailedProcessingIllionCategories = 'FAILED_PROCESSING_ILLION_CATEGORIES',

  /**
   * Used when invalid data is received from the banking provider. The banking
   * report should be allowed to be processed with new banking data since it's
   * likely that we will need to manually fix the data and post it to the system
   * again.
   */
  InvalidDataReceived = 'INVALID_DATA_RECEIVED',

  OcrScanningInProgress = 'OCR_SCANNING_IN_PROGRESS',
  OcrScanningSuccessful = 'OCR_SCANNING_SUCCESSFUL',
  OcrScanningFailed = 'OCR_SCANNING_FAILED',

  /**
   * The files were successfully scanned but extra manual checks are required
   * to make sure the files are legitimate
   */
  OcrScanningCompleteChecksRequired = 'OCR_SCANNING_COMPLETE_CHECKS_REQUIRED',

  /*
   * DEPRECATED STATUSES BELOW
   * Leaving them here so as not to cause mongoose validation errors
   * when updating old reports but not updating the status
   */

  /** @deprecated */
  InProcess = 'IN_PROCESS',
  /** @deprecated */
  Processing = 'PROCESSING',
  /** @deprecated */
  Failed = 'FAILED',
}

export enum OcrBankStatementsSubmissionAttachmentScanningStatus {
  Successful = 'SUCCESSFUL',
  Failed = 'FAILED',
}

export enum OcrBankStatementsSubmissionAttachmentReviewState {
  Pass = 'PASS',
  Fail = 'FAIL',
}

export interface IOcrBankStatementSubmissionAttachment {
  id: string;
  bankingReportId: string;
  updatedAt: string;
  attachmentId: string;
  fileName: string;
  reviewState?: OcrBankStatementsSubmissionAttachmentReviewState;
  reviewNotes?: string;
  previewedAt?: string;
  previewedBy?: string;
  reviewedAt?: string;
  reviewedBy?: string;
  scanningErrorMessage?: string;
  scanningErrorRecommendation?: string;
  scanningStatus?: OcrBankStatementsSubmissionAttachmentScanningStatus;
}

export interface IOcrBankStatementsSubmission {
  id: string;
  created_at: string;
  updated_at: string;
  status: BankingReportStatus;
  ocrStatementAttachments: Array<IOcrBankStatementSubmissionAttachment>;
  ocr_fraud_flags: Array<string>;
  submitted_by?: string;
  start_processing_at?: string;
  period_start_date?: string;
  period_end_date?: string;
  processing_error_code?: string;
}

export enum BankingReportType {
  CreditSense = 'CREDIT_SENSE',
  BankFeeds = 'BANK_FEEDS',
  BankFeedsBrokerFlow = 'BANK_FEEDS_BROKER_FLOW',
  Manual = 'MANUAL',
  StatementOcr = 'STATEMENT_OCR',
}

export type BankingReport = {
  id?: string;
  owner_id?: string;
  owner_type?: string;
  status?: BankingReportStatus;
  type?: BankingReportType | string;
  token?: string;
  accounts?: BankAccount[];
  expired?: boolean;
  created_at?: Date;
  updated_at?: Date;
  refreshed_at?: Date;
  has_mfa?: boolean;
  encryption_key?: string;
  customer_id?: string;
  file_id?: string;
  document_id?: string;
  bank_name?: string;
  completed_at?: Date;
  period_start_date?: Date;
  period_end_date?: Date;
  failed_account_numbers?: string[];
  refreshable?: boolean;
  processing_error_code?: string;
  updated_processing_at?: Date;
  error_occured_at?: Date;
  secondary_classification_status?: BankingReportStatus;
};
