let _fieldName = field => {
  let specials = ['email', 'phone'];
  return specials.indexOf(field) !== -1 ? '$' + field : field;
};

export function identifySimple(id, props) {
  analytics.ready(function () {
    // Setting default props (Google Analytics related)
    const defaultProps = {
      anonymousId: window.analytics?.user()?.anonymousId() || null,
      userId: id || null,
    };
    const returnedProps = { ...props, ...defaultProps };

    // Setting default traits (Google Analytics related)
    let defaultTraits = {
      anonymousId: window.analytics?.user()?.anonymousId() || null,
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    };

    analytics.identify(id, returnedProps, defaultTraits);
  });
}

export function identify(id, fields, data) {
  let props;
  fields.forEach(field => {
    if (
      Object.keys(data || {}).indexOf(field) !== -1 &&
      data[field] !== '' &&
      data[field] !== null
    ) {
      props[_fieldName(field)] = data[field];
    }
  });
  if (Object.keys(props || {}).length > 0) {
    // Setting default props (Google Analytics related)
    const defaultProps = {
      anonymousId: window.analytics?.user()?.anonymousId() || null,
      userId: id || null,
    };
    const returnedProps = { ...props, ...defaultProps };

    // Setting default traits (Google Analytics related)
    let defaultTraits = {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    };

    analytics.identify(id, returnedProps, defaultTraits);
  } else {
    console.log('no analytics properties to setup');
  }
}

export async function track(eventName, properties) {
  const env = getEnv();
  properties.env = env;
  properties.app = 'client';
  await analytics.track(eventName, properties, {
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  });
}

export async function trackAction(action) {
  const url = window.location.href;
  await analytics.track(
    'leads',
    {
      action,
      env: getEnv(),
      app: 'client',
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

export function getEnv() {
  const url = window.location.href;
  if (url.includes('localhost') || url.includes('staging')) {
    return 'dev';
  }
  return 'prod';
}

export function trackExternalLink(name, location, href, text, attrs) {
  analytics.track(
    'interactions',
    {
      name,
      location,
      href,
      text,
      ...attrs,
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

export function trackPageView(pageName) {
  const props = {
    pageName: pageName,
    url: window.location.pathname,
    interaction: 'click',
  };
  analytics.track('interactions', props, {
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  });
}

export function trackPage(pageName, properties, traits) {
  let props;
  let tra = {};
  if (properties) props = { ...properties };
  if (traits) tra = { ...traits };

  // Setting default traits (Google Analytics related)
  let defaultTraits = {
    anonymousId: window.analytics?.user()?.anonymousId() || null,
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  };

  const returnedTraits = { ...tra, ...defaultTraits };

  analytics.page(pageName, props, returnedTraits);
}

export function alias(id) {
  analytics.alias(id);
}

const step_list = {
  '1.0': {
    login: 1,
    application: 1,
    loan_info: 2,
    business_info: 3,
    director_info: 4,
    driver_licence: 5,
    // 'financial_statements': 6,
    banking_info: 7,
    mobile_verification: 8,
  },
  1.1: {
    login: 1,
    application: 1,
    loan_info: 2,
    business_info: 3,
    director_info: 4,
    driver_licence: 5,
    banking_info: 7,
    extra_info: 8,
  },
};

export function funnel_step(step, version) {
  return step_list[version][step];
}

export function funnel_step_version() {
  return '1.1';
}
