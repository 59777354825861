export const INDUSTRY_CHANGED = 'INDUSTRY_CHANGED';
export const ADDING_NEW_STAKEHOLDER = 'ADDING_NEW_STAKEHOLDER';
export const ADDED_NEW_STAKEHOLDER = 'ADDED_NEW_STAKEHOLDER';

export const XERO_LOGGED_IN = 'XERO_LOGGED_IN';
export const UPLOADING = 'UPLOADING';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

export const UPLOADING_PROFIT = 'UPLOAD_PROFIT';
export const UPLOADING_BALANCE = 'UPLOAD_BALANCE';

export const GENERIC_DOCUMENTS_UPLOAD_STARTED =
  'GENERIC_DOCUMENTS_UPLOAD_STARTED';
export const GENERIC_DOCUMENTS_UPLOAD_SUCCESS =
  'GENERIC_DOCUMENTS_UPLOAD_SUCCESS';
export const GENERIC_DOCUMENTS_UPLOAD_ERROR = 'GENERIC_DOCUMENTS_UPLOAD_ERROR';

const initialState = {
  selected_industry: null,
  adding_new_stakeholder: false,
  uploading: false,
  upload_progress: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case INDUSTRY_CHANGED:
      return Object.assign({}, state, {
        selected_industry: action.newIndustry,
      });
    case ADDING_NEW_STAKEHOLDER:
      return Object.assign({}, state, {
        adding_new_stakeholder: true,
      });
    case UPLOADING_PROFIT: {
      return Object.assign({}, state, {
        profit_progress: action.progress,
      });
    }
    case UPLOADING_BALANCE: {
      return Object.assign({}, state, {
        balance_progress: action.progress,
      });
    }
    case ADDED_NEW_STAKEHOLDER:
      return Object.assign({}, state, {
        adding_new_stakeholder: false,
      });
    case XERO_LOGGED_IN:
      return Object.assign({}, state, {
        xero_logged_in: true,
      });
    case UPLOADING:
      return Object.assign({}, state, {
        uploading: action.isUploading,
      });
    case UPLOAD_PROGRESS:
      return Object.assign({}, state, {
        upload_progress: action.progress,
      });
    case GENERIC_DOCUMENTS_UPLOAD_STARTED:
      return {
        generic_doc_uploading: true,
        generic_doc_uploaded: false,
      };
    case GENERIC_DOCUMENTS_UPLOAD_SUCCESS:
      return {
        generic_doc_uploading: false,
        generic_doc_uploaded: true,
      };
    case GENERIC_DOCUMENTS_UPLOAD_ERROR:
      return {
        generic_doc_uploading: false,
      };
    default:
      return state;
  }
}
