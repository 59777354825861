type SetLocalStorageOpts = {
  toJson?: boolean;
};

/**
 * Function to wrap storing information in browser local storage. This
 * implementation is wrapped so that it can be replaced if needed - potentially
 * replace it with a more robust local cookie storage if needed
 * @param key
 * @param payload
 * @param opts
 */
export function setLocalStorage<T>(
  key: string,
  payload: T,
  opts: SetLocalStorageOpts,
) {
  const valToStore = opts.toJson ? JSON.stringify(payload) : String(payload);
  localStorage.setItem(key, valToStore);
}

type GetLocalStorageOpts = {
  fromJson?: boolean;
};

export function getLocalStorage<T>(
  key: string,
  opts: GetLocalStorageOpts,
): T | null {
  const valFromStore = localStorage.getItem(key);
  if (!valFromStore || valFromStore === 'null') return null;
  return opts.fromJson ? JSON.parse(valFromStore) : valFromStore;
}
