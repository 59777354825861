import { ensureAuthenticated } from '../../../utils/auth';
import React from 'react';

export default (store: any) => ({
  path: 'extra_info',
  progress: { step: 6, percentage: 90 },
  enableHeader: true,
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const ExtraInfoView = React.lazy(
      () => import('./components/ExtraInfoView'),
    );
    cb(null, ExtraInfoView);
  },
});
