import React from 'react';
import Text from 'junction/components/text/Text';
import Tooltip, {
  TooltipContentType,
} from 'junction/components/tooltip/Tooltip';
import Question from 'junction/assets/question.svg';

const BankFeedsHelpTooltip = () => (
  <Tooltip placement='bottom' className='lm-leading-none'>
    <Tooltip.Trigger
      useLegacyAlignment
      ariaLabel='More information'
      className='lm-cursor-pointer lm-outline-none lm-focus-yellow lm-h-full'
    >
      <Question className='lm-text-base-pink' />
    </Tooltip.Trigger>
    <Tooltip.Content
      className='lm-max-w-88 lm-mt-2'
      type={TooltipContentType.INFO}
      padding='lm-p-4'
    >
      <Text size='small' className='lm-text-base-pink lm-mb-0'>
        Lumi uses a third-party provider, BankFeeds, to securely connect to your
        nominated bank or financial institution.
        <br />
        <br />
        By completing this step of the application, you are providing us with
        access to up to 12 months of financial data as encrypted, read-only
        files. If we choose to provide you with credit, you will provide Lumi
        with an Ongoing Use Authorisation to access the same information. This
        allows us to better assess lending eligibility and to protect you and
        your business against fraud.
      </Text>
    </Tooltip.Content>
  </Tooltip>
);

export default BankFeedsHelpTooltip;
