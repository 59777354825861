import { connect } from 'react-redux';
import {
  msgError,
  saveApplication,
  saveAccount,
} from '../../../../CommonCreators';
import { push } from 'react-router-redux';
import { updateProgress as docCaptureProgress } from '../../DocumentCaptureActions';

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    documentProgress: progress.document_capture_progress,
    application: state.user.application,
    account: state.user.account,
  };
};

type MapDispatchToProps = {
  msgError: (title: string, error: string) => void;
  saveApplication: (application: any, callback: (app: any) => void) => void;
  saveAccount: (account: any, callback: (account: any) => void) => void;
  push: (path: string) => void;
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
};

const mapDispatchToProps: MapDispatchToProps = {
  msgError,
  saveApplication,
  saveAccount,
  push,
  updateProgress: docCaptureProgress,
};

import DebtsCollection from '../components/Debts';

export default connect(mapStateToProps, mapDispatchToProps)(DebtsCollection);
