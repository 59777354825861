import * as React from 'react';
import pickBy from 'lodash/pickBy';

const getUnitString = (unit: string | number) => {
  // Protects in the case the unit is 0
  if (unit === undefined) return unit;

  if (typeof unit === 'number') return `${unit}px`;

  return unit;
};

interface PropTypes {
  children: any;
  columnTemplate?: string;
  rowTemplate?: string;
  align?: string;
  justify?: string;
  width?: number | string;
  rowGap?: number | string;
  columnGap?: number | string;
  style?: any;
  className?: string;
}

const Grid = (props: PropTypes) => {
  const {
    children,
    rowTemplate,
    columnTemplate,
    align,
    justify,
    width,
    rowGap,
    columnGap,
    className,
    style,
  } = props;
  const newStyle = {
    display: 'grid',
    gridTemplateRows: rowTemplate,
    gridTemplateColumns: columnTemplate,
    gridRowGap: getUnitString(rowGap),
    gridColumnGap: getUnitString(columnGap),
    alignItems: align,
    justifyItems: justify,
    width: getUnitString(width),
    ...style,
  };

  // Remove the null/undefined values from the style object
  const removedNull = pickBy(newStyle);
  return (
    <div className={className} style={removedNull}>
      {children}
    </div>
  );
};

Grid.defaultProps = {
  width: '100%',
};

export default Grid;
