import React, { FC, useContext } from 'react';
import Container from '../container/Container';
import { Hidden } from '../hidden/Hidden';
import ContentLoader from 'react-content-loader';
import { AnimatePresence } from 'framer-motion';

type Props = {
  loading: boolean;
};

type LoaderProps = {
  /**
   * Flag to indicate whether the content / loader should fade in
   * and out of each state
   */
  animate?: boolean;
  /**
   * The SVG viewbox for the skeleton loader.
   */
  viewBox?: string;
  /**
   * Width of the skeleton loader
   */
  width: string;
  /**
   * Height of the skeleton loader
   */
  height: string;
  /**
   * If using the skeleton loader by itself, you can pass in this flag
   * to manually indicate a loading state.
   */
  loading?: boolean;
};

type ContentProps = {
  height?: string;
};

type SkeletonComponent = FC<Props> & {
  Loader: FC<LoaderProps>;
  Content: FC<ContentProps>;
};

const SkeletonLoaderContext = React.createContext(false);
const willChange = { willChange: 'opacity' };

const Content: FC<ContentProps> = props => {
  const { children, height } = props;
  if (height) {
    (willChange as any).height = height;
  }
  const loading = useContext(SkeletonLoaderContext);
  return (
    <AnimatePresence exitBeforeEnter>
      <Hidden when={loading}>
        <Container
          style={willChange}
          animation={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
          }}
        >
          {children}
        </Container>
      </Hidden>
    </AnimatePresence>
  );
};

export const Loader: FC<LoaderProps> = props => {
  const { children, animate = true, viewBox, width, height, loading } = props;
  let _loading = useContext(SkeletonLoaderContext);
  if (loading !== undefined) _loading = loading;
  return (
    <AnimatePresence exitBeforeEnter>
      <Hidden when={!_loading}>
        <Container>
          <ContentLoader
            backgroundColor='#EEF0F5'
            foregroundColor='#F8F9FB'
            speed={1.75}
            animate={animate}
            viewBox={viewBox}
            width={width}
            height={height}
          >
            {children}
          </ContentLoader>
        </Container>
      </Hidden>
    </AnimatePresence>
  );
};

const Skeleton: SkeletonComponent = props => {
  const { loading, children } = props;

  return (
    <SkeletonLoaderContext.Provider value={loading}>
      {children}
    </SkeletonLoaderContext.Provider>
  );
};

Skeleton.Content = Content;
Skeleton.Loader = Loader;

export default Skeleton;
