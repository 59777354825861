import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { Loan } from '../../common/api/dataTypes/loan';
import { LoanProduct } from '../../common/api/dataTypes/loanProduct';
import {
  GraphQLResponse,
  useGraphQuery,
  useGraphQueryClean,
} from '../../common/utils/graphql_utils';
import { settingsDefaultFragment } from '../fragments/platform';
import { ScheduledRepaymentTermsHistory } from '../../common/api/dataTypes/scheduledRepaymentTermsHistory';

export const getLoanQuery = (requestedFields: string | DocumentNode) => gql`
    query GetLoan($loanId: ID!) {
        loan(id: $loanId) ${requestedFields}
    }
`;

/**
 * See getOriginalLoan in LoanActions.js
 */
export const getOriginalLoanQuery = (
  requestedFields: string | DocumentNode,
) => gql`
 query GetLoan($loanId: ID!, $loanPartIndex: Float) {
     loan(id: $loanId, loan_part_index: $loanPartIndex) ${requestedFields}
 }
`;

export const getCreditApplicationQuery = (
  requestedFields: string | DocumentNode,
) => gql`
    query GetCreditApplication($creditAppId: ID!) {
        creditApplication(credit_application_id: $creditAppId) ${requestedFields}
    }
`;

export const getCreditApplicationWithDecisionHistoriesQuery = (
  requiredCreditAppFields: string | DocumentNode,
) => gql`
    query GetCreditApplication($creditAppId: ID!, $decisionNames: [String!]!) {
      creditApplication(credit_application_id: $creditAppId) ${requiredCreditAppFields}
    }
`;

export const getDocumentCapturesByAccountQuery = (
  requestedFields: string | DocumentNode,
) => gql`
  query GetDocumentCaptureByAccount($account_ids: [ID!]!) {
    documentCapturesByAccountIds(account_ids: $account_ids) ${requestedFields}
  }
`;

/**
 * @param fragments Any GQL fragments used in `requestedField`
 */
export const getDeal = (
  requestedField: string | DocumentNode,
  fragments?: string | DocumentNode,
) => gql`
    query GetDeal($loanId: String, $id: String) {
        deal(loan_id: $loanId, id: $id) ${requestedField}
    }
    ${fragments || ''}
`;

export const getApplicantDeal = (requestedField: string | DocumentNode) => gql`
    query GetDeal($loanId: String) {
        applicantDeal(loan_id: $loanId) ${requestedField}
    }
`;

export const getAccountQuery = (requestedFields: string | DocumentNode) => gql`
    query GetAccount($accountId: ID!) {
        account(id: $accountId) ${requestedFields}
    }
`;

export const getAccountsQuery = (requestedFields: string | DocumentNode) => gql`
    query GetAccounts(
        $pageSize: Int,
        $pageIndex: Int,
        $roles: [String!],
        $managerAccountIds: [String!]
    ) {
        accounts(page_size: $pageSize, page_index: $pageIndex, roles: $roles, manager_account_ids: $managerAccountIds) ${requestedFields}
    }
`;

export const getLeadQuery = (requestedFields: string | DocumentNode) => gql`
    query GetLead($leadId: ID, $loanId: ID) {
        lead(id: $leadId, loan_id: $loanId) ${requestedFields}
    }
`;

export const getExperimentGroupQuery = gql`
  query GetExperimentGroup(
    $trialId: String
    $experimentName: String!
    $userId: String
  ) {
    experiment_group(
      trial_id: $trialId
      experiment_name: $experimentName
      user_id: $userId
    )
  }
`;

export const getAggregatorsQuery = gql`
  query GetAggregatorList {
    aggregators {
      id
      name
    }
  }
`;

export type SuppliersResponse = GraphQLResponse<{
  suppliers: {
    id: string;
    name: string;
  }[];
}>;

export const SuppliersQuery = gql`
  query Suppliers {
    suppliers {
      id
      name
    }
  }
`;

export const getLoansQuery = (requestedFields: string | DocumentNode) => gql`
    query GetLoans($accountId: ID, $loanStatuses: String, $applicationType: ApplicationType) {
        loans(loan_status: $loanStatuses, applicant_account_id: $accountId, application_type: $applicationType) ${requestedFields}
    }
`;

export const AllProductPoliciesQuery = gql`
  query AllProductPolicies {
    allProductPolicies {
      id
      name
      label
      establishment_fee {
        ...SettingsDefaultFragment
      }
      early_repayment_date_offset_weeks {
        ...SettingsDefaultFragment
      }
      property_security {
        validOptions
        default {
          preset_value_string
        }
      }
      initial_payment_holiday_weeks {
        ...SettingsDefaultFragment
      }
      payment_pause_weeks {
        ...SettingsDefaultFragment
      }
    }
  }
  ${settingsDefaultFragment}
`;

// GraphQL directives have been invented. Let's use them. https://graphql.org/learn/queries/#directives
export const LoanProductsQuery = gql`
  query LoanProducts {
    loanProducts {
      id
      name
      product_name
      loan_product_type
      default_product_policy
      populated_base_product_policy {
        id
        name
        label
        interest_rate {
          ...SettingsDefaultFragment
        }
        loan_amount {
          ...SettingsDefaultFragment
        }
        term_length_weeks {
          ...SettingsDefaultFragment
        }
        establishment_fee {
          ...SettingsDefaultFragment
        }
        early_repayment_date_offset_weeks {
          ...SettingsDefaultFragment
        }
        property_security {
          validOptions
          default {
            preset_value_string
          }
        }
        initial_payment_holiday_weeks {
          ...SettingsDefaultFragment
        }
        payment_pause_weeks {
          ...SettingsDefaultFragment
        }
        interest_rate_reduction_amount {
          ...SettingsDefaultFragment
        }
        interest_rate_reduction_period_weeks {
          ...SettingsDefaultFragment
        }
        interest_rate_minimum_after_reductions {
          default {
            preset_value_number
          }
          min
          max
        }
        maximum_total_interest_rate_reduction_apr
        amortisation_period_weeks {
          ...SettingsDefaultFragment
        }
        minimum_repayment_percentage {
          ...SettingsDefaultFragment
        }
        monthly_fee {
          ...SettingsDefaultFragment
        }
        drawdown_fee {
          ...SettingsDefaultFragment
        }
        late_fee {
          ...SettingsDefaultFragment
        }
        minimum_disbursement_amount {
          ...SettingsDefaultFragment
        }
      }
      populated_product_policy_options {
        id
        name
        label
        interest_rate {
          ...SettingsDefaultFragment
        }
        loan_amount {
          ...SettingsDefaultFragment
        }
        term_length_weeks {
          ...SettingsDefaultFragment
        }
        establishment_fee {
          ...SettingsDefaultFragment
        }
        early_repayment_date_offset_weeks {
          ...SettingsDefaultFragment
        }
        property_security {
          validOptions
          default {
            preset_value_string
          }
        }
        initial_payment_holiday_weeks {
          ...SettingsDefaultFragment
        }
        payment_pause_weeks {
          ...SettingsDefaultFragment
        }
        amortisation_period_weeks {
          ...SettingsDefaultFragment
        }
        minimum_repayment_percentage {
          ...SettingsDefaultFragment
        }
        minimum_repayment_calculation_method {
          validOptions
          default {
            preset_value_string
          }
        }
        repayment_period_unit {
          ...SettingsDefaultFragment
        }
        monthly_fee {
          ...SettingsDefaultFragment
        }
        drawdown_fee {
          ...SettingsDefaultFragment
        }
        late_fee {
          ...SettingsDefaultFragment
        }
        minimum_disbursement_amount {
          ...SettingsDefaultFragment
        }
        interest_rate_reduction_amount {
          ...SettingsDefaultFragment
        }
        interest_rate_reduction_period_weeks {
          ...SettingsDefaultFragment
        }
        interest_rate_minimum_after_reductions {
          default {
            preset_value_number
          }
          min
          max
        }
        interest_rate_reduction_times {
          default {
            preset_value_number
            fixed
          }
          min
          max
        }
        maximum_total_interest_rate_reduction_apr
      }
    }
  }
  ${settingsDefaultFragment}
`;

const requiredAllFields = `
populated_base_product_policy {
  id
  name
  label
  interest_rate {
    ...SettingsDefaultFragment
  }
  loan_amount {
    ...SettingsDefaultFragment
  }
  term_length_weeks {
    ...SettingsDefaultFragment
  }
  establishment_fee {
    ...SettingsDefaultFragment
  }
  early_repayment_date_offset_weeks {
    ...SettingsDefaultFragment
  }
  property_security {
    validOptions
    default {
      preset_value_string
    }
  }
  initial_payment_holiday_weeks {
    ...SettingsDefaultFragment
  }
  payment_pause_weeks {
    ...SettingsDefaultFragment
  }
  interest_rate_reduction_amount {
    ...SettingsDefaultFragment
  }
  interest_rate_reduction_period_weeks {
    default {
      preset_value_number
    }
    min
    max
  }
  interest_rate_minimum_after_reductions {
    default {
      preset_value_number
    }
    min
    max
  }
  interest_rate_reduction_times {
    default {
      preset_value_number
    }
    min
    max
  }
  maximum_total_interest_rate_reduction_apr
  amortisation_period_weeks {
    ...SettingsDefaultFragment
  }
  minimum_repayment_percentage {
    ...SettingsDefaultFragment
  }
  monthly_fee {
    ...SettingsDefaultFragment
  }
  drawdown_fee {
    ...SettingsDefaultFragment
  }
  late_fee {
    ...SettingsDefaultFragment
  }
  minimum_disbursement_amount {
    ...SettingsDefaultFragment
  }
}
populated_product_policy_options {
  id
  name
  label
  interest_rate {
    ...SettingsDefaultFragment
  }
  loan_amount {
    ...SettingsDefaultFragment
  }
  term_length_weeks {
    ...SettingsDefaultFragment
  }
  establishment_fee {
    ...SettingsDefaultFragment
  }
  early_repayment_date_offset_weeks {
    ...SettingsDefaultFragment
  }
  property_security {
    validOptions
    default {
      preset_value_string
    }
  }
  initial_payment_holiday_weeks {
    ...SettingsDefaultFragment
  }
  payment_pause_weeks {
    ...SettingsDefaultFragment
  }
  amortisation_period_weeks {
    ...SettingsDefaultFragment
  }
  minimum_repayment_percentage {
    ...SettingsDefaultFragment
  }
  repayment_period_unit {
    ...SettingsDefaultFragment
  }
  monthly_fee {
    ...SettingsDefaultFragment
  }
  drawdown_fee {
    ...SettingsDefaultFragment
  }
  late_fee {
    ...SettingsDefaultFragment
  }
  minimum_disbursement_amount {
    ...SettingsDefaultFragment
  }
  interest_rate_reduction_amount {
    ...SettingsDefaultFragment
  }
  interest_rate_reduction_period_weeks {
    ...SettingsDefaultFragment
  }
  maximum_total_interest_rate_reduction_apr
}`;

/**
 *
 * @param includingAllFields: if true, including all populated policy fields
 * @returns
 */
export const LoanProductQuery = (
  requiredFields: any = requiredAllFields,
  includingAllFields: boolean = true,
) => gql`
  query LoanProduct($loan_product_id: String!) {
    loanProduct(loan_product_id: $loan_product_id) {
      id
      name
      product_name
      loan_product_type
      default_product_policy
      ${requiredFields}
    }
  }
  ${includingAllFields ? settingsDefaultFragment : ''}
`;

export const getProductPolicyByIdQuery = (
  requestedFields: string | DocumentNode,
) => gql`
  query ProductPolicyById($productPolicy: String!) {
    productPolicy(product_policy_id: $productPolicy) {
      ${requestedFields}
    }
  }
`;

/** todo epic-264 can be deleted */
export const LOCProductPoliciesQuery = gql`
  query LOCProductPolicies($productPolicy: String!) {
    allProductPolicies(product_policy_id: $productPolicy) {
      term_length_weeks {
        ...LOCSettingsMinMax
      }
      amortisation_period_weeks {
        ...LOCSettingsMinMax
      }
      initial_payment_holiday_weeks {
        ...LOCSettingsMinMax
      }
      minimum_repayment_percentage {
        ...LOCSettingsMinMax
      }
      repayment_period_unit {
        ...LOCSettingsMinMax
      }
      monthly_fee {
        min
        max
        ...SettingsDefaultFragment
      }
      drawdown_fee {
        min
        ...SettingsDefaultFragment
      }
      establishment_fee {
        min
        max
        ...SettingsDefaultFragment
      }
      late_fee {
        min
        ...SettingsDefaultFragment
      }
      property_security {
        validOptions
        default {
          preset_value_string
        }
      }
      manual_repayment_delay_weeks {
        ...LOCSettingsMinMax
      }
      minimum_repayment_amount_delay_weeks {
        ...LOCSettingsMinMax
      }
      minimum_disbursement_amount {
        ...LOCSettingsMinMax
      }
      minimum_fees_waived_period_weeks {
        ...LOCSettingsMinMax
      }
    }
  }
`;

export const getDealFields = gql`
  {
    id
    stage
    pipeline
    title
    status
    owner_id
    created_at
    value
    manager_account_id
    account_id
    owner_type
    hubspot_deal_id
    pending_create
    hubspotDealUrl
    hubspotTicketUrl
  }
`;

export const getCreditApplicationFields = gql`
  {
    decline_reasons
    outcome_date
    remaining_facility_limit
    previous_facility_limit
    credit_impaired
    auto_decisioned
    is_using_merlin
    loan {
      id
      lead {
        id
        channel
      }
      is_drawdown_locked
      latestSMSRequest {
        type
        status
        confirmed_at
        metadata {
          drawdown_amount
          disbursement_amount
          payout_details {
            amount
            lender
          }
        }
      }
      latestLoanClosureFile
    }
    id
    business_details {
      defaults
      nature_of_business
      purpose
    }
    initial_cutoff {
      score_under_400
      active_material_ccj
      previous_failed_company
      less_than_6_months
      material_gambling
      excessive_dishonours
      unpaid_default
      non_telco_default
    }
    secondary_cutoff {
      major_drop_in_balances
      under_23_years_old
      factors_lot_of_revenue
      stacking
      company_less_than_year_old
      poor_personal_account
      exceeds_overdraft_facility
    }
    company_criteria {
      industry_sector
      no_property
      score_less_than_600
      company_less_than_two_years_old
    }
    financials {
      existing_debt
      dishonours_and_overdrawn
      tax_position
      revenue
      overdraft_limit_available
      current_servicing
      ato_backlog
      current_balance
      underwriter_offer
      ato_payment_plan
      ato_monthly_amount
      major_customers_concentration
      overridden_annualised_revenue
      overridden_annualised_revenue_reason
      debt {
        id
        debtor
        amount
      }
      dishonours {
        dishonours_categories {
          category_id
          count
          override_count
          override_reason
          overridden_at
          overridden_by
          populated_overridden_by {
            combined_name
          }
        }
        notes
        count
        refreshed_at
      }
    }
    servicability {
      unserviceable_balances
      balance_30_days
      balance_45_days
      balance_60_days
      balance_80_days
      average_balance
      servicable
      refreshed_at
    }
    affordability {
      profit_margin
      profit_margin_override
      profit_margin_override_reason
      annual_free_cashflow
      max_acceptable_lumi_payment
      annualised_financial_expenses
      max_affordable_payment
      is_from_previous_credit_application
    }
    previous_credit_application_id
    parent_id
    status
    stage
    notes
    banking_state {
      selected_months
      selected_bank_accounts
      selected_graph
      selected_30_day_rolling
    }
    type
    pricing_tier
    version
    dscr_calculations {
      maximum_loan_amount
      weekly_repayment
      minimum_dscr
      dscr
      payment_holiday
      repayment_term
      weekly_interest_rate
      recommended_minimum_dscr_matrix_id
      recommended_minimum_dscr_parameters {
        loan_term_max
        risk_grade
        product_feature
        loan_product_type
      }
    }
    exception
    name
    created_at
    updated_at
    parent_type
    last_approved_or_declined_by
    credit_industry_version_at_creation
    debt_assessment_date
    concentrated_income_percentage
    is_using_secondary_classifications
    overridden_dscr_limit
    debts_includes_competitor_loan
    new_drawdown_availability_period_ends
    decisionHistories(decision_names: $decisionNames) {
      id
      decision_time
      decision_name
      assessment_decision_history_id
      credit_application_id
      populated_decisioned_by_name
      payload {
        credit_application {
          decision_rationale
          decline_reasons
          type
          lock_reason
          raise_reason
        }
        credit_flags {
          unresolved_flags {
            flag_id
          }
        }
        conditions_precedent {
          conditions {
            title
          }
        }
        facility_lock {
          reason
          rationale
        }
      }
      populated_selected_bank_accounts {
        label
      }
    }
    incompleteConditionsPrecedent {
      count
    }
  }
`;

const baseRequiredLoanFields = gql`
  {
    id
    underwriting_status
    current_loan_part {
      status
      sub_status
      total_balance
      accrued_interest
      settings {
        drawdown_fee {
          fee_calculation_method
          value
        }
        product_policy
        minimum_disbursement_amount
      }
    }
    locBalance {
      facility_limit
      facility_used
      available_amount
      pending_amount
    }
  }
`;

export const creditArrangementRequiredFields = gql`
  {
    id
    credit_arrangement {
      id
      status
      property_ids
      supplier_id
      mambu_id
      openpay_business_id
      active_invoices {
        id
        amount
        current_loan_part {
          mambu_id
          disbursement_date
          first_repayment_date
        }
      }
      amount_available
      amount_consumed
      start_date
      end_date
      last_approved_or_declined_by
      notes
      company {
        id
        company_name
        industry_code
        industry_sector
        granular_industry_code
      }
      supplier {
        name
      }
      settings {
        preset
        loan_settings {
          terms {
            value
            unit
          }
          minimum_invoice_amount
          maximum_invoice_amount
          loan_product
          penalty_interest_rate {
            amount
            frequency
            frequency_unit
            penalty_tolerance_period_days
          }
        }
        arrangement_settings {
          term_length
          amount
          security
          business_type
        }
        credit_settings {
          affordability_percentage
        }
      }
    }
  }
`;

export const getEstimatedMinimumRepaymentAmount = gql`
  query calculateMinimumRepaymentAmount(
    $loan_id: ID!
    $drawdown_amount: Float!
  ) {
    calculateMinimumRepaymentAmount(
      loan_id: $loan_id
      drawdown_amount: $drawdown_amount
    ) {
      minimum_repayment_amount
    }
  }
`;

export const getCreditIndustryQuery = gql`
  query getCreditIndustry($creditIndustryId: ID!) {
    creditIndustry(credit_industry_id: $creditIndustryId) {
      id
      industry
      granular_industry
      lower_free_cash_margin
      mid_free_cash_margin
      upper_free_cash_margin
    }
  }
`;

export const getCreditIndustryByCodeQuery = (
  requestedFields: string | DocumentNode,
) => gql`
    query getCreditIndustry(
        $code: String!,
        $version: Float
    ) {
        creditIndustryByCode(code: $code, version: $version) ${requestedFields}
    }
`;

export const getCreditArrangementByIdQuery = (
  requestedFields: string | DocumentNode,
) => gql`
    query getCreditArrangementId($creditArrangementId: ID!) {
        creditArrangement(credit_arrangement_id: $creditArrangementId) {
            ${requestedFields}
        }
    }
`;

export const getPaytoAgreementsQuery = (
  requestedFields: string | DocumentNode,
) => gql`
  query getPaytoAgreements($loanId: ID!) {
    paytoAgreements(loanId: $loanId)
      ${requestedFields}
  }
`;

export const getDSCRMatricesQuery = (
  requestedFields: string | DocumentNode,
) => gql`
  query getDSCRMatrices{
    dscrMatrices
      ${requestedFields}
  }
`;

export const getLatestPaytoAgreementQuery = (
  requestedFields: string | DocumentNode,
) => gql`
  query getLatestPaytoAgreementQuery($loanId: ID!) {
    latestPaytoAgreement(loanId: $loanId)
      ${requestedFields}
  }
`;

export const getCreditArrangementWithAdditionalInfoByIdQuery = (
  requestedFields: string | DocumentNode,
) => gql`
    query getCreditArrangementWithAdditionalInfoId($creditArrangementId: ID!) {
        creditArrangementWithAdditionalInfo(credit_arrangement_id: $creditArrangementId) {
            ${requestedFields}
        }
    }
`;

/**
 * Retrieves the loan by loan id with a base set of fields by default. If you
 * wish to include more fields, pass them in as a third argument, but make sure
 * that you include a uniqueQueryKey. WARNING: This query is cached every 10
 * minutes! The cache is based on the query key you pass in, so make sure that
 * it is unique and you invalidate that cache when  you expect an update
 * @param loanId
 * @param uniqueQueryKey - WARNING: This must be unique for each set of unique gql fields you are requesting
 * @param requiredFields
 */
export const GetLoan = (
  loanId: string | undefined,
  uniqueQueryKey?: string,
  requiredFields?: any,
) => {
  if (requiredFields && !uniqueQueryKey)
    throw new Error(
      'Query key must be provided if required fields is passed in',
    );
  const fields = requiredFields || baseRequiredLoanFields;
  const queryKey = uniqueQueryKey;
  return useGraphQuery<GraphQLResponse<{ loan: Loan }>>(
    [
      'loan',
      {
        loanId,
      },
      queryKey,
    ],
    getLoanQuery(fields),
    {
      // Dont refetch every time, just keep it alive
      staleTime: 60 * 1000 * 10, // 10 minutes
    },
  );
};

export const useGetProductPolicyName = (productPolicy: string) => {
  const { data } = useGraphQuery(
    ['productPolicyName', { productPolicy }],
    getProductPolicyByIdQuery('label'),
    { enabled: !!productPolicy },
  );
  return data?.data?.productPolicy?.label;
};

export const useGetLoanProductName = (loanProductId: string) => {
  const { data: loanProduct } = useGraphQuery<
    GraphQLResponse<{
      loanProduct?: LoanProduct;
    }>
  >(
    [
      'loanProduct',
      {
        loan_product_id: loanProductId,
      },
    ],
    gql`
      query LoanProduct($loan_product_id: String!) {
        loanProduct(loan_product_id: $loan_product_id) {
          product_name
        }
      }
    `,
  );
  return loanProduct?.data?.loanProduct?.product_name;
};

export const getLoanBalanceFields = gql`
  {
    id
    balance {
      principal_paid
      interest_paid
      penalty_paid
      fees_paid
      principal_balance
      interest_balance
      fees_balance
      total_paid
      interest_accrued
      interest_capitalised
      establishment_fee
      trust_write_off_amount
      fees_balance_breakdown {
        overdue_interest
        overdue_fees
        overdue_penalty
        ordinary_fees
      }
      fees_paid_breakdown {
        overdue_interest
        overdue_penalty
        ordinary_fees
      }
      fees_applied_breakdown {
        overdue_interest
        overdue_fees
        overdue_penalty
        ordinary_fees
      }
      interest_due
      penalty_due
      fees_due
      fees_due_breakdown {
        overdue_interest
        overdue_fees
        overdue_penalty
        ordinary_fees
      }
      principal_due
      total_due
      days_late
      days_in_arrears
      total_balance
      status
      write_off {
        principal_write_off
        interest_write_off
        penalty_write_off
        fees_write_off
      }
      due_reduction {
        interest_reduced
        penalty_reduced
        fees_reduced
      }
      penalty_balance
      interest_received_in_advance
      fees_applied_breakdown_current_loan_part {
        overdue_interest
        overdue_fees
        overdue_penalty
        ordinary_fees
      }
    }
  }
`;

export const getLoanClosureStatementQuery = gql`
  query LoanClosureDocument($fileId: ID!) {
    loanClosureDocument(file_id: $fileId) {
      file_name
      dataBase64String
    }
  }
`;

export type GetLoanClosureStatementQueryResponse = {
  data: {
    loanClosureDocument: {
      file_name: string;
      dataBase64String: string;
    };
  };
};

export const getDynamicEstablishmentFee = gql`
  query GetEstablishmentFee($loanId: ID!) {
    dynamicEstablishmentFee(loan_id: $loanId)
  }
`;
