import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { connect } from 'react-redux';
import Help from '../../components/help';
import style from '../../styles/base.scss';
import { refreshToken } from '../../routes/Auth/Login/modules/actions';
import LumiSpinner from '../../../common/components/LumiSpinner';
import lumiEnvironment from '../../../lumi.environment';

export default function OnboardingLayout(
  stepsConfig: { key: string; title: string }[],
) {
  return (props: { children: any; location: any }) => {
    const { children, location } = props;
    return (
      <React.Suspense fallback={<LumiSpinner />}>
        <div className={style['signup-container']}>
          <Header steps={stepsConfig} location={location} />
          <div className={style['signup-body']} id='body'>
            <div className={style['signup-content']}>
              {children}
              <Help fixed />
            </div>
          </div>
        </div>
      </React.Suspense>
    );
  };
}
