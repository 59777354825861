import lumiEnvironment from '../../lumi.environment';
// eslint-disable-next-line no-redeclare
/* global analytics */
let _fieldName = field => {
  let specials = ['email', 'phone'];
  return specials.indexOf(field) !== -1 ? '$' + field : field;
};

export function identifySimple(id, props) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  // Setting default props (Google Analytics related)
  const defaultProps = {
    anonymousId: window.analytics?.user()?.anonymousId() || null,
    userId: id || null,
  };
  const returnedProps = { ...props, ...defaultProps };

  // Setting default traits (Google Analytics related)
  let defaultTraits = {
    anonymousId: window.analytics?.user()?.anonymousId() || null,
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  };

  analytics.identify(id, returnedProps, defaultTraits);
}

export function identify(id, fields, data) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  let props;
  fields.forEach(field => {
    if (
      Object.keys(data || {}).indexOf(field) !== -1 &&
      data[field] !== '' &&
      data[field] !== null
    ) {
      props[_fieldName(field)] = data[field];
    }
  });
  if (Object.keys(props || {}).length > 0) {
    // Setting default props (Google Analytics related)
    const defaultProps = {
      anonymousId: window.analytics?.user()?.anonymousId() || null,
      userId: id || null,
    };
    const returnedProps = { ...props, ...defaultProps };

    // Setting default traits (Google Analytics related)
    let defaultTraits = {
      anonymousId: window.analytics?.user()?.anonymousId() || null,
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    };

    analytics.identify(id, returnedProps, defaultTraits);
  } else {
    console.log('no analytics properties to setup');
  }
}
export function track(eventName, properties) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;
  analytics.track(eventName, properties, {
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  });
}

const _trackWithEnv = (eventName, properties) => {
  analytics.track(
    eventName,
    {
      url: window.location.href,
      ...properties,
      env: getEnv(),
      app: lumiEnvironment.APP_NAME,
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
};

export function trackWithEnv(eventName, properties) {
  // check to see if analytics has load, if not, set a timeout to try again
  if (window.analytics?.user) {
    _trackWithEnv(eventName, properties);
  } else {
    setTimeout(() => {
      if (window.analytics?.user) {
        _trackWithEnv(eventName, properties);
      }
    }, 1000);
  }
}

// This is supposed to be a common function?
export function trackAction(action) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  analytics.track(
    'leads',
    {
      action,
      env: getEnv(),
      app: 'admin',
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

export function trackActionByApp(action, optionalMessage) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  analytics.track(
    'leads',
    {
      action,
      env: getEnv(),
      app: lumiEnvironment.APP_NAME,
      message: optionalMessage,
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

export function trackActionAndProperties(name, action, properties) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  analytics.track(
    name,
    {
      action,
      ...properties,
      env: getEnv(),
      app: lumiEnvironment.APP_NAME,
      url: window.location.pathname,
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

export function trackInteractionOnPage(action, properties) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  analytics.track(
    'interactions',
    {
      action,
      ...properties,
      env: getEnv(),
      app: lumiEnvironment.APP_NAME,
      url: window.location.pathname,
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

function getEnv() {
  const url = window.location.href;
  if (url.includes('localhost') || url.includes('staging')) {
    return 'dev';
  }
  return 'prod';
}

export function trackExternalLink(name, location, href, text, attrs) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  analytics.track(
    'interactions',
    {
      name,
      location,
      href,
      text,
      ...attrs,
    },
    {
      integrations: {
        'Google Analytics': {
          clientId: window.analytics?.user()?.anonymousId() || null,
        },
      },
    },
  );
}

export function trackPageView(pageName) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  const props = { pageName: pageName, url: window.location.pathname };
  analytics.track('interactions', props, {
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  });
}

export function trackPage(pageName, properties, traits) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  let props;
  let tra = {};
  if (properties) props = { ...properties };
  if (traits) tra = { ...traits };

  // Setting default traits (Google Analytics related)
  let defaultTraits = {
    integrations: {
      'Google Analytics': {
        clientId: window.analytics?.user()?.anonymousId() || null,
      },
    },
  };

  const returnedTraits = { ...tra, ...defaultTraits };

  analytics.page(pageName, props, returnedTraits);
}

export function alias(id) {
  // Analytics has not been loaded. Guard it from crashing
  if (!window.analytics?.user) return;

  analytics.alias(id);
}
