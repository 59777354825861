import * as React from 'react';

import * as styles from '../sass/components/_dots.scss';

const ProgressDots: React.SFC = () => (
  <div
    role='progressbar'
    className={[styles.loadIcon, styles.center].join(' ')}
  >
    <span />
    <span />
    <span />
  </div>
);

export default ProgressDots;
