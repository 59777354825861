import { BankFeedsReturnedStatus } from '../routes/DocumentCapture/Banking/viewmodel/BankingViewModel';

export class LumiError extends Error {
  constructor(
    message: string,
    private identifier: string,
  ) {
    super(message);
  }
}

export const displayErrors: Partial<Record<BankFeedsReturnedStatus, string>> = {
  [BankFeedsReturnedStatus.InvalidToken]: 'The page has timed out.',
  [BankFeedsReturnedStatus.LoginFail]:
    'Your bank did not accept the username and password',
  [BankFeedsReturnedStatus.Error]:
    'An unexpected error has occurred and the Lumi team has been notified. Please try again, otherwise get in touch with your account representative',
};
