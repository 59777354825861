import React from 'react';
import PropTypes from 'prop-types';
import GeoSuggest from 'react-geosuggest';
import styles from '../sass/components/_input.scss';
import FormInput from './TextField';
import '../sass/components/_geoSuggest.scss';

class GeoSuggestBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }
  focus() {
    this.setState({ focus: true });
  }
  blur(value) {
    this.setState({ focus: !!value });
    // Cover the scenario where Google finds the address but the user doesn't pick it from the list,
    // instead typing it in then exiting from the input.
    if (value) {
      if (this.props.fieldData.value.formatted !== value) {
        this.props.onSuggestNoResults(value);
      }
    }
  }
  render() {
    const { fieldData, label, name, noNesting, onFocus, onBlur, initialValue } =
      this.props;
    let onGeoSuggestChanged = newAddress => {
      if (this.props.onSuggestSelect) {
        this.props.onSuggestSelect(newAddress);
      }
    };
    let onNoSuggestsFound = userInput => {
      if (this.props.onSuggestNoResults) {
        this.props.onSuggestNoResults(userInput);
      }
    };

    const focusClass = this.state.focus ? styles.focus : '';

    if (!noNesting) {
      return (
        <FormInput
          fieldData={fieldData}
          name={name}
          label={label}
          className={focusClass}
          type='text'
        >
          <GeoSuggest
            ref='geoSuggest'
            country={'AU'}
            placeholder=''
            initialValue={((fieldData || {}).value || {}).formatted}
            onFocus={this.focus}
            onBlur={this.blur}
            onSuggestSelect={onGeoSuggestChanged}
            onSuggestNoResults={onNoSuggestsFound}
          />
        </FormInput>
      );
    } else {
      return (
        <GeoSuggest
          country={'AU'}
          placeholder=''
          initialValue={initialValue}
          onFocus={onFocus}
          onBlur={onBlur}
          onSuggestSelect={onGeoSuggestChanged}
          onSuggestNoResults={onNoSuggestsFound}
        />
      );
    }
  }
}

GeoSuggestBox.propTypes = {
  onSuggestSelect: PropTypes.func,
  onSuggestNoResults: PropTypes.func,
  fieldData: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  noNesting: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  initialValue: PropTypes.string,
};

export default GeoSuggestBox;
