/* @flow */
// import Promise from 'bluebird'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as utils from '../../utils';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import SelectField from 'material-ui/SelectField';
import Select from 'react-select';
import MenuItem from 'material-ui/MenuItem';
import Slider from 'material-ui/Slider';
import streetTypes from './streetTypes';
import SlimSlider from './slimSlider';
import InputMask from '../../../common/components/input_mask';
import Grid from '../../../common/components/Grid/Grid';
import layoutStyles from '../../../common/sass/layout/_layout.scss';

import 'react-datepicker/dist/react-datepicker.css';

class Field extends Component {
  nativeEvent(name, value) {
    if (this.props.onChange) {
      const fakeEvent = { target: { name, value: value } };
      this.props.onChange(fakeEvent);
    }
  }

  render() {
    const name = this.props.name;
    const value = this.props.value;
    const disabled = this.props.disabled;

    let props = Object.assign({}, this.props);
    if (props.touched && props.error) {
      // error = <FormError error={fieldData.error} />
    }

    let input;
    const fieldProps = {
      name,
      value,
      errorText: this.props.errorText,
      disabled: props.disabled,
      floatingLabelText: this.props.label,
      onChange: event => this.nativeEvent(name, event.target.value),
    };

    if (props.step) {
      fieldProps.step = props.step;
    }

    if (props.type) {
      fieldProps.type = props.type;
    }

    let adjustedValue = value;
    if (!Number.isInteger(value)) {
      adjustedValue = (value || '').toString();
    }

    if (this.props.type === 'select') {
      input = (
        <SelectField
          value={adjustedValue}
          floatingLabelText={this.props.label}
          floatingLabelFixed
          hintText={this.props.placeholder}
          errorText={this.props.errorText}
          disabled={props.disabled}
          onChange={(event, index, value) => this.nativeEvent(name, value)}
        >
          {this.props.children}
        </SelectField>
      );
    } else if (props.type === 'search-select') {
      input = (
        <div>
          <Select
            errorText={this.props.errorText}
            placeholder={props.placeholder}
            isDisabled={props.disabled}
            isLoading={props.isLoading}
            isMulti={props.multi}
            isSearchable
            options={props.options}
            onChange={value => {
              const val = props.multi ? value : (value || {}).value;
              this.nativeEvent(name, val);
            }}
          />
          <label
            style={{ color: 'red', fontWeight: 'lighter', fontSize: 'smaller' }}
          >
            {this.props.errorText}
          </label>
        </div>
      );
    } else if (props.type === 'range') {
      input = (
        <SlimSlider
          {...Object.assign({}, this.props, fieldProps)}
          onChange={(event, value) => this.nativeEvent(name, value)}
          value={value}
        />
      );
    } else if (props.type === 'yesno') {
      fieldProps.value = fieldProps.value ? 'YES' : '';
      input = (
        <select {...fieldProps}>
          <option value='YES'>Yes</option>
          <option value=''>No</option>
        </select>
      );
    } else if (props.type === 'mask') {
      input = (
        <TextField fullWidth {...fieldProps}>
          <InputMask
            type='string'
            mask={props.mask}
            placeholderChar={props.maskChar}
            keepCharPositions={props.keepCharPositions}
            name={props.name}
            value={value}
            disabled={props.disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
          />
        </TextField>
      );
    } else if (props.type === 'date') {
      const dateObj =
        value !== '' && value !== undefined && value !== null
          ? new Date(value)
          : null;
      input = (
        <DatePicker
          name={name}
          container='inline'
          errorText={this.props.errorText}
          mode='landscape'
          disabled={props.disabled}
          floatingLabelText={this.props.label}
          hintText={this.props.label}
          onChange={(event, value) => this.nativeEvent(name, value)}
          minDate={this.props.minValue}
          maxDate={this.props.maxValue}
          value={dateObj}
        />
      );
    } else if (props.type === 'address') {
      const getProps = (subName, label) => {
        return {
          label,
          name: `${name}.${subName}`,
          value: utils.getRef(value, subName),
          floatingLabelText: label,
          onChange: this.props.onChange,
        };
      };

      const stateOptions = [
        { value: 'NSW', label: 'NSW' },
        { value: 'ACT', label: 'ACT' },
        { value: 'NT', label: 'NT' },
        { value: 'QLD', label: 'QLD' },
        { value: 'SA', label: 'SA' },
        { value: 'TAS', label: 'TAS' },
        { value: 'VIC', label: 'VIC' },
        { value: 'WA', label: 'WA' },
      ];
      const stateValue = utils.getRef(value, 'state');
      const selectedStateValue = { label: stateValue, value: stateValue };

      input = (
        <Grid
          className={layoutStyles.formGrid}
          columnTemplate='1fr 1fr 1fr'
          columnGap='20px'
          rowGap='3px'
        >
          <Field {...getProps('street', 'Street Name')} />
          <Field {...getProps('street_number', 'Street #')} />
          <Field {...getProps('unit_number', 'Unit #')} />
          <Field
            errorText={this.props.errorText}
            {...getProps('city', 'Suburb')}
          />
          <Select
            value={selectedStateValue}
            placeholder={'State'}
            disabled={props.disabled}
            isLoading={props.isLoading}
            searchable
            options={stateOptions}
            onChange={value => {
              return this.nativeEvent(`${name}.state`, (value || {}).value);
            }}
          />
          <Field {...getProps('postal_code', 'Postcode')} />
        </Grid>
      );
    } else {
      input = (
        <TextField
          fullWidth
          {...fieldProps}
          multiLine={this.props.multiLine}
          rows={this.props.rows}
        />
      );
    }
    return input;
  }
}

Field.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.any,
  value: PropTypes.any,
  maxValue: PropTypes.any,
  minValue: PropTypes.any,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.array,
  step: PropTypes.string,
  multiLine: PropTypes.bool,
  rows: PropTypes.number,
  mask: PropTypes.string,
  keepCharPositions: PropTypes.string,
  onBlur: PropTypes.func,
  placeholderChar: PropTypes.string,
  labels: PropTypes.object,
};

Field.defaultProps = {
  multiLine: false,
  rows: 1,
};

export default Field;
