import * as querystring from 'querystring';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useGenerateNewBankingLink } from 'chimera/components/Banking/useGenerateNewBankingLink';
import { friendlyErrorMap } from 'chimera/layouts/viewmodels/DocumentCaptureLayoutViewModel';
import { useDocCapState } from '../../DocumentCaptureViewModel';
import { displayErrors } from '../../../../utils/errorUtils';

type BankingViewModel = [
  {
    referenceId: string;
    setReferenceId: (referenceId: string) => void;
    loading: boolean;
    stages: any;
  },
  (query: string) => Promise<BankingResponse>,
];

export type BankingResponse = {
  success?: boolean;
  error?: string;
  loading?: boolean;
};

export enum BankFeedsReturnedStatus {
  InvalidToken = 'INVALID_TOKEN',
  LoginFail = 'LOGIN_FAIL',
  Complete = 'COMPLETE',
  Error = 'ERROR',
}

export type BankFeedsParsedResponse = {
  status: BankFeedsReturnedStatus;
  errorText: string;
  errorCode: string;
};

export function useBankingViewModel(): BankingViewModel {
  const {
    documentCaptureId,
    updateDocumentCapture,
    isLoading,
    documentCaptureProgress,
  } = useDocCapState();

  const {
    referenceId,
    setReferenceId,
    processSuccessBankingResponse,
    processErrorBankingResponse,
  } = useGenerateNewBankingLink(documentCaptureId);

  const { id } = useParams<{ id: string }>();

  const handleBankingResponse = useCallback(
    async (query: string) => {
      const response: BankingResponse = {};

      if (query) {
        const responseQuery = query.substring(1); // remove the ? from start of query
        const bankfeedsResponse = querystring.parse(
          responseQuery,
        ) as BankFeedsParsedResponse;

        response.success =
          bankfeedsResponse.status === BankFeedsReturnedStatus.Complete;

        if (!response.success) {
          const { errorText, status, errorCode } = bankfeedsResponse;
          response.error = errorText || displayErrors[status];
          await processErrorBankingResponse(errorCode);
        } else {
          // this mutation could still fail so let's catch it
          try {
            await updateDocumentCapture({
              id: id || '',
              body: { stages: [{ name: 'bank-feeds' }] },
            });
            await processSuccessBankingResponse();
          } catch (error: any) {
            response.success = false;
            response.error = error.identifier
              ? (friendlyErrorMap as any)[error.identifier]
              : error.message;
          }
        }
      }
      return response;
    },
    [
      id,
      processErrorBankingResponse,
      processSuccessBankingResponse,
      updateDocumentCapture,
    ],
  );

  return [
    {
      referenceId,
      setReferenceId,
      loading: isLoading,
      stages: documentCaptureProgress?.stages,
    },
    handleBankingResponse,
  ];
}
