import * as Platform from '../utils/platform';
import mapKeys from 'lodash/mapKeys';

export const screenSize = {
  extraSmall: 0,
  small: 1,
  medium: 2,
  large: 3,
};

export const alertType = {
  error: 0,
  info: 1,
  success: 2,
};

export const scrollTop = () => {
  const body = document.getElementById('body');
  if (body) {
    body.scrollTop = 0;
  }
};

export function getScreenSize() {
  let theScreenSize = screenSize.large;
  if (matchMedia('screen and (min-width: 1440px)')) {
    theScreenSize = screenSize.large;
  } else if (matchMedia('screen and (min-width: 970px)')) {
    theScreenSize = screenSize.medium;
  } else if (matchMedia('screen and (min-width: 768px)')) {
    theScreenSize = screenSize.small;
  } else {
    theScreenSize = screenSize.extraSmall;
  }
  return theScreenSize;
}

export function styles(styles) {
  return transformStylePixelValues(styles);
}

export function transformPixelValue(value) {
  return (value * Platform.getScaleFactor()) / 28;
}

export function centerPopup(url, title, w, h) {
  var dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  var dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;

  var width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  var height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  var left = width / 2 - w / 2 + dualScreenLeft;
  var top = height / 2 - h / 2 + dualScreenTop;
  var newWindow = window.open(
    url,
    title,
    'scrollbars=yes, location=no, width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left,
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export function matchMedia(query) {
  return window.matchMedia && window.matchMedia(query).matches;
}

function transformStylePixelValues(style) {
  return mapKeys(style, (value, key) => {
    if (key.indexOf('@media') !== -1) {
      return value;
    }

    if (typeof value === 'object') {
      return transformStylePixelValues(value);
    }

    if (typeof value === 'string') {
      return transformPixelValues(value);
    }

    return value;
  });
}

function transformPixelValues(str) {
  let numberChars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    '.',
    '-',
  ];
  let pxIndex = -1;
  let numberIndex;
  let pxValue;
  let newValue;
  let newValueStr;

  while (true) {
    pxIndex = str.indexOf('px', pxIndex + 1);
    if (pxIndex === -1) {
      break;
    }

    numberIndex = pxIndex - 1;
    while (str[numberIndex] === ' ') {
      numberIndex--;
    }

    if (numberChars.indexOf(str[numberIndex]) === -1) {
      continue;
    }

    while (numberChars.indexOf(str[numberIndex]) !== -1) {
      numberIndex--;
    }

    pxValue = parseFloat(str.substring(numberIndex + 1, pxIndex));
    newValue = transformPixelValue(pxValue);
    newValueStr = newValue.toString();
    str =
      str.substring(0, numberIndex + 1) + newValueStr + str.substring(pxIndex);
    pxIndex += numberIndex + 1 - pxIndex + newValueStr.length;
  }

  return str.replace(/([-\d\.]+)(pl)(\W)/g, '$1px$3');
}

export function commafyNumber(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
}

// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function formatLongNumber(num, decimalPlaces = 0) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e6, symbol: 'm' },
    { value: 1e9, symbol: 'b' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) break;
  }
  return (
    (num / si[i].value).toFixed(decimalPlaces).replace(rx, '$1') + si[i].symbol
  );
}

export function getMonthName(d) {
  var monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return monthNames[d.getMonth()];
}
export function getDayName(d) {
  var dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return dayNames[d.getDay()];
}
