import gql from 'graphql-tag';

export const addressFragment = gql`
  fragment AddressFields on GQL_Address {
    state
    street
    street_number
    unit_number
    postal_code
    formatted
    city
  }
`;

export const settingsDefaultFragment = gql`
  fragment SettingsDefaultFragment on GQL_ProductPolicySettingConfigMinMax {
    max
    min
    default {
      __typename
      ... on DefaultValueSetting {
        preset_value_number
        preset_value_string
        enabled
        fee_calculation_method
        fee_type
        fixed
      }
    }
  }
`;

export const leadFragment = gql`
  fragment LeadFragment on GQL_Lead {
    id
    expires_at
    created_at
    loan_id
    brokerOwner {
      id
      groups {
        id
        group_name
        hubspot_company_id
      }
    }
    lead_expiry_update_events {
      updated_by {
        combined_name
      }
      created_at
      event_type
      notes
      to_expiry_date
    }
  }
`;
