import React, { SyntheticEvent, useState } from 'react';
import { Controller } from 'react-hook-form';
import LumiPicker from '../../../common/components/LumiPicker';
import Field from '../field/InternalField';

type SelectOption = {
  label: string;
  value: string;
  options?: SelectOption[];
};

interface Props {
  pickName: string;
  label?: string;
  fieldName: string;
  type?: string;
  onChange?: any;
  error?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  pickerClassName?: string;
  disabled?: boolean;
  isArea?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  mask?: (string | RegExp)[];
  control: any;
  rules?: any;
  wrapperStyle?: any;
  selectedOption: string;
  options: SelectOption[];
  attachedToField?: boolean;
  isFormPicker?: boolean;
  onBlur?: (args: any) => any;
  format?: (event: SyntheticEvent<HTMLInputElement>) => any;
}

function PickerField(props: Props) {
  const {
    selectedOption,
    options,
    control,
    rules,
    format,
    onBlur,
    pickName,
    fieldName,
    wrapperClassName,
    inputClassName,
    pickerClassName,
    mask,
    error,
    placeholder,
    label,
    type,
    disabled,
    isArea,
    autoFocus,
    attachedToField,
    isFormPicker,
  } = props;
  const [value, setValue] = useState(selectedOption || '');
  const onChange = (e: any) => setValue(e);
  // The value gets stuck on the first selectedOption prop passed if we don't do this
  React.useEffect(() => {
    setValue(props.selectedOption);
  }, [props.selectedOption]);

  const disabledValues = disabled
    ? options.map((option: SelectOption) => option.value)
    : [];

  let PickInput = (
    <LumiPicker
      options={options}
      selectedOption={value}
      onChange={onChange}
      attachedToField={attachedToField}
      isFormPicker={isFormPicker}
      disabledValues={disabledValues}
      pickerClassName={pickerClassName}
    />
  );

  let PickComponent = PickInput;
  if (control) {
    PickComponent = (
      <Controller
        defaultValue={selectedOption}
        as={PickInput}
        name={pickName}
        control={control}
        rules={rules}
        onChange={([event]: [SyntheticEvent<HTMLInputElement>]) => {
          if (onChange) onChange(event);
          return event;
        }}
        onBlur={onBlur}
      />
    );
  }

  const FieldInput = (
    <Field
      name={fieldName}
      type={type}
      inputClassName={`lm-rounded-t-none ${inputClassName}`}
      mask={mask}
      error={error}
      format={format}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  );

  let FieldComponent = FieldInput;
  if (control) {
    FieldComponent = (
      <Controller
        as={FieldInput}
        name={fieldName}
        control={control}
        rules={rules}
        onChange={([event]: [SyntheticEvent<HTMLInputElement>]) => {
          if (format) return format(event);
          return (event.target as HTMLInputElement).value;
        }}
        onBlur={onBlur}
      />
    );
  }
  return (
    <div className={wrapperClassName}>
      <label className='lm-mb-px lm-text-gray-700 lm-text-sm lm-font-medium'>
        {label}
      </label>
      <div>
        {PickComponent}
        {FieldComponent}
      </div>
    </div>
  );
}

export default PickerField;
