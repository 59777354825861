/**
 * @deprecated
BankingViewModel is used instead in chimera
 */
import * as React from 'react';
import { push } from 'react-router-redux';
import * as querystring from 'querystring';
import LumiButton from '../../../../../../common/components/LumiButton';
import { connect } from 'react-redux';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../../DocumentCollectionUtils';
import * as styles from './LandingPage.scss';

import { updateProgress as docCaptureProgress } from '../../../DocumentCaptureActions';
import { displayErrors } from '../../../../../../chimera/utils/errorUtils';

// https://docs.bankstatements.com.au/#returning-control

type Props = {
  document_progress: DocumentCollectionProgress;
  params: Params;
  dispatch: any;
};

type Params = {
  id: string;
};

type State = {
  success?: boolean;
  errorText?: string;
};

class LandingPage extends React.Component<Props & MapDispatchToProps, State> {
  constructor(props: Props & MapDispatchToProps) {
    super(props);
    // Future-proofing. Newer versions of react-router don't parse the query string for us. https://github.com/ReactTraining/react-router/issues/4410
    let responseQuery = location.search;

    const { dispatch, updateProgress, params } = this.props;

    if (responseQuery) {
      if (responseQuery.substring(0, 1) === '?') {
        responseQuery = responseQuery.substring(1);
      }
      const bankFeedsResponse = querystring.parse(responseQuery);
      const bankFeedsStatus = bankFeedsResponse.status as string;
      const stateToSet: State = {
        success:
          bankFeedsResponse.status &&
          bankFeedsStatus.toUpperCase() === 'COMPLETE',
      };

      if (stateToSet.success) {
        updateProgress(params.id, ['bank-feeds']);
      }
      stateToSet.errorText =
        !stateToSet.success && bankFeedsResponse.errorText
          ? bankFeedsResponse.errorText
          : (displayErrors as any)[bankFeedsStatus];

      this.state = {
        ...stateToSet,
      };
    } else {
      this.state = {
        success: true,
      };
    }
  }

  handleNext = () => {
    const { document_progress, params, dispatch } = this.props;
    const path = 'banking_info';
    const nextPath = getNextPath(path, document_progress.stages);
    dispatch(push(`/app/documents/${params.id}/${nextPath}`));
  };

  handleBack = () => {
    const { params, dispatch } = this.props;
    dispatch(push(`/app/documents/${params.id}/banking_info`));
  };

  render() {
    const { errorText, success } = this.state;
    const button = success ? (
      <LumiButton rounded phat label='NEXT' onClick={this.handleNext} />
    ) : (
      <LumiButton rounded phat label='BACK' onClick={this.handleBack} />
    );
    const landingTitle = success ? 'Bank Statements Connected' : 'Error';
    const landingMessage = success
      ? 'Thanks for connecting your bank statements.'
      : `${errorText || displayErrors['ERROR']}`;

    return (
      <div>
        <h2 className='text-center'>{landingTitle}</h2>
        <p className='text-center'>{landingMessage}</p>
        <div className={styles.nextButton}>{button}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    document_progress: state.documentCapture.progress.document_capture_progress,
  };
};

type MapDispatchToProps = {
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
};

const mapDispatchToProps: MapDispatchToProps = {
  updateProgress: docCaptureProgress,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
