import * as React from 'react';
import * as styles from './License.scss';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import * as uploadStyles from '../../../../styles/modules/driver_license_upload.scss';
import LumiButton from '../../../../../common/components/LumiButton';
import FileUploadSingle from '../../../../../common/components/file_upload_single';
import {
  upload,
  serverURL,
  apiFetchRaw,
} from '../../../../../common/utils/fetch_utils';
import { msgError, getAccount } from '../../../../CommonCreators';
import { push } from 'react-router-redux';
import * as icons from '../../../../../common/sass/icons/lumi-icon.scss';
import * as flashStyle from '../../../../styles/components/_flash.scss';
import ProgressSpinner from '../../../../../common/components/ProgressSpinner';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../DocumentCollectionUtils';
import { updateProgress } from '../../DocumentCaptureActions';
import Tick from '../../../../../common/images/tick.svg';
import { getToken } from '../../../../utils/auth';
import * as analyticsUtils from '../../../../utils/analytics';
import * as actions from '../../StakeholderActions';
import { connect } from 'react-redux';

type State = {
  file: any;
  uploaded: boolean;
  canUpload: boolean;
  loading: boolean;
  showUploadBody: boolean;
  documentImageUrl: string;
};

type PropTypes = {
  uploading: boolean;
  uploading_progress: any;
  dispatch: any;
  application: any;
  documentProgress?: DocumentCollectionProgress;
  params: { id: string };
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
  account?: any;
};

const path = 'license';

class License extends React.Component<PropTypes> {
  /**
   * The existing version of the file uploader should allow uploading of files if there is no upload currently in progress.
   * When this component initially loads, we know that there is no upload happening yet, and so 'uploaded' is set to true.
   */
  state: State = {
    uploaded: true,
    canUpload: true,
    file: null,
    loading: false,
    showUploadBody: true,
    documentImageUrl: '',
  };

  async componentDidMount() {
    const { documentProgress, account } = this.props;
    const hasLicense =
      !!documentProgress.stages['drivers-license'].completed_at;
    if (hasLicense) this.setState({ showUploadBody: false });
    analyticsUtils.trackPageView('document_capture_license');
    let file: any = {};
    try {
      const idDocument = account.account_details.id_document_picture;

      if (idDocument) {
        const licenseUrl = `${serverURL}/api/documents/id_documents/${idDocument}?access_token=${getToken()}`;
        this.setState({ documentImageUrl: licenseUrl });
      }
    } catch (error) {
      this.setState({ file: null, uploaded: false, loading: false });
      console.log(error);
    }
  }

  clearFile = () => {
    this.setState({ file: null, uploaded: false });
  };

  toggleUploadBody = () => {
    const { showUploadBody } = this.state;
    this.setState({ showUploadBody: !showUploadBody });
  };

  onUpload = async (file: any) => {
    const { dispatch, account, documentProgress, application } = this.props;

    if (!file || (account && !account.id)) {
      return;
    }
    this.setState({ uploaded: false });

    dispatch(
      actions.uploadIdDocument(
        documentProgress.document_capture_id,
        account.id,
        file,
        application?.loan?.id,
        () => {
          this.setState({ uploaded: true, file, canUpload: false });
          dispatch(
            updateProgress(documentProgress.document_capture_id, [
              'drivers-license',
            ]),
          );
          dispatch(
            getAccount(
              (data: any) => {},
              () => {
                msgError(
                  'Error',
                  'There was an error uploading the file, please try again',
                );
              },
            ),
          );
        },
      ),
    );
  };

  nextStep = () => {
    const { documentProgress, params, dispatch } = this.props;
    const nextPath = getNextPath(path, documentProgress.stages);
    dispatch(push(`/app/documents/${params.id}/${nextPath}`));
  };

  render() {
    const { uploading, uploading_progress, documentProgress } = this.props;
    const {
      uploaded,
      file,
      loading,
      showUploadBody,
      documentImageUrl,
      canUpload,
    } = this.state;
    const hasLicense =
      !!documentProgress.stages['drivers-license'].completed_at;

    const confirmation = file && (
      <div className={styles.confirmation}>
        <div className={flashStyle.flash}>
          <i
            className={[icons['icon-check-circle'], icons['icon-margin']].join(
              ' ',
            )}
          />
          Your ID was successfully uploaded
        </div>
        <div className={uploadStyles.fileList}>
          <div
            className={uploadStyles.image}
            style={{ backgroundImage: `url(${file.preview})` }}
          />
          <div className={uploadStyles.name}>{file.name}</div>
          <div className={uploadStyles.remove}>
            <i
              className={[icons['icon-close'], 'lg-icon clickable'].join(' ')}
              onClick={this.clearFile}
            />
          </div>
        </div>
        <hr className='mb-8' />
      </div>
    );

    const uploadBody = () => {
      if (canUpload) {
        return (
          <div className={styles.fileUploadWrapper}>
            <FileUploadSingle
              uploading={uploading}
              upload_progress={uploading_progress}
              upload_complete={uploaded}
              image={documentImageUrl}
              onFile={this.onUpload}
            />
          </div>
        );
      } else {
        return confirmation;
      }
    };

    let body;
    if (loading) body = <ProgressSpinner />;
    if (!loading && showUploadBody) {
      body = uploadBody();
    } else {
      body = (
        <div style={{ margin: '16px auto' }}>
          <LumiButton
            noAnimate
            secondary
            rounded
            phat
            label='Upload Another'
            onClick={this.toggleUploadBody}
          />
        </div>
      );
    }

    return (
      <div className={layoutStyles.documentCapturePage}>
        <div className={layoutStyles.documentCaptureBox}>
          <div className={layoutStyles.innerBox}>
            <h1>A photo of your license</h1>
            <span>
              To further verify your identity we'll need a photo of your drivers
              license. You can take a photo of your license with a smartphone,
              or scan the license and upload it here. Please ensure the license
              is well lit and the text is clearly visible.
            </span>
            {hasLicense && (
              <div className={styles.alreadyUploaded}>
                <React.Fragment>
                  <Tick /> Uploaded
                </React.Fragment>
              </div>
            )}
            {body}
          </div>
          <div className={layoutStyles.nextButton}>
            {(file || hasLicense) && (
              <LumiButton
                noAnimate
                rounded
                phat
                label='Next'
                onClick={this.nextStep}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    account: state.user.account,
  };
};

export default connect(mapStateToProps)(License);
