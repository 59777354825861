import * as analyticsUtils from '../../../../utils/analytics';
import {
  LOGIN_FAILED,
  LOGGED_SUCCESSFULLY,
  LOGGED_OUT_SUCCESSFULLY,
  TOKEN_REFRESHED,
} from '../../../../redux/modules/user';
import {
  apiFetch,
  rawApiFetch,
  serverURL,
} from '../../../../../common/utils/fetch_utils';
import {
  getApplication,
  getAccountTokens,
  getLoanData,
  msgError,
} from '../../../../CommonCreators';

export function loginError(error) {
  console.log('loggin error:' + error);
  return dispatch => {
    dispatch({ error, type: LOGIN_FAILED });
  };
}

export function loginSuccess(response) {
  return dispatch => {
    dispatch({ response, type: LOGGED_SUCCESSFULLY });
  };
}

export function logoutSuccess(response) {
  return dispatch => {
    dispatch({ response, type: LOGGED_OUT_SUCCESSFULLY });
  };
}

export function refreshTokenSuccess(response) {
  return dispatch => {
    dispatch({ response, type: TOKEN_REFRESHED });
  };
}

export function getLoan(loanId, resolve, reject) {
  return dispatch => {
    dispatch(
      getLoanData(
        loanId,
        loan => {
          console.log('got loan');
          resolve(loan);
        },
        error => {
          console.log('error while getting loan:' + error);
          reject(error);
        },
      ),
    );
  };
}

export function postLegalFile(file, loanId, resolve, reject) {
  let bod = JSON.stringify({ loan_id: loanId });
  return dispatch =>
    apiFetch(
      `${serverURL}/legal/${file}`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: bod,
        bypassJSON: true,
      },
      data => {
        resolve(data);
      },
    ).catch(function (error) {
      console.log('postDirectDebitAuth request failed', error);
      reject(error);
    });
}

function _analyticsIdentify(data) {
  let fields = [
    'email',
    'email_verified',
    'first_name',
    'last_name',
    'middle_name',
    'phone',
    'mobile_verified',
  ];
  analyticsUtils.identify(data.result.id, fields, data.result);
  analyticsUtils.track('leads', {
    action: 'login',
    platform: 'website',
    funnel_step: analyticsUtils.funnel_step(
      'login',
      analyticsUtils.funnel_step_version(),
    ),
    version: analyticsUtils.funnel_step_version(),
  });
}

export function refreshToken(searchString, token, resolve, reject) {
  return dispatch =>
    apiFetch(
      `${serverURL}/api/accounts/refreshToken`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        if (!data.success) {
          console.log(data.error);
          throw new Error(data.error);
        }
        dispatch(refreshTokenSuccess(data));
        _analyticsIdentify(data);
        // dispatch(getAccountTokens(data => {}, err => {
        //     console.log(err);
        // }));
        dispatch(
          getApplication(
            searchString,
            application => {
              if (resolve) {
                resolve(data);
              }
            },
            error => {
              dispatch(loginError(error));
              if (reject) {
                reject(error);
              }
            },
          ),
        );
      },
    ).catch(function (error) {
      console.log('refreshToken request failed', error);
      dispatch(
        msgError(
          'Token Error',
          'Something went wrong, please contact support@lumi.com.au.',
        ),
      );
      dispatch(loginError(error));
      if (reject) {
        reject(error);
      }
    });
}

export function login(userData, resolve, reject) {
  return dispatch => {
    rawApiFetch(
      `${serverURL}/api/accounts/authenticate`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
          password: userData.password,
        }),
      },
      data => {
        if (!(data && data.success) || data.error) {
          throw new Error(data.error);
        }
        _analyticsIdentify(data);
        dispatch(loginSuccess(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('request failed', error);
      dispatch(msgError('Login Error', error.message));
      dispatch(loginError(error));
      reject(error);
    });
  };
}

export function logout() {
  return dispatch => {
    apiFetch(
      `${serverURL}/api/accounts/logout`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      data => {
        dispatch(logoutSuccess(data));
      },
    ).catch(function (error) {
      dispatch(msgError('Logout Error', error.message));
      console.log('request failed', error);
    });
  };
}
