import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  GraphQLResponse,
  usePublicGraphMutation,
} from 'common/utils/graphql_utils';
import {
  markBankingReportAwaitingDataDocumentNode,
  markBankingReportFailedDocumentNode,
} from 'common/modules/actions';
import { displayErrors } from 'chimera/utils/errorUtils';
import { BankFeedsReturnedStatus } from 'chimera/routes/DocumentCapture/Banking/viewmodel/BankingViewModel';
import { BankingReportStatus } from 'common/api/dataTypes/bankingReport';

const BankFeedsThanks = () => {
  const bankfeedsResponse = new URLSearchParams(location.search);
  const status = bankfeedsResponse.get('status');
  const token = bankfeedsResponse.get('referrerCode');
  const errorCode = bankfeedsResponse.get('errorCode');
  const errorText = bankfeedsResponse.get('errorText');

  const success = status === BankFeedsReturnedStatus.Complete;

  const [markBankingReportAwaitingData] = usePublicGraphMutation(
    markBankingReportAwaitingDataDocumentNode,
    { throwOnError: false },
    { noAuth: true },
  );

  const [markBankingReportFailed] = usePublicGraphMutation<
    GraphQLResponse<{
      markBankingReportFailed: {
        success: boolean;
      };
    }>,
    { token: string; status: BankingReportStatus; errorCode: number }
  >(
    markBankingReportFailedDocumentNode,
    { throwOnError: false },
    { noAuth: true },
  );

  useEffect(() => {
    if (success) {
      markBankingReportAwaitingData({ token });
    } else {
      markBankingReportFailed({
        token,
        errorCode: Number(errorCode),
        status: BankingReportStatus.FailedRetrieval,
      });
    }
  }, [
    success,
    token,
    markBankingReportAwaitingData,
    markBankingReportFailed,
    errorCode,
  ]);

  return (
    <div>
      <h2 className='text-center'>
        {success ? 'Bank Statements Connected' : 'Error'}
      </h2>
      <div className='text-center'>
        {success ? (
          'Thanks for connecting your bank statements. Your sales representative will be in touch shortly'
        ) : errorText ? (
          <>
            <p>{errorText}</p>
            <p>
              Please contact your account manager to see what options are
              available.
            </p>
          </>
        ) : (
          displayErrors[status as BankFeedsReturnedStatus]
        )}
      </div>
    </div>
  );
};

export default connect()(BankFeedsThanks);
