import { mergeClasses } from 'common/utils/utils';
import { motion } from 'framer-motion';
import React from 'react';
import { StylesConfig } from 'react-select';
import Shown from '../../layout/shown/Shown';
import ReactSelectComponent from './ReactSelectRenderer';
import { SelectProps } from './Select';

export const internalStyles: StylesConfig = {
  input: provided => ({
    ...provided,
    display: 'flex',
    height: '14px',
    color: '#5D7287',
    padding: '0',
    margin: '0',
    lineHeight: '0.875rem',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? '#A0AEC0' : '#5D7287',
    lineHeight: '0.875rem',
    margin: '0',
    flexShrink: 0,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#A0AEC0',
    lineHeight: '0.875rem',
    margin: '0',
  }),
  option: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    fontSize: '0.875rem',
    border: 'none',
    color: isDisabled ? '#CBD5E0' : '#486586',
    backgroundColor: isFocused ? '#FFF5FD' : '#FFFFFF',
    borderRadius: '4px',
    ':active': isDisabled
      ? {}
      : {
          ...provided[':active'],
          backgroundColor: '#6245B5',
          color: '#FFFFFF',
        },
    cursor: isDisabled ? 'not-allowed' : 'default',
    padding: '10px 16px',
  }),
  menu: provided => ({
    ...provided,
    border: 'none',
    boxShadow: '0px 2px 4px #E7EDF5, 0px 1px 1px #E2E8F0, 0 0 0 1px #CBD5E0',
    borderRadius: '10px',
    zIndex: 9999,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: '10px',
    padding: 0,
  }),
  group: provided => ({
    ...provided,
    padding: '16px 0px 0px',
  }),
  groupHeading: provided => ({
    ...provided,
    color: '#8FA6AA',
    fontSize: '14px',
    padding: '0px 16px 0px',
    margin: '0',
    textTransform: 'none',
    fontFamily: 'AvenirNextLTPro-Demi',
  }),
  multiValue: (provided, { isDisabled }) => ({
    ...provided,
    background: isDisabled ? '#EEF0F5' : 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '32px',
    gap: '10px',
    padding: '9px 12px',
    boxShadow: '0 0 0 1px #CBD5E0',
    margin: '0',
  }),
  multiValueLabel: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? '#A0AEC0' : '#5D7287',
    fontFamily: 'AvenirNextLTPro-Demi',
    fontSize: '14px',
    lineHeight: '14px',
    paddingLeft: '0',
    padding: '0',
  }),
  multiValueRemove: (provided, { isDisabled }) => ({
    ...provided,
    padding: '0',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: isDisabled ? '#CBD5E0' : '#EEF0F5',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#DF4280',
    },
    svg: {
      fill: isDisabled ? '#EEF0F5' : '#718096',
    },
    '&:hover svg': {
      fill: 'white',
    },
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#EEF0F5' : 'white',
    border: 'none',
    boxShadow: '0 0 0 1px #CBD5E0',
    borderRadius: '10px',
    fontSize: '0.875rem',
    color: '#00234B',
    padding: '0px 0px 0px 0px',
    outline: isFocused ? '1px solid #fd55d8' : 'none',
    minHeight: '48px',
  }),
  valueContainer: (provided, { isMulti, hasValue }) => ({
    ...provided,
    padding: isMulti && hasValue ? '5px 6px' : '13px 18px',
    gap: '14px',
  }),
};

const InternalSelect = React.forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const {
      label,
      labelChildren,
      error,
      wrapperClassName = '',
      wrapperStyle,
      animation = {},
      customComponents = {},
      disabled,
    } = props;
    const animateLayout = animation?.layout;
    return (
      <motion.div
        layout={animateLayout}
        style={wrapperStyle}
        className={mergeClasses(
          wrapperClassName,
          'lm-flex lm-flex-col lm-w-full',
          disabled && 'lm-cursor-not-allowed',
        )}
      >
        <Shown when={Boolean(label) || Boolean(labelChildren)}>
          <motion.label
            layout={animateLayout}
            className='lm-text-copy lm-text-sm lm-font-proMedium lm-mb-1'
          >
            {label}
            {labelChildren}
          </motion.label>
        </Shown>
        <ReactSelectComponent
          {...props}
          ref={ref}
          themedStyles={internalStyles}
          customComponents={{ IndicatorSeparator, ...customComponents }}
        />
        {error && (
          <label className='lm-text-red-500 lm-font-medium lm-text-sm lm-mt-1'>
            {(error as { message: string })?.message || error}
          </label>
        )}
      </motion.div>
    );
  },
);

const IndicatorSeparator = (): JSX.Element => null;

export default InternalSelect;
