import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash/bindAll';
import { push } from 'react-router-redux';
import moment from 'moment';
import { reduxForm } from 'redux-form-legacy';
import { saveApplication, msgError } from '../../../../../CommonCreators';
import { mandatoryFieldsValidation } from '../../../../../../common/utils/utils';
import { getNumber } from '../../../../../utils/validators';
import { commafyNumber, scrollTop } from '../../../../../utils/view';
import Base from '../../../../../components/base';
import FormInput from '../../../../../../common/components/TextField';
import FormSelect from '../../../../../components/form_select';
import Button from '../../../../../components/button';
import * as analyticsUtils from '../../../../../utils/analytics';
import GeoSuggestBox from '../../../../../../common/components/geo_suggest_box';
import formStyle from '../../../../../../common/sass/components/_form.scss';
import inputStyle from '../../../../../styles/components/_input.scss';
import icons from '../../../../../../common/sass/icons/lumi-icon.scss';
import cardStyle from '../../../../../../common/sass/components/_card.scss';
import Cash from '../../../../../images/cash.svg';
import Coin from '../../../../../images/coin.svg';
import RadioGroup from '../../../../../components/radio_group';
import styles from './DirectorInfoView.scss';
import { getNextApplicationRoute, getExperimentGroup } from '../../..';
import { parseGeocodingResponse } from '../../../../../../common/utils/google_maps';

export const fields = [
  'drivers_license',
  'drivers_license_state',
  'birth_date',
  'home_address',
  'has_property',
];
export const mandatory = [
  'drivers_license',
  'drivers_license_state',
  'birth_date',
  'home_address',
];

export const dateFormat = 'DD/MM/YYYY';
const stateOptions = [
  { value: '', label: 'Select state...' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'ACT', label: 'Australian Capital Territory' },
  { value: 'NT', label: 'Northern Territory' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'SA', label: 'South Australia' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'WA', label: 'Western Australia' },
];

const initialValues = {};

function loadFromStakeHolder(state) {
  const accountDetails = state.user.application.account_details;
  const additionalInfo = state.user.application.additional_info;
  const driversLicense = accountDetails
    ? accountDetails.id_document_number
    : '';
  const driversLicenseState = accountDetails
    ? accountDetails.id_document_state
    : '';
  const birthDate =
    accountDetails && accountDetails.birth_date
      ? moment(accountDetails.birth_date).format(dateFormat)
      : '';
  const hasProperty = additionalInfo.has_property;
  let address;
  if (accountDetails && accountDetails.home_address) {
    address = accountDetails.home_address;
  } else {
    address = {};
  }
  return {
    drivers_license: driversLicense,
    drivers_license_state: driversLicenseState,
    birth_date: birthDate,
    home_address: address,
    has_property: hasProperty,
  };
}

function asyncValidate(values) {
  return new Promise((resolve, reject) => {
    let errors = {};
    const bdate = moment(values['birth_date'].trim(), 'DD/MM/YYYY', false);
    if (!bdate.isValid() || bdate.isAfter()) {
      errors['birth_date'] = 'Invalid Date';
    }
    if (errors && Object.keys(errors).length > 0) {
      scrollTop();
      reject(errors);
    } else {
      resolve();
    }
  });
}

function validate(values) {
  return mandatoryFieldsValidation(values, mandatory);
}
class DirectorInfo extends Base {
  constructor(props) {
    super(props);
    bindAll(this, 'submit');
    this.onSuggestSelect = this.onSuggestSelect.bind(this);
    this.onSuggestNoResults = this.onSuggestNoResults.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);

    const application = this.props.application || {};
    const additionalInfo = application.additional_info || {};
    this.state = {
      selectedPropertyOption: additionalInfo.has_property,
    };
  }

  async componentWillMount() {
    await analyticsUtils.track('leads', {
      action: 'director_info_started',
      platform: 'website',
      funnel_step: analyticsUtils.funnel_step(
        'director_info',
        analyticsUtils.funnel_step_version(),
      ),
      version: analyticsUtils.funnel_step_version(),
    });

    if (this.props.application.company) {
      return;
    }

    console.log('no company information => Navigating back to business info');
    this.props.dispatch(push('/app/director_info'));
  }

  submit(values, dispatch) {
    return new Promise(async (resolve, reject) => {
      let fn = values.first_name;
      let ln = values.last_name;
      let application = {
        account_details: {
          birth_date: moment.utc(values.birth_date, dateFormat),
          drivers_license_number: values.drivers_license,
          drivers_license_state: values.drivers_license_state,
          legal_first_name: fn,
          legal_last_name: ln,
          home_address: values.home_address,
        },
        additional_info: {
          has_property: this.state.selectedPropertyOption,
        },
      };
      await analyticsUtils.track('leads', {
        action: 'director_info_completed',
        platform: 'website',
        funnel_step: analyticsUtils.funnel_step(
          'director_info',
          analyticsUtils.funnel_step_version(),
        ),
        version: analyticsUtils.funnel_step_version(),
      });
      dispatch(
        saveApplication(
          application,
          result => {
            analyticsUtils.identify(
              result.application.account_id,
              fields,
              application,
            );
            dispatch(
              push(
                getNextApplicationRoute(
                  this.props.location.pathname,
                  getExperimentGroup(),
                ),
              ),
            );
          },
          err => {
            dispatch(msgError('Error', err.message));
          },
        ),
      );
    });
  }

  isSubmitEnabled() {
    const {
      fields: {
        drivers_license,
        drivers_license_state,
        birth_date,
        home_address,
      },
      valid,
    } = this.props;
    let enabled =
      drivers_license.value &&
      drivers_license.value.length >= 2 &&
      drivers_license_state.value !== '' &&
      birth_date.value &&
      home_address.value &&
      ((home_address.value.formatted &&
        home_address.value.formatted.length >= 10) ||
        (home_address.value.street && home_address.value.street.length >= 2)) &&
      valid;

    return enabled;
  }

  onSuggestSelect(suggest) {
    const {
      fields: { home_address },
    } = this.props;
    // TODO: Move this translation to a common lib. https://developers.google.com/maps/documentation/geocoding/intro#Types
    // https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
    if (suggest && suggest.gmaps) {
      let gmapObject = parseGeocodingResponse(suggest.gmaps);

      // https://stackoverflow.com/a/40652622
      home_address.onChange(Object.assign({}, gmapObject));
    }
  }

  onSuggestNoResults(userInput) {
    const {
      fields: { home_address },
    } = this.props;
    home_address.onChange({ street: userInput, formatted: userInput });
  }

  handleOptionChange(event) {
    this.setState({
      selectedPropertyOption: event.target.value,
    });
  }

  render() {
    const {
      fields: {
        drivers_license,
        drivers_license_state,
        birth_date,
        home_address,
        has_property,
      },
      application,
    } = this.props;
    const { handleSubmit, submitting } = this.props;
    const { selectedPropertyOption } = this.state;
    const monthly_turnover = getNumber(application.company.monthly_turnover);
    const preQualifyAmount = Math.max(5000, Math.min(monthly_turnover, 100000));

    let isSubmitEnabled = this.isSubmitEnabled();

    const addressProps = {
      fieldData: home_address,
      onSuggestSelect: this.onSuggestSelect,
      onSuggestNoResults: this.onSuggestNoResults,
      label: 'Home address',
    };

    const driversLicenseInputProps = {
      fieldData: drivers_license,
      label: 'Drivers license number',
      type: 'text',
    };

    const birthdateProps = {
      fieldData: birth_date,
      label: 'Date of birth (ex 28/02/1980)',
      type: 'text',
      keepCharPositions: true,
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    };

    const driversLicenseStateSelectProps = {
      fieldData: drivers_license_state,
      label: 'Drivers license state',
      id: 'business-info-license-state',
      optionsData: stateOptions,
    };

    const propertyOptions = [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ];

    return (
      <div>
        <div
          className={[
            cardStyle.pageCard,
            cardStyle.iconCard,
            'text-center',
            'mb-6 p-5 pl-10 pr-10',
          ].join(' ')}
        >
          <div className={cardStyle.title}>
            <h3 className='mb-0'>Congratulations, you qualify for up to</h3>
            <h1 className='title mb-0 mt-1'>
              ${commafyNumber(preQualifyAmount)}
            </h1>
          </div>
        </div>
        <div className={cardStyle.pageCard}>
          <form
            className={formStyle.container}
            onSubmit={handleSubmit(this.submit)}
          >
            <p className='text-center mb-8'>
              We're almost there. Please fill out the details below to continue
              your application.
            </p>
            <GeoSuggestBox {...addressProps} />
            <div className={inputStyle.wrapper}>
              <FormInput {...birthdateProps} />
              <div className={inputStyle.rightActions}>
                <div className={[icons['icon-calendar'], 'lg-icon'].join(' ')}>
                  <span className={icons['path1']} />
                  <span className={icons['path2']} />
                  <span className={icons['path3']} />
                  <span className={icons['path4']} />
                  <span className={icons['path5']} />
                </div>
              </div>
            </div>

            <FormInput {...driversLicenseInputProps} />
            <FormSelect {...driversLicenseStateSelectProps} />

            <div className={styles.propertyQuestion}>
              <span>Do you own property?</span>
            </div>
            <RadioGroup
              options={propertyOptions}
              className={inputStyle.radio}
              onChange={this.handleOptionChange}
              name='has_property'
              selected={selectedPropertyOption}
              labelClassName={styles.propertyLabel}
              required
            />
            <Button
              disabled={!isSubmitEnabled || submitting}
              loading={submitting}
              label='Next step'
            />
          </form>
        </div>
      </div>
    );
  }
}

DirectorInfo.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  application: PropTypes.object,
};

function mapStateToProps(state) {
  let user = state.user;
  let app = user ? user.application : null;
  return {
    first_name: user ? user.first_name : undefined,
    last_name: user ? user.last_name : undefined,
    application: user ? user.application : undefined,
    initialValues: app ? loadFromStakeHolder(state) : initialValues,
  };
}

export default reduxForm(
  {
    form: 'director_info',
    fields,
    asyncValidate,
    asyncBlurFields: ['birth_date'],
    validate,
    initialValues,
  },
  mapStateToProps,
)(DirectorInfo);
