export const GOT_LINK = 'GOT_LINK';

export default function banking(state = {}, action) {
  switch (action.type) {
    case GOT_LINK:
      return {
        loading: false,
        url: action.response.url,
        account: action.response.account,
      };
    default:
      return state || {};
  }
}
