import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../DocumentCollectionUtils';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import { currencyMaskOptions } from '../../../../../junction/components/field/masks/currency';
import { useForm } from 'react-hook-form';
import Button from '../../../../../junction/components/button/Button';
import Feedback from '../../../../../junction/components/feedback/Feedback';
import {
  useGraphMutation,
  useGraphQuery,
  isQueryLoading,
} from '../../../../../common/utils/graphql_utils';
import Loading from '../../../../../junction/components/loading/Loading';
import Stack from '../../../../../junction/layout/stack/Stack';
import Select from '../../../../../junction/components/select/Select';
import VehicleForm from './VehicleForm';
import PropertyForm from './PropertyForm';
import OtherAssetsForm from './OtherAssetsForm';
import assetFormSchema from '../modules/AssetFormSchema';
import { isEqual } from 'lodash';
import { getSOFPLiteral } from '../modules/assetUtils';
import { updateSOFPAssetsLiteral } from '../../Income/modules/actions';
import { yupResolver } from '@hookform/resolvers';

const path = 'sofp-assets';

type PropTypes = {
  dispatch: any;
  application: any;
  documentProgress?: DocumentCollectionProgress;
  params: { id: string };
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
  account?: any;
  saveApplication: (application: any, callback: (app: any) => void) => void;
  push: (path: string) => void;
};

const typeOptions = [
  { label: 'Vehicle', value: 'vehicle' },
  { label: 'Investment Property', value: 'investment_property' },
  { label: 'Residential Property', value: 'residential_property' },
  { label: 'Other Assets', value: 'other_asset' },
];

const Assets = (props: PropTypes) => {
  const [chosenType, setChosenType] = useState('');
  const [assets, setAssets] = useState([]);
  const {
    data: sofpData,
    status: sofpStatus,
    isFetching,
  } = useGraphQuery(
    [
      'sofp',
      { document_capture_id: props.documentProgress.document_capture_id },
    ],
    getSOFPLiteral,
  );

  useEffect(() => {
    const loadedAssets = sofpData?.data?.getSOFP?.assets || [];
    setAssets([...loadedAssets]);
  }, [sofpData]);

  const [saveSOFP, { status, error }] = useGraphMutation(
    updateSOFPAssetsLiteral,
    {
      throwOnError: true,
    },
  );

  const { control, register, errors, handleSubmit, getValues } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(assetFormSchema),
  });

  const addAsset = (newAsset: any) => {
    if (newAsset?.type?.value) newAsset.type = newAsset?.type?.value;
    setAssets([...assets, newAsset]);
    setChosenType('');
  };

  // We need to pass a function to the formSubmit that returns a function with the asset to remove in its scope
  const removeAsset = (assetToRemove: any) => {
    return (event: any) => {
      event.preventDefault();
      const removeIndex = assets.findIndex((asset: any) =>
        isEqual(asset, assetToRemove),
      );
      const copyOfAssets = [...assets];
      copyOfAssets.splice(removeIndex, 1);
      setAssets(copyOfAssets);
    };
  };

  const createFormBody = useCallback(
    (options: any): any => {
      const {
        type,
        control,
        register,
        formSubmit,
        errors,
        values,
        isDisabled,
      } = options;

      const commonFormProps = {
        control,
        register,
        formSubmit,
        errors,
        values,
        isDisabled,
        type,
      };
      switch (type) {
        case 'vehicle':
          return <VehicleForm {...commonFormProps} />;
        case 'investment_property':
        case 'residential_property':
          return <PropertyForm {...commonFormProps} />;
        case 'other_asset':
          return <OtherAssetsForm {...commonFormProps} />;
        default:
          return null;
      }
    },
    [chosenType, errors],
  );

  const displayCurrentAssets = (assets: any[]): any => {
    if (!assets?.length) return <div>No assets added to list.</div>;
    return assets.map((asset: any) => {
      return createFormBody({
        isDisabled: true,
        formSubmit: removeAsset(asset),
        values: asset,
        type: asset?.type,
      });
    });
  };

  const progressToNextPage = async () => {
    const { documentProgress, updateProgress, params, push } = props;

    const sofpId = sofpData?.data?.getSOFP?.id;

    const nextPath = getNextPath(path, documentProgress.stages);
    const progressThrough = () => {
      updateProgress(documentProgress.document_capture_id, ['sofp-assets']);
      push(`/app/documents/${params.id}/${nextPath}`);
    };

    try {
      await saveSOFP({ request: { id: sofpId, assets } });
      progressThrough();
    } catch (err) {
      return;
    }
  };

  if (isQueryLoading(sofpStatus) || isFetching)
    return <Loading color='purple' />;

  return (
    <div
      className={`${layoutStyles.documentCapturePage} lm-w-1/2 lm-border-t lm-bg-white`}
    >
      <div style={{ maxWidth: '520px' }}>
        <div className='lm-mt-4 lm-max-w-80'>
          <Stack orientation='vertical' spacing='m'>
            {error && (
              <Feedback type='error'>
                There was an error updating your information. Please contact
                your account manager.
              </Feedback>
            )}
            <h1>Assets</h1>
            <Feedback type='lumi'>
              Please add here all your current assets including property,
              vehicles, savings etc. Click on add more if you have more than one
              asset
            </Feedback>
            <div className='lm-flex lm-justify-center lm-mt-4'>
              <Select
                label='Asset type'
                name='type'
                options={typeOptions}
                value={chosenType}
                control={control}
                onChange={(e: any) => setChosenType(e.value)}
              />
            </div>
            <div>
              {createFormBody({
                formSubmit: handleSubmit(addAsset),
                control,
                register,
                errors,
                type: chosenType,
              })}
            </div>
            <h1>Added Assets</h1>
            <Feedback type='lumi'>To save this list, press Next.</Feedback>
            <div>{displayCurrentAssets(assets)}</div>
            <div className='lm-flex lm-justify-center'>
              <Button
                onClick={() => progressToNextPage()}
                className='lm-w-40'
                loading={isQueryLoading(status)}
                disabled={isQueryLoading(status)}
              >
                Next
              </Button>
            </div>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Assets;
