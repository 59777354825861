import { connect } from 'react-redux';

import Financials from '../components/Financials';

function mapStateToProps(state: any) {
  return {
    application: state.user.application,
    profit_progress: state.ui.profit_progress,
    balance_progress: state.ui.balance_progress,
    uploading: state.ui.uploading,
    document_progress: state.documentCapture.progress.document_capture_progress,
    document_capture: state.documentCapture.progress.document_capture,
  };
}

export default connect(mapStateToProps)(Financials);
