import Tick from 'common/images/tick.svg';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { components } from 'react-select';
import { isAutoLayoutChild } from '../../../common/utils/animation_utils';
import { Hidden } from '../../layout/hidden/Hidden';
import Shown from '../../layout/shown/Shown';
import Stack from '../../layout/stack/Stack';
import ValidationError from '../field/ValidationError';
import ReactSelectComponent from './ReactSelectRenderer';
import { SelectProps, StylesConfigWithCSSProperties } from './Select';
import { StylesConfig } from 'react-select';

const { Option } = components;

export const externalStyles = (
  size: 'small' | 'base',
  zIncrement = 0,
  styles?: StylesConfigWithCSSProperties,
): StylesConfig => ({
  control: (provided, { isFocused, hasValue }) => ({
    ...provided,
    paddingLeft: '1rem',
    paddingTop: size === 'base' ? '0.75rem' : '0.25rem',
    paddingBottom: size === 'base' ? '0.75rem' : '0.25rem',
    paddingRight: '0.75rem',
    background: '#F8F9FB',
    borderRadius: '0',
    border: 'none',
    cursor: 'pointer',
    boxShadow:
      !isFocused && !hasValue
        ? 'none'
        : `0 2px 0 0 ${hasValue && !isFocused ? '#10BF8D' : '#FD55D8'}`,
    ...(styles?.control || {}),
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0',
    fontSize: '1rem',
    fontFamily: 'AvenirNextLTPro-Demi',
    caretColor: '#6245B5',
    marginLeft: '0px',
    ...(styles?.valueContainer || {}),
  }),
  placeholder: provided => ({
    ...provided,
    color: '#5D7287',
    margin: '0',
    fontWeight: 400,
    fontFamily: 'AvenirNextLTPro-Medium',
    opacity: 0.6,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...(styles?.placeholder || {}),
  }),
  container: provided => ({
    ...provided,
    width: '100%',
    position: 'relative',
    'z-index': 10 + zIncrement,
    color: '#5D7287',
    ...(styles?.container || {}),
  }),
  indicatorSeparator: provided => ({
    ...provided,
    margin: '0 1rem',
    ...(styles?.indicatorSeparator || {}),
  }),
  indicatorsContainer: provided => ({
    ...provided,
    margin: '0.25rem',
    position: 'relative',
    'z-index': 10,
    paddingLeft: '10px',
    cursor: 'pointer',
    ...(styles?.indicatorsContainer || {}),
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '5px',
    color: '#5D7287',
    position: 'relative',
    'z-index': 10,
    ...(styles?.dropdownIndicator || {}),
  }),
  multiValue: provided => ({
    ...provided,
    background: '#FFFFFF !important',
    fontSize: '0.875rem !important',
    borderColor: '#DBDDE1 !important',
    borderWidth: '1px',
    borderRadius: '20px !important',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    margin: '0.125rem 0.25rem',
    ':first-child': {
      margin: '0.125rem 0.25rem 0.125rem 0',
    },
    ...(styles?.multiValue || {}),
  }),
  multiValueLabel: provided => ({
    ...provided,
    fontSize: '0.875rem !important',
    color: '#476487',
    ...(styles?.multiValueLabel || {}),
  }),
  multiValueRemove: provided => ({
    ...provided,
    borderRadius: '100%',
    height: '20px',
    width: '20px',
    marginTop: 'auto',
    marginBottom: 'auto',
    background: '#EEF0F5',
    marginLeft: '0.5rem',
    ':hover': {
      background: '#DF4280',
      color: 'white',
    },
    ':active': {
      border: '2px solid #FFBC3B',
    },
    ...(styles?.multiValueRemove || {}),
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#A0AEC0',
    background: '#EEF0F5',
    ':hover': {
      background: '#DF4280',
      color: 'white',
    },
    ':active': {
      border: '2px solid #FFBC3B',
    },
    borderRadius: '50%',
    display: 'flex',
    padding: '0px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    ...(styles?.clearIndicator || {}),
  }),
  singleValue: provided => ({
    ...provided,
    color: '#5D7287',
    paddingLeft: '0px',
    margin: '0px',
    ...(styles?.singleValue || {}),
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    cursor: 'pointer',
    backgroundColor: isSelected ? '#FFF5FD' : isFocused ? 'transparent' : '',
    color: isSelected ? '#FD55D8' : '#486586',
    fontFamily: 'AvenirNextLTPro-Medium',
    ':hover': {
      background: '#FFF5FD',
    },
    padding: '0.5rem 1rem',
    ...(styles && styles.option),
  }),
});

const IconOption = (props: any) => {
  return (
    <Option {...props}>
      <Stack orientation='horizontal' justify='between' className='lm-w-full'>
        {props.data.label}
        <Shown when={props.isSelected}>
          <Tick style={{ height: '18px', width: '18px' }} />
        </Shown>
      </Stack>
    </Option>
  );
};

const ExternalSelect = React.forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const {
      label,
      error,
      wrapperClassName,
      wrapperStyle,
      name,
      size = 'base',
      zIncrement,
      isClearable = true,
      styles,
    } = props;
    const { control, errors } = useFormContext();
    let _error = error;
    if (control) {
      _error = errors[name];
    }
    const themedStyles = externalStyles(size, zIncrement || 0, styles);
    const isAutoLayout = isAutoLayoutChild();
    return (
      <Stack
        animation={{ layout: isAutoLayout }}
        spacing='m'
        style={wrapperStyle}
        className={`${wrapperClassName} lm-flex lm-flex-col lm-w-full`}
      >
        <Stack animation={{ layout: isAutoLayout }} className='lm-w-full'>
          <ReactSelectComponent
            {...props}
            themedStyles={themedStyles}
            control={control}
            error={_error}
            ref={ref}
            isClearable={isClearable}
            customComponents={{ Option: IconOption }}
          />
          <div style={{ height: '2px' }} className='lm-w-full lm-bg-copy' />
        </Stack>
        <Hidden when={!error}>
          <ValidationError>{(error as any)?.message || error}</ValidationError>
        </Hidden>
      </Stack>
    );
  },
);

export default ExternalSelect;
