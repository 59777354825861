/**
 *  Name: DashboardIndex
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import IdentityRoute from './IdentityCollection';
import WelcomeRoute from './Welcome';
import ErrorRoute from './Error';
import DocumentCollectionLayout from '../../layouts/DocumentCollectionLayout/DocumentCollectionLayout';
import ConfirmationRoute from './Confirmation';
import GenericDocumentsRoute from './GenericDocuments';
import { documentCaptureRetrieve } from './DocumentCaptureActions';
import ThanksRoute from './Thanks';
import BankingInfo from './BankingInfo';
import Financials from './Financials';
import LicenseRoute from './License';
import About from './AboutYourBusiness';
import LoanInfo from './LoanInfo';
import Debts from './Debts';
import Income from './Income';
import Assets from './Assets';

export default store => ({
  path: 'app/documents/:id',
  component: DocumentCollectionLayout,
  indexRoute: {
    onEnter: documentCaptureRetrieve(store, '/start'),
  },
  childRoutes: [
    WelcomeRoute(store),
    IdentityRoute(store),
    WelcomeRoute(store),
    ConfirmationRoute(store),
    ErrorRoute(store),
    ThanksRoute(store),
    BankingInfo(store),
    About(store),
    Financials(store),
    LicenseRoute(store),
    GenericDocumentsRoute(store),
    LoanInfo(store),
    Income(store),
    Assets(store),
    Debts(store),
  ],
});
