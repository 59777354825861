import { push } from 'react-router-redux';
import { GOT_USER, LOGGED_OUT_SUCCESSFULLY } from '../../redux/modules/user';
import jwtDecode from 'jwt-decode';
import lumiEnvironment from '../../../lumi.environment';

function gotUser(response) {
  return { response, type: GOT_USER };
}

// async action creators
export const getUser = () => {
  return dispatch => {
    const access_token = localStorage.getItem(
      lumiEnvironment.LOCAL_STORAGE_NAME,
    );
    const user = jwtDecode(access_token);
    if (user) {
      try {
        dispatch(gotUser(user));
      } catch (e) {
        console.log(e);
      }
    }
  };
};

export const logout = () => {
  return dispatch => {
    localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_USER_NAME);
    localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
    dispatch({ type: LOGGED_OUT_SUCCESSFULLY });
    dispatch(push('/app/login'));
  };
};
