import ApplicationRoutes from './Application';
import AuthRoutes from './Auth';
// import HomeRoutes from './Homepage';
import DashboardRoutes from './Dashboard';
import DocumentCollectionRoutes from './DocumentCollection';

export default store => [
  {
    path: '/',
    onChange: () => {
      const body = document.getElementById('body');
      if (body) {
        body.scrollTop = 0;
      }
    },
    childRoutes: [
      ...ApplicationRoutes(store),
      DashboardRoutes(store),
      DocumentCollectionRoutes(store),
      AuthRoutes(store),
      // HomeRoutes(store)
    ],
  },
];
