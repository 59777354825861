import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/components/_input.scss';
import icons from '../sass/icons/lumi-icon.scss';
import InputMask from './input_mask';
import { Field } from 'redux-form';
import '../sass/components/_geoSuggest.scss';
import GeoSuggestBox from './geo_suggest_box';
import omit from 'lodash/omit';
import IconButton from '../../junction/components/icon_button/IconButton';
import Cross from '../../junction/assets/remove.svg';

const getFieldRenderer = prop => {
  return fieldData => {
    const {
      style,
      onChange,
      allowCancel,
      icon,
      keepCharPositions,
      label,
      onBlur,
      type,
      children,
      className,
      mask,
      maskChar,
      placeholder,
      disabled,
      onKeyPress,
      extraElement,
    } = prop;
    let focusClass = fieldData.focusClass;
    let error = '';
    const meta = fieldData.meta || fieldData;
    const input = fieldData.input || fieldData;
    const hasIcon = icon ? styles.hasIcon : '';
    const isActive =
      ((input.value && JSON.stringify(input.value) !== '{}') || '')
        .toString()
        .trim() !== '';
    const activeClass = isActive ? styles.active : '';
    const errorClass = error ? styles.error : '';

    if (meta.touched && meta.error) {
      error = meta.error;
    }

    let iconElement;
    if (icon) {
      iconElement = <span className={styles.icon}>{icon}</span>;
    }

    let cancelButton;
    if (allowCancel && isActive) {
      cancelButton = (
        <IconButton
          containerClassName='lm-absolute lm-top-[14px] lm-right-2'
          state='neutral-borderless'
          size='xs'
          icon={
            <Cross
              className='lm-fill-current'
              style={{ height: '8px', width: '8px' }}
            />
          }
          onClick={prop.onCancel}
          stack={false}
        />
      );
    }

    const inputProps = omit(input, [
      'initialValue',
      'onBlur',
      'onUpdate',
      'autofill',
      'autofilled',
      'valid',
      'invalid',
      'dirty',
      'pristine',
      'error',
      'active',
      'touched',
      'visited',
    ]);

    let inputElem = (
      <input
        className={[styles.input, activeClass].join(' ')}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...inputProps}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value);
          }
          if (inputProps.onChange) {
            inputProps.onChange(e);
          }
        }}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
    );

    if (mask) {
      inputElem = (
        <InputMask
          className={[styles.input, activeClass].join(' ')}
          type={type}
          mask={mask}
          disabled={disabled}
          placeholderChar={maskChar}
          keepCharPositions={keepCharPositions}
          {...inputProps}
          onBlur={onBlur}
        />
      );
    } else if (children) {
      inputElem = (
        <div className={[styles.input, activeClass].join(' ')}>{children}</div>
      );
    } else if (type === 'address') {
      inputElem = (
        <div className={[styles.input, activeClass].join(' ')}>
          <GeoSuggestBox
            noNesting
            initialValue={input.value}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            onChange={input.onChange}
            {...prop}
          />
        </div>
      );
    }

    return (
      <div
        className={[
          styles.group,
          errorClass,
          type === 'address' ? focusClass : '',
          hasIcon,
          className,
        ].join(' ')}
        style={style}
      >
        {iconElement}
        {inputElem}
        <label className={styles.label}>{label}</label>
        {cancelButton}
        {!!error && (
          <label className={styles.errorLabel}>
            <i className={icons['icon-exclaimation-triangle']} />
            {error}
          </label>
        )}
        {extraElement}
      </div>
    );
  };
};

class FormInput extends Component {
  constructor(props) {
    super(props);
    this.state = { focusClass: '' };
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  focus() {
    this.setState({ focusClass: styles.focus });
  }

  blur(e, value) {
    if (!value) {
      this.setState({ focusClass: '' });
    } else {
      // Cover the scenario where Google finds the address but the user doesn't pick it from the list,
      // instead typing it in then exiting from the input.
      this.props.onSuggestNoResults(value, true);
    }
  }

  UNSAFE_componentWillMount() {
    this.fieldRenderer = getFieldRenderer(this.props);
  }

  render() {
    const { name, fieldData } = this.props;
    // Support name and fieldData for different versions of redux form
    if (fieldData) {
      return this.fieldRenderer(fieldData);
    } else {
      return (
        <Field
          name={this.props.name}
          required
          component={this.fieldRenderer}
          focusClass={this.state.focusClass}
          onFocus={this.focus}
          onBlur={this.blur}
        />
      );
    }
  }
}

/* eslint-disable react/no-unused-prop-types */
FormInput.propTypes = {
  style: PropTypes.object,
  fieldData: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.element,
  extraElement: PropTypes.element,
  onBlur: PropTypes.func,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.bool,
    PropTypes.shape({
      mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
      pipe: PropTypes.func,
    }),
  ]),
  keepCharPositions: PropTypes.bool,
  maskChar: PropTypes.string,
  onChange: PropTypes.func,
  onSuggestNoResults: PropTypes.func,
};

FormInput.defaultProps = {
  maskChar: ' ',
};

export default FormInput;
