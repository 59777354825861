import 'regenerator-runtime/runtime';

import React from 'react';
import { hydrate, render } from 'react-dom';
import makeRoutes from './routes';
import Root from './containers/Root';
import configureStore from './redux/configureStore';
import getUrlParamaterByName from './utils/auth';
import lumiEnvironment from '../lumi.environment';

// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the key "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.

// We initialize the state with the accessToken and isLoggedIn if there is
// an access token from the localStorage
let initialState = {};

// Here we are going to check the url to see if a JWT has been passed from server
const jwt = getUrlParamaterByName('token');
if (jwt) {
  localStorage.setItem(lumiEnvironment.LOCAL_STORAGE_NAME, jwt);
}
let accessToken = localStorage.getItem(lumiEnvironment.LOCAL_STORAGE_NAME);

if (accessToken) {
  // console.log(`Found access token: ${accessToken}`)
  initialState = { user: { isLoggedIn: true, access_token: accessToken } };
}

const store = configureStore(initialState);

// Now that we have the Redux store, we can create our routes. We provide
// the store to the route definitions so that routes have access to it for
// hooks such as `onEnter`.
const routes = makeRoutes(store);
// Now that redux and react-router have been configured, we can render the
// React application to the DOM!

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<Root routes={routes} store={store} />, rootElement);
} else {
  render(<Root routes={routes} store={store} />, rootElement);
}
