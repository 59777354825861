/** Copied from lumi server */
export interface IAddress {
  street?: string;
  street_number?: string;
  unit_number?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  formatted?: string;
}

// https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
export interface GoogleGeocodingResponse {
  address_components: any[];
  formatted_address?: string;
}

export const parseGeocodingResponse = (
  gmaps: GoogleGeocodingResponse,
): IAddress => {
  let gmapObject: IAddress = {
    formatted: gmaps.formatted_address,
  };
  gmapObject.street = gmaps.address_components.find(address_part => {
    return address_part.types.indexOf('route') > -1;
  }).long_name;

  let streetNumber = gmaps.address_components.find(address_part => {
    return address_part.types.indexOf('street_number') > -1;
  });
  gmapObject.street_number = streetNumber ? streetNumber.long_name : null;

  let unitNumber = gmaps.address_components.find(address_part => {
    return address_part.types.indexOf('subpremise') > -1;
  });
  gmapObject.unit_number = unitNumber ? unitNumber.long_name : null;

  let suburb = gmaps.address_components.find(address_part => {
    return (
      address_part.types.indexOf('locality') > -1 &&
      address_part.types.indexOf('political') > -1
    );
  });
  gmapObject.city = suburb ? suburb.long_name : null;

  let state = gmaps.address_components.find(address_part => {
    return (
      address_part.types.indexOf('administrative_area_level_1') > -1 &&
      address_part.types.indexOf('political') > -1
    );
  });
  gmapObject.state = state.short_name;

  let postCode = gmaps.address_components.find(address_part => {
    return address_part.types.indexOf('postal_code') > -1;
  });
  gmapObject.postal_code = postCode.long_name;

  return gmapObject;
};
