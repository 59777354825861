import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from '../../styles/components/signup_progress.scss';
import number from '../../../common/sass/components/_number.scss';
import * as icons from '../../../common/sass/icons/lumi-icon.scss';

class SignupProgress extends Component {
  getIndexes(path) {
    const steps = this.props.steps.map(x => x.key);
    const pathParts = this.props.location.pathname.split('/');
    const currentPath = pathParts[pathParts.length - 1];

    const pathIndex = steps.indexOf(path);
    const currentPathIndex = steps.indexOf(currentPath);
    console.log(path, currentPath);
    console.log(currentPathIndex, pathIndex);
    return {
      pathIndex,
      currentPathIndex,
    };
  }

  getClass(path) {
    const { pathIndex, currentPathIndex } = this.getIndexes(path);

    if (pathIndex < currentPathIndex) {
      return style.completed;
    } else if (pathIndex === currentPathIndex) {
      return style.active;
    }
  }

  getColor(path) {
    const { pathIndex, currentPathIndex } = this.getIndexes(path);

    if (pathIndex < currentPathIndex) {
      return '#9df3ef';
    } else if (pathIndex === currentPathIndex) {
      return '#ffa200';
    } else {
      return '#875ca8';
    }
  }

  render() {
    const line1Color = this.getColor('loan_info');
    const line2Color = this.getColor('business_info');
    const steps = this.props.steps;
    return (
      <div className={style.container}>
        <div className={this.getClass(steps[0].key)}>
          <div className={number.numberWrapper}>
            <div className={[number.number, style.number].join(' ')}>
              <span>1</span>
              <i className={icons['icon-expand']} />
            </div>
          </div>
          <div className={number.label}>{steps[0].title}</div>
        </div>
        <div className={style.line}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.33 9'>
            <path
              d='M231.67-75.55s.64-.35,1.8-.86'
              transform='translate(-230.17 81.83)'
              fill='none'
              stroke={line1Color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='3'
            />
            <path
              d='M241.38-79A45.57,45.57,0,0,1,252-80.33c10.16,0,13.84,4,21.85,5.47'
              transform='translate(-230.17 81.83)'
              fill='none'
              stroke={line1Color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='3'
              strokeDasharray='4.17 8.34'
            />
            <path
              d='M278-74.38q1,0,2,.05'
              transform='translate(-230.17 81.83)'
              fill='none'
              stroke={line1Color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='3'
            />
          </svg>
        </div>
        <div className={this.getClass(steps[1].key)}>
          <div className={number.numberWrapper}>
            <div className={[number.number, style.number].join(' ')}>
              <span>2</span>
              <i className={icons['icon-expand']} />
            </div>
          </div>
          <div className={number.label}>{steps[1].title}</div>
        </div>
        <div className={style.line}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.33 9.05'>
            <path
              d='M280-77.55s-.63-.1-2-.23'
              transform='translate(-227.17 79.83)'
              fill='none'
              stroke={line2Color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='3'
            />
            <path
              d='M269.21-78.27c-1.64,0-3.48-.07-5.54-.07-10.69,0-17.17,7.64-28.84,5.76'
              transform='translate(-227.17 79.83)'
              fill='none'
              stroke={line2Color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='3'
              strokeDasharray='4.41 8.82'
            />
            <path
              d='M230.55-73.64q-.92-.31-1.88-.69'
              transform='translate(-227.17 79.83)'
              fill='none'
              stroke={line2Color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='3'
            />
          </svg>
        </div>
        <div className={this.getClass(steps[2].key)}>
          <div className={number.numberWrapper}>
            <div className={[number.number, style.number].join(' ')}>
              <span>3</span>
              <i className={icons['icon-expand']} />
            </div>
          </div>
          <div className={number.label}>{steps[2].title}</div>
        </div>
      </div>
    );
  }
}

SignupProgress.propTypes = {
  location: PropTypes.object.isRequired,
  steps: PropTypes.array,
};

export default SignupProgress;
