import React, { Component } from 'react';
import PropTypes from 'prop-types';
import spinnerStyle from '../sass/components/_spinner.scss';

class ProgressSpinner extends Component {
  render() {
    const { small, page, style } = this.props;
    const classes = [spinnerStyle.spinner];

    if (small) {
      classes.push(spinnerStyle.small);
      classes.push(spinnerStyle.pink);
    } else {
      classes.push(spinnerStyle.pageSpinner);
    }

    return <div style={style} className={classes.join(' ')} />;
  }
}

ProgressSpinner.propTypes = {
  small: PropTypes.bool,
  page: PropTypes.bool,
  style: PropTypes.any,
};

export default ProgressSpinner;
