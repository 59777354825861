/* @flow */
// import Promise from 'bluebird'
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Base from './base';
import styles from '../../common/sass/components/_input.scss';
import icons from '../../common/sass/icons/lumi-icon.scss';
import ChevronDown from '../../common/images/chevron-down.svg';

class FormSelect extends Base {
  constructor(props, context) {
    super(props, context);

    this.state = { isDropdownOpen: false };
  }

  render() {
    const { style, fieldData, label, disabled, optionsData } = this.props;
    let value = fieldData.value;

    let error;
    if (fieldData.touched && fieldData.error) {
      error = fieldData.error;
    }

    let options = optionsData.map(data => {
      return (
        <option key={data.value} value={data.value}>
          {data.label}
        </option>
      );
    });

    const activeClass = value ? styles.active : '';
    const errorClass = error ? styles.error : '';

    const props = omit(fieldData, [
      'initialValue',
      'onUpdate',
      'autofill',
      'autofilled',
      'valid',
      'invalid',
      'dirty',
      'pristine',
      'error',
      'active',
      'touched',
      'visited',
    ]);
    return (
      <div>
        <div className={[styles.group, errorClass].join(' ')} style={style}>
          <select
            className={[styles.input, activeClass].join(' ')}
            disabled={disabled}
            {...props}
          >
            <option disabled />
            {options}
          </select>
          <div className={styles.dropdown}>
            <ChevronDown />
          </div>
          <label className={styles.label}>{label}</label>
          {!!error && (
            <label className={styles.errorLabel}>
              <i className={icons['icon-exclaimation-triangle']} />
              {error}
            </label>
          )}
        </div>
      </div>
    );
  }
}

FormSelect.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  fieldData: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  optionsData: PropTypes.array, // [{value: x, label: y}, ...]
};

export default FormSelect;
