import React from 'react';
import Select from '../../../../../junction/components/select/Select';
import Field from '../../../../../junction/components/field/InternalField';
import { Button } from '../../../../../junction/components/button/Button';
import Stack from '../../../../../junction/layout/stack/Stack';
import { currencyMask, numberMask } from '../modules/assetUtils';
import numeral from 'numeral';
import ActionButton from '../../../../../junction/components/action_button/ActionButton';

type Props = {
  control?: any;
  register?: any;
  formSubmit: any;
  errors?: any;
  isDisabled?: boolean;
  values?: any;
};

const OtherAssetsForm = (props: Props) => {
  const { control, register, formSubmit, errors, isDisabled, values } = props;
  const commonProps = {
    control,
    disabled: isDisabled,
  };
  return (
    <form onSubmit={formSubmit}>
      <Stack orientation='vertical' spacing='m'>
        <Select
          label=''
          options={[]}
          name='type'
          wrapperClassName='lm-hidden'
          value='other_asset'
          control={control}
        />
        <Field
          label='Details'
          name='details'
          type='text'
          placeholder={values?.details}
          error={(errors?.details as any)?.message}
          {...commonProps}
        />
        <Field
          label='Quantity'
          name='quantity'
          type='text'
          placeholder={values?.quantity}
          error={(errors?.quantity as any)?.message}
          {...commonProps}
        />
        <Field
          label='Estimated Value'
          name='estimated_value'
          type='text'
          placeholder={
            values?.estimated_value
              ? `$${values?.estimated_value}`
              : '$1,00,000'
          }
          mask={currencyMask}
          format={(event: any) => numeral((event.target as any).value).value()}
          error={(errors?.estimated_value as any)?.message}
          {...commonProps}
        />
        <div className='lm-flex lm-justify-center'>
          {isDisabled && (
            <ActionButton type='submit' isSecondary>
              - Remove Asset
            </ActionButton>
          )}
          {!isDisabled && (
            <ActionButton type='submit' isSecondary>
              + Add to list
            </ActionButton>
          )}
        </div>
      </Stack>
    </form>
  );
};

export default OtherAssetsForm;
