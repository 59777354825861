import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import style from '../../styles/modules/onboarding_header.scss';
import icons from '../../../common/sass/icons/lumi-icon.scss';
import Logo from '../../images/Logo_header_large.svg';
import LumiLogoHorizontal from '../../../common/images/logo/lumi-h.svg';
import SignupProgress from './SignupProgress';

class Header extends Component {
  render() {
    const { location, hideSteps, steps } = this.props;
    const pathParts = location.pathname.split('/');
    const currentPath = pathParts[pathParts.length - 1];
    if (currentPath === 'thanks') return null;
    return (
      <div className={style.container}>
        <div className={style.inner}>
          <div className={style.logo}>
            <LumiLogoHorizontal />
          </div>
          {!hideSteps && (
            <div className={style.progress}>
              <SignupProgress steps={steps} location={location} />
            </div>
          )}
          <div className={style.login}>
            {!hideSteps && currentPath === 'signup' && (
              <Link className={style.continue} to='/app/login'>
                Already started?
                <br />
                <span>Continue here</span>{' '}
                <i className={icons['icon-dropdown']} />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
  hideSteps: PropTypes.bool,
  steps: PropTypes.array,
};

Header.defaultProps = {
  hideSteps: false,
};

export default Header;
