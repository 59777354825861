import {
  apiGet,
  apiPost,
  publicServerURL,
} from '../../../common/utils/fetch_utils';
import { DocumentCaptureProgressStageUpdate } from '../viewmodels/DocumentCaptureLayoutViewModel';
import {
  DocumentCaptureProgress,
  DocumentCapture,
} from 'common/api/dataTypes/documentCapture';

export type GetDocumentCaptureProgressResult = {
  document_capture_progress: DocumentCaptureProgress;
  document_capture: DocumentCapture;
};

export async function getDocumentCaptureProgress(
  key: string,
  documentCaptureId: string,
): Promise<GetDocumentCaptureProgressResult> {
  const response = await apiGet(
    `${publicServerURL}/api/documentCapture/${documentCaptureId}/progress`,
  );

  if (!response || response.error) {
    throw new Error((response || {}).error || 'Unknown error');
  }

  return response.result;
}

export interface IUpdateDocCaptureRequest {
  id: string;
  body: {
    link_opened_at?: Date;
    stages?: DocumentCaptureProgressStageUpdate[];
    completed_at?: Date;
    application_id?: string;
  };
}
/** This doesn't update the entire document capture object, but rather the progress of an individual */
export async function updateDocumentCapture({
  id,
  body,
}: IUpdateDocCaptureRequest) {
  const response = await apiPost(
    `${publicServerURL}/api/documentCapture/${id}/progress`,
    body,
  );
  if (!response || response.error) {
    throw new Error(response?.error);
  }
  return response;
}
