import React, { useMemo, useCallback, useEffect, useState } from 'react';

import * as styles from './ExtraInfoView.scss';
import cardStyle from '../../../../../common/sass/components/_card.scss';
import LumiForm from '../../../../../common/components/LumiForm';
import LumiButton from '../../../../../common/components/LumiButton';
import { mapFields } from '../../../../../common/utils/utils';
import LumiField from '../../../../../common/components/LumiField';
import { saveApplication } from '../../../../CommonCreators';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '../../../../components/button';
import * as analyticsUtils from '../../../../utils/analytics';

type Props = {
  application: any;
} & MapDispatchToProps;

const extraInfoForm = 'extra-info-form';

const mapStateToProps = (state: any) => {
  return {
    application: state.user.application || {},
  };
};

type MapDispatchToProps = {
  saveApplication?: (params: any, callback?: any) => void;
  push: (path: string) => void;
};

const mapDispatchToProps: MapDispatchToProps = {
  saveApplication,
  push,
};

export function ExtraInfoView(props: Props) {
  const { application, saveApplication, push } = props;
  const initialValues = { customer_notes: application.customer_notes };
  const [savingApplication, setApplicationSaving] = useState(false);

  const handleSubmit = useCallback(async data => {
    setApplicationSaving(true);
    await saveApplication({ customer_notes: data.customer_notes }, async () => {
      setApplicationSaving(false);
      /* Copied from
       * https://bitbucket.org/lumiengineering/lumi_clients/commits/68cb745cbbf67760e05818e49fc6a2f2b2a73819#chg-src/client/routes/Application/MobileVerification/components/MobileVerificationView.js
       */
      await analyticsUtils.track('leads', {
        action: 'signup_completed',
        platform: 'website',
        funnel_step: analyticsUtils.funnel_step(
          'extra_info',
          analyticsUtils.funnel_step_version(),
        ),
        version: analyticsUtils.funnel_step_version(),
      });
      push(`/app/thankyou`);
    });
  }, []);

  useEffect(() => {
    analyticsUtils.track('leads', {
      action: 'extra_info_started',
      platform: 'website',
      version: analyticsUtils.funnel_step_version(),
    });
  }, []);

  const customerNotesField = {
    name: 'customer_notes',
    placeholder:
      'E.g. I run a cafe in Surry Hills and I am looking to purchase a coffee machine to expand my business...',
    component: LumiField,
    extras: { isArea: true },
    wrapperClassName: styles.field,
    validate: (value: any) => (!value ? 'Required' : undefined),
  };

  return (
    <div className={cardStyle.pageCard}>
      <LumiForm
        name={extraInfoForm}
        initialValues={initialValues}
        warnUnsaved={false}
        onSubmit={handleSubmit}
        clientForm
        touchOnBlur={false}
        className={styles.formContainer}
      >
        <h1>A bit about your business...</h1>
        <span>
          Please describe your business and its operations. Important details to
          include:
        </span>
        <div className={styles.requirementBullet}>
          What does your business do?
        </div>
        <div className={styles.requirementBullet}>
          How will you use the funds?
        </div>
        {mapFields([customerNotesField])}
        <Button disabled={savingApplication} label='Next' type='submit' />
      </LumiForm>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraInfoView);
