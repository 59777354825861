import React from 'react';
import Field from '../../../../../junction/components/field/InternalField';
import PickerField from '../../../../../junction/components/picker_field/PickerField';
import Button from '../../../../../junction/components/button/Button';
import Select from '../../../../../junction/components/select/Select';
import Stack from '../../../../../junction/layout/stack/Stack';
import { currencyMask } from '../modules/assetUtils';
import numeral from 'numeral';
import ActionButton from '../../../../../junction/components/action_button/ActionButton';

type Props = {
  type?: string;
  control?: any;
  register?: any;
  formSubmit: any;
  errors?: any;
  values?: any;
  isDisabled?: boolean;
};

const propertyStatusOptions = [
  { label: 'Mortgage', value: 'MORTGAGE' },
  { label: 'Fully Paid', value: 'FULLY_PAID' },
];

const PropertyForm = (props: Props) => {
  const { control, register, formSubmit, type, errors, values, isDisabled } =
    props;
  const commonProps = {
    control,
    disabled: isDisabled,
  };

  return (
    <form onSubmit={formSubmit}>
      <Stack orientation='vertical' spacing='m'>
        <Select
          label=''
          options={[]}
          name='type'
          wrapperClassName='lm-hidden'
          value={type}
          control={control}
        />
        <Field
          label='Address'
          type='text'
          name='address'
          error={(errors?.address as any)?.message}
          placeholder={values?.address}
          {...commonProps}
        />
        <Field
          label='Estimated Value'
          type='text'
          name='estimated_value'
          mask={currencyMask}
          error={(errors?.estimated_value as any)?.message}
          format={(event: any) => numeral((event.target as any).value).value()}
          placeholder={
            values?.estimated_value ? `$${values?.estimated_value}` : '$0'
          }
          {...commonProps}
        />
        <PickerField
          label='Status'
          pickName='property_status'
          fieldName=''
          inputClassName='lm-hidden'
          options={propertyStatusOptions}
          selectedOption={values?.property_status || 'MORTGAGE'}
          error={(errors?.property_status as any)?.message}
          isFormPicker
          {...commonProps}
        />
        <div className='lm-flex lm-justify-center'>
          {isDisabled && (
            <ActionButton type='submit' isSecondary>
              - Remove Asset
            </ActionButton>
          )}
          {!isDisabled && (
            <ActionButton type='submit' isSecondary>
              + Add to list
            </ActionButton>
          )}
        </div>
      </Stack>
    </form>
  );
};

export default PropertyForm;
