/**
 *  Name: ConnectBankAccount
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { getLoanData } from '../../../utils/auth';

import ConnectBankAccount from '../../../../common/components/ConnectBankAccount';

export default (store: any) => ({
  path: 'connect_bank_account',
  enableHeader: true,
  progress: { step: 5, percentage: 90 },
  onEnter: getLoanData(store),
  getComponent(nextState: any, cb: any) {
    cb(null, ConnectBankAccount);
  },
});
