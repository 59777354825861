import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { apiMiddleware } from 'redux-api-middleware';
import { updateLocation } from './modules/location';
import { routerMiddleware as RouterMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';

export default function configureStore(initialState) {
  // Compose final middleware and use devtools in debug environment
  // deep merging is problematic, helping it.
  if (!initialState) initialState = {};
  let mergedUser = Object.assign(
    {},
    initialState.user || {},
    (window.___INITIAL_STATE__ || {}).user || {},
  );
  let mergedInitialState = Object.assign(
    {},
    initialState,
    window.___INITIAL_STATE__,
  );
  mergedInitialState = Object.assign(mergedInitialState, { user: mergedUser });

  const middlewares = [apiMiddleware, thunk];
  const enhancers = [];

  // if (__DEV__) {
  //     const devToolsExtension = window.devToolsExtension;
  //     if (typeof devToolsExtension === 'function') {
  //         enhancers.push(devToolsExtension());
  //     }
  // }

  const routerMiddleware = RouterMiddleware(browserHistory);
  middlewares.push(routerMiddleware);

  // Create final store and subscribe router in debug env ie. for devtools
  const store = createStore(
    rootReducer(),
    mergedInitialState,
    compose(
      applyMiddleware(...middlewares),
      applyMiddleware(routerMiddleware),
      applyMiddleware(...enhancers),
    ),
  );

  store.unsubscribeHistory = browserHistory.listen(updateLocation(store));
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default;

      store.replaceReducer(nextRootReducer(store.asyncReducers));
    });
  }
  return store;
}
