import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import style from '../../styles/base.scss';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { sideBarOpen: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  toggleMenu() {
    this.setState({ sideBarOpen: !this.state.sideBarOpen });
  }
  render() {
    return (
      <div className={style.bodyWrapper}>
        <div className={style.bodyContainer}>
          <div className={style.bodyInner} id='body'>
            <div className={style.content}>{this.props.children}</div>
          </div>
        </div>
        <Header onOpen={this.toggleMenu} />
        <Notifications notifications={this.props.notifications} />
      </div>
    );
  }
}

DashboardLayout.propTypes = {
  children: PropTypes.element.isRequired,
  notifications: PropTypes.array,
};

export default connect(state => ({
  notifications: state.notifications,
}))(DashboardLayout);
