import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

function warnUnsavedFormChanges(WrappedComponent, formName, errorMessage) {
  class WarnUnsavedFormChanges extends React.Component {
    constructor() {
      super();
      this.setClean = this.setClean.bind(this);
    }

    componentDidUpdate() {
      // eslint-disable-next-line
      const { isFormDirty, warnUnsaved } = this.props;
      if (warnUnsaved !== undefined) {
        if (warnUnsaved) {
          this._promptUnsavedChange(isFormDirty);
        }
      } else {
        this._promptUnsavedChange(isFormDirty);
      }
    }

    componentWillUnmount() {
      window.onbeforeunload = undefined;
    }

    setClean() {
      this._promptUnsavedChange(false);
    }

    _promptUnsavedChange(
      isUnsaved = false,
      leaveMessage = errorMessage ||
        'You have unsaved changes on the page. Are you sure you want to leave?',
    ) {
      // Detecting page transition (prevent leaving by setting true)
      // for React Router version > 2.4
      // eslint-disable-next-line
      const routes = this.props.routes;
      // eslint-disable-next-line
      this.props.router.setRouteLeaveHook(
        routes[routes.length - 1],
        nextRoute => {
          if (!isUnsaved) return true;
          return isUnsaved && confirm(leaveMessage);
        },
      );

      // Detecting browser close
      if (!isUnsaved) {
        window.onbeforeunload = undefined;
      } else {
        window.onbeforeunload = isUnsaved && (() => leaveMessage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} setClean={this.setClean} />;
    }
  }

  const mapStateToProps = (state, props) => {
    let _formName = formName;
    if (!formName && props.name) _formName = props.name;
    return {
      isFormDirty: isDirty(_formName)(state),
    };
  };

  return withRouter(connect(mapStateToProps, null)(WarnUnsavedFormChanges));
}

export default warnUnsavedFormChanges;
