import { motion } from 'framer-motion';
import { get as lodashGet, isEmpty } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { mergeClasses } from '../../../common/utils/utils';
import Container from '../../layout/container/Container';
import { Hidden } from '../../layout/hidden/Hidden';
import Stack from '../../layout/stack/Stack';
import FieldLabel from './FieldLabel';
import InputRenderer, { FieldProps } from './InputRenderer';

const InternalField = React.forwardRef<any, FieldProps>((props, ref) => {
  const {
    animation = {},
    className,
    error: _error,
    label,
    labelChildren,
    name,
    typeLabel,
    wrapperClassName,
    wrapperStyle,
    type,
    icon,
  } = props;

  const { inputClassName = '', ...propsToPassAlong } = props;

  const formContext = useFormContext();
  let error = _error;
  if (!isEmpty(formContext?.errors)) {
    error = lodashGet(formContext.errors, name)?.message;
  }

  const animateLayout = animation?.layout;

  return (
    <Container
      animation={{ layout: animateLayout }}
      className={[className, type === 'hidden' ? 'lm-hidden' : undefined]
        .filter(Boolean)
        .join(' ')}
    >
      <Stack
        spacing='xs'
        style={wrapperStyle}
        className={`lm-relative lm-w-full lm-flex ${wrapperClassName}`}
      >
        <Hidden when={!label && !typeLabel}>
          <motion.div
            layout={animateLayout}
            className='lm-flex lm-relative lm-justify-between'
          >
            <FieldLabel layout={animateLayout} htmlFor={name}>
              {label}
              {labelChildren}
            </FieldLabel>

            {typeLabel && (
              <label className='lm-text-gray-600 lm-text-sm lm-font-normal'>
                {typeLabel}
              </label>
            )}
          </motion.div>
        </Hidden>

        <motion.div layout={animateLayout} className='lm-relative lm-w-full'>
          <Hidden when={!icon}>
            <motion.div
              className={`lm-absolute lm-flex lm-top-0 lm-bottom-0 lm-right-0 lm-pr-6 lm-items-center lm-text-subheading`}
            >
              {icon}
            </motion.div>
          </Hidden>
          <InputRenderer
            innerRef={ref}
            inputClassName={mergeClasses('lumi_field', inputClassName)}
            {...propsToPassAlong}
          />
        </motion.div>

        {error && (
          <span className='lm-text-red-500 lm-font-medium lm-text-sm lm-text-left'>
            {error}
          </span>
        )}
      </Stack>
    </Container>
  );
});

export default InternalField;
