import React, { FC } from 'react';
import ProgressSpinner from '../../../common/components/ProgressSpinner';
import { Size } from '../../types';
import { getClassName } from '../../utils';
import Stack from '../../layout/stack/Stack';
import { Spacing } from '../../types';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: any) => any;
  id?: string;
  /**
   * className attached to the button
   */
  className?: string;
  /**
   * className attached to the button container
   */
  containerClassName?: string;
  style?: any;
  disabled?: boolean;
  isLoading?: boolean;
  /**
   * @default 'primary'
   */
  state?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'neutral'
    | 'destructive'
    | 'neutral-borderless'
    | 'neutral-square';
  icon?: React.ReactNode;

  /**
   * @default m
   */
  size?: Size;
  /**
   * stack: wrap in a stack component
   * @default true
   */
  stack?: boolean;
  spacing?: Spacing;
};

const coreClass = `
    lm-outline-none
    lm-font-medium
    lm-text-sm
    lm-leading-sm
    focus:lm-outline-none
    lm-focus-border
    active:lm-shadow-button-active
`;

const primaryClasses = `
    lm-rounded-full
    lm-text-white
    lm-bg-pink-500
    active:lm-bg-pink-600
    hover:lm-bg-pink-600
`;

const secondaryClasses = `
    lm-text-white
    lm-rounded-full
    lm-bg-base-purple
    active:lm-bg-purple-800
    hover:lm-bg-purple-800
`;

const tertiaryClasses = `
    lm-text-base-navy
    lm-rounded-full
    lm-bg-white
    lm-focus-yellow
    active:lm-shadow-button-active
    active:lm-bg-gray-300
    hover:lm-bg-base-navy
    hover:lm-text-white
    lm-border
    lm-border-base-navy
`;

const neutralClasses = `
    lm-bg-white
    lm-rounded-full
    lm-border-gray-400
    lm-border-2
    hover:lm-bg-gray-100
    active:lm-bg-gray-100
`;

const neutralSquareClasses = `
    lm-bg-white
    lm-border-gray-400
    lm-border-2
    hover:lm-bg-gray-100
    active:lm-bg-gray-100
    lm-rounded-xl
`;

const neutralBorderlessClasses = `
    lm-bg-gray-200
    lm-text-gray-600
    hover:lm-text-white
    hover:lm-bg-base-red
    lm-rounded-full
`;

const destructiveClasses = `
    lm-bg-white
    lm-text-base-navy
    lm-text-center
    hover:lm-text-white
    hover:lm-bg-base-red
    lm-rounded-full
`;

const disabledClasses = `
    lm-cursor-not-allowed
    lm-rounded-full
`;

const IconButton: FC<Props> = props => {
  const {
    icon,
    type,
    onClick,
    id,
    containerClassName,
    className,
    style,
    disabled,
    state = 'primary',
    isLoading,
    size = 'm',
    children,
    stack = true,
    spacing = 'r',
  } = props;

  let classes = coreClass;

  classes += getClassName(size, [
    ['xs', 'lm-p-1'],
    ['s', 'lm-p-2'],
    ['m', 'lm-p-3'],
    ['l', 'lm-p-4'],
  ]);

  classes += getClassName(state, [
    ['primary', primaryClasses],
    ['secondary', secondaryClasses],
    ['tertiary', tertiaryClasses],
    ['neutral', neutralClasses],
    ['neutral-borderless', neutralBorderlessClasses],
    ['neutral-square', neutralSquareClasses],
    ['destructive', destructiveClasses],
  ]);

  if (disabled) classes += disabledClasses;
  const _className = `${classes} ${className}`;

  const buttonContainerContent = (
    <React.Fragment>
      <button
        disabled={disabled}
        className={_className}
        style={style}
        type={type}
        id={id}
        onClick={onClick}
      >
        {!isLoading && icon}
        {isLoading && (
          <ProgressSpinner style={{ width: '14px', height: '13px' }} />
        )}
      </button>
      <span className='lm-font-demi lm-text-base-blue'>{children}</span>
    </React.Fragment>
  );

  if (stack) {
    return (
      <Stack
        orientation='horizontal'
        spacing={spacing}
        className={`lm-items-center lm-cursor-pointer ${containerClassName}`}
      >
        {buttonContainerContent}
      </Stack>
    );
  }

  return <div className={containerClassName}>{buttonContainerContent}</div>;
};

export default IconButton;
