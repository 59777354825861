import { serverURL, apiGet, apiPost } from '../../../common/utils/fetch_utils';
import { Dispatch, Action } from 'redux';
import { getApplication, getAccount } from '../../CommonCreators';
import { checkTokenOrLogout } from '../../utils/auth';
import {
  GET_DOCUMENT_CAPTURE_PROGRESS_STARTED,
  GET_DOCUMENT_CAPTURE_PROGRESS_SUCCESS,
  GET_DOCUMENT_CAPTURE_PROGRESS_ERROR,
  IDocumentCaptureProgress,
} from '../../redux/modules/documentCapture';

import { endPath } from './DocumentCollectionUtils';
export interface IBankfeedsErrorResponse {
  status?: string;
  referrerCode?: string;
  errorCode?: string;
  errorText?: string;
}

export interface IBankfeedsWebhookResponse {}

/** This doesn't update the entire document capture object, but rather the progress of an individual */
function updateDocumentCapture(documentCaptureId: string, body: any) {
  return async (dispatch: Dispatch<Action>) => {
    const response = await apiPost(
      `${serverURL}/api/documentCapture/${documentCaptureId}/progress`,
      body,
    );
    dispatch(getDocumentCaptureProgressSuccess(response.result));
  };
}

export function linkOpened(documentCaptureId: string) {
  return updateDocumentCapture(documentCaptureId, {
    link_opened_at: new Date(),
  });
}

export function submitDocCapture(documentCaptureId: string) {
  return updateDocumentCapture(documentCaptureId, {
    completed_at: new Date(),
  });
}

export function updateProgress(documentCaptureId: string, stages: string[]) {
  return updateDocumentCapture(documentCaptureId, {
    stages,
  });
}

export function getDocumentCaptureProgressStarted() {
  return { type: GET_DOCUMENT_CAPTURE_PROGRESS_STARTED };
}

export function getDocumentCaptureProgressSuccess(
  response: IDocumentCaptureProgress,
) {
  return { type: GET_DOCUMENT_CAPTURE_PROGRESS_SUCCESS, response };
}

export function getDocumentCaptureProgressError(error: string) {
  return { type: GET_DOCUMENT_CAPTURE_PROGRESS_ERROR, error };
}

export function getDocumentCaptureProgress(
  documentCaptureId: string,
  callback: (progress: IDocumentCaptureProgress) => void,
  reject: (err: Error) => void,
) {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(getDocumentCaptureProgressStarted());
    try {
      const response = await apiGet(
        `${serverURL}/api/documentCapture/${documentCaptureId}/progress`,
      );
      if (!response || response.error) {
        throw new Error((response || {}).error || 'Unknown error');
      }
      dispatch(getDocumentCaptureProgressSuccess(response.result));
      if (callback) callback(response.result);
    } catch (err) {
      dispatch(getDocumentCaptureProgressError(err.message));
      if (reject) reject(err);
    }
  };
}

const retrieveApplication = (dispatch: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch(
      getApplication(
        location.search,
        (data: any) => {
          resolve(data);
        },
        (err: Error) => {
          reject(err);
        },
      ),
    );
  });
};

const retrieveAccountDetails = (dispatch: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch(
      getAccount((data: any) => {
        resolve(data);
      }, reject),
    );
  });
};

const retrieveDocumentCaptureProgress = (
  dispatch: any,
  docCaptureId: string,
): Promise<IDocumentCaptureProgress> => {
  return new Promise((resolve, reject) => {
    dispatch(
      getDocumentCaptureProgress(
        docCaptureId,
        data => {
          resolve(data);
        },
        reject,
      ),
    );
  });
};

export const documentCaptureRetrieve = (
  store: any,
  redirectRoute?: string,
  path?: string,
) => {
  return async (
    nextState: any,
    replace: (route: string | any) => void,
    callback: () => void,
  ) => {
    const docCaptureId = nextState.params.id;
    const s = store.getState();
    const dispatch = store.dispatch;
    const promises = [];
    try {
      checkTokenOrLogout(replace, nextState, s.user.isLoggedIn, callback);

      if (!s.user.application) {
        promises.push(retrieveApplication(dispatch));
      }

      if (!s.user.account) {
        promises.push(retrieveAccountDetails(dispatch));
      }

      if (!s.documentCapture || !s.documentCapture.progress) {
        promises.push(retrieveDocumentCaptureProgress(dispatch, docCaptureId));
      }
      let documentCaptureProgressRes = (s.documentCapture || {}).progress;
      if (promises.length) {
        const results = await Promise.all(promises);
        documentCaptureProgressRes = results[results.length - 1];
      }

      const documentProgress =
        documentCaptureProgressRes.document_capture_progress;
      if (documentProgress.completed && path !== 'thanks') {
        replace(`/app/documents/${docCaptureId}/${endPath}`);
      }

      if (redirectRoute) {
        replace(`/app/documents/${docCaptureId}${redirectRoute}`);
      }

      callback();
    } catch (err) {
      console.error(err);
      replace({
        pathname: `/app/documents/${docCaptureId}/error`,
        state: { message: err.message || '' },
      });
      callback();
    }
  };
};
