import React from 'react';
import * as styles from './Welcome/components/Welcome.scss';
import * as layoutStyles from '../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';

export const welcomeApplicationCopy = (
  firstName: string,
  requirementsElements: any,
  otherRequirementsElements: any,
) => {
  return (
    <div className={layoutStyles.innerBox}>
      <h1>{`Hi ${firstName} 👋`}</h1>
      <span>
        We just need a few more details and documents to complete your loan
        application.
      </span>
      <div className={styles.whatWeNeed}>
        <span>To continue processing your application we'll need:</span>
        {[...requirementsElements, ...otherRequirementsElements]}
      </div>
      <span style={{ marginTop: '24px' }}>
        We use advanced data protection to ensure your personal and financial
        details are kept safe.
      </span>
    </div>
  );
};

export const welcomeHardshipCopy = (
  firstName: string,
  requirementsElements: any,
  otherRequirementsElements: any,
) => {
  return (
    <div className={layoutStyles.innerBox}>
      <h1>{`Hi ${firstName}`}</h1>
      <span>Thanks for submitting your hardship request to us.</span>
      <div className={styles.whatWeNeed}>
        <span>In order for us to process your request, we'll need:</span>
        {[...requirementsElements, ...otherRequirementsElements]}
      </div>
      <span style={{ marginTop: '24px' }}>
        We use advanced data protection to ensure your personal and financial
        details are kept safe.
      </span>
    </div>
  );
};

export const thanksApplicationCopy = (
  <span>
    Thanks for passing on your details. We’ll be in touch shortly to progress
    your application. If you have any questions in the meantime please get in
    touch with us at sales@lumi.com.au or give us a call on 1300 00 LUMI.
  </span>
);

export const thanksHardshipCopy = (
  <span>
    Thanks for passing on your details. Your request will be processed by our
    hardship team as soon as possible. Please be patient and we will contact you
    as soon as we have an update. If you have any questions in the meantime
    please get in touch with us at hardship@lumi.com.au.
  </span>
);
