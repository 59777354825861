import React from 'react';

import Ai from '../../common/images/fileicons/ai.svg';
import Avi from '../../common/images/fileicons/avi.svg';
import Css from '../../common/images/fileicons/css.svg';
import Csv from '../../common/images/fileicons/csv.svg';
import Dbf from '../../common/images/fileicons/dbf.svg';
import Doc from '../../common/images/fileicons/doc.svg';
import File from '../../common/images/fileicons/file.svg';
import Fla from '../../common/images/fileicons/fla.svg';
import Html from '../../common/images/fileicons/html.svg';
import Iso from '../../common/images/fileicons/iso.svg';
import Jpg from '../../common/images/fileicons/jpg.svg';
import Js from '../../common/images/fileicons/js.svg';
import Json from '../../common/images/fileicons/json.svg';
import Mp3 from '../../common/images/fileicons/mp3.svg';
import Mp4 from '../../common/images/fileicons/mp4.svg';
import Pdf from '../../common/images/fileicons/pdf.svg';
import Png from '../../common/images/fileicons/png.svg';
import Ppt from '../../common/images/fileicons/ppt.svg';
import Psd from '../../common/images/fileicons/psd.svg';
import Rtf from '../../common/images/fileicons/rtf.svg';
import Svg from '../../common/images/fileicons/svg.svg';
import Txt from '../../common/images/fileicons/txt.svg';
import Xls from '../../common/images/fileicons/xls.svg';
import Xml from '../../common/images/fileicons/xml.svg';
import Zip from '../../common/images/fileicons/zip.svg';

export const AttachmentIcons = {
  ai: <Ai />,
  avi: <Avi />,
  css: <Css />,
  csv: <Csv />,
  dbf: <Dbf />,
  doc: <Doc />,
  docx: <Doc />,
  file: <File />,
  fla: <Fla />,
  html: <Html />,
  iso: <Iso />,
  jpg: <Jpg />,
  jpeg: <Jpg />,
  js: <Js />,
  json: <Json />,
  mp3: <Mp3 />,
  mp4: <Mp4 />,
  pdf: <Pdf />,
  png: <Png />,
  ppt: <Ppt />,
  pptx: <Ppt />,
  psd: <Psd />,
  rtf: <Rtf />,
  svg: <Svg />,
  txt: <Txt />,
  xls: <Xls />,
  xlsx: <Xls />,
  xml: <Xml />,
  zip: <Zip />,
  other: <File />,
};

export function getIcon(filename) {
  if (filename) {
    const nameParts = filename.split('.');
    const extension = nameParts[nameParts.length - 1];

    if (AttachmentIcons[extension]) {
      return AttachmentIcons[extension];
    }
  }
  return AttachmentIcons.file;
}
