import {
  apiPost,
  publicServerURL,
} from '../../../../../common/utils/fetch_utils';
import { LumiError } from '../../../../utils/errorUtils';
import { IPrecompletedSteps } from '../../../OnboardingOP/layout/modules/OnboardingOPActions';
import gql from 'graphql-tag';

export type AccountInfo = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  consent: boolean;
};

export type IAccountDetails = {
  is_business_owner?: boolean;
};

export type IAdditionalInfo = {
  time_in_business_months?: number;
  trading_time_months?: number;
  is_business_owner?: boolean;
};

export type ICompany = {
  monthly_turnover: number;
  industry_code: string;
  company_number: string;
  company_name: string;
};

export interface ILoanInfoFormValues {
  loan_product?: string;
  loan_amount?: number;
  loan_term_months?: number;
  loan_purpose?: string[];
  // LEN-1873
  do_you_qualify?: DoYouQualify | boolean;
  do_you_qualify_lux?: boolean;
  requalify?: Requalify;
}

export type DoYouQualify = {
  sales?: boolean;
  abn?: boolean;
  citizen?: boolean;
};

export type Requalify = {
  trading_time_months?: number;
  has_abn?: 'has-active-abn' | 'start-new-business' | 'business-not-registered';
};

export type IExperimentValues = {
  loan?: ILoanInfoFormValues;
  additional_info?: IAdditionalInfo;
  company?: ICompany;
  account_details?: IAccountDetails;
  // A flag saved to the local storage to indicate that the application doesn't qualify for a loan
  unqualified_lead?: boolean;
};

export type ISegmentValues = {
  flow_name?: string;
};

export type ApplicationInfo = {
  loan?: ILoanInfoFormValues;
  additional_info?: IAdditionalInfo;
  company?: ICompany;
  account_details?: IAccountDetails;
  // A flag saved to the local storage to indicate that the application doesn't qualify for a loan
  unqualified_lead?: boolean;
  account_info?: AccountInfo;
  qualify_for_covid_kickstarter?: boolean;
  covid_industry?: string;
  precompleted_stages?: IPrecompletedSteps[];
  segment?: ISegmentValues;
};

export const mapApplicationRequest = (app: ApplicationInfo) => {
  return {
    company: {
      monthly_turnover: app.company?.monthly_turnover,
      industry_code: app.company?.industry_code,
      company_number: app.company?.company_number,
      company_name: app.company?.company_name,
    },
    loan: {
      amount: app.loan?.loan_amount,
      repayment_installments:
        app.loan?.loan_term_months &&
        Math.round(app.loan?.loan_term_months * 4.345), // convert to weeks
      repayment_period_unit: 'WEEKS',
      reason_for_loan: app.loan?.loan_purpose,
    },
    additional_info: {
      time_in_business_months: app.additional_info?.time_in_business_months,
      trading_time_months: app.additional_info?.trading_time_months,
      is_business_owner: app.additional_info?.is_business_owner,
    },
    customer_selected_product: app.loan?.loan_product,
  };
};

export async function updateApplication(
  id: string,
  application: ApplicationInfo,
) {
  const body = mapApplicationRequest(application);
  const response = await apiPost(
    `${publicServerURL}/api/application/${id}`,
    body,
  );
  if (!response || !response.success) {
    throw new LumiError(response.error, response.identifier);
  }
  return response;
}

export type AccountResponse = {
  id: string;
  application_id: string;
  access_token: string;
  email: string;
};

export const mapAccountRequest = (
  account: AccountInfo,
  prospect_token?: string,
  referrerId?: string,
  partner?: string,
) => {
  return {
    first_name: account.first_name,
    last_name: account.last_name,
    phone: account.phone,
    email: account.email,
    password: account.password,
    email_disabled: false,
    credit_consented: account.consent,
    prospect_token,
    source: referrerId,
    referrer: referrerId, // If there is a partner config, then attribute to that partner
    partner: partner,
  };
};

export async function signup(
  account: AccountInfo,
  unqualified_lead: boolean = false,
  prospect_token?: string,
  referrerId?: string, // If this is a referrer branded page, then attribute to the broker
  partner?: string,
): Promise<AccountResponse> {
  const body = mapAccountRequest(account, prospect_token, referrerId, partner);
  const response = await apiPost(
    `${publicServerURL}/api/accounts`,
    { ...body, unqualified_lead },
    null,
    { noAuth: true },
  );
  if (!response || !response.success) {
    const error = new LumiError(response.error, response.identifier);
    throw error;
  }
  return response?.result;
}

export const requalifyDeal = gql`
  mutation RequalifyDeal($id: ID!) {
    requalifyDeal(id: $id) {
      id
    }
  }
`;
