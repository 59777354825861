// these are the weights (Unit is 1 hour) that are defined to the DocumentCaptureProgressIncentiveExperiment
// the numbers are used are just arbitrary, refer to
// See the LF-2566 card in JIRA for tabular view of the weights.
// As the user completes these document capture requirements, the time
// to their application will reduce by the weight for that requirement.
const DocumentCollectionFieldWeights: any = {
  'drivers-license': 0.5,
  'date-of-birth': 0.5,
  'has-property': 0.3,
  'other-loans': 0.3,
  'veda-consent': 0.5,
  'bank-feeds': 2,
  'loan-amount': 0.1,
  'loan-purpose': 0.2,
  about: 0.5,
  'time-in-business': 0.1,
  'drivers-license-number': 1,
  abn: 1,
};

const roundHalf = (num: number) => {
  return Math.round(num * 2) / 2;
};

// configuration of fields which belong to a path in document capture
const PathFieldMap: any = {
  identity: [
    'drivers-license-number',
    'veda-consent',
    'has-property',
    'date-of-birth',
    'has-property',
    'abn',
  ],
  banking_info: ['bank-feeds'],
  license: ['drivers-license'],
  about: ['about', 'time-in-business'],
  loan_info: ['loan-amount', 'loan-purpose'],
};

export const getRemainingOutcomeTimeByPath = (path: string, stages: any) => {
  const fieldsInPath = PathFieldMap[path] || [];
  const remainingTime = fieldsInPath.reduce(
    (cumTime: number, fieldKey: string) => {
      if (!Object.keys(stages).includes(fieldKey)) return cumTime;
      if (stages[fieldKey].completed_at) return cumTime;
      cumTime = cumTime + (DocumentCollectionFieldWeights[fieldKey] || 0);
      return cumTime;
    },
    0,
  );

  return roundHalf(remainingTime);
};

export const getCompletedTime = (stages: any) => {
  const completedTime = Object.keys(stages).reduce(
    (cumTime: number, currentStage: string) => {
      if (stages[currentStage].completed_at)
        cumTime = cumTime + (DocumentCollectionFieldWeights[currentStage] || 0);
      return cumTime;
    },
    0,
  );
  return completedTime;
};

export const getTotalRemainingTime = (stages: any) => {
  const completedTime = Object.keys(stages).reduce(
    (cumTime: number, currentStage: string) => {
      cumTime = cumTime + (DocumentCollectionFieldWeights[currentStage] || 0);
      return cumTime;
    },
    0,
  );
  return completedTime;
};
