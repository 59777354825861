import { useCallback, useState } from 'react';
import {
  GraphQLResponse,
  usePublicGraphMutation,
} from 'common/utils/graphql_utils';
import {
  markBankingReportAwaitingDataDocumentNode,
  markBankingReportFailedDocumentNode,
} from 'common/modules/actions';
import { getUser } from 'common/utils/auth_utils';
import { BankingReportStatus } from 'common/api/dataTypes/bankingReport';

export const useGenerateNewBankingLink = (prefix?: string) => {
  const user = getUser();
  const accountId = (user.account_id || user.id)!;

  const referenceIdLocalStorageKey = `${
    prefix || accountId
  }_banking_referenceId`;

  const [referenceId, setReferenceIdState] = useState(
    localStorage.getItem(referenceIdLocalStorageKey) || accountId,
  );

  const setReferenceId = (referenceId: string) => {
    setReferenceIdState(referenceId);
    localStorage.setItem(referenceIdLocalStorageKey, referenceId);
  };

  const [markBankingReportAwaitingData] = usePublicGraphMutation<
    GraphQLResponse<{
      markBankingReportAwaitingData: {
        success: boolean;
      };
    }>,
    { token: string }
  >(markBankingReportAwaitingDataDocumentNode);

  const [markBankingReportFailed] = usePublicGraphMutation<
    GraphQLResponse<{
      markBankingReportFailed: {
        success: boolean;
      };
    }>,
    { token: string; status: BankingReportStatus; errorCode: number }
  >(markBankingReportFailedDocumentNode);

  const processSuccessBankingResponse = useCallback(async () => {
    localStorage.removeItem(referenceIdLocalStorageKey);
    await markBankingReportAwaitingData({ token: referenceId });
  }, [markBankingReportAwaitingData, referenceId, referenceIdLocalStorageKey]);

  const processErrorBankingResponse = useCallback(
    async (errorCode: string) => {
      localStorage.removeItem(referenceIdLocalStorageKey);
      await markBankingReportFailed({
        token: referenceId,
        status: BankingReportStatus.FailedRetrieval,
        errorCode: Number(errorCode),
      });
    },
    [markBankingReportFailed, referenceId, referenceIdLocalStorageKey],
  );

  return {
    referenceIdLocalStorageKey,
    referenceId,
    setReferenceId,
    processSuccessBankingResponse,
    processErrorBankingResponse,
  };
};

export enum Flows {
  TOPUP = 'topup',
}
