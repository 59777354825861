import React, { FC, useState, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, Switch } from 'react-router-dom';

export const ElevatedMountAnimation = {
  initial: {
    opacity: 0,
    transform: 'scale(0.95)',
  },
  animate: {
    opacity: 1,
    transform: 'scale(1)',
  },
  exit: {
    opacity: 0,
    transform: 'scale(0.95)',
  },
  transition: {
    type: 'spring',
    mass: 0.1,
    stiffness: 100,
  },
};

export const MotionStub = {
  initial: {},
  animate: {},
  exit: {},
  transition: {},
};

export const MountAnimation = {
  initial: {
    opacity: 0,
    transform: 'scale(0.95)',
  },
  animate: {
    opacity: 1,
    transform: 'scale(1.0)',
  },
  exit: {
    opacity: 0,
    transform: 'scale(0.95)',
  },
  transition: {
    type: 'spring',
    mass: 0.1,
    stiffness: 100,
    damping: 20,
  },
};

type FramerAnimation = {
  initial: any;
  animate: any;
  exit: any;
  transition: any;
};

type MountTransitionProps = {
  animation: FramerAnimation;
  className?: string;
};

/**
 * An abstraction component for framer-motions motion.div
 * which allows a cleaner API for developers.
 */
export const MountTransition: FC<MountTransitionProps> = ({
  children,
  className,
  animation,
}) => {
  return (
    <motion.div className={className} {...animation}>
      {children}
    </motion.div>
  );
};

type AnimatedRoutesProps = {
  /**
   * Exit before enter refers to the motion capable route
   * to animate its unmount before continuing to route
   */
  exitBeforeEnter?: boolean;
  /**
   * If false, will mean that the initial animation will
   * not execute and a direct appearance of the route
   * will occur regardless of its own initial animation.
   */
  initial?: boolean;
};

/**
 * A wrapper for react-router-dom which will allow
 * all of its subroutes to be registered as motion
 * capable html elements which can be monitored
 * for mount and unmount hooks for animation
 */
export const AnimatedRoutes: FC<AnimatedRoutesProps> = ({
  children,
  exitBeforeEnter = true,
  initial = true,
}) => {
  const location = useLocation();

  const [pathname, setPathname] = useState(location?.pathname);

  useEffect(() => {
    if (pathname !== location.pathname) setPathname(location?.pathname);
  }, [pathname]);

  return (
    <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
      <Switch location={location} key={pathname}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};

const AutoLayoutContext = React.createContext(false);
export const isAutoLayoutChild = () => useContext(AutoLayoutContext);

export const AutoLayout: FC<unknown> = ({ children }) => {
  return (
    <AutoLayoutContext.Provider value>{children}</AutoLayoutContext.Provider>
  );
};
