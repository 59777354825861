import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import icons from '../../common/sass/icons/lumi-icon.scss';
import style from '../styles/components/_alert_box.scss';
import buttonStyle from '../../common/sass/components/_button.scss';
import { DIALOG_CLOSE } from '../redux/modules/alert';

class AlertBox extends Component {
  constructor() {
    super();
    this.close = this.close.bind(this);
  }
  close() {
    this.props.dispatch({ type: DIALOG_CLOSE });
  }
  render() {
    const { type, title, open, message } = this.props;
    const openClass = open ? style.open : '';

    return (
      <div className={[style.wrapper, openClass].join(' ')}>
        <div className={style.overlay} />
        <div className={style.box}>
          <div className={[style.header, style[type]].join(' ')}>
            <div className={style.title}>{title}</div>
            <div className={style.close} onClick={this.close}>
              <i className={icons['icon-mobile-cross']} />
            </div>
          </div>
          <div className={style.body}>
            <p>{message}</p>
          </div>
          <hr />
          <div className={style.actions}>
            <button className={buttonStyle.flatButton} onClick={this.close}>
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AlertBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
};

function mapStateToProps(state) {
  const { type, title, message, open } = state.alert;
  return {
    type,
    title,
    message,
    open,
  };
}

export default connect(mapStateToProps)(AlertBox);
