import React from 'react';

import Clap from '../../../../../../common/images/lumi-clap.svg';

import * as styles from '../../components/SignupView.scss';
type Props = {};

export default function Thanks(props: Props) {
  return (
    <div className={styles.thanksPage}>
      <Clap />
      <div className={styles.content}>
        <h1 className='sm-padded'>Thanks for your request</h1>
        <span className={styles.copy}>
          One of our team members will be in touch with you shortly to discuss
          your application. If you have any questions, you can also reach us on{' '}
          <b>1300 00 LUMI</b>. Our business hours are 8AM-7PM Monday to Friday.
        </span>
        <a className='mt-3' href='https://www.lumi.com.au'>
          Click here to go back to our homepage
        </a>
      </div>
    </div>
  );
}
