import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cardStyle from '../../../../../common/sass/components/_card.scss';
import { getUser, environment } from '../../../../../common/utils/auth_utils';

// Not to be confused with the ThankyouView or tThanks view
class DashboardPage extends Component {
  render() {
    const { routeParams } = this.props;
    if (routeParams.step === 'thanks') {
      const user = getUser();
      if (user.has_loc) {
        const isLocal = window.location.href.includes('localhost');
        if (isLocal) {
          window.location.replace(`${environment.LOCAL_CHIMERA}/hub/login`);
          return null;
        }
        window.location.pathname = '/hub/login';
        return null;
      }
    }
    const user = getUser();
    if (user.has_loc) {
      const isLocal = window.location.href.includes('localhost');
      if (isLocal) {
        window.location.replace(`${environment.LOCAL_CHIMERA}/hub/login`);
        return null;
      }
      window.location.pathname = '/hub/login';
      return null;
    } else {
      // same as block below, could be simplified
      return (
        <div
          className={[cardStyle.pageCard, 'text-center p-5 pl-10 pr-10'].join(
            ' ',
          )}
        >
          <h2 className='mb-0'>Your application is in process!</h2>
          <h3 className='mt-3'>&nbsp;</h3>
          <p className='mt-6 mb-10 max-width'>
            We’ve received your information and are currently processing it.
            Feel free to reach out to us on 1300 00 LUMI or sales@lumi.com.au to
            get further help
          </p>
        </div>
      );
    }
  }
}

DashboardPage.propTypes = {
  routeParams: PropTypes.object,
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(DashboardPage);
