import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './actions';
import LumiLogoHorizontal from '../../../common/images/logo/lumi-h.svg';
import Account from '../../images/account.svg';
import style from '../../styles/modules/_header.scss';
import icons from '../../../common/sass/icons/lumi-icon.scss';

class Header extends Component {
  constructor() {
    super();
    this.home = this.home.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(actions.getUser());
    this.fixed = document.getElementById('fixed');
    this.fixed.addEventListener('touchmove', this.preventScroll, false);
  }
  componentWillUnmount() {
    if (this.fixed) {
      this.fixed.removeEventListener('touchmove', this.preventScroll, false);
    }
  }
  preventScroll(e) {
    e.preventDefault();
  }
  home() {
    // this.props.dispatch(push('/broker/dashboard'));
  }
  logout() {
    this.props.dispatch(actions.logout());
  }
  render() {
    const user = this.props.user || {};
    return (
      <div className={style.header} id='fixed'>
        <div className={style.nav} onClick={this.props.onOpen}>
          <span className={icons['icon-nav']} />
        </div>
        <div className={style.logo} onClick={this.home}>
          <LumiLogoHorizontal />
        </div>
        <div className={style.middle}>
          <span>
            Hello{!user.first_name ? '' : ' '}
            {user.first_name}, welcome to Lumi.
          </span>
        </div>
        <div className={style.account}>
          <div className={style.body}>
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div className={style['mt-1']}>
              <a onClick={this.logout} className='accent'>
                logout
              </a>
            </div>
          </div>
        </div>
        <div className={style.image}>
          <Account />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    currentPath: state.location
      ? state.location.pathname
      : window.location.pathname,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Header);
