import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

const Column = ({ gridRow, gridColumn, style, className, children }) => {
  const newStyle = {
    gridRow,
    gridColumn,
    className,
    ...style,
  };

  // Remove the null/undefined values from the style object
  const removedNull = _.pickBy(newStyle, _.identity);

  return <div style={removedNull}>{children}</div>;
};

Column.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  gridRow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gridColumn: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Column;
