import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from '../sass/components/_input.scss';
import Field from '../../admin/components/SlimForm/Field';
import icons from '../sass/icons/lumi-icon.scss';
import { multiSelectStyles } from '../components/LumiSelect';

const getFieldRenderer = prop => {
  const { placeholder, onChange, value, options } = prop;

  return fieldData => {
    const meta = fieldData.meta || fieldData;
    let error = '';
    if (meta.fieldData.touched && meta.fieldData.error) {
      error = meta.fieldData.error;
    }
    const errorClass = error ? styles.error : '';
    const renderItem = (
      <Select
        isMulti
        closeMenuOnSelect={false}
        className={styles.multiSelect}
        styles={multiSelectStyles}
        value={value}
        onChange={onChange}
        fieldData={fieldData}
        options={options}
        placeholder={placeholder}
      />
    );

    return (
      <div className={styles.multiSelectWrapper}>
        {!error && <label className={styles.activeLabel}>{placeholder}</label>}
        {renderItem}
        {!!error && (
          <label className={styles.errorLabel}>
            <i className={icons['icon-exclaimation-triangle']} />
            {error}
          </label>
        )}
      </div>
    );
  };
};

class MultiSelect extends Component {
  componentWillMount() {
    this.fieldRenderer = getFieldRenderer(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.fieldRenderer = getFieldRenderer(nextProps);
  }
  render() {
    const { fieldData, options } = this.props;
    if (fieldData) {
      return this.fieldRenderer({ fieldData });
    } else {
      return (
        <Field
          name={this.props.placeholder}
          required
          component={this.fieldRenderer}
        />
      );
    }
  }
}

MultiSelect.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  fieldData: PropTypes.object,
};

export default MultiSelect;
