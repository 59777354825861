/**
 *  Name: BankingInfo
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import LandingRoute from './BankingInfoLanding';
import React from 'react';

export default (store: any) => ({
  path: 'banking_info',
  onEnter: documentCaptureRetrieve(store),
  childRoutes: [LandingRoute(store)],
  indexRoute: {
    getComponent(nextState: any, cb: any) {
      const BankingInfo = React.lazy(
        () => import('./containers/BankingInfoContainer'),
      );
      cb(null, BankingInfo);
    },
  },
});
