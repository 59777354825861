const streets = [
  { key: 'ACCS', name: 'Access' },
  { key: 'CRST', name: 'Crest' },
  { key: 'ALLY', name: 'Alley' },
  { key: 'CRSS', name: 'Cross' },
  { key: 'ALWY', name: 'Alleyway' },
  { key: 'CRSG', name: 'Crossing' },
  { key: 'AMBL', name: 'Amble' },
  { key: 'CRD', name: 'Crossway' },
  { key: 'ANCG', name: 'Anchorage' },
  { key: 'COWY', name: 'Crossroad' },
  { key: 'APP', name: 'Approach' },
  { key: 'CUWY', name: 'Cruiseway' },
  { key: 'ARC', name: 'Arcade' },
  { key: 'CDS', name: 'Cul-de-sac' },
  { key: 'ART', name: 'Artery' },
  { key: 'CTTG', name: 'Cutting' },
  { key: 'AVE', name: 'Avenue' },
  { key: 'DALE', name: 'Dale' },
  { key: 'BASN', name: 'Basin' },
  { key: 'DELL', name: 'Dell' },
  { key: 'BCH', name: 'Beach' },
  { key: 'DEVN', name: 'Deviation' },
  { key: 'BEND', name: 'Bend' },
  { key: 'DIP', name: 'Dip' },
  { key: 'BLK', name: 'Block' },
  { key: 'DSTR', name: 'Distributor' },
  { key: 'BVD', name: 'Boulevard' },
  { key: 'DR', name: 'Drive' },
  { key: 'BRCE', name: 'Brace' },
  { key: 'DRWY', name: 'Driveway' },
  { key: 'BRAE', name: 'Brae' },
  { key: 'EDGE', name: 'Edge' },
  { key: 'BRK', name: 'Break' },
  { key: 'ELB', name: 'Elbow' },
  { key: 'BDGE', name: 'Bridge' },
  { key: 'END', name: 'End' },
  { key: 'BDWY', name: 'Broadway' },
  { key: 'ENT', name: 'Entrance' },
  { key: 'BROW', name: 'Brow' },
  { key: 'ESP', name: 'Esplanade' },
  { key: 'BYPA', name: 'Bypass' },
  { key: 'EST', name: 'Estate' },
  { key: 'BYWY', name: 'Byway' },
  { key: 'EXP', name: 'Expressway' },
  { key: 'CAUS', name: 'Causeway' },
  { key: 'EXTN', name: 'Extension' },
  { key: 'CTR', name: 'Centre' },
  { key: 'FAWY', name: 'Fairway' },
  { key: 'CNWY', name: 'Centreway' },
  { key: 'FTRK', name: 'Fire Track' },
  { key: 'CH', name: 'Chase' },
  { key: 'FITR', name: 'Fire Trail' },
  { key: 'CIR', name: 'Circle' },
  { key: 'FLAT', name: 'Flat' },
  { key: 'CLT', name: 'Circlet' },
  { key: 'FOLW', name: 'Follow' },
  { key: 'CCT', name: 'Circuit' },
  { key: 'FTWY', name: 'Footway' },
  { key: 'CRCS', name: 'Circus' },
  { key: 'FSHR', name: 'Foreshore' },
  { key: 'CL', name: 'Close' },
  { key: 'FORM', name: 'Formation' },
  { key: 'CLDE', name: 'Colonnade' },
  { key: 'FWY', name: 'Freeway' },
  { key: 'CMMN', name: 'Common' },
  { key: 'FRNT', name: 'Front' },
  { key: 'CON', name: 'Concourse' },
  { key: 'FRTG', name: 'Frontage' },
  { key: 'CPS', name: 'Copse' },
  { key: 'GAP', name: 'Gap' },
  { key: 'CNR', name: 'Corner' },
  { key: 'GDN', name: 'Garden' },
  { key: 'CSO', name: 'Corso' },
  { key: 'GDNS', name: 'Gardens' },
  { key: 'CT', name: 'Court' },
  { key: 'GTE', name: 'Gate' },
  { key: 'CTYD', name: 'Courtyard' },
  { key: 'GTES', name: 'Gates' },
  { key: 'COVE', name: 'Cove' },
  { key: 'GLD', name: 'Glade' },
  { key: 'CRES', name: 'Crescent' },
  { key: 'GLEN', name: 'Glen' },
  { key: 'GRA', name: 'Grange' },
  { key: 'PLAT', name: 'Plateau' },
  { key: 'GRN', name: 'Green' },
  { key: 'PLZA', name: 'Plaza' },
  { key: 'GRND', name: 'Ground' },
  { key: 'PKT', name: 'Pocket' },
  { key: 'GR', name: 'Grove' },
  { key: 'PNT', name: 'Point' },
  { key: 'GLY', name: 'Gully' },
  { key: 'PORT', name: 'Port' },
  { key: 'HTS', name: 'Heights' },
  { key: 'PROM', name: 'Promenade' },
  { key: 'HRD', name: 'Highroad' },
  { key: 'QUAD', name: 'Quad' },
  { key: 'HWY', name: 'Highway' },
  { key: 'QDGL', name: 'Quadrangle' },
  { key: 'HILL', name: 'Hill' },
  { key: 'QDRT', name: 'Quadrant' },
  { key: 'INTG', name: 'Interchange' },
  { key: 'QY', name: 'Quay' },
  { key: 'INTN', name: 'Intersection' },
  { key: 'QYS', name: 'Quays' },
  { key: 'JNC', name: 'Junction' },
  { key: 'RMBL', name: 'Ramble' },
  { key: 'KEY', name: 'Key' },
  { key: 'RAMP', name: 'Ramp' },
  { key: 'LDG', name: 'Landing' },
  { key: 'RNGE', name: 'Range' },
  { key: 'LANE', name: 'Lane' },
  { key: 'RCH', name: 'Reach' },
  { key: 'LNWY', name: 'Laneway' },
  { key: 'RES', name: 'Reserve' },
  { key: 'LEES', name: 'Lees' },
  { key: 'REST', name: 'Rest' },
  { key: 'LNE', name: 'Line' },
  { key: 'RTT', name: 'Retreat' },
  { key: 'LINK', name: 'Link' },
  { key: 'RIDE', name: 'Ride' },
  { key: 'LT', name: 'Little' },
  { key: 'RDGE', name: 'Ridge' },
  { key: 'LKT', name: 'Lookout' },
  { key: 'RGWY', name: 'Ridgeway' },
  { key: 'LOOP', name: 'Loop' },
  { key: 'ROWY', name: 'Right of Way' },
  { key: 'LWR', name: 'Lower' },
  { key: 'RING', name: 'Ring' },
  { key: 'MALL', name: 'Mall' },
  { key: 'RISE', name: 'Rise' },
  { key: 'MNDR', name: 'Meander' },
  { key: 'RVR', name: 'River' },
  { key: 'MEW', name: 'Mew' },
  { key: 'RVWY', name: 'Riverway' },
  { key: 'MEWS', name: 'Mews' },
  { key: 'RVRA', name: 'Riviera' },
  { key: 'MWY', name: 'Motorway' },
  { key: 'RD', name: 'Road' },
  { key: 'MT', name: 'Mount' },
  { key: 'RDS', name: 'Roads' },
  { key: 'NOOK', name: 'Nook' },
  { key: 'RDSD', name: 'Roadside' },
  { key: 'OTLK', name: 'Outlook' },
  { key: 'RDWY', name: 'Roadway' },
  { key: 'PDE', name: 'Parade' },
  { key: 'RNDE', name: 'Ronde' },
  { key: 'PARK', name: 'Park' },
  { key: 'RSBL', name: 'Rosebowl' },
  { key: 'PKLD', name: 'Parklands' },
  { key: 'RTY', name: 'Rotary' },
  { key: 'PKWY', name: 'Parkway' },
  { key: 'RND', name: 'Round' },
  { key: 'PART', name: 'Part' },
  { key: 'RTE', name: 'Route' },
  { key: 'PASS', name: 'Pass' },
  { key: 'ROW', name: 'Row' },
  { key: 'PATH', name: 'Path' },
  { key: 'RUE', name: 'Rue' },
  { key: 'PHWY', name: 'Pathway' },
  { key: 'RUN', name: 'Run' },
  { key: 'PIAZ', name: 'Piazza' },
  { key: 'SWY', name: 'Service Way' },
  { key: 'PL', name: 'Place' },
  { key: 'SDNG', name: 'Siding' },
  { key: 'SLPE', name: 'Slope' },
  { key: 'SND', name: 'Sound' },
  { key: 'SPUR', name: 'Spur' },
  { key: 'SQ', name: 'Square' },
  { key: 'STRS', name: 'Stairs' },
  { key: 'SHWY', name: 'State Highway' },
  { key: 'STPS', name: 'Steps' },
  { key: 'STRA', name: 'Strand' },
  { key: 'ST', name: 'Street' },
  { key: 'STRP', name: 'Strip' },
  { key: 'SBWY', name: 'Subway' },
  { key: 'TARN', name: 'Tarn' },
  { key: 'TCE', name: 'Terrace' },
  { key: 'THOR', name: 'Thoroughfare' },
  { key: 'TLWY', name: 'Tollway' },
  { key: 'TOP', name: 'Top' },
  { key: 'TOR', name: 'Tor' },
  { key: 'TWRS', name: 'Towers' },
  { key: 'TRK', name: 'Track' },
  { key: 'TRL', name: 'Trail' },
  { key: 'TRLR', name: 'Trailer' },
  { key: 'TRI', name: 'Triangle' },
  { key: 'TKY', name: 'Trunkway' },
  { key: 'TURN', name: 'Turn' },
  { key: 'UPAS', name: 'Underpass' },
  { key: 'UPR', name: 'Upper' },
  { key: 'VALE', name: 'Vale' },
  { key: 'VDCT', name: 'Viaduct' },
  { key: 'VIEW', name: 'View' },
  { key: 'VLLS', name: 'Villas' },
  { key: 'VSTA', name: 'Vista' },
  { key: 'WADE', name: 'Wade' },
  { key: 'WALK', name: 'Walk' },
  { key: 'WKWY', name: 'Walkway' },
  { key: 'WAY', name: 'Way' },
  { key: 'WHRF', name: 'Wharf' },
  { key: 'WYND', name: 'Wynd' },
  { key: 'YARD', name: 'Yard' },
];

export default streets;
