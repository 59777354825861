import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { isAutoLayoutChild } from '../../../common/utils/animation_utils';
import { getClassName } from '../../utils';

export type TextSize =
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'base'
  | 'large'
  | 'x-large';

export type TextWeight = 'bold' | 'medium' | 'regular' | 'demi';

type Props = {
  element?: 'p' | 'span';
  className?: string;
  style?: React.CSSProperties;
  weight?: TextWeight;
  size?: TextSize;
  unselectable?: boolean;

  /**
   * Framer motion animation props
   */
  animation?: any;
};

const motionComponentMap = {
  p: motion.p,
  span: motion.span,
};

const Text: FC<Props> = props => {
  const {
    element = 'p',
    children,
    className = '',
    weight = 'regular',
    size = 'base',
    unselectable = false,
    animation = {},
    ...elementProps
  } = props;

  const isAutoLayout = isAutoLayoutChild();
  const weightClass = getClassName(weight, [
    ['demi', 'lm-font-demi'],
    ['bold', 'lm-font-bold'],
    ['medium', 'lm-font-proMedium'],
    ['regular', 'lm-font-display'],
  ]);

  const sizeClass = getClassName(size, [
    ['xx-small', 'lm-text-xxs'],
    ['x-small', 'lm-text-xs'],
    ['small', 'lm-text-sm'],
    ['base', 'lm-text-base'],
    ['large', 'lm-text-large'],
    ['x-large', 'lm-text-xl'],
  ]);

  const selectableClass = getClassName(unselectable, [
    [true, 'lm-select-none'],
    [false, 'lm-select-auto'],
  ]);

  const Element = motionComponentMap[element];

  return (
    <Element
      {...{ layout: isAutoLayout, ...animation }}
      className={`${sizeClass} ${className} ${weightClass} ${selectableClass}`}
      {...elementProps}
    >
      {children}
    </Element>
  );
};

export default Text;
