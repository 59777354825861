const config = {
  APP_NAME: 'admin',
  DIST: 'dist/admin',
  LOCAL_STORAGE_NAME: 'admin_access_token',
  LOCAL_STORAGE_USER_NAME: 'admin_user',
  LOGIN_PATH: '/admin/login',
  ERROR_LOGGING: true,
  FEATURE_BETAS_ENABLED: true,
};

export default config;
