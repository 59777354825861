import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import React from 'react';

export default (store: any) => ({
  path: 'loan_info',
  onEnter: documentCaptureRetrieve(store),
  indexRoute: {
    getComponent(nextState: any, cb: any) {
      const LoanInfo = React.lazy(
        () => import('./containers/LoanInfoContainer'),
      );
      cb(null, LoanInfo);
    },
  },
});
