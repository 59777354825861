import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
import {
  serverURL,
  apiFetchRaw,
  upload,
} from '../../../../../common/utils/fetch_utils';
import { push } from 'react-router-redux';
import * as analyticsUtils from '../../../../utils/analytics';
import { setUploading, uploadProgress } from '../modules/actions';
import { msgError } from '../../../../CommonCreators';
// TODO: placeholder images, need to replace with real ones from shany
import cardStyle from '../../../../../common/sass/components/_card.scss';
import flashStyle from '../../../../styles/components/_flash.scss';
import spinner from '../../../../../common/sass/components/_spinner.scss';
import icons from '../../../../../common/sass/icons/lumi-icon.scss';
import styles from '../../../../styles/modules/driver_license_upload.scss';
import FileUpload from '../../../../../common/components/file_upload';
import Button from '../../../../components/button';
import { getNextApplicationRoute, getExperimentGroup } from '../..';

class DriverLicenseUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      uploaded: false,
      loading: true,
    };
    this.uploadProgress = this.uploadProgress.bind(this);
    this.clearFile = this.clearFile.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }
  async componentDidMount() {
    await analyticsUtils.track('leads', {
      action: 'license_upload_started',
      platform: 'website',
      funnel_step: analyticsUtils.funnel_step(
        'driver_licence',
        analyticsUtils.funnel_step_version(),
      ),
      version: analyticsUtils.funnel_step_version(),
    });
    let file = {};
    apiFetchRaw(
      `${serverURL}/api/application/${this.props.application.id}/idDocument`,
      {
        method: 'get',
        headers: {},
      },
    )
      .then(response => {
        file.name = response.headers.get('File-name');
        return response.blob();
      })
      .then(data => {
        if (!data.type || data.type === 'application/json')
          throw new Error('No file');
        const reader = new FileReader();
        reader.readAsDataURL(data);

        reader.onloadend = () => {
          file.preview = reader.result;
          if (!reader.result) file = null;
          // Workaround for browsers that detect a null file as an empty object
          if (file && !file.name) file = null;
          this.setState({ file, uploaded: !!file, loading: false });
        };
      })
      .catch(err => {
        this.setState({ file: null, uploaded: false, loading: false });
        console.log(err);
      });
  }

  clearFile() {
    this.setState({ file: null, uploaded: false });
  }

  uploadProgress(e) {
    this.props.dispatch(uploadProgress((e.loaded / e.total) * 100));
  }

  onUpload(file) {
    const { dispatch } = this.props;

    if (!file) {
      return;
    }

    dispatch(setUploading(true));
    let formData = new FormData();
    formData.append('image', file);
    const id = this.props.application.id;
    upload(
      `${serverURL}/api/application/${id}/idDocument`,
      {
        method: 'post',
        headers: {},
        body: formData,
      },
      this.uploadProgress,
    )
      .then(data => {
        if (!(data && data.success)) {
          throw new Error(data.error);
        }
        this.setState({ uploaded: true, file });
        analyticsUtils.track('leads', {
          action: 'licence_upload_completed',
          platform: 'website',
          funnel_step: analyticsUtils.funnel_step(
            'driver_licence',
            analyticsUtils.funnel_step_version(),
          ),
          version: analyticsUtils.funnel_step_version(),
        });
        dispatch(setUploading(false));
        dispatch(setUploading(false));
      })
      .catch(() => {
        dispatch(setUploading(false));
        dispatch(
          msgError(
            'Error',
            'There was an error uploading the file, please try again',
          ),
        );
      });
  }

  nextStep() {
    this.props.dispatch(
      push(
        getNextApplicationRoute(
          this.props.location.pathname,
          getExperimentGroup(),
        ),
      ),
    );
  }

  render() {
    const { uploaded, file, loading } = this.state;
    const { uploading, upload_progress } = this.props;
    const confirmation = file && (
      <div>
        <div className={flashStyle.flash}>
          <i
            className={[icons['icon-check-circle'], icons['icon-margin']].join(
              ' ',
            )}
          />
          Your ID was successfully uploaded
        </div>
        <div className={styles.fileList}>
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${file.preview})` }}
          />
          <div className={styles.name}>{file.name}</div>
          <div className={styles.remove}>
            <i
              className={[icons['icon-close'], 'lg-icon clickable'].join(' ')}
              onClick={this.clearFile}
            />
          </div>
        </div>
        <hr className='mb-8' />
        <div>
          <Button className='mt-0' onClick={this.nextStep} label='Next step' />
        </div>
      </div>
    );
    const body = (
      <div>
        {!uploaded && (
          <div className={styles.fileUploadWrapper}>
            <FileUpload
              uploading={uploading}
              upload_progress={upload_progress}
              onFile={this.onUpload}
            />
          </div>
        )}
        {uploaded && confirmation}
      </div>
    );
    return (
      <div className={[cardStyle.pageCard, 'text-center'].join(' ')}>
        <div className={styles.body}>
          <h2>Please verify your identity</h2>
          <p>
            We need to verify your identity to approve your loan. Please take a
            photo of your license using a scanner or your smartphone and upload
            it below. Please ensure that the license is well lit and the text
            clearly visible.
          </p>
          {loading && <div className={spinner.pageSpinner} />}
          {!loading && body}
        </div>
      </div>
    );
  }
}

DriverLicenseUpload.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  upload_progress: PropTypes.number,
  application: PropTypes.object.isRequired,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    uploading: state.ui.uploading,
    upload_progress: state.ui.upload_progress || 0,
    account_id: state.user.account_id,
    application: state.user.application,
  };
}

export default connect(mapStateToProps)(DriverLicenseUpload);
