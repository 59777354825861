import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from '../styles/modules/help.scss';
import HelpIcon from '../images/help.svg';

class Help extends Component {
  render() {
    const fixedClass = this.props.fixed ? style.fixed : '';
    return (
      <div className={[style.container, fixedClass].join(' ')}>
        <div className={style.icon}>
          <HelpIcon height='30px' width='30px' />
        </div>
        <div className={style.inner}>
          <b className='text-muted'>Need some help?</b>
          <h4>
            <b>1300 00 LUMI</b>
          </h4>
        </div>
      </div>
    );
  }
}

Help.propTypes = {
  fixed: PropTypes.bool,
};

export default Help;
