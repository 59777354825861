import {
  refreshToken,
  logout,
  getLoan,
} from '../routes/Auth/Login/modules/actions';
import { getApplication } from '../CommonCreators';
import * as analyticsUtils from './analytics';
import lumiEnvironment from '../../lumi.environment';
import { getUser } from '../../common/utils/auth_utils';
import { apiGet } from '../../common/utils/fetch_utils';
import { getOnboardingRoute } from '../../common/utils/auth_utils';

export default function getUrlParamaterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  let results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const getNextDefaultRoute = async email => {
  let nextDefaultRoute = '/app/business_info';
  const response = await apiGet(`/deals/onboarding-step/${email}`);
  if (!response || !response.success) throw new Error(response.error);
  nextDefaultRoute = `/${getOnboardingRoute(response.result.step)}`;
  return nextDefaultRoute;
};

export const ensureNotLoggedIn = store => {
  return (nextState, replace, callback) => {
    if (/(\?)?reason=401/.test(location.search)) {
      callback();
    }

    let s = store.getState();
    if (s.user.isLoggedIn) {
      // dispatch token refresh
      store.dispatch(
        refreshToken(
          location.search,
          s.user.access_token,
          async resolveData => {
            console.log(
              'ensureNotLoggedIn(reactRouter.onEnter): Token was refreshed - navigating to business_info',
            );
            const user = getUser();
            const isLocal = window.location.href.includes('localhost');

            if (isLocal) {
              if (user.has_loc) {
                // This logic is temporary. See LF-2890.
                window.location.replace(
                  `${lumiEnvironment.LOCAL_CHIMERA}/hub/login`,
                );
                return null;
              }
            } else {
              if (user.has_loc) {
                window.location.replace(
                  `${lumiEnvironment.SERVER_HOST}/hub/login`,
                );
                return null;
              }
            }

            const route = await getNextDefaultRoute(user.email);
            // If we are changing across to the other chimera app, then do a browser redirect rather than react router
            if (route.startsWith('/apply')) {
              window.location.pathname = route;
              return;
            }
            replace({
              pathname: route,
              state: { nextPathname: nextState.location.pathname },
            });
            callback();
          },
          error => {
            console.log(
              'ensureNotLoggedIn(reactRouter.onEnter): Error refreshing token:' +
                error,
            );
            console.log('loggin out.');
            localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
            callback();
          },
        ),
      );
    } else {
      callback();
    }
  };
};

export const getLoanData = store => {
  return (nextState, replace, callback) => {
    let s = store.getState();
    if (!s.user.isLoggedIn) {
      console.log('User must first login, navigating to /app/login');
      replace({
        pathname: '/app/login',
        state: {
          nextPathname: nextState.location.pathname + nextState.location.search,
        },
      });
      callback();
    } else {
      // if user is logged in just refresh token and continue
      store.dispatch(
        refreshToken(
          location.search,
          s.user.access_token,
          resolveData => {
            console.log(
              'getLoanData(reactRouter.onEnter): Token was refreshed',
            );

            console.log(nextState.location.query.loan_id);
            console.log(nextState.location.search);

            store.dispatch(
              getLoan(
                nextState.location.query.loan_id,
                loanData => {
                  let isOfferApprovedLink =
                    nextState.location.pathname.indexOf(
                      'app/offer_approved',
                    ) !== -1;
                  console.log(
                    'getLoanData(reactRouter.onEnter): Got loan data',
                  );
                  let legal =
                    ['ACTIVE', 'IN_ARREARS'].indexOf(loanData.result.state) !==
                    -1;

                  // if this is an offer approved link that's fine, also if this is a dashboard link with a legal loanId that's also fine.
                  if (!isOfferApprovedLink && !legal) {
                    console.log(
                      'Passed loan was not in the right state to look at: ' +
                        loanData.result.state,
                    );
                    replace({
                      pathname: '/app/business_info',
                      state: {
                        nextPathname:
                          nextState.location.pathname +
                          nextState.location.search,
                      },
                    });
                  }
                  callback();
                },
                error => {
                  console.log(
                    'getLoanData(reactRouter.onEnter): Error getting loan data:' +
                      error,
                  );

                  // TODO: What to do if we dont get the
                  // offer data (for instance - no offer_id param) ?
                  // callback();
                },
              ),
            );
          },
          error => {
            console.log(
              'getLoanData(reactRouter.onEnter): Error refreshing token:' +
                error,
            );
            console.log('loggin out.');
            localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
            callback();
          },
        ),
      );
    }
  };
};

export const getToken = () => {
  const token = localStorage.getItem(lumiEnvironment.LOCAL_STORAGE_NAME);
  return token;
};

export const checkTokenOrLogout = (
  replace,
  nextState,
  isLoggedIn,
  callback,
) => {
  const token = getToken();
  if (!token || token === '') {
    // Default values if nextState isn't provided
    nextState =
      !nextState || !nextState.location ? { location: {} } : nextState;
    // Different error handling for document capture
    const nextRoute = '' + nextState.location.pathname;
    if (
      nextRoute.indexOf('app/documents/') > -1 ||
      nextRoute.indexOf('app/auth/') > -1
    ) {
      analyticsUtils.track('document_capture', {
        action: 'lost_token',
        nextLocation: nextState.location,
      });
      throw new Error(
        "Looks like there's been an error. Please click the link we sent you to try again.",
      );
    }
    replace({
      pathname: '/app/login',
      state: {
        nextPathname: nextState.location.pathname + nextState.location.search,
      },
    });
    callback();
    return;
  }
};

/**
 *  NOTE: Only use this endpoint from ONBOARDING. DO NOT USE FROM OTHER ENTRIES SUCH AS DOC CAPTURE.
 *  THRE WILL BE SIDEEFFECTS.
 */
export const ensureAuthenticated = store => {
  return (nextState, replace, callback) => {
    let s = store.getState();

    checkTokenOrLogout(replace, null, s.user.isLoggedIn, callback);

    if (!s.user.application) {
      store.dispatch(
        getApplication(
          location.search,
          application => {
            callback();
          },
          () => {
            callback();
          },
          true,
        ),
      ); // this flag will create a lead if there is not a valid lead
    } else {
      callback();
    }
  };
};

export const handleLogout = store => {
  return (nextState, replace, callback) => {
    let s = store.getState();
    if (s.user.isLoggedIn) {
      store.dispatch(logout());
      localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
      console.log('logged out successfully!!');
      // redirecting to /
      replace({
        pathname: '/app',
        state: { nextPathname: '' },
      });
      callback();
    } else {
      callback();
    }
  };
};
