import { connect } from 'react-redux';
import LoanInfo from '../components/LoanInfo';
import {
  msgError,
  saveApplication,
  saveAccount,
} from '../../../../CommonCreators';
import { push } from 'react-router-redux';
import { updateProgress as docCaptureProgress } from '../../DocumentCaptureActions';
import { initialize } from 'redux-form';

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    documentProgress: progress.document_capture_progress,
    application: state.user.application,
    account: state.user.account,
  };
};

export type MapDispatchToPropsLoanInfo = {
  msgError: (title: string, error: string) => void;
  saveApplication: (application: any, callback: (app: any) => void) => void;
  saveAccount: (account: any, callback: (account: any) => void) => void;
  push: (path: string) => void;
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
  initialize: (form: string, data: any) => void;
};

const mapDispatchToProps: MapDispatchToPropsLoanInfo = {
  msgError,
  saveApplication,
  saveAccount,
  push,
  updateProgress: docCaptureProgress,
  initialize,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanInfo);
