import { motion } from 'framer-motion';
import React, { useMemo, FC } from 'react';
import { isAutoLayoutChild } from '../../../common/utils/animation_utils';
import { getClassName } from '../../utils';

type HeadingSize = '1' | '2' | '3' | '4' | '5' | '6';

type Props = {
  className?: string;
  size?: HeadingSize;
  style?: any;
  color?: string;
  weightClassName?: string;
  unselectable?: boolean;
};

const HeadingMap: any = (
  className: string,
  children: string,
  style: any,
  color?: string,
  layout = false,
) => ({
  1: (
    <motion.h1
      layout={layout}
      style={style}
      className={`${color} lm-text-mobile-heading-one lm-mobile-leading-heading-one sm:lm-text-heading-one sm:lm-leading-heading-one ${className}`}
    >
      {children}
    </motion.h1>
  ),
  2: (
    <motion.h2
      layout={layout}
      style={style}
      className={`${color} lm-text-mobile-heading-two lm-mobile-leading-heading-two sm:lm-text-heading-two sm:lm-leading-heading-two ${className}`}
    >
      {children}
    </motion.h2>
  ),
  3: (
    <motion.h3
      layout={layout}
      style={style}
      className={`${color} lm-text-mobile-heading-three lm-mobile-leading-heading-three sm:lm-text-heading-three sm:lm-leading-heading-three ${className}`}
    >
      {children}
    </motion.h3>
  ),
  4: (
    <motion.h4
      layout={layout}
      style={style}
      className={`${color} lm-text-mobile-heading-four lm-mobile-leading-heading-four sm:lm-text-heading-four sm:lm-leading-heading-four ${className}`}
    >
      {children}
    </motion.h4>
  ),
  5: (
    <motion.h5
      layout={layout}
      style={style}
      className={`${color} lm-text-mobile-heading-five lm-mobile-leading-heading-five sm:lm-text-heading-five sm:lm-leading-heading-five ${className}`}
    >
      {children}
    </motion.h5>
  ),
  6: (
    <motion.h6
      layout={layout}
      style={style}
      className={`${color} lm-text-mobile-heading-six lm-mobile-leading-heading-six sm:lm-text-heading-six sm:lm-leading-heading-six ${className}`}
    >
      {children}
    </motion.h6>
  ),
});

const Heading: FC<Props> = props => {
  const {
    children,
    className,
    size = '4',
    style,
    color = 'lm-text-base-blue',
    weightClassName = 'lm-font-bold',
    unselectable = false,
  } = props;
  const isAutoLayout = isAutoLayoutChild();
  const selectableClass = getClassName(unselectable, [
    [true, 'lm-select-none'],
    [false, 'lm-select-auto'],
  ]);

  const _HeadingMap = useMemo(
    () =>
      HeadingMap(
        `${className} ${selectableClass} ${weightClassName}`,
        children,
        style,
        color,
        isAutoLayout,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children],
  );

  return _HeadingMap[size] || _HeadingMap['3'];
};
export default Heading;
