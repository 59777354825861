import {
  apiFetch,
  publicServerURL,
} from '../../../../../common/utils/fetch_utils';
import {
  RESET_PASSWORD_SUCCESSFULLY,
  CLEAR_RESET_PASSWORD_SUCCESSFULLY,
} from '../../../../redux/modules/user';
import { msgError } from '../../../../CommonCreators';

export function forgotPasswordSent(response) {
  return dispatch => {
    dispatch({ response, type: RESET_PASSWORD_SUCCESSFULLY });
  };
}

export function clearPasswordResetSuccesfully() {
  return dispatch => {
    dispatch({ type: CLEAR_RESET_PASSWORD_SUCCESSFULLY });
  };
}

export function forgotPassword(userData, resolve, reject) {
  return dispatch =>
    apiFetch(
      `${publicServerURL}/api/accounts/forgotPassword`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
        }),
      },
      data => {
        if (!data.success) {
          throw new Error(data.error);
        }
        console.log(
          'forgotPassword request succeeded with JSON response',
          data,
        );
        dispatch(forgotPasswordSent(data));
        resolve(data);
      },
    ).catch(function (error) {
      console.log('forgotPassword request for forgotPassword failed', error);
      dispatch(msgError('Error', error.message));
      reject(error);
    });
}
