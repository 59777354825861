import Popper from 'popper.js';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { mergeClasses } from '../../../common/utils/utils';
import Container from '../../layout/container/Container';
import Portal from '../portal/Portal';
import Hidden from '../../layout/hidden/Hidden';

export enum TooltipContentType {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  NEUTRAL = 'neutral',
  INFO = 'info',
  ACTION = 'action',
  SECONDARY = 'secondary',
}

export type TooltipContentProps = {
  id?: string;
  children: React.ReactNode;
  className?: string;
  borderClassName?: string;
  type?: TooltipContentType | 'custom';
  padding?: string;
  noDefaultClassNames?: boolean;
  style?: React.CSSProperties;
};

const contentClasses = `
lm-z-50
lm-block
lm-rounded-lg
lm-shadow-md
lm-text-sm lm-font-proMedium lm-font-medium`;

const getColorByType = (type: TooltipContentType | 'custom') => {
  switch (type) {
    case TooltipContentType.WARNING:
      return 'lm-bg-burnt-yellow-100 lm-border-warning lm-text-warning';

    case TooltipContentType.ERROR:
      return 'lm-bg-red-100 lm-border-base-red lm-text-base-red';

    case TooltipContentType.SUCCESS:
      return 'lm-bg-green-100 lm-border-green-500 lm-text-green-500';

    case TooltipContentType.NEUTRAL:
      return 'lm-bg-gray-100 lm-border-base-navy lm-text-copy';

    case TooltipContentType.INFO:
      return 'lm-bg-pink-background lm-border-pink-500 lm-text-pink-500';

    case TooltipContentType.ACTION:
      return 'lm-bg-gray-100 lm-border-gray-400 lm-text-copy';

    case TooltipContentType.SECONDARY:
      return 'lm-bg-purple-100 lm-border-purple-700 lm-text-purple-700';

    default:
      return '';
  }
};

const TooltipContent: FC<TooltipContentProps> = ({
  id,
  borderClassName,
  className,
  padding = 'lm-p-6',
  type = TooltipContentType.WARNING,
  children,
  noDefaultClassNames,
  style,
}: TooltipContentProps) => {
  const defaultClassNames = noDefaultClassNames
    ? []
    : [
        contentClasses,
        padding,
        getColorByType(type),
        borderClassName ?? 'lm-border',
      ];

  return (
    <div
      id={id}
      className={mergeClasses(...defaultClassNames, className)}
      style={style}
    >
      {children}
    </div>
  );
};

type TooltipTriggerProps = {
  children: React.ReactNode;
  className?: string;
  /**
   * Required aria label for screen readers
   */
  ariaLabel?: string;

  /**
   * @deprecated Used while we transition all uses over to the updated css to
   * fix alignment. If you see a `Tooltip` where this is `true`, try removing it
   * and if nothing breaks, leave it removed.
   *
   * This was added because the amount of usages of `Tooltip` meant that the
   * testing effort would have been unrealistic if we applied the change
   * everywhere.
   */
  useLegacyAlignment?: boolean;
};

const TooltipTrigger = (props: TooltipTriggerProps) => (
  <React.Fragment>{props.children}</React.Fragment>
);

export type TooltipProps = {
  children: [
    React.ReactElement<TooltipTriggerProps, typeof TooltipTrigger>,
    React.ReactElement<typeof TooltipContent>,
  ];
  /**
   * Where to display the tooltip. e.g. bottom, top, right, left
   */
  placement: Popper.Placement;

  /**
   * Classname gets applied to the wrapper
   */
  className?: string;

  contentZIndex?: number;

  /**
   * Tooltip will not show if disabled
   */
  disabled?: boolean;
};

type ExtraTooltip = {
  Trigger: typeof TooltipTrigger;
  Content: typeof TooltipContent;
};
const Tooltip: FC<TooltipProps> & ExtraTooltip = (props: TooltipProps) => {
  const {
    children,
    placement,
    contentZIndex,
    className = '',
    disabled = false,
  } = props;

  const [tooltipShow, setTooltipShow] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  const triggerContainerRef = useRef<HTMLDivElement>();
  const tooltipRef = useRef<HTMLDivElement>();

  const open = () => {
    const pop = new Popper(triggerContainerRef.current, tooltipRef.current, {
      placement,
    });
    setTooltipShow(true);
  };

  // Uncomment this if you wanna debug the component and not have it disapear
  // every time you try to inspect element
  // const close = (): any => null;
  const close = () => setTooltipShow(false);

  const mouseIn = () => {
    if (disabled || tooltipShow) return; // Do nothing, its already showing or disabled
    open();
  };

  const mouseOut = useCallback(() => {
    if (disabled || !tooltipShow) return; // Do nothing, its not showing or disabled
    if (focused) return; // Do nothing, the trigger is still focused, so it should remain open
    close();
  }, [focused, tooltipShow, disabled]);

  const focus = () => {
    if (tooltipShow) return; // Do nothing, its already showing
    setFocused(true);
    open();
  };

  const blur = useCallback(() => {
    if (!tooltipShow) return; // Do nothing, its not showing
    setFocused(false);
    close();
  }, [tooltipShow]);

  const { trigger, content } = useMemo(() => {
    const getChild = (name: string) =>
      children.find(c => (c.type as any).name === name);
    return {
      trigger: getChild(TooltipTrigger.name),
      content: getChild(TooltipContent.name),
    };
  }, [children]);

  const popClass = useMemo(() => {
    if (tooltipShow) return '';
    return 'lm-hidden';
  }, [tooltipShow]);

  return (
    <Container className={`lm-inline-block ${className}`}>
      <div
        tabIndex={0}
        onMouseEnter={mouseIn}
        onMouseLeave={mouseOut}
        onFocus={focus}
        onBlur={blur}
        ref={triggerContainerRef}
        aria-label={(trigger.props as TooltipTriggerProps).ariaLabel}
        className={mergeClasses(
          (trigger.props as TooltipTriggerProps).useLegacyAlignment
            ? 'lm-inline-block'
            : undefined,

          (trigger.props as TooltipTriggerProps).className,
        )}
      >
        {trigger}
        <Hidden when={disabled}>
          <Portal idPrefix='tooltip'>
            <div
              role='tooltip'
              className={`${popClass} lm-z-50`}
              style={{ zIndex: contentZIndex }}
              ref={tooltipRef}
            >
              {content}
            </div>
          </Portal>
        </Hidden>
      </div>
    </Container>
  );
};

Tooltip.Content = TooltipContent;
Tooltip.Trigger = TooltipTrigger;

export default Tooltip;
