import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { getExperimentGroup, getNextApplicationRoute } from '../..';
import cardStyle from '../../../../../common/sass/components/_card.scss';
import * as analyticsUtils from '../../../../utils/analytics';
import { displayErrors } from 'chimera/utils/errorUtils';
import { BankFeedsReturnedStatus } from 'chimera/routes/DocumentCapture/Banking/viewmodel/BankingViewModel';
const querystring = require('querystring');

type Props = {
  location: any;
} & MapDispatchToProps;

type MapDispatchToProps = {
  replace: (path: string) => void;
};

const mapDispatchToProps: MapDispatchToProps = {
  replace,
};

const experimentGroup = localStorage.getItem('signup_variant');

export function BankingLandingView(props: Props) {
  const { location, replace } = props;
  const [errorText, setErrorText] = useState('');
  const [bankingSuccess, setBankingSuccess] = useState(false);

  useEffect(() => {
    // Future-proofing. Newer versions of react-router don't parse the query string for us. https://github.com/ReactTraining/react-router/issues/4410
    let responseQuery = location.search;
    if (responseQuery.substring(0, 1) === '?') {
      responseQuery = responseQuery.substring(1);
    }
    const bankFeedsResponse = querystring.parse(responseQuery);

    setBankingSuccess(
      bankFeedsResponse.status &&
        bankFeedsResponse.status.toUpperCase() === 'COMPLETE',
    );

    if (!bankingSuccess) {
      if (bankFeedsResponse.errorText) {
        setErrorText(bankFeedsResponse.errorText);
      } else {
        setErrorText(
          displayErrors[bankFeedsResponse.status as BankFeedsReturnedStatus],
        );
      }
    } else {
      analyticsUtils.track('leads', {
        action: 'financials_completed',
        platform: 'website',
        funnel_step: analyticsUtils.funnel_step(
          'banking_info',
          analyticsUtils.funnel_step_version(),
        ),
        version: analyticsUtils.funnel_step_version(),
      });
    }
  }, [bankingSuccess, location.search]);

  if (bankingSuccess)
    replace(getNextApplicationRoute('/app/banking_info', getExperimentGroup()));
  return (
    <div className={cardStyle.pageCard}>
      <h2 className='text-center'>Error</h2>
      <p className='text-center'>{errorText || displayErrors['ERROR']}</p>
      {!bankingSuccess && (
        <p className='text-center'>
          <a onClick={() => history.back()}>Try again</a>
        </p>
      )}
    </div>
  );
}

export default connect(null, mapDispatchToProps)(BankingLandingView);
