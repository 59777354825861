import { IOption } from 'admin/routes/CreditView/components/VersionSelector';
import { CSSObjectWithLabel, StylesConfig } from 'react-select';

export { CreditIndustrySelect } from './CreditIndustrySelect';
export { default } from './LumiSelect';

export type LumiSelectType = {
  placeholder?: string;
  onChange?: (selected: any) => void;
  options?: IOption[];
  clearable?: boolean;
  disabled?: boolean;
  label?: string;
  error?: string;
  value?: string | Object;
  isMulti?: boolean;
  input?: { value: any; onChange?: (selected: any) => void };
  meta?: { error: string; touched: boolean; dirty: boolean; active: boolean };
  className?: string;
  small?: boolean;
  maxMenuHeight?: number;
  minMenuHeight?: number;
  width?: string;
  loading?: boolean;
  ref?: any;
  customStyle?: any;
};

type SelectStyles = {
  small: boolean;
  disabled: boolean;
  error: string;
  customStyle?: CSSObjectWithLabel;
};

export const selectStyles = ({
  small,
  error,
  disabled,
  customStyle,
}: SelectStyles): StylesConfig => {
  const fontSize = small ? '14px' : '16px';
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      background: disabled ? '#dfe3e6' : '#FDFDFF',
      padding: small ? '0px' : '6px 8px 6px 0px',
      borderColor: error ? '#DC3030' : isFocused ? '#FF7DE4' : '#818E9A',
      boxShadow: isFocused ? '0 0 0 1px #FF7DE4' : 'none',
      fontSize,
      cursor: 'pointer',
      ':hover': {
        borderColor: '#FF7DE4',
      },
      ...customStyle,
    }),
    option: (styles, { isSelected, isFocused }) => ({
      ...styles,
      fontSize,
      background: isSelected ? '#FF7DE4' : isFocused ? '#FFD4F6' : '#FFF',
    }),
    singleValue: styles => ({
      ...styles,
      fontSize: `${fontSize} !important`,
      color: '#5D7287',
    }),
  };
};
