import { ensureAuthenticated } from '../../../utils/auth';
import React from 'react';

export default (store: any) => ({
  path: 'banking_info/landing',
  progress: { step: 5, percentage: 90 },
  enableHeader: true,
  onEnter: ensureAuthenticated(store),
  getComponent(nextState: any, cb: any) {
    const BankingLandingView = React.lazy(
      () => import('./components/BankingLandingView'),
    );
    cb(null, BankingLandingView);
  },
});
