import * as React from 'react';
import { mergeClasses } from '../utils/utils';
import * as styles from '../sass/components/_abnLookup.scss';
import * as icons from '../sass/icons/lumi-icon.scss';
import ProgressDots from '../components/ProgressDots';

type Props = {
  loading: boolean;
  onClick: (event: any) => void;
  className?: string;
};

const abrLink = 'http://abr.business.gov.au/';

const ABNLookup = (props: Props) => {
  const { loading, onClick, className } = props;
  let content = (
    <span onClick={onClick} className='clickable md-icon ml-2'>
      <i
        className={mergeClasses(styles.searchIcon, icons['icon-search-simple'])}
      />
      <span className={styles.abnCheckLabel}>Check ABN/ACN</span>
      <div className={styles.abrLink}>
        <span>
          <a href={abrLink} target='_blank' rel='noreferrer'>
            Lookup using ABR
          </a>
        </span>
      </div>
    </span>
  );
  if (loading)
    content = (
      <div className={styles.abnLoader}>
        <ProgressDots />
      </div>
    );
  return <div className={className}>{content}</div>;
};

export default ABNLookup;
