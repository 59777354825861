import React from 'react';
import styles from '../sass/components/_input.scss';
import InputMask from './input_mask';
import { mergeClasses } from '../utils/utils';
import { isNil } from 'lodash';

type Props = {
  'aria-label'?: string;
  label?: string;
  name?: string;
  type?: string;
  onChange?: any;
  value?: string;
  error?: string;
  className?: string;
  disabled?: boolean;
  input?: {
    value?: any;
    onChange?: any;
    onClick?: React.MouseEventHandler<HTMLElement>;
  };
  meta?: any;
  isArea?: boolean;
  small?: boolean;
  placeholder?: string;
  style?: any;
  elementSuffix?: string;
  mask?: any[];
  keepCharPositions?: boolean;
  placeholderChar?: string;
  inputClassName?: string;
  useCommonStyle?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  serverError?: string;
  inlineElement?: React.ReactNode;
  width?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  rows?: number;
  asLabel?: boolean;
  bottomError?: boolean;
  step?: string;
  required?: boolean;
  id?: string;
  forceActiveLabel?: boolean;
  containerOnClick?: React.MouseEventHandler<HTMLElement>;
};

const getInputProps = (props: Props, error: any) => {
  const {
    'aria-label': ariaLabel,
    small,
    meta,
    label,
    type,
    disabled,
    style,
    input,
    value,
    onChange,
    inputClassName,
    useCommonStyle = true,
    autoComplete,
    placeholder,
    name,
    readOnly,
    tabIndex,
    autoFocus,
    width,
    asLabel,
    step,
    required,
    id,
  } = props;

  const _value = input && !isNil(input.value) ? input.value : value;
  const isActive = _value !== '' && _value !== undefined;
  const activeClass = isActive ? styles.active : '';
  let _onChange = onChange;
  if (input) _onChange = input.onChange;

  let inputClass = useCommonStyle ? styles.input : '';
  if (small) inputClass = mergeClasses(inputClass, styles.small);

  let _className = mergeClasses(inputClass, activeClass, inputClassName);
  if (meta && meta.touched && error) {
    _className = mergeClasses(_className, styles.error);
  }

  if (asLabel) {
    _className = mergeClasses(_className, styles.editableLabel);
  }

  let ph = meta && meta.touched && error ? label : null;
  if (placeholder) ph = placeholder;

  const _style = { ...style, width };
  return {
    'aria-label': ariaLabel,
    type: type,
    placeholder: ph,
    name: name,
    className: _className,
    onChange: _onChange,
    disabled: disabled,
    value: _value,
    style: _style,
    tabIndex,
    readOnly,
    autoComplete,
    autoFocus,
    step,
    required,
    id,
    ...input,
  };
};

const getFieldElement = (props: Props, error: any) => {
  const { isArea, type, placeholderChar, keepCharPositions, mask, rows } =
    props;
  const inputProps = getInputProps(props, error);
  if (type === 'mask') {
    return (
      <InputMask
        placeholderChar={placeholderChar}
        keepCharPositions={keepCharPositions}
        mask={mask}
        {...inputProps}
        type='string'
      />
    );
  }
  if (isArea) {
    return <textarea {...inputProps} rows={rows} />;
  }
  return <input {...inputProps} />;
};

function LumiField(props: Props) {
  const {
    label,
    error,
    className,
    meta,
    style,
    serverError,
    inlineElement,
    small,
    bottomError,
    forceActiveLabel,
    containerOnClick,
  } = props;
  const violated = meta && (meta.touched || meta.dirty);
  let _error = error;
  // The field is marked as touched after a focus + unfocus has occurred and during submit
  // all fields are touched ;). However that means a field can be touched ;)
  // yet still have an error, that is because the input can be dirty, hence the dual check.
  if (violated) {
    _error = meta.error;
  }
  if (serverError) _error = serverError;

  const inputId = React.useMemo(
    () => props.id ?? `${props.label}-input`,
    [props.id, props.label],
  );

  let labelClass = forceActiveLabel ? styles.activeLabel : styles.label;
  if (small) labelClass = mergeClasses(labelClass, styles.small);
  let infoLabel = (
    <label className={labelClass} htmlFor={inputId}>
      {label}
    </label>
  );

  if (_error) {
    let errorClassName = styles.lumiFieldErrorLabel;
    if (bottomError) {
      errorClassName = mergeClasses(
        errorClassName,
        'lm-top-auto lm-bottom-[-24px]',
      );
    }
    infoLabel = <label className={errorClassName}>{_error}</label>;
  }

  return (
    <div
      style={{ position: 'relative', ...style }}
      className={className}
      onClick={containerOnClick}
    >
      {getFieldElement({ ...props, id: inputId }, _error)}
      {props.elementSuffix}
      {infoLabel}
      {inlineElement}
    </div>
  );
}

LumiField.propTypes = {};

export default LumiField;
