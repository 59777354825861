import { connect } from 'react-redux';

import Thanks from '../components/Thanks';

const mapStateToProps = (state: any) => {
  const documentCapture = state.documentCapture || {};
  const progress = documentCapture.progress || {};
  return {
    documentCapture: progress.document_capture,
  };
};

export default connect(mapStateToProps)(Thanks);
