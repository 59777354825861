/**
 *  Name: ApplicationIndex
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */
import DashboardApplicationLayout from '../../layouts/DashboardLayout/DashboardApplicationLayout';
import OnboardingLayout from '../../layouts/OnboardingLayout/OnboardingLayout';
import AuthLayout from '../../layouts/OnboardingLayout/AuthLayout';

import BusinessInfoRoute from './BusinessInfo';
import ConnectBankAccountRoute from './ConnectBankAccount';
import BankFeedsLandingRoute from './BankFeedsLanding';
import BankFeedsThanksRoute from './BankFeedsThanks';
import DirectorInfoRoute from './DirectorInfo';
import ExperimentDirectorInfoRoute from './DirectorInfo/experiment';
import DriverLicenseUploadRoute from './DriverLicenseUpload';
import BankingInfoRoute from './BankingInfo';
import LoanInfoRoute from './LoanInfo';
import ThankyouRoute from './Thankyou';
// import UpdatePhoneNumberRoute from './UpdatePhoneNumber';
import SignupRoute from './Signup';
// import FinancialStatements from './FinancialStatements';
import ExtraInfoRoute from './ExtraInfo';
import BankingLandingRoute from './BankingLanding';

import ExperimentLoanInfoRoute from './LoanInfo/experiment';
import ExperimentBusinessInfoRoute from './BusinessInfo/experiment';
import ExperimentSignupRoute from './Signup/experiment';

const BusinessLoanInfoFirstOrderConfig = [
  {
    route: '/app/variant/loan_info',
    step: 1,
    percentage: 20,
    label: 'Loan Info',
  },
  {
    route: '/app/variant/business_info',
    step: 2,
    percentage: 30,
    label: 'Business Info',
  },
  {
    route: '/app/variant/signup',
    step: 3,
    percentage: 40,
    label: 'Your Details',
  },
  {
    route: '/app/variant/director_info',
    step: 4,
    percentage: 60,
    label: 'Details',
  },
  {
    route: '/app/driver_license_upload',
    step: 4,
    percentage: 70,
    label: 'Details',
  },
  { route: '/app/banking_info', step: 5, percentage: 80, label: 'Financials' },
  { route: '/app/extra_info', step: 6, percentage: 90, label: 'Extra Info' },
  // { route: '/app/mobile_verification', step: 7, percentage: 95, label: 'Verify SMS' }
];

const OnboardingRouteOrderConfig = [
  { route: '/app/signup', step: 1, percentage: 20, label: 'Get started' },
  { route: '/app/loan_info', step: 2, percentage: 30, label: 'Your loan' },
  {
    route: '/app/business_info',
    step: 3,
    percentage: 40,
    label: 'Your business',
  },
  { route: '/app/director_info', step: 4, percentage: 50, label: 'Details' },
  {
    route: '/app/driver_license_upload',
    step: 4,
    percentage: 60,
    label: 'Details',
  },
  { route: '/app/banking_info', step: 5, percentage: 70, label: 'Financials' },
  { route: '/app/extra_info', step: 6, percentage: 85, label: 'Extra Info' },
  // { route: '/app/mobile_verification', step: 7, percentage: 95, label: 'Verify SMS' }
];

export const OnboardingConfig = {
  control: OnboardingRouteOrderConfig,
  variant: BusinessLoanInfoFirstOrderConfig,
};

export const getExperimentGroup = () => {
  const group = localStorage.getItem('signup_variant');
  if (!group) return 'control';
  return group;
};

export const getNextApplicationRoute = (route, group) => {
  const routeOrder =
    OnboardingConfig[group] || [] || OnboardingRouteOrderConfig;
  const index = routeOrder.findIndex(
    routeConfig => routeConfig.route === route,
  );
  if (!route) return routeOrder[0].route;

  if (index < 0) return routeOrder[1].route;
  if (index === routeOrder.length - 1)
    return routeOrder[routeOrder.length - 1].route;
  return routeOrder[index + 1].route;
};

export const getRouteProgress = (route, group) => {
  const routeOrder =
    OnboardingConfig[group] || [] || OnboardingRouteOrderConfig;
  const index = routeOrder.findIndex(
    routeConfig => routeConfig.route === route,
  );

  if (index < 0) return {};
  if (index === routeOrder.length - 1) return {};

  const config = routeOrder[index];
  return {
    step: config.step,
    percentage: config.percentage,
  };
};

export default store => [
  {
    path: 'app',
    component: DashboardApplicationLayout,
    indexRoute: {
      onEnter: (nextState, replace) => replace('/app/login'),
    },
    childRoutes: [
      ConnectBankAccountRoute(store),
      DirectorInfoRoute(store),
      ExperimentDirectorInfoRoute(store),
      DriverLicenseUploadRoute(store),
      BankingInfoRoute(store),
      ThankyouRoute(store),
      // UpdatePhoneNumberRoute(store),
      // FinancialStatements(store),
      ExtraInfoRoute(store),
      BankingLandingRoute(store),
    ],
  },
  {
    path: 'app',
    component: OnboardingLayout([
      // Defines the 1.2.3 steps to display in the header of the onboarding layout
      { key: 'signup', title: 'About you' },
      { key: 'loan_info', title: 'Your loan' },
      { key: 'business_info', title: 'Your business' },
    ]),
    childRoutes: [
      SignupRoute(store),
      LoanInfoRoute(store),
      BusinessInfoRoute(store),
    ],
  },
  {
    path: 'app',
    component: OnboardingLayout([
      { key: 'loan_info', title: 'Your loan' },
      { key: 'business_info', title: 'Your business' },
      { key: 'signup', title: 'About you' },
    ]),
    childRoutes: [
      // Defines the 1.2.3 steps to display in the header of the onboarding layout
      ExperimentLoanInfoRoute(store),
      ExperimentBusinessInfoRoute(store),
      ExperimentSignupRoute(store),
    ],
  },
  {
    path: 'app',
    component: AuthLayout,
    childRoutes: [BankFeedsLandingRoute(store), BankFeedsThanksRoute(store)],
  },
];
