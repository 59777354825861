import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import styles from '../sass/components/_file_upload.scss';
import progressStyle from '../../common/sass/components/_progress.scss';
import buttonStyle from '../../common/sass/components/_button.scss';
import icons from '../../common/sass/icons/lumi-icon.scss';
import { getIcon } from '../utils/attachment_icons';
import { windowOpenInPost } from 'common/utils/form_utils';
import { getAccessToken } from '../../admin/utils';

class FileUploadSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      files: [],
    };

    this.onFileDrop = this.onFileDrop.bind(this);
    this.onSelectFileButton = this.onSelectFileButton.bind(this);
    this.onRemoveImageButtonClick = this.onRemoveImageButtonClick.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  onFileDrop(files) {
    const { files: stateFiles } = this.state;
    if (this.refs.dropzone.props.disabled) return;
    if (files.length <= 0) {
      this.props.onFileChange(null);
      return;
    }
    let fileState = { file: files[0] };
    this.setState(fileState);

    this.props.onFileChange(files[0]);
  }

  onSelectFileButton() {
    if (!this.refs.dropzone) {
      return;
    }

    this.refs.dropzone.open();
  }

  onUpload() {
    const { file, files } = this.state;
    const { onFile } = this.props;
    if (onFile) {
      const toUpload = file;
      onFile(toUpload);
      this.setState({ file: null, files: [] });
    }
  }

  onRemoveImageButtonClick() {
    this.setState({ file: null, files: [] });
    this.props.onFileChange(null);
  }

  componentDidUpdate(prevProps) {
    const { image } = this.props;
    const prevImage = prevProps.image;

    if (prevImage !== image) {
      this.setState({ file: null }); // eslint-disable-line
    }
  }

  getImagePreview() {
    const file = this.state.file || {};
    const { image } = this.props;
    const imageUrl = file.preview || image;

    let previewClass = '';
    if (image && !file.preview) {
      previewClass = styles.largeImagePreview;
    }

    return (
      <div
        className={[styles.imagePreview, previewClass].join(' ')}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
    );
  }

  getFilesPreview = () => {
    const files = this.state.files || [];
    const { upload_complete } = this.props;
    if (!files.length) return null;
    const fileElements = files.map((file, i) => {
      const fileIcon = getIcon(file.name);
      return (
        <div key={`filepreview-${i}`} className={styles.file}>
          {fileIcon}&nbsp;
          {file.name}
        </div>
      );
    });
    return (
      <div className={styles.filesPreview}>
        <div className={styles.filesPreviewTitle}>
          <span>Documents To Upload</span>
          {!upload_complete && (
            <div
              className={styles.clearButton}
              onClick={this.onRemoveImageButtonClick}
            >
              <i className={icons['icon-close']} /> Remove All
            </div>
          )}
        </div>
        {fileElements}
      </div>
    );
  };

  getProgressBar() {
    const { uploading, upload_progress, upload_complete } = this.props;
    if (upload_complete) {
      return (
        <div className={styles.progressWrapper}>
          <div className={styles.info} />
        </div>
      );
    }

    if (uploading) {
      return (
        <div className={styles.progressWrapper}>
          <div
            className={[progressStyle.progress, progressStyle.thin].join(' ')}
          >
            <div
              className={[progressStyle.bar, progressStyle.accent].join(' ')}
              style={{ width: `${upload_progress}%` }}
            />
          </div>
          <div className={styles.info}>Uploading file</div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { file, files } = this.state;
    const {
      uploading,
      upload_complete,
      image,
      className,
      acceptAllTypes,
      disabled,
    } = this.props;
    const readyToUpload =
      (files.length || file) && !(!upload_complete || uploading);
    const acceptedTypes = acceptAllTypes ? '' : 'image/*';
    const dropzoneProps = {
      className: [styles.dropzone, className].join(' '),
      activeClassName: styles.dropzoneActive,
      rejectClassName: styles.dropzoneReject,
      ref: 'dropzone',
      disableClick: true,
      multiple: false,
      disabled: uploading || !upload_complete || disabled,
      accept: acceptedTypes,
      onDrop: this.onFileDrop,
    };

    const info = (
      <div className={styles.body}>
        <div className={styles.hintIcon}>
          {<i className={icons['icon-upload']} />}
        </div>
        <div className={styles.info}>
          <span>Drag &amp; drop a file here to upload</span>
        </div>
      </div>
    );

    let selectText = 'select a file';
    if (image) {
      selectText = 'select a new file';
    }

    const selectFileButton = (
      <button
        className={[styles.selectFile, buttonStyle.button].join(' ')}
        onClick={this.onSelectFileButton}
        disabled={disabled}
      >
        <span className={styles.or}>or </span> {selectText}
      </button>
    );

    const removeImageButton = (
      <div className={styles.remove} onClick={this.onRemoveImageButtonClick}>
        <i className={icons['icon-close']} /> remove
      </div>
    );

    const uploadButton = (
      <button
        className={[
          styles.selectFile,
          buttonStyle.button,
          buttonStyle.cta,
          buttonStyle.green,
        ].join(' ')}
        onClick={this.onUpload}
      >
        <i className={icons['icon-upload']} /> upload
      </button>
    );

    return (
      <div className={styles.uploadWrapper}>
        {this.getFilesPreview()}
        <Dropzone {...dropzoneProps}>
          {!file && !image && info}
          {!readyToUpload && !file && !uploading && selectFileButton}
          {this.getImagePreview()}
          {readyToUpload && removeImageButton}
          {this.getProgressBar()}
          {readyToUpload && uploadButton}
        </Dropzone>
      </div>
    );
  }
}

FileUploadSingle.propTypes = {
  onFile: PropTypes.func,
  onFileChange: PropTypes.func,
  uploading: PropTypes.bool,
  upload_progress: PropTypes.number,
  upload_complete: PropTypes.bool,
  image: PropTypes.string,
  className: PropTypes.string,
  acceptAllTypes: PropTypes.bool,
  fileDropCallback: PropTypes.func,
};

FileUploadSingle.defaultProps = {
  onFileChange: () => {},
  uploading: false,
  upload_progress: 0,
  upload_complete: false,
  image: null,
  className: '',
  acceptAllTypes: false,
  fileDropCallback: () => {
    return '';
  },
};

export default FileUploadSingle;
