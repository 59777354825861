import React from 'react';
import LumiForm from '../../../../../common/components/LumiForm';
import LumiField from '../../../../../common/components/LumiField';
import LumiButton from '../../../../../common/components/LumiButton';
import {
  DocumentCollectionProgress,
  getNextPath,
} from '../../DocumentCollectionUtils';
import { mapFields } from '../../../../../common/utils/utils';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as styles from './About.scss';
import * as layoutStyles from '../../../../layouts/DocumentCollectionLayout/DocumentCollectionLayout.scss';
import { intersection } from 'lodash';

const path = 'about';
const aboutForm = 'about';

type PropTypes = {
  dispatch: any;
  application: any;
  documentProgress?: DocumentCollectionProgress;
  params: { id: string };
  updateProgress: (documentCaptureId: string, stages: string[]) => void;
  account?: any;
  saveApplication: (application: any, callback: (app: any) => void) => void;
  push: (path: string) => void;
};

type State = {
  loading: boolean;
};

const fields: { [key: string]: any } = {
  'time-in-business': {
    name: 'time_in_business',
    placeholder: 'E.g. 6',
    wrapperClassName: 'lm-mt-2 lm-w-full',
    component: LumiField,
    type: 'mask',
    extras: {
      mask: createNumberMask({ prefix: '', includeThousandsSeparator: false }),
    },
    validate: (value: any) => (!value ? 'Required' : null),
  },
  about: {
    name: 'about',
    placeholder:
      'E.g. I run a cafe in Surry Hills and I am looking to purchase a coffee machine to expand business...',
    wrapperClassName: styles.aboutFormField,
    component: LumiField,
    validate: (value: any) => (!value ? 'Required' : null),
    extras: { isArea: true },
  },
};

class About extends React.Component<PropTypes, State> {
  handleSubmit = (form: any) => {
    const {
      application,
      saveApplication,
      documentProgress,
      updateProgress,
      params,
      push,
    } = this.props;
    const requiredInfoKeys = Object.keys(documentProgress.stages) || [];
    const stageNames = intersection(requiredInfoKeys, [
      'about',
      'time-in-business',
    ]);

    const nextPath = getNextPath(path, documentProgress.stages);
    const progressThrough = () => {
      updateProgress(documentProgress.document_capture_id, stageNames);
      push(`/app/documents/${params.id}/${nextPath}`);
    };
    if (application && application.id) {
      const updatedApplication: any = {
        id: application.id,
      };
      const appendAppInfo = (innerKey: string, obj: any) =>
        (updatedApplication[innerKey] = {
          ...updatedApplication[innerKey],
          ...obj,
        });
      if (form.about) updatedApplication.customer_notes = form.about;
      if (form.time_in_business)
        appendAppInfo('additional_info', {
          time_in_business_months: form.time_in_business,
          trading_time_months: form.time_in_business,
        });
      saveApplication(updatedApplication, () => false);
    }
    progressThrough();
  };

  render() {
    const { application, documentProgress } = this.props;
    const requiredInfoKeys = Object.keys(documentProgress.stages) || [];

    const initialValues = {
      about: application.customer_notes,
      time_in_business: application.additional_info?.time_in_business_months,
    };

    return (
      <div className={layoutStyles.documentCapturePage}>
        <div className={layoutStyles.documentCaptureBox}>
          <LumiForm
            name={aboutForm}
            initialValues={initialValues}
            warnUnsaved={false}
            onSubmit={this.handleSubmit}
            className={styles.identityForm}
            clientForm
            touchOnBlur={false}
          >
            <div className={layoutStyles.innerBox}>
              <h1>A bit about your business...</h1>
              {requiredInfoKeys.includes('about') && (
                <div>
                  <span>
                    Please describe your business and its operations. Important
                    details to include:
                  </span>
                  <div className={styles.requirementBullet}>
                    What does your business do?
                  </div>
                  <div className={styles.requirementBullet}>
                    How will you use the funds?
                  </div>
                  {mapFields([fields['about']])}
                </div>
              )}
              {requiredInfoKeys.includes('time-in-business') && (
                <div style={{ marginTop: '6px' }}>
                  <span>
                    How many months has your business been trading for?
                  </span>
                  {mapFields([fields['time-in-business']])}
                </div>
              )}
            </div>
            <div className={layoutStyles.nextButton}>
              <LumiButton rounded phat type='submit' label='NEXT' />
            </div>
          </LumiForm>
        </div>
      </div>
    );
  }
}

export default About;
