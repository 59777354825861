import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import ConnectBankAccount from '../../../../../common/components/ConnectBankAccount';
import * as analyticsUtils from '../../../../utils/analytics';
import cardStyle from '../../../../../common/sass/components/_card.scss';
import Button from '../../../../components/button';
import HelpIcon from '../../../../images/help.svg';
import { getUrl } from '../../../../../common/utils/utils';
import HelpCard from '../../../../components/help_card';

class BankingInfo extends Component {
  componentDidMount() {
    analyticsUtils.track('leads', {
      action: 'financials_started',
      platform: 'website',
      funnel_step: analyticsUtils.funnel_step(
        'banking_info',
        analyticsUtils.funnel_step_version(),
      ),
      version: analyticsUtils.funnel_step_version(),
    });
  }

  render() {
    const redirectUrl = getUrl('/app/banking_info/landing');
    return (
      <div className={cardStyle.pageCard}>
        <HelpCard />
        <ConnectBankAccount
          referenceId={this.props.application.account_id}
          redirectUrl={redirectUrl}
        />
      </div>
    );
  }
}

BankingInfo.propTypes = {
  application: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    account_id: state.user.account_id,
    application: state.user.application,
  };
}

export default connect(mapStateToProps)(BankingInfo);
