/**
 * Note: all references to process.env only work in local or during the
 * CI build process
 */
import adminConfig from './admin/lumi.admin.config';
import brokerConfig from './broker/lumi.broker.config';
import buzzConfig from './buzz/lumi.buzz.config';
import chimeraConfig from './chimera/lumi.chimera.config';
import clientConfig from './client/lumi.client.config';

const ENVIRONMENT = process.env.NODE_ENV || 'local';
const devVersion = process.env.DEV_VERSION;
if (
  ENVIRONMENT !== 'test' &&
  ENVIRONMENT !== 'local' &&
  !devVersion &&
  (!process.env.PULSE_DOMAIN_NAME || !process.env.PUBLIC_DOMAIN_NAME)
) {
  throw new Error('domain name is not set');
}

const CLIENT_APPLICATION = process.env.CLIENT_APPLICATION;
const CLIENT_APP_URL_SLUG =
  process.env.CLIENT_APP_URL_SLUG || CLIENT_APPLICATION;

// For staging, production, devdeploy and Puppeteer
const scheme = {
  httpProtocol: 'https',
  websockProtocol: 'wss',
};

// staging and production will get hostnames from environment variables;
// localhost and test don't have env vars, will use default values
let privateAppHost = process.env.PULSE_DOMAIN_NAME || 'localhost:3030';
let privateApiHost = process.env.PULSE_DOMAIN_NAME || 'localhost:3030';
let publicAppHost = process.env.PUBLIC_DOMAIN_NAME || 'localhost:7070';
let publicApiHost = process.env.PUBLIC_DOMAIN_NAME || 'localhost:5000';
let HUBSPOT_PORTAL_ID = '6212581';
let AUTH0_CLIENT_ID = 'cVBU2ky1srYi0OXqRobyhFSmjrxumFrJ';
let AUTH0_API_IDENTIFIER = 'http://localhost:3000/';
let AUTH0_MANAGEMENT_DOMAIN = 'dev-cf34dn8woy6jlxqi.us.auth0.com';

// staging
if (ENVIRONMENT === 'staging') {
  AUTH0_CLIENT_ID = '1LH8lCI8Yqdsw27HXKJQwXizupTx6cpl';
  AUTH0_API_IDENTIFIER = 'https://staging.lumi.com.au/';
}

// devdeploy; Devdeploy should come after staging as ENVIRONMENT is "staging" too.
if (devVersion) {
  privateAppHost = `${devVersion}.lumi-tech.dev`;
  privateApiHost = privateAppHost;
  publicAppHost = privateAppHost;
  publicApiHost = publicAppHost;
  AUTH0_CLIENT_ID = 'eO2JAkY0lIXmrFTeUHuJqLstG9OcmDFU';
  AUTH0_API_IDENTIFIER = 'https://auth0.lumi-tech.dev/';
}

// production
if (ENVIRONMENT === 'production') {
  HUBSPOT_PORTAL_ID = '5718736';
  AUTH0_CLIENT_ID = 'UPJeZkr32iL8pgaqR4wl6rGGf3U1Fxl8';
  AUTH0_API_IDENTIFIER = 'https://www.lumi.com.au/';
  AUTH0_MANAGEMENT_DOMAIN = 'lumi.au.auth0.com';
}

// Puppeteer must use an internal domain name to capture loan assessment history page
const isPuppeteerInsideEKSCluster = /private-client-server/.test(
  window.location.hostname,
);
// No need to change publicAppHost and publicApiHost for Puppeteer as these two are not used at all.
if (isPuppeteerInsideEKSCluster) {
  privateAppHost = `private-client-server.${ENVIRONMENT}.svc.cluster.local:8081`;
  privateApiHost = `private-api.${ENVIRONMENT}.svc.cluster.local:8081`;
}

// localhost and test
if (!devVersion && !['staging', 'production'].includes(ENVIRONMENT)) {
  scheme.httpProtocol = 'http';
  scheme.websockProtocol = 'ws';
}

const DATABRICKS_TIME_WARNING_START =
  process.env.DATABRICKS_TIME_WARNING_START || '12.00am';
const DATABRICKS_TIME_WARNING_END =
  process.env.DATABRICKS_TIME_WARNING_END || '10.10am';
const SOFT_CREDIT_SCORE_THRESHOLD =
  process.env.SOFT_CREDIT_SCORE_THRESHOLD || 400;
const DAYS_WARNING_BEFORE_LOC_EXPIRY =
  parseInt(process.env.DAYS_WARNING_BEFORE_LOC_EXPIRY, 10) || 30;
const DAYS_TO_SHOW_APPROVED_FILE_REVIEW_NOTIFICATIONS =
  parseInt(process.env.DAYS_TO_SHOW_APPROVED_FILE_REVIEW_NOTIFICATIONS, 10) ||
  14;
const MINIMUM_LOAN_AMOUNT_MONTHLY_TURNOVER =
  parseInt(process.env.MINIMUM_LOAN_AMOUNT_MONTHLY_TURNOVER, 10) || 10000;
const MAXIMUM_LOAN_AMOUNT =
  parseInt(process.env.MAXIMUM_LOAN_AMOUNT, 10) || 750_000;

const TWILIO_SMS_REQUEST_NUMBER =
  process.env.TWILIO_SMS_REQUEST_NUMBER || '0440131643';

type AllPossibleOverrides = Partial<typeof adminConfig> &
  Partial<ReturnType<typeof clientConfig>> &
  Partial<ReturnType<typeof brokerConfig>> &
  Partial<ReturnType<typeof buzzConfig>> &
  Partial<ReturnType<typeof chimeraConfig>>;

const getOverrides = (
  environment: string,
  clientAppName: string,
): AllPossibleOverrides | undefined => {
  const configByClientApp = {
    admin: adminConfig,
    client: clientConfig(environment),
    broker: brokerConfig(environment, devVersion),
    buzz: buzzConfig(environment, privateAppHost),
    chimera: chimeraConfig(environment),
  };

  if (clientAppName in configByClientApp) {
    return configByClientApp[clientAppName as keyof typeof configByClientApp];
  }
  return undefined;
};

const lumiEnvironment = {
  SERVER_HOST: `${scheme.httpProtocol}://${privateAppHost}`,
  ADMIN_PUBLIC_SERVER_HOST: `${scheme.httpProtocol}://${publicAppHost}`, // need public server url from private app
  PUBLIC_SERVER_HOST: `${scheme.httpProtocol}://${publicApiHost}/public`,
  PUBLIC_APP_HOST: `${scheme.httpProtocol}://${publicAppHost}`,
  NODE_ENVIRONMENT: ENVIRONMENT,
  IS_LOCAL: ENVIRONMENT === 'local',
  IS_DEVELOPMENT: ENVIRONMENT === 'staging',
  IS_TEST: ENVIRONMENT === 'test',
  IS_PRODUCTION: ENVIRONMENT === 'production',
  API_URL: `${scheme.httpProtocol}://${privateApiHost}/api`,
  GRAPHQL_URL: `${scheme.httpProtocol}://${privateApiHost}/graphql`,
  PUBLIC_API_URL: `${scheme.httpProtocol}://${publicApiHost}/public/api`,
  PUBLIC_GRAPHQL_URL: `${scheme.httpProtocol}://${publicApiHost}/public/graphql`,
  LEADS_API_URL: `${scheme.httpProtocol}://${publicApiHost}/v1/leads`,
  GRAPHQL_SUBSCRIPTIONS_URL: `${scheme.websockProtocol}://${privateApiHost}/graphql`,
  BASENAME: process.env.BASENAME || '',
  CLIENT: CLIENT_APPLICATION,
  AUTH0_CLIENT_ID,
  AUTH0_API_IDENTIFIER,
  AUTH0_MANAGEMENT_DOMAIN,
  APM_HOST:
    'https://a2ed2c5d4b2a4ea8bfab107563c7bb43.apm.ap-southeast-2.aws.cloud.es.io:443',
  APM_LOCAL: Boolean(process.env.APM_LOCAL_SUFFIX),
  APM_LOCAL_SUFFIX: process.env.APM_LOCAL_SUFFIX,

  ...getOverrides(ENVIRONMENT, CLIENT_APPLICATION),

  /**
   * The part of the URL path that identifies the client app. For example, in
   * 'http://.../admin', the CLIENT_APP_URL_SLUG would be "admin"
   */
  CLIENT_APP_URL_SLUG,

  LOCAL_CLIENT: 'http://localhost:8080',
  LOCAL_CHIMERA: 'http://localhost:9090',
  HUBSPOT_PORTAL_ID,
  DATABRICKS_TIME_WARNING_START,
  DATABRICKS_TIME_WARNING_END,
  SOFT_CREDIT_SCORE_THRESHOLD,
  MINIMUM_LOAN_AMOUNT_MONTHLY_TURNOVER,
  MAXIMUM_LOAN_AMOUNT,
  RECAPTCHA_SITE_KEY:
    ENVIRONMENT === 'production'
      ? '6LfjYvEpAAAAAMU34JIS2hVVyr0a_PqBsPxsR5AM'
      : '6LdqYvEpAAAAACLso0eWSihRgrUKVPp8N98SsUSo',
  DAYS_WARNING_BEFORE_LOC_EXPIRY,
  DAYS_TO_SHOW_APPROVED_FILE_REVIEW_NOTIFICATIONS,
  TWILIO_SMS_REQUEST_NUMBER,
};

if (ENVIRONMENT !== 'production' && ENVIRONMENT !== 'test') {
  console.log(`Client Environments: ${JSON.stringify(lumiEnvironment)}`);
}

export default lumiEnvironment;
