export const getNumber = str => {
  const val = (str || '')
    .toString()
    .trim()
    .replace(/\$/g, '')
    .replace(/\,/g, '');
  return parseFloat(val);
};

export const validateABN = val => {
  let viewValue = val && val.replace(/\s+/g, '');

  let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  // Only check if the viewValue is defined
  if (typeof viewValue !== 'undefined') {
    // strip anything other than digits
    let abn = viewValue.replace(/[^0-9]+/g, '');

    // Trim any extra digits after 11
    if (abn.length > 11) {
      abn = abn.substring(0, 11);
    }
    // abn is always 11 digits long
    if (abn.length === 11) {
      // apply ato check method
      let sum = 0;
      let digit = null;
      for (var i = 0; i < weights.length; i++) {
        digit = parseInt(abn.substring(i, i + 1) - (i ? 0 : 1), 10);
        sum += weights[i] * digit;
      }
      if (sum % 89 === 0) {
        return true;
      }
    } else {
      return abn.length === 9; // For now allow any digit based 9 length ACN.
    }
  }
  return false;
};

export const validateDriverLicense = number => {
  console.log('validateDriverLicense(' + number + ')');
  let str = number;
  let characters = 0;
  let numerics = 0;
  if (str.length > 3 && str.length < 10) {
    for (let i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 47 && str.charCodeAt(i) < 58) {
        numerics++;
      } else {
        characters++;
      }
    }
    if (characters > 2 && numerics < 4) {
      return false;
    } else {
      if (
        str.charCodeAt(2) > 47 &&
        str.charCodeAt(2) < 58 &&
        str.charCodeAt(3) > 47 &&
        str.charCodeAt(3) < 58
      ) {
        return true;
      } else {
        console.log('3rd and 4th position must be numeric in Driver License');
        return false;
      }
    }
  } else {
    console.log('drivers_license_number should be 4-9 digits long!!!');
    return false;
  }
};

export const validateMonth = month => {
  return month >= 1 && month <= 12;
};
