import React, { useState, Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  getDocumentCaptureProgress,
  updateDocumentCapture as updateDocumentCaptureAction,
  IUpdateDocCaptureRequest,
} from '../../layouts/actions/DocumentCaptureLayoutActions';
import { isQueryLoading } from '../../../common/utils/graphql_utils';
import { useMutation } from 'react-query';
import { useMemo } from 'react';

import About from '../../../junction/assets/about.svg';
import Coins from '../../../junction/assets/coins.svg';
import Company from '../../../junction/assets/company.svg';
import Identity from '../../../junction/assets/identity.svg';
import Statement from '../../../junction/assets/statement.svg';
import BankStatement from '../../../junction/assets/bankstatement.svg';
import { queryCache } from 'react-query';
import BankingSkeleton from '../../routes/DocumentCapture/Banking/views/BankingSkeleton';
import { useLocation } from 'react-router-dom';
import { last } from 'lodash';

export type FormValuesToStageMapping = Partial<{
  [key in DocumentCaptureStages]: any;
}>;

export type DocumentCaptureStages =
  | 'full-name'
  | 'abn'
  | 'date-of-birth'
  | 'has-property'
  | 'veda-consent'
  | 'financial-statements'
  | 'bank-feeds'
  | 'company-website'
  | 'about'
  | 'about-your-business'
  | 'loan-amount'
  | 'drivers-license'
  | 'drivers-license-back'
  | 'other-documents'
  | 'other-loans'
  | 'ato-balance'
  | 'mobile-number'
  | 'email-address'
  | 'home-address'
  | 'business-address'
  | 'business-activity'
  | 'properties'
  | 'social-links'
  | 'monthly-turnover'
  | 'industry-code'
  | 'credit-limit'
  | 'monthly-spend-partner'
  | 'annual-turnover'
  | 'purchase-purpose'
  | 'refinance-loan-purpose'
  | 'refinance-business-changes'
  | 'has-changed'
  | 'has-changed-reason'
  | 'accounts-payable'
  | 'accounts-payable-phone'
  | 'accounts-payable-email'
  | 'accounts-payable-full-name'
  | 'previous-invoice'
  | 'trust-deed'
  | 'idv-send-link'
  | 'idv-await-result';

export type TRouteStageMap = Record<
  string,
  {
    stages: DocumentCaptureStages[];
    icon: React.ReactNode;
    label: React.ReactNode;
    supplementaryRoutes?: string[];
    skeleton?: React.ReactNode;
    condition?: ({ documentCapture }: any) => boolean;
  }
>;

export const RouteStageMap: TRouteStageMap = {
  welcome: {
    stages: [
      'abn',
      'veda-consent',
      'has-property',
      'other-loans',
      'ato-balance',
      'full-name',
      'mobile-number',
      'home-address',
      'financial-statements',
      'bank-feeds',
      'loan-amount',
      'business-address',
      'monthly-turnover',
      'industry-code',
      'about',
      'drivers-license',
      'drivers-license-back',
      'date-of-birth',
      'credit-limit',
      'monthly-spend-partner',
      'annual-turnover',
    ],
    icon: null,
    label: null,
    condition: ({ documentCapture }: any) => {
      if (
        documentCapture.request_source &&
        documentCapture.request_source === 'Onboarding'
      ) {
        return false;
      }
      return true;
    },
  },
  'about-you': {
    stages: [
      'veda-consent',
      'has-property',
      'properties',
      'date-of-birth',
      'other-loans',
      'ato-balance',
      'full-name',
      'mobile-number',
      'home-address',
      'email-address',
    ],
    icon: About,
    label: () => (
      <Fragment>
        About
        <br />
        You
      </Fragment>
    ),
  },
  financials: {
    stages: ['financial-statements'],
    icon: Statement,
    label: () => (
      <Fragment>
        Financial
        <br />
        Statements
      </Fragment>
    ),
  },
  banking: {
    stages: ['bank-feeds'],
    icon: BankStatement,
    supplementaryRoutes: ['banking-landing'],
    label: () => (
      <Fragment>
        Bank
        <br />
        Statements
      </Fragment>
    ),
    skeleton: <BankingSkeleton placeholder />,
  },
  'loan-info': {
    stages: [
      'loan-amount',
      'credit-limit',
      'refinance-loan-purpose',
      'purchase-purpose',
    ],
    icon: Coins,
    label: () => (
      <Fragment>
        Loan
        <br />
        Information
      </Fragment>
    ),
  },
  'about-your-business': {
    stages: [
      'business-address',
      'monthly-turnover',
      'annual-turnover',
      'abn',
      'industry-code',
      'about',
      'business-activity',
      'about-your-business',
      'social-links',
      'company-website',
      'refinance-business-changes',
    ],
    icon: <Company className='lm-text-white' />,
    label: () => (
      <Fragment>
        Your
        <br />
        Business
      </Fragment>
    ),
  },
  'driver-licence': {
    stages: ['drivers-license', 'drivers-license-back', 'date-of-birth'],
    icon: Identity,
    label: () => (
      <Fragment>
        Your
        <br />
        Identity
      </Fragment>
    ),
  },
  'other-documents': {
    stages: ['other-documents'],
    icon: BankStatement,
    label: () => (
      <Fragment>
        Other
        <br />
        Documents
      </Fragment>
    ),
  },
  'trust-deed': {
    stages: ['trust-deed'],
    icon: BankStatement,
    label: () => (
      <Fragment>
        Trust
        <br />
        Deed
      </Fragment>
    ),
  },
  confirmation: {
    stages: [
      'abn',
      'veda-consent',
      'has-property',
      'other-loans',
      'ato-balance',
      'full-name',
      'mobile-number',
      'home-address',
      'financial-statements',
      'bank-feeds',
      'loan-amount',
      'business-address',
      'monthly-turnover',
      'industry-code',
      'about',
      'drivers-license',
      'drivers-license-back',
      'date-of-birth',
      'credit-limit',
      'monthly-spend-partner',
      'annual-turnover',
    ],
    icon: null,
    label: null,
  },
  success: {
    stages: [],
    icon: null,
    label: null,
  },
};

export type DocumentCaptureStageProgress = {
  [key in DocumentCaptureStages]: { completed_at: Date; value: any };
};

export type DocumentCaptureProgressStageUpdate = {
  name: DocumentCaptureStages;
  value?: any;
};

export const documentCaptureProgressStage = (
  currentPageStages: Partial<DocumentCaptureStages>[],
  values: any,
  dirtyFields?: any,
): Partial<DocumentCaptureProgressStageUpdate[]> => {
  const progress = Object.values(currentPageStages).map(key => {
    if (dirtyFields && !dirtyFields[key]) return;
    const stage: DocumentCaptureProgressStageUpdate = {
      name: key,
      value: values[key],
    };

    return stage;
  });
  return progress.filter(p => p);
};

export type DocumentCaptureStateContext = [
  {
    stages: DocumentCaptureStageProgress;
    loading: boolean;
    currentPageStages: Partial<DocumentCaptureStages>[];
    account: any;
    accountDetails: any;
    documentCaptureId: string;
    documentCapture: any;
    isUpdatingDetails: boolean;
    documentCaptureProgressId: string;
    dealId: string;
    /** This is not the only source of server-side error messages.
     * If the component referencing this model has specific network requests,
     * apart from those defined in this model, the component needs to check for errors on
     * those requests as well, and handle accordingly.
     */
    serverError: any;
    otherDocuments: string[];
  },
  (request: IUpdateDocCaptureRequest) => any,
  Function,
  Function,
  Function,
  (options?: any) => void,
];

export const friendlyErrorMap = {
  // This is temporary. In future each error should have its own key from the server that maps to this
  'generic-error':
    'Sorry, something went wrong with the form. Please try again, or contact us at 1300 00 LUMI for help.',
  'file-upload':
    'Sorry, something went wrong with the file. Please try again, or contact us at 1300 00 LUMI for help.',
};

type RouterFuncs = {
  /**
   *   Accept a pushRoute function through the args here so that we can have a
   *   platform agnostic way of changing the route. Since react and react-native
   *   implement their own history api
   */
  pushRoute: (route: string, redirect?: boolean) => void;
};

export function useDocumentCaptureLayoutViewModel(
  id: string,
  routerFuncs?: RouterFuncs,
): DocumentCaptureStateContext {
  const {
    status,
    data: progressResponse,
    refetch: refetchProgress,
    isFetching,
  } = useQuery(['progress', id], getDocumentCaptureProgress);
  const [currentPageStages, setCurrentPageStages] = useState(
    [] as DocumentCaptureStages[],
  );
  const loading = isQueryLoading(status);
  const progress = progressResponse?.document_capture_progress;
  const stages = useMemo(() => progress?.stages || {}, [progress?.stages]);
  const dealId = progressResponse?.document_capture?.deal_id;
  const documentCaptureId = progressResponse?.document_capture?.id;
  const documentCaptureProgressId = progress?.id;
  const otherDocuments = progress?.other_documents;
  const location = useLocation();

  const [
    updateDocumentCapture,
    { status: updateDocumentCaptureStatus, error: documentCaptureError },
  ] = useMutation(updateDocumentCaptureAction, {
    onSuccess: () => queryCache.invalidateQueries('progress'),
    throwOnError: true,
    onError: (err: any) => {
      err.identifier = 'generic-error';
    },
  });

  const docCapture = progressResponse?.document_capture;
  useEffect(() => {
    const isSuccessPage =
      last((location?.pathname || '').split('/')) === 'success';
    // If the doc capture has expired, then redirect to the expiry page
    if (docCapture?.id && !docCapture.active && !isSuccessPage) {
      routerFuncs?.pushRoute(`/hub/document-capture/expired`, true);
    }
  }, [docCapture?.id, docCapture.active, location?.pathname, routerFuncs]);

  const memoizedViewModel = useMemo(
    () => [
      {
        loading,
        documentCaptureId,
        documentCaptureProgressId,
        documentCapture: progressResponse?.document_capture,
        dealId,
        stages,
        currentPageStages,
        isUpdatingDetails: isQueryLoading(updateDocumentCaptureStatus),
        serverError: documentCaptureError,
        otherDocuments,
      },
      updateDocumentCapture,
      setCurrentPageStages,
      refetchProgress,
    ],
    [
      loading,
      currentPageStages,
      documentCaptureId,
      documentCaptureProgressId,
      progressResponse?.document_capture,
      refetchProgress,
      stages,
      updateDocumentCapture,
      dealId,
      updateDocumentCaptureStatus,
      documentCaptureError,
      otherDocuments,
    ],
  );
  return memoizedViewModel as DocumentCaptureStateContext;
}
