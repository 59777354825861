import React, { Component } from 'react';
import PropTypes from 'prop-types';
import icons from '../sass/icons/lumi-icon.scss';
import { Field } from 'redux-form';
import style from '../sass/components/_upload_line.scss';
import UploadButton from './UploadButton';
import Tooltip from 'junction/components/tooltip/Tooltip';
import Question from 'junction/assets/pink-question';

const getFieldRenderer = prop => {
  return fieldData => {
    const {
      name,
      label,
      onChange,
      isUploading,
      progress,
      subLabel,
      disabled,
      tooltipMessage,
    } = prop;
    const emptyFunc = () => ({});
    const input = fieldData.input || fieldData;
    const hasFile = input.value && input.value.length;
    return (
      <div className={style.container}>
        <div className={style.label}>
          <div className={style.indicator}>
            {hasFile && <i className={icons['icon-expand']} />}
            {!hasFile && <i className={style.bullet} />}
          </div>
          {label}
          {tooltipMessage && (
            <Tooltip placement='right'>
              <Tooltip.Trigger
                useLegacyAlignment
                ariaLabel='Bank feeds tooltip'
                className='lm-p-3 lm-cursor-pointer lm-outline-none lm-focus-yellow'
              >
                <Question />
              </Tooltip.Trigger>
              <Tooltip.Content className='lm-max-w-88'>
                {tooltipMessage}
              </Tooltip.Content>
            </Tooltip>
          )}
          <div className={style.subLabel}>{subLabel}</div>
        </div>
        <UploadButton
          onChange={onChange || emptyFunc}
          fieldData={prop.fieldData}
          name={name}
          isUploading={isUploading}
          disabled={disabled}
          progress={progress}
        />
      </div>
    );
  };
};

class FileUploadLine extends Component {
  componentWillMount() {
    this.fieldRenderer = getFieldRenderer(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fieldRenderer = getFieldRenderer(nextProps);
  }

  render() {
    const { name, fieldData } = this.props;
    // Support name and fieldData for different versions of redux form
    if (fieldData) {
      return this.fieldRenderer(fieldData);
    } else {
      return <Field name={this.props.name} component={this.fieldRenderer} />;
    }
  }
}

/* eslint-disable react/no-unused-prop-types */
FileUploadLine.propTypes = {
  fieldData: PropTypes.object,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  onChange: PropTypes.func,
  isUploading: PropTypes.bool,
  name: PropTypes.string,
  progress: PropTypes.number,
  disabled: PropTypes.bool,
};

export default FileUploadLine;
