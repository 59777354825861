import React from 'react';

export default (store: any) => ({
  path: 'thanks',
  enableHeader: false,
  getComponent(nextState: any, cb: any) {
    const Thanks = React.lazy(() => import('./Thanks'));
    cb(null, Thanks);
  },
});
