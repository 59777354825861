import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styles from '../sass/components/_duration.scss';
import inputStyles from '../sass/components/_input.scss';
import icons from '../sass/icons/lumi-icon.scss';
import number from '../sass/components/_number.scss';

const getFieldRenderer = prop => {
  return fieldData => {
    const { label, subLabel } = prop;
    const meta = fieldData.meta || fieldData;

    let error = '';
    if (meta.touched && meta.error) {
      error = meta.error;
    }

    const renderItem = val => {
      const theVal = Math.round(val * 4.34524).toString();

      return (
        <div className={number.numberWrapper} key={val}>
          <input
            type='radio'
            id={`duration-${val}`}
            name='duration'
            value={theVal}
            checked={
              theVal === ((fieldData.input || fieldData).value || '').toString()
            }
            onChange={(fieldData.input || fieldData).onChange}
          />
          <label htmlFor={`duration-${val}`} className={number.number}>
            {val}
          </label>
        </div>
      );
    };
    return (
      <div className={styles.container}>
        <label>
          {label}
          <span>{subLabel}</span>
        </label>
        <div className={styles.picker}>
          <div className={styles.wrap}>
            {['3', '4', '5', '6', '7'].map(renderItem)}
          </div>
          <div className={styles.wrap}>
            {['8', '9', '10', '11', '12'].map(renderItem)}
          </div>
          <div className={styles.wrap}>
            {['13', '14', '15', '16', '17'].map(renderItem)}
          </div>
          <div className={styles.wrap}>{['18'].map(renderItem)}</div>
        </div>
        {!!error && (
          <div className={[inputStyles.errorLabel, 'mb-0 mt-4'].join(' ')}>
            <i className={icons['icon-exclaimation-triangle']} />
            {error}
          </div>
        )}
      </div>
    );
  };
};

class DurationPicker extends Component {
  componentWillMount() {
    this.fieldRenderer = getFieldRenderer(this.props);
  }

  render() {
    const { name, fieldData } = this.props;
    // Support name and fieldData for different versions of redux form
    if (fieldData) {
      return this.fieldRenderer(fieldData);
    } else {
      return (
        <Field name={this.props.name} required component={this.fieldRenderer} />
      );
    }
  }
}

/* eslint-disable react/no-unused-prop-types */
DurationPicker.propTypes = {
  fieldData: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
};

export default DurationPicker;
