import React from 'react';
import { serverURL } from '../utils/fetch_utils';

export const consentCopy = (
  <span>
    I have read and agree to the Lumi Funding
    <a href={`${serverURL}/tos`} target='_blank'>
      &nbsp;Terms of Service.&nbsp;
    </a>
    All information that I provide is current and accurate and I have authority
    to provide these details. I understand that Lumi Funding collects and will
    handle my personal information in accordance with its
    <a href={`${serverURL}/privacy_policy`} target='_blank'>
      &nbsp;Privacy Policy.
    </a>
    &nbsp;
    <br />
  </span>
);
