// Copied from admin app
import { upload } from '../../../common/utils/fetch_utils';
import { msgError } from '../../CommonCreators';
import { UPLOADING, UPLOAD_PROGRESS } from '../../redux/modules/ui';

function startUploading() {
  return { isUploading: true, type: UPLOADING };
}

function finishUploading() {
  return { isUploading: false, type: UPLOADING };
}

function setUploadingProgress(dispatch) {
  return event => {
    dispatch({
      progress: (event.loaded / event.total) * 100,
      type: UPLOAD_PROGRESS,
    });
  };
}

export function uploadIdDocument(
  docCaptureId,
  accountId,
  file,
  loanId,
  finishCallback,
) {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('account_id', accountId);
      if (loanId) formData.append('parent_id', loanId);
      formData.append('image', file);

      dispatch(startUploading());
      const data = await upload(
        '/identity/upload',
        {
          method: 'post',
          headers: { 'x-lumi-capture': docCaptureId },
          body: formData,
        },
        setUploadingProgress(dispatch),
      );

      if (!(data && data.success)) {
        throw new Error(data.error);
      }

      if (finishCallback) {
        finishCallback();
      }

      dispatch(finishUploading());
    } catch (err) {
      dispatch(finishUploading());
      dispatch(
        msgError(
          err.message ?? 'Error',
          `There was an error uploading the file, please try again`,
        ),
      );
    }
  };
}
