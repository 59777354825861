import React from 'react';
import { isAutoLayoutChild } from '../../../common/utils/animation_utils';
import { mergeClasses } from '../../../common/utils/utils';
import { Alignment, Justification, Orientation, Spacing } from '../../types';
import {
  responsiveAlignmentClassNamesByBreakpoint,
  responsiveJustificationClassNamesByBreakpoint,
  responsiveStackClassNamesByBreakpoint,
} from '../../utils';
import Container, { ContainerProps } from '../container/Container';
import {
  isResponsiveBreakpoint,
  ResponsiveBreakPoint,
  Screen,
} from '../layout.t';

type Props = {
  children: React.ReactNode[] | React.ReactNode;
  spacing?: Spacing;
  orientation?: Orientation | ResponsiveBreakPoint<Orientation>;
  className?: string;
  style?: any;
  align?: Alignment | ResponsiveBreakPoint<Alignment>;
  justify?: Justification | ResponsiveBreakPoint<Justification>;
  /**
   * Framer motion animation props
   */
  animation?: any;
} & ContainerProps;

const stackClassNamesBySpacing: Record<Spacing, string> = {
  l: 'lm-stack-l',
  m: 'lm-stack-m',
  r: 'lm-stack-r',
  s: 'lm-stack-s',
  xl: 'lm-stack-xl',
  xm: 'lm-stack-xm',
  xs: 'lm-stack-xs',
};

const getOrientationClassNames = (breakpoints: Props['orientation']) => {
  if (!breakpoints) return '';

  if (isResponsiveBreakpoint(breakpoints)) {
    return Object.keys(breakpoints)
      .map((screen: Screen) => {
        const value = breakpoints[screen];

        return responsiveStackClassNamesByBreakpoint[screen][value];
      })
      .filter(Boolean)
      .join(' ');
  }

  return responsiveStackClassNamesByBreakpoint.base[breakpoints];
};

const getAlignmentClassNames = (breakpoints: Props['align']) => {
  if (!breakpoints) return '';

  if (isResponsiveBreakpoint(breakpoints)) {
    return Object.keys(breakpoints)
      .map((screen: Screen) => {
        const value = breakpoints[screen];

        return responsiveAlignmentClassNamesByBreakpoint[screen][value];
      })
      .filter(Boolean)
      .join(' ');
  }

  return responsiveAlignmentClassNamesByBreakpoint.base[breakpoints];
};

const getJustificationClassNames = (breakpoints: Props['justify']) => {
  if (!breakpoints) return '';

  if (isResponsiveBreakpoint(breakpoints)) {
    return Object.keys(breakpoints)
      .map((screen: Screen) => {
        const value = breakpoints[screen];

        return responsiveJustificationClassNamesByBreakpoint[screen][value];
      })
      .filter(Boolean)
      .join(' ');
  }

  return responsiveJustificationClassNamesByBreakpoint.base[breakpoints];
};

export const Stack = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    spacing,
    orientation = 'vertical',
    className,
    style,
    align = 'initial',
    justify = 'initial',
    animation = {},
  } = props;

  const _orientation = getOrientationClassNames(orientation);

  const _spacing = stackClassNamesBySpacing[spacing];
  const isAutoLayout = isAutoLayoutChild();

  const alignment = getAlignmentClassNames(align);
  const justifyClass = getJustificationClassNames(justify);

  return (
    <Container
      {...props}
      animation={{ layout: isAutoLayout, ...animation }}
      style={style}
      innerRef={ref}
      className={mergeClasses(
        _orientation,
        _spacing,
        className,
        justifyClass,
        alignment,
      )}
    >
      {children}
    </Container>
  );
});

export default Stack;
