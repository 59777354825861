import { toast, ToastOptions } from 'react-toastify';
import styles from '../sass/components/_toast.scss';
import React from 'react';
import SuccessIcon from 'junction/components/feedback/assets/success.svg';
import ErrorIcon from 'junction/assets/close-circle.svg';
import WarningIcon from 'junction/assets/warning-triangle-outlined.svg';
import InfoIcon from 'junction/components/feedback/assets/info.svg';
import lumiEnvironment from '../../lumi.environment';

export enum TOAST_TYPES {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  NEUTRAL = 'NEUTRAL',
}

const getToastOptions = (toastType: TOAST_TYPES): ToastOptions => {
  const isErrorOrWarningToast = [
    TOAST_TYPES.ERROR,
    TOAST_TYPES.WARNING,
  ].includes(toastType);

  const baseConfig: ToastOptions = {
    position: 'top-right',
    autoClose:
      isErrorOrWarningToast && lumiEnvironment.APP_NAME !== 'broker'
        ? false
        : 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressClassName: 'lm-bg-gray-primary lm-mb-4 lm-ml-4 lm-w-11/12',
  };

  let toastContainerClassName = `lm-text-base-navy lm-rounded-[5px] lm-border-2 lm-px-4 lm-pt-4 ${
    // more padding for auto close toasts to accommodate auto-close progress bar
    isErrorOrWarningToast && lumiEnvironment.APP_NAME !== 'broker'
      ? 'lm-pb-4'
      : 'lm-pb-9'
  }`;

  switch (toastType) {
    case TOAST_TYPES.SUCCESS:
      toastContainerClassName += ' lm-bg-green-100 lm-border-success';
      break;
    case TOAST_TYPES.ERROR:
      toastContainerClassName += ' lm-bg-red-100 lm-border-error';
      break;
    case TOAST_TYPES.WARNING:
      toastContainerClassName += ' lm-bg-orange-100 lm-border-warning';
      break;
    case TOAST_TYPES.NEUTRAL:
      toastContainerClassName += ' lm-bg-background lm-border-base-navy';
      break;
    default:
      toastContainerClassName += ' lm-bg-pink-background lm-border-base-pink';
      // default toast (by calling vanilla toast(...)) has default styles that override Tailwind
      // so we have to manually set these
      baseConfig.closeButton = false;
      baseConfig.progressClassName = styles.defaultToast;
  }

  return {
    ...baseConfig,
    className: toastContainerClassName,
  };
};

export const showToast = ({
  message,
  toastType,
  title,
}: {
  message: string | Error;
  toastType?: TOAST_TYPES;
  title?: string;
}) => {
  const toastOptions = getToastOptions(toastType);
  const toastContentProps = { title, message };

  switch (toastType) {
    case TOAST_TYPES.SUCCESS:
      toast.success(
        <ToastContent
          {...toastContentProps}
          icon={<SuccessIcon className='lm-text-success' />}
        />,
        toastOptions,
      );
      break;

    case TOAST_TYPES.ERROR:
      toast.error(
        <ToastContent
          {...toastContentProps}
          icon={<ErrorIcon className='lm-text-error lm-w-5 lm-h-5' />}
        />,
        toastOptions,
      );
      break;

    case TOAST_TYPES.WARNING:
      toast.warn(
        <ToastContent
          {...toastContentProps}
          icon={<WarningIcon className='lm-w-5 lm-h-5' />}
        />,
        toastOptions,
      );
      break;

    case TOAST_TYPES.NEUTRAL:
      toast.info(
        <ToastContent
          {...toastContentProps}
          icon={<InfoIcon className='lm-fill-current' />}
        />,
        toastOptions,
      );
      break;

    default:
      toast(<ToastContent {...toastContentProps} />, toastOptions);
  }
};

const ToastContent = ({
  title,
  message: messageStringOrError,
  icon,
}: {
  title?: string;
  message: string | Error;
  icon?: JSX.Element;
}) => (
  <div className='lm-flex lm-gap-2'>
    {/* wrapping div for icon is for it to take its full width and height
      otherwise, when message is too long, icon automatically shrinks 😕 */}
    {icon && <div>{icon}</div>}
    <div className='lm-flex lm-flex-col lm-gap-3 lm-w-[240px] lm-break-words'>
      {title && <span className='lm-font-demi'>{title}</span>}
      <span className='lm-font-proMedium'>
        {typeof messageStringOrError === 'string'
          ? messageStringOrError
          : messageStringOrError instanceof Error
          ? messageStringOrError.message
          : (messageStringOrError as unknown)?.toString()}
      </span>
    </div>
  </div>
);
