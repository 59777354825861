import { documentCaptureRetrieve } from '../DocumentCaptureActions';
import React from 'react';

export default (store: any) => ({
  path: 'about',
  onEnter: documentCaptureRetrieve(store),
  indexRoute: {
    getComponent(nextState: any, cb: any) {
      const About = React.lazy(() => import('./containers/AboutContainer'));
      cb(null, About);
    },
  },
});
