import { DocumentNode } from 'graphql';
import {
  MutationConfig,
  QueryConfig,
  QueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { Client, subscriptionExchange } from 'urql';
import { Auth0LocalStorageKey } from '../../admin/utils';
import lumiEnvironment from '../../lumi.environment';
import { getAuth0RedirectUrl, getToken } from './auth_utils';
import { apiPost, graphURL, publicGraphURL } from './fetch_utils';
import { TOAST_TYPES, showToast } from './toast';

class GraphQLError extends Error {
  errors: null;
  identifiers: any = {};

  constructor(message: string, errors: any) {
    super(message);
    this.name = 'GraphQLError';
    this.errors = errors;
    this.identifiers = (function () {
      return (errors || []).map((error: any) => {
        return error?.extensions?.identifier;
      });
    })();
  }
}

export type GraphQLResponse<T> = { data: T };

export type RequestOpts = {
  /**
   * Set this to 'true' to prevent the request from attempting to refresh the
   * user access token
   */
  noAuth?: boolean;

  /**
   * `true` if we should _not_ show a toast if the request returns an error
   */
  noErrorToast?: boolean;

  /**
   * `true` if we should ignore access denied errors
   */
  swallowAccessDeniedError?: boolean;

  headers?: { 'recaptcha-token'?: string };
};

const baseGraphQLApiRequest = async <TVariables>(
  query: string,
  requestOptions: RequestOpts,
  variables: TVariables,
  isPublicApi: boolean = false,
) => {
  const { noErrorToast, swallowAccessDeniedError, ...apiPostOptions } =
    requestOptions;

  const response = await apiPost(
    isPublicApi ? publicGraphURL : graphURL,
    { query, variables },
    null,
    apiPostOptions || {},
  );

  if (swallowAccessDeniedError) {
    response.errors = response.errors?.filter(
      (error: GraphQLError) =>
        error.message.startsWith('Access denied') === false,
    );
  }

  if (!response || response.errors?.length > 0) {
    console.error(`Error(s) Occurred:`, response.errors);

    if (
      (response.errors as any[]).find(err =>
        ['Token has expired', 'Not authenticated'].includes(err.message),
      )
    ) {
      localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
      localStorage.removeItem(Auth0LocalStorageKey);
      window.location.href = `${
        lumiEnvironment.LOGIN_PATH
      }?reason=401&redirectUrl=${getAuth0RedirectUrl()}`;
    }

    const fieldResolverErrors = (response?.errors || []).filter(
      (err: any) =>
        err?.extensions?.code === CustomGQLError.FIELD_RESOLVER_ERROR,
    );

    const otherErrorMessages = (response?.errors || [])
      .filter(
        (err: any) =>
          err?.extensions?.code !== CustomGQLError.FIELD_RESOLVER_ERROR,
      )
      .map((error: any) => error.message);

    if (!noErrorToast) {
      if (fieldResolverErrors.length > 0) {
        const failedFieldResolvers = fieldResolverErrors
          .map(
            (fieldResolverError: any) =>
              fieldResolverError.extensions.customFieldName,
          )
          .join(', ');

        showToast({
          message: `Something went wrong, failed to retrieve ${failedFieldResolvers}.
        If the problem persists, please contact tech support.`,
          toastType: TOAST_TYPES.ERROR,
        });
      }

      if (otherErrorMessages.length > 0) {
        showToast({
          message: `An error occurred. ${otherErrorMessages.join(', ')}`,
          toastType: TOAST_TYPES.ERROR,
        });
      }
    }

    if (otherErrorMessages.length > 0) {
      throw new GraphQLError(
        `An Error Occurred. ${otherErrorMessages.join(', ')}`,
        response.errors,
      );
    }
  }
  return response;
};

const graphQLQueryRequest =
  (query: string, requestOptions: RequestOpts = {}) =>
  <TVariables>(key: string, variables: TVariables) =>
    baseGraphQLApiRequest(query, requestOptions, variables);

const graphQLPublicQueryRequest =
  (query: string, requestOptions: RequestOpts = {}) =>
  <TVariables>(key: string, variables: TVariables) =>
    baseGraphQLApiRequest(query, requestOptions, variables, true);

const graphQLMutationRequest =
  (query: string, requestOptions: RequestOpts = {}) =>
  <TVariables>(variables: TVariables) =>
    baseGraphQLApiRequest(query, requestOptions, variables);

const graphQLPublicMutationRequest =
  (query: string, requestOptions: RequestOpts = {}) =>
  <TVariables>(variables: TVariables) =>
    baseGraphQLApiRequest(query, requestOptions, variables, true);

export const useGraphQuery = <TResult = any>(
  queryKey: any,
  gqlLiteral: any,
  options?: QueryConfig<TResult>,
  requestOptions?: RequestOpts,
) => {
  const query = gqlLiteral.loc.source.body;

  const result = useQuery<TResult>(
    queryKey,
    graphQLQueryRequest(query, requestOptions),
    options,
  );
  return result;
};

export const usePublicGraphQuery = <TResult = any>(
  queryKey: any,
  gqlLiteral: any,
  options?: QueryConfig<TResult>,
  requestOptions?: RequestOpts,
) => {
  const query = gqlLiteral.loc.source.body;

  return useQuery<TResult>(
    queryKey,
    graphQLPublicQueryRequest(query, requestOptions),
    options,
  );
};

/** This one return type for data, also filter out .data wrapper */
const graphQLQueryRequestClean = <TResult>(
  query: string,
  requestOptions: RequestOpts = {},
) => {
  const { noErrorToast, swallowAccessDeniedError, ...apiPostOptions } =
    requestOptions;

  return async <TVariables>(key: string, variables: TVariables) => {
    interface Response {
      data: TResult;
      errors: any;
    }
    const response: Response = await apiPost(
      graphURL,
      { query, variables },
      null,
      apiPostOptions,
    );

    if (swallowAccessDeniedError) {
      response.errors = response.errors?.filter(
        (error: GraphQLError) =>
          error.message.startsWith('Access denied') === false,
      );
    }

    if (!response || response.errors?.length > 0) {
      const errors = (response?.errors || []).map(
        (error: any) => error.message,
      );

      console.error(`Error(s) Ocurred:`, response.errors);
      if (!noErrorToast) {
        showToast({
          message: `An error occurred. ${errors}`,
          toastType: TOAST_TYPES.ERROR,
        });
      }

      if (
        (response.errors as any[]).find(err =>
          ['Token has expired', 'Not authenticated'].includes(err.message),
        )
      ) {
        localStorage.removeItem(lumiEnvironment.LOCAL_STORAGE_NAME);
        localStorage.removeItem(Auth0LocalStorageKey);
        window.location.href = `${
          lumiEnvironment.LOGIN_PATH
        }?reason=401&redirectUrl=${getAuth0RedirectUrl()}`;
      }

      throw new GraphQLError(`An Error Occurred ${errors}`, response.errors);
    }
    return response.data;
  };
};

/** Use this for proper typed */
export const useGraphQueryClean = <TResult>(
  queryKey: any,
  gqlLiteral: any,
  options?: QueryConfig<TResult> | QueryConfig<TResult>,
  requestOptions?: RequestOpts,
) => {
  const query = gqlLiteral.loc.source.body;
  return useQuery(
    queryKey,
    graphQLQueryRequestClean<TResult>(query, requestOptions),
    options,
  );
};

export const useGraphMutation = <
  TResults = any,
  TVariables extends object = Record<string, any>,
>(
  gqlLiteral: any,
  options?: MutationConfig<TResults, unknown, TVariables>,
  requestOptions: RequestOpts = {},
) => {
  const query = gqlLiteral.loc.source.body;
  return useMutation<TResults, unknown, TVariables>(
    graphQLMutationRequest(query, requestOptions),
    options,
  );
};

export const usePublicGraphMutation = <
  TResults = any,
  TVariables extends object = Record<string, any>,
>(
  gqlLiteral: any,
  options?: MutationConfig<TResults, unknown, TVariables>,
  requestOptions: RequestOpts = {},
) => {
  const query = gqlLiteral.loc.source.body;
  return useMutation<TResults, unknown, TVariables>(
    graphQLPublicMutationRequest(query, requestOptions),
    options,
  );
};

export const getSubscriptionClient = (): Client => {
  const subscriptionClient = new SubscriptionClient(
    lumiEnvironment.GRAPHQL_SUBSCRIPTIONS_URL,
    {
      reconnect: true,
      connectionParams: async () => ({
        token: await getToken(),
        requestedWith: lumiEnvironment.CLIENT,
      }),
    },
  );

  const client = new Client({
    url: lumiEnvironment.GRAPHQL_URL,
    exchanges: [
      subscriptionExchange({
        forwardSubscription: operation => subscriptionClient.request(operation),
      }),
    ],
  });

  return client;
};

type QueryStatus = 'loading' | 'error' | 'success' | 'idle';

export const isQueryLoading = (status: QueryStatus) => {
  return status === 'loading';
};

export const hasQueryErrored = (status: QueryStatus) => {
  return status === 'error';
};

export const hasQuerySucceeded = (status: QueryStatus) => {
  return status === 'success';
};

// * To use useGraphQuery hook in class component
// TODO: generalise it for mutation as well
export const UseGraphQueryWrapper = <T>({
  children,
  queryParams: { queryDocument, queryKeyName, queryVariables },
}: {
  children: (queryResult: QueryResult<T, unknown>) => JSX.Element;
  queryParams: {
    queryDocument: DocumentNode;
    queryKeyName: string;
    queryVariables?: object;
  };
}) => {
  const queryKey = queryVariables
    ? [queryKeyName, queryVariables]
    : [queryKeyName];

  const queryResult = useGraphQuery<T>(queryKey, queryDocument);

  return children(queryResult);
};

enum CustomGQLError {
  FIELD_RESOLVER_ERROR = 'FIELD_RESOLVER_ERROR',
}
