/**
 *  Name: LoanInfo
 *  Description:
 *  Factory used to asyncronously load the component using webpack's code spliting.
 *  This ensures that the component is only loaded once the current route matches the path
 */

import { ensureAuthenticated } from '../../../../utils/auth';
import React from 'react';
import { getRouteProgress, getExperimentGroup } from '../..';

export default (store: any) => ({
  path: 'variant/loan_info',
  progress: (group: string) =>
    getRouteProgress('/app/variant/loan_info', group || getExperimentGroup()),
  enableFooter: true,
  enableHeader: true,
  getComponent(nextState: any, cb: any) {
    const LoanInfo = React.lazy(() => import('./LoanInfoViewExperiment'));
    cb(null, LoanInfo);
  },
});
